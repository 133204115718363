


<div class="modal bs-example-modal-sm in" role="dialog" style="top: 0%; display: none; padding-right: 12px;"
  aria-hidden="false">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <span style="padding-left:10px;font-size:15px;">Logout</span>
      </div>
      <div class="modal-body"> Are you sure you want to logout ?</div>
      <div class="modal-footer"><a (click)="logOut();" class="btn btn-primary ">Yes</a><a href="javascript:;"
          class="btn btn-primary" data-dismiss="modal">No</a> </div>
    </div>
  </div>
</div>



<nav class="navbar navbar-expand navbar-light navbar-bg expand-nav-size">
  <a class="sidebar-toggle" (click)="togglesidebar(sidebarfalg)">
    <i class="hamburger align-self-center" ></i>
  </a>
  <ul class="navbar-nav navbarnav-padding">
    <li class="nav-item  dropdown">
       <div *ngIf="rsmlogin" class="btn logintextdiv-new d-none d-sm-inline-block">Regional Sales Manager Dashboard</div>
      <div *ngIf="feclogin" class="btn logintextdiv-new d-none d-sm-inline-block">Fleet Edge Champ Portal</div>
      <div *ngIf="nationallogin" class="btn logintextdiv-new d-none d-sm-inline-block">Fleetedge National Head Dashboard</div>
    </li>
  </ul>
  <div class="navbar-collapse collapse">
    <ul class="navbar-nav navbar-align">
      <li class="nav-item dropdown nav-icon-drop " style="display:none">
        <a class="nav-icon dropdown-toggle" href="#" id="alertsDropdown" data-bs-toggle="dropdown">
          <div class="position-relative">
            <!-- <i class="align-middle" data-feather="bell"></i> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
            fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-bell align-middle">
            <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
            <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0" aria-labelledby="alertsDropdown">
          <div class="dropdown-menu-header">
            0 New Notifications
          </div>
          <div class="list-group" style="display: none;">
            <a href="#" class="list-group-item">
              <div class="row g-0 align-items-center">
                <div class="col-2">
                  <i class="text-danger" data-feather="alert-circle"></i>
                </div>
                <div class="col-10">
                  <div class="text-dark">Update completed</div>
                  <div class="text-muted small mt-1">Restart server 12 to complete the update.</div>
                  <div class="text-muted small mt-1">2h ago</div>
                </div>
              </div>
            </a>
            <a href="#" class="list-group-item">
              <div class="row g-0 align-items-center">
                <div class="col-2">
                  <i class="text-warning" data-feather="bell"></i>
                </div>
                <div class="col-10">
                  <div class="text-dark">Lorem ipsum</div>
                  <div class="text-muted small mt-1">Aliquam ex eros, imperdiet vulputate hendrerit et.</div>
                  <div class="text-muted small mt-1">6h ago</div>
                </div>
              </div>
            </a>
            <a href="#" class="list-group-item">
              <div class="row g-0 align-items-center">
                <div class="col-2">
                  <i class="text-primary" data-feather="home"></i>
                </div>
                <div class="col-10">
                  <div class="text-dark">Login from 192.186.1.1</div>
                  <div class="text-muted small mt-1">8h ago</div>
                </div>
              </div>
            </a>
            <a href="#" class="list-group-item">
              <div class="row g-0 align-items-center">
                <div class="col-2">
                  <i class="text-success" data-feather="user-plus"></i>
                </div>
                <div class="col-10">
                  <div class="text-dark">New connection</div>
                  <div class="text-muted small mt-1">Anna accepted your request.</div>
                  <div class="text-muted small mt-1">12h ago</div>
                </div>
              </div>
            </a>
          </div>
          <div class="dropdown-menu-footer">
            <a href="" [routerLink]="['/rsmdashboard']" class="text-muted">Show all notifications</a>
          </div>
        </div>
      </li>
      
      <li class="nav-item dropdown">
        <a class="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#" data-bs-toggle="dropdown">
          <!-- <i class="align-middle" data-feather="settings"></i> -->
                        <svg xmlns="http://www.w3.org/2000/svg" 
                width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-settings align-middle">
                <circle cx="12" cy="12" r="3"></circle>
                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 
                0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 
                0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 
                0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 
                2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 
                0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 
                4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 
                0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 
                0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 
                1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 
                0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 
                0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                </svg>
        </a>

        <a class="nav-link dropdown-toggle d-none d-sm-inline-block" href="#" data-bs-toggle="dropdown">
          <img src="assets/images/user-pic-1.png" class="avatar img-fluid rounded-circle me-1" alt="CMP" /> <span class="text-dark">
            <span *ngIf="rsmlogin">RSM</span>
            <span *ngIf="feclogin">FEC</span>
            <span *ngIf="nationallogin" style="font-weight: 600;">National Head </span>
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <!-- <a class="dropdown-item" href="pages-profile.html">
            <svg xmlns="http://www.w3.org/2000/svg" 
              width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-user align-middle me-1">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
               <circle cx="12" cy="7" r="4"></circle>
              </svg>
            Profile</a> -->
          <a class="dropdown-item"  data-bs-toggle="modal" data-bs-target="#logout">
            <!-- <i class="align-middle me-1" data-feather="power"> -->
              <svg xmlns="http://www.w3.org/2000/svg" 
              width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-power align-middle me-1">
              <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
              <line x1="12" y1="2" x2="12" y2="12"></line>
              </svg>
            <!-- </i>  -->
            Sign out</a>
        </div>
        <!-- data-bs-toggle="modal" data-bs-target -->
      </li>
    </ul>
  </div>
</nav>

<div class="modal fade" id="logout" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="padding-bottom: 9%;">
        <div class="logout-text">Are you sure you want to Logout?</div>
        <div class="logout-btns">
          <button type="button" class="btn btn-primary yes-btn btn-lg" (click)="logOut();">Yes</button>&nbsp;&nbsp;
          &nbsp;&nbsp;
          <div></div>
          <button type="button" class="btn btn-secondary no-btn btn-lg" data-bs-dismiss="modal">No</button>
        </div>
      </div>
      
    </div>
  </div>
</div>