import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseApiService } from 'src/app/base-api.service';
import { SharedService } from 'src/app/shared.service';
import { FormGroup, FormsModule, FormControl, Validators, ValidatorFn, FormBuilder, ReactiveFormsModule, AbstractControl } from "@angular/forms";

import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
const today = new Date(new Date().toISOString().split('T').shift());

@Component({
  selector: 'app-fectasks',
  templateUrl: './fectasks.component.html',
  styleUrls: ['./fectasks.component.scss']
})
export class FectasksComponent implements OnInit {
  currentDate = new Date();
  month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  newData = [];
  newDataSearch:any=[];
  newDataSearchCompany:any=[];
  username = '';
  taskid:string="";
  event = 0;
  itemsPerPage: number = 9;
  page = 1;
  totalItems = 0;
  currentPage = 0;
  
  resultCheck = true;
  searchString = '';
  overdueVisits: number = 0;
  assignedVisits: number = 0;
  completedVisits: number = 0;
  scheduledVisits: number = 0;
  unscheduledVisits: number = 0;
  fleetEdgeChampList: any = [];
  listingwidth:boolean=false;
  // rangeDates: Date[];
  sdate: Date | null = new Date();
  sales_opty_count:any=0;

  // sheduleTaskForm: FormGroup;
  // sdate: Date | null = today;
  filterStartDate: Date | null = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
  filterEndDate: Date | null = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
  rangeDates: Date[] = [this.filterStartDate,this.filterEndDate]
  currentTab:string = '';
  today = new Date().toISOString().split('T')[0];
  minimumDate = new Date();
  dsm_username:any;
  // for Testing 
  saleswindowdata : any[] = [];
  paginateData: any[] = [];
  pageSize = 10;
  collectionSize = 0;
  getAllRecord:any=0;
  searchTerm:any;
  VisitDate:any;
  constructor(
    public router: Router,
    private sharedService: SharedService,
    private baseAPIService: BaseApiService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private _Activatedroute: ActivatedRoute,
    private toastr:ToastrService

  ) { }
  listView = false;
  gridView = true;
  ngOnInit(): void {
    this.dsm_username=localStorage.getItem('dsm_username');
    this._Activatedroute.params.subscribe(params => {
    this.currentTab=params['currentTab'];
    });
    if( this.currentTab==undefined)
    {
      this.currentTab='completed_visits';
    }
    this.baseAPIService.DataObj.subscribe(res => {
      if(res==null)
      {
        this.filterEndDate = this.sharedService.dateFormater(this.filterEndDate);
        this.filterStartDate = this.sharedService.dateFormater(this.filterStartDate);
        let obj={'filterEndDate':this.filterEndDate,'filterStartDate':this.filterStartDate};
        this.baseAPIService.onDateRange(obj);
      }
      else
      {
        if(isJson(res)==false)
        {           
            this.filterEndDate = this.sharedService.dateFormater(res['filterEndDate']);
            this.filterStartDate = this.sharedService.dateFormater(res['filterStartDate']);
          this.getTabsCount();
          this.toggleTaskScreen(this.currentTab);
        }
        else
        {
          let obj=JSON.parse(res);
          this.filterEndDate=obj.filterEndDate;
          this.filterStartDate=obj.filterStartDate;
          this.getTabsCount();
          this.toggleTaskScreen(this.currentTab);
        }
      }
    }); 
  }
  getSalespagination()
  {
    this.currentPage=(this.page - 1) * this.pageSize;
    this.itemsPerPage=10;
    var requestPayload = this.applyAllFilters();
    this.fetchDataListByFilterApplied(requestPayload);
  }
  ngAfterViewChecked() {
    let div=document.querySelector('.listing-card-desktop');
    if(document.querySelector('nav.sidebar')?.classList.contains('collapsed')===true)
    {
      this.listingwidth=true;
    }
    else
    {
      this.listingwidth=false;
    }
  }
  toggleView(new_bool:boolean) {
    this.listView = !new_bool;
    this.gridView = new_bool;
  }
  getTaskTabStatusCode(tab: string): string {
    if (tab == "completed_visits") {
      return '4';
    }
    if (tab == "scheduled_visits") {
      return '2';
    }
    if (tab == "unscheduled_tasks") {
      return '1';
    }
    if (tab == "overdue_visits") {
      return '3';
    }
    return '';
  }
  toggleTaskScreen(tab:string):void {
    this.currentTab = tab;
    this.itemsPerPage=9;
    this.currentPage=0;
    if(this.currentTab=='sales_window')
    {
      this.itemsPerPage=10;
    }
    this.totalItems=0
    var user_id = localStorage.getItem("username");
    if (!(user_id)) {
      console.log("FEC not found");
      // alert("Unable to process request. Please try again");
      this.toastr.error("Unable to process request. Please try again")
      return;
    }
    this.username = user_id;
    var requestPayload = this.applyAllFilters();
    this.fetchDataListByFilterApplied(requestPayload)
  }
  pageChanged(event) {
    this.currentPage = event;
    this.event = this.currentPage;
    var requestPayload = this.applyAllFilters();
    this.fetchDataListByFilterApplied(requestPayload);
  }
  Loadmore()
  {
    this.itemsPerPage=this.itemsPerPage*2;
    var requestPayload = this.applyAllFilters();
    this.fetchDataListByFilterApplied(requestPayload);
  }
  searchRecords() {
    var requestPayload = this.applyAllFilters();
    this.fetchDataListByFilterApplied(requestPayload);
  }
  applyDateFilter() {
    var requestPayload = this.applyAllFilters();
    this.getTabsCount();
    this.fetchDataListByFilterApplied(requestPayload);
  }
  applyAllFilters():any {
    var requestPayload = {
      "fec_user_id": this.username,
      "status": this.getTaskTabStatusCode(this.currentTab),
      "is_fec_user_data": true,
      "offset": this.currentPage,
      "limit": this.itemsPerPage,
    };
    if (this.filterStartDate) {
       requestPayload["fromdate"] = this.filterStartDate;
      // requestPayload["fromdate"]='2022-05-01';
    }
    if (this.filterEndDate) {
       requestPayload["todate"] = this.filterEndDate;
      // requestPayload["todate"]='2023-05-31';
    }
    if(this.searchString && this.searchString.length){
      requestPayload["city"] = this.searchString;
    }
    if(this.currentTab=='sales_window')
    {
      requestPayload["sales_opty"] =true;
    }
     if(this.currentTab=='sales_window')
    {
      requestPayload["sales_opty"] =true;
    }
    return requestPayload;
  }
  downloadMasterData(): void {
    console.log(this.newData)
    let sales_opty=false;
    var requestPayload:any;
    if(this.currentTab=='sales_window')
    {
      sales_opty=true;
      requestPayload={
        "fec_user_id": this.username,
        //  "sales_opty": sales_opty,
        "todate": this.filterEndDate,
        "fromdate": this.filterStartDate,
      };
    }
    else{
      requestPayload={
        "fec_user_id": this.username,
        // "status": this.getTaskTabStatusCode(this.currentTab),
        // "sales_opty": sales_opty,
        "todate": this.filterEndDate,
        "fromdate": this.filterStartDate,
      };
    }
    
    if(this.getAllRecord==0)
    {
      alert("No Record Found");
    }
    else
    this.sharedService.downloadListData(requestPayload);
  }
  fetchDataListByFilterApplied(requestPayload):void {
    this.newData = [];
    this.newDataSearch=[];
    this.newDataSearchCompany=[];
    this.spinnerService.show();
    if(this.currentTab=='completed_visits')
    {
      var requestURL = "fecportal/completed/list/";
    }
    else if(this.currentTab=='scheduled_visits')
    {
      var requestURL = "fecportal/scheduled/list/";
    }
    else
    {
      var requestURL = "fecportal/get/list/";
    }
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      // console.log(response,'response')
      if (response) {
        this.totalItems = response[0].total_record;
        this.collectionSize=this.totalItems
        this.newData = response;
        this.paginateData=response;
        this.newData.shift();
        this.newDataSearchCompany=this.newData;
        this.newDataSearch= this.newData.filter((ele:any) => {return ele.company_name!==null});
      } else {
        console.log("FEC - No Records Found");
        var message = this.baseAPIService.handleAPIResponse(response, "Unable to process request. Please try again");
        // alert(message);
        // this.toastr.error(message);
      }
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      if(error.status==401)
      {
        // alert(errMessage);
        this.toastr.error(errMessage);
        this.router.navigate(['/login']);
      }
    }),setTimeout(() => {
      // $("#lastpopup").modal('hide');
      this.spinnerService.hide();
      },1500);
  }
  getTabsCount(): void {
    this.spinnerService.show();
    var requestURL = "fecportal/dashboard/count/";
    var user_id = localStorage.getItem("username");
    if (!(user_id)) {
      console.log("FEC not found");
      // alert("Unable to process request. Please try again");
      this.toastr.error("Unable to process request. Please try again")
      return;
    }
    var requestPayload = {
      "fec_user_id": user_id
    };
    if (this.filterStartDate) {
      requestPayload["fromdate"] = this.filterStartDate;
    }
    if (this.filterEndDate) {
      requestPayload["todate"] = this.filterEndDate;
    }
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      this.overdueVisits = 0;
      this.completedVisits = 0;
      this.scheduledVisits = 0;
      this.unscheduledVisits = 0;
      this.getAllRecord=0;
      if (response) {
        if (response['total_task_assigned']) {
          this.getAllRecord = response['total_task_assigned'];
        }
        if (response['overdue']) {
          this.overdueVisits = response['overdue'];
        }
        if (response['completed']) {
          this.completedVisits = response['completed'];
        }
        if (response['scheduled']) {
          this.scheduledVisits = response['scheduled'];
        }
        if (response['unscheduled']) {
          this.unscheduledVisits = response['unscheduled'];
        }
        if(response['sales_opty_count'])
        {
          this.sales_opty_count = response['sales_opty_count'];

        }
      } else {
        console.log("FEC - No Records Found");
        var message = this.baseAPIService.handleSucessfulAPIResponse(response, "Unable to process request. Please try again");
        // alert(message);
        this.toastr.error(message);
      }
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      this.router.navigate(['/login']);
      // if(error.status==401)
      // {
      //   alert(errMessage);
      // }
    });
  }
  goToTaskDetails(data):void {
    console.log(data)
    if(this.currentTab=='completed_visits')
    {
      this.router.navigate(['/fectaskdetail', { 'data': data.id,'currentTab':this.currentTab,'fecid':data.fec_assign_id}]);
    }
    else if(this.currentTab=='scheduled_visits')
    {
     this.router.navigate(['/fectaskdetail', { 'data': data.id,'currentTab':this.currentTab,'fecid':data.fec_assign_id}]);
    }
    else
    {
     this.router.navigate(['/fectaskdetail', { 'data': data.id,'currentTab':this.currentTab,'fecid':data.fec_assign[0].fec_assign_id}]);
    }
  }
  SelectDateRange(myDateRangeModal:any)
  {
    this.modalService.open(myDateRangeModal, { size: "sm",centered: true });
  }
  selectTaskId(item:any,MyModel:any):void {
    this.taskid = item.fec_assign[0].fec_assign_id;
    this.modalService.open(MyModel, { size: "sm",centered: true });
  }
  selectUnschedTaskId(item:any,MyUnModel:any):void {
    this.taskid = item.fec_assign[0].fec_assign_id;
    this.modalService.open(MyUnModel, { size: "sm",centered: true });
  }
  saveDatetimeSch(schedTask:any):any {
    if(!(this.taskid && this.sharedService.dateFormater(this.sdate))){
      console.log("Assign Date:", this.taskid, this.sharedService.dateFormater(this.sdate));
      // alert("Unable to process request. Please try again");
      this.toastr.error("Unable to process request. Please try again")
      return;
    }
    this.spinnerService.show();
    var requestURL = "fecportal/fec/set_date_time/";
    var requestPayload = {
      "id": this.taskid,
      "visit_date_time": this.sharedService.dateFormater(this.sdate)+" 00:00:00",
    }
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      this.spinnerService.hide();
      this.VisitDate=this.sharedService.dateFormater(this.sdate);
      var message = this.baseAPIService.handleAPIResponse(response, "Task Details Submitted Successfully");
      this.modalService.dismissAll();
      this.modalService.open(schedTask, { size: "sm",centered: true });
      // alert(message);
      // schedTask
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      this.toastr.error(errMessage);

      // alert(errMessage);
    });

  }
  saveOverDueDatetime(OverDueTask:any):any {
    if(!(this.taskid && this.sharedService.dateFormater(this.sdate))){
      console.log("Assign Date:", this.taskid, this.sharedService.dateFormater(this.sdate));
      // alert("Unable to process request. Please try again");
      this.toastr.error("Unable to process request. Please try again")
      return;
    }
    this.spinnerService.show();
    // fecportal/fec/set_date_time
    var requestURL = "fecportal/fec/set_date_time/";
    var requestPayload = {
      "id": this.taskid,
      "visit_date_time": this.sharedService.dateFormater(this.sdate)+" 00:00:00",
    }
    console.log(requestPayload,'requestPayload')
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      this.spinnerService.hide();
      var message = this.baseAPIService.handleAPIResponse(response, "Task Details Submitted Successfully");
      // alert(message);
      this.VisitDate=this.sharedService.dateFormater(this.sdate);
      this.modalService.dismissAll();
      this.modalService.open(OverDueTask, { size: "sm",centered: true });
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      // alert(errMessage);
      this.toastr.error(errMessage);
    });

  }
  DateRangeModel(myDateRangeModal:any)
  {
    this.modalService.open(myDateRangeModal, { size: "sm",centered: true });
  }
  SaveDateRange()
  {
    if(this.rangeDates[1]==null)
    {
      alert("select proper range date")
    }
    else
    {
      this.filterEndDate = this.sharedService.dateFormater(this.rangeDates[1]);
      this.filterStartDate = this.sharedService.dateFormater(this.rangeDates[0]);
      let obj={'filterEndDate':this.filterEndDate,'filterStartDate':this.filterStartDate};
      this.baseAPIService.onDateRange(obj);
      // this.getTabsCount();
      // this.toggleTaskScreen(this.currentTab);
      this.modalService.dismissAll();
    }
  }
  closeCompletepopup(item:any)
  {
    this.modalService.dismissAll();
    this.currentTab=item;
    this.getTabsCount();
    this.toggleTaskScreen(this.currentTab);
    // this.route.navigate(['/fecdashboard']);
  }
  searchCompanyName(event:any)
  {
    let eventStream=event.target.value.trim();
    if(eventStream=='' || eventStream==undefined)
    {
      this.newData=this.newDataSearchCompany;
    }
    else
    {
      if(eventStream.length>3)
      {
       let args=eventStream.toLowerCase();
       return this.newData=this.newDataSearch.filter(function(item:any){
        return JSON.stringify(item).toLowerCase().includes(args)
       });
      }
      else
      {
        if(this.currentTab=='sales_window')
        {
          console.log(eventStream)
          return this.newData=this.newDataSearch.filter((ele:any) => {
          return(ele.first_name.toLowerCase().startsWith(eventStream.toLowerCase()))});
        }
        else
        {
          return this.newData=this.newDataSearch.filter((ele:any) => {
          return(ele.company_name.toLowerCase().startsWith(eventStream.toLowerCase()))});
        }
      }
    }
  }
}
function isJson(str:any) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return isNaN(str);
}