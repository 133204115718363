import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BaseApiService } from 'src/app/base-api.service';
import { SharedService } from 'src/app/shared.service';
import { FormGroup, FormsModule, FormControl, Validators, ValidatorFn, FormBuilder, ReactiveFormsModule, AbstractControl } from "@angular/forms";

import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ExcelService } from 'src/app/excel-service.service';
const today = new Date(new Date().toISOString().split('T').shift());

@Component({
  selector: 'app-fecdashboard',
  templateUrl: './fecdashboard.component.html',
  styleUrls: ['./fecdashboard.component.scss']
})
export class FecdashboardComponent implements OnInit {
  currentDate = new Date();
  month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


  totalFEC: number = 0;
  assignedVisitFEC: number = 0;
  visitCompletedFEC: number = 0;
  visitScheduledFEC: number = 0;
  visitUnScheduledFEC: number = 0;
  overdueVisits: number = 0;
  salesOppurtunities: number = 0;
  completedVisits: number = 0;
  scheduledVisits: number = 0;
  unscheduledVisits: number = 0;
  fleetEdgeChampList: any = [];
  resultCheck = true;
  itemsPerPage: number = 20;
  page = 1;
  totalItems = 0;
  currentPage = 0;
  currentTab: string = "completed_visits";
  event = 0;
  searchByString: string = "";
  username: string;
  filterStartDate: Date | null = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
  filterEndDate: Date | null = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
  // sheduleTaskForm: FormGroup;
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private spinnerService: NgxSpinnerService,
    private baseAPIService: BaseApiService,
    private http: HttpClient,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {
    this.initializeComponent();
  }

  initializeComponent(): void {
    this.filterEndDate = this.sharedService.dateFormater(this.filterEndDate);
    this.filterStartDate = this.sharedService.dateFormater(this.filterStartDate);
    this.fetchFECDashboardCount();
    this.toggleListing(this.currentTab);
  }

  getTabStatusCode(tab: string): string {
    if (tab == "completed_visits") {
      return '4';
    }
    if (tab == "overdue_visits") {
      return '3';
    }
    if (tab == "scheduled_visits") {
      return '2';
    }
    if (tab == "unscheduled_visits") {
      return '1';
    }
    if (tab == "sales_opportunity") {
      return '';
    }
    return '';
  }

  pageChanged(event) {
    this.currentPage = event;
    this.event = this.currentPage;
    var requestPayload = this.applyDashboardDataFilters();
    this.fetchDashboardListingData(requestPayload);
  }

  fetchFECDashboardCount(): void {
    this.spinnerService.show();
    var requestURL = "fecportal/dashboard/count/";
    var user_id = localStorage.getItem("username");
    if (!(user_id)) {
      console.log("FEC not found");
      alert("Unable to process request. Please try again");
      return;
    }
    this.username = user_id;
    var requestPayload = {
      "fec_user_id": user_id
    };
    if (this.filterStartDate) {
      // requestPayload["fromdate"] = this.filterStartDate;
      requestPayload["fromdate"] = '2022-05-01';
      
    }
    if (this.filterEndDate) {
      requestPayload["todate"] ='2022-05-31';
      // requestPayload["todate"] = this.filterEndDate;
    }
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      this.totalFEC = 0;
      this.overdueVisits = 0;
      this.completedVisits = 0;
      this.scheduledVisits = 0;
      this.unscheduledVisits = 0;
      this.salesOppurtunities = 0;
      if (response) {
        if (response['overdue']) {
          this.overdueVisits = response['overdue'];
        }
        if (response['completed']) {
          this.completedVisits = response['completed'];
        }
        if (response['scheduled']) {
          this.scheduledVisits = response['scheduled'];
        }
        if (response['unscheduled']) {
          this.unscheduledVisits = response['unscheduled'];
        }
        if (response['sales_opty_count']) {
          this.salesOppurtunities = response['sales_opty_count'];
        }
      } else {
        console.log("FEC - No Records Found");
        var message = this.baseAPIService.handleSucessfulAPIResponse(response, "Unable to process request. Please try again");
        alert(message);
      }
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      alert(errMessage);
    });
  }

  toggleListing(status_tab: string) {
    this.currentTab = status_tab;
    var requestPayload = this.applyDashboardDataFilters();
    this.fetchDashboardListingData(requestPayload);
  }

  downloadListAsExcel(): void {
    var excelFileName = "Excel-Data-Download";
    var jsonData = this.fleetEdgeChampList;
    if (!(jsonData)) {
      console.log("No Records found");
    } else {
      this.excelService.exportAsExcelFile(jsonData, excelFileName);
    }
  }

  fetchDashboardListingData(requestPayload: any): void {
    this.spinnerService.show();
    this.fleetEdgeChampList = [];
    var requestURL = "fecportal/get/list/";
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      if (response) {
        this.totalItems = response[0]["total_record"];
        this.fleetEdgeChampList = response;
        this.fleetEdgeChampList.shift();

      } else {
        console.log("FEC - No Records Found");
        var message = this.baseAPIService.handleAPIResponse(response, "Unable to process request. Please try again");
        alert(message);
      }
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      alert(errMessage);
    });
  }

  searchByFECName(): void {
    console.log("SEARCH BY:", this.searchByString);
    var requestPayload = this.applyDashboardDataFilters();
    this.fetchDashboardListingData(requestPayload);
  }

  downloadMasterData(): void {
    this.sharedService.downloadMasterData();
  }

  applyDashboardDataFilters(): any {
    var requestPayload = {
      "fec_user_id": this.username,
      "status": this.getTabStatusCode(this.currentTab),
      "is_fec_user_data": true,
      "offset": this.currentPage,
      "limit": this.itemsPerPage,
    };
    if (this.filterStartDate) {
      // requestPayload["fromdate"] = this.filterStartDate;
      requestPayload["fromdate"] = '2022-05-01';
    }
    if (this.filterEndDate) {
      // requestPayload["todate"] = this.filterEndDate;
      requestPayload["fromdate"] = '2022-05-01';
    }
    if (this.searchByString) {
      requestPayload["search_key"] = this.searchByString;
    }
    return requestPayload;
  }

  searchDatetime(): void {
    console.log("SEARCH BY DATE:", this.filterStartDate, this.filterEndDate);
    var requestPayload = this.applyDashboardDataFilters();
    this.fetchFECDashboardCount();
    this.fetchDashboardListingData(requestPayload);
  }
}
