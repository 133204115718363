import { Component, OnInit,ViewChild } from '@angular/core';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';

import { BaseApiService } from 'src/app/base-api.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as XLSX from 'xlsx';
import { RouterModule, Router } from '@angular/router';
 
 import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
declare var myExtObject: any;
declare var webGlObject: any;

@Component({
  selector: 'app-vehicle-can-data',
  templateUrl: './vehicle-can-data.component.html',
  styleUrls: ['./vehicle-can-data.component.scss']
})
export class VehicleCanDataComponent implements OnInit {
	
	
       

  constructor(private router: Router,private baseAPI: BaseApiService, public spinnerService: NgxSpinnerService,private http: HttpClient) {
          var token =localStorage.getItem('token');
	       
			if(token==undefined || token==='' ){
			  this.router.navigate(['/login']);
			}
			 webGlObject.init();
			
			

		 }
	  
	    // @ViewChild('form') form;
			dropdownList = [];
			selectedItems = [];
			//dropdownSettings = {};
			dropdownSettings: IDropdownSettings;
			
			
			
	
	resultCheck:boolean = false; 	
	 globalData:any=[];
	event_array= [];
	selectedSPN_array= [];
	pageOfItems = [];
	 editStartDate:string;
     editEndDate:string;
	 start_date:string;
	 end_date:string;
	 pgnNo:string;
	searchData:string;
	public  apps: any[] = [];
  vechileDetails: any;
   public min = new Date(2018, 1, 12, 10, 30);
	 
	

    // Max moment: April 21 2018, 20:30
	public yearMax=this.getCurrentDay('Y');
	public monthMax=this.getCurrentDay('M');
	public dateMax=this.getCurrentDay('D');
	private todate = new Date();
	 public Hours=this.todate.getHours();
    public getMinutes=this.todate.getMinutes();
    public max = new Date(this.yearMax, this.monthMax, this.dateMax, 20, 30);
	
	
	 public myDate: IMyDate = {

    year: this.getCurrentDay('Y'),
    month: this.getCurrentDay('M'),
    day: this.getCurrentDay('D'),
  }
	 
  getCurrentDay(cat): any {
    let output;
    var today = new Date();
    var day = today.getDate();
	 var month = today.getMonth() + 1; //January is 0!
    if (day != 1) {
        //day = day - 1;
    }else{
		if(month==5 || month==7 || month==10 || month==12)
		{
			day=30;
		}
		
		if(month==2 || month===4 || month==6 || month==8 ||month==9 || month==11 || month==1)
		{
			day=31;
		}
		
		if (year % 4 == 0 && month==3) 
		{
			day=29;
		}
		else if (year % 4 != 0 && month==3) 
		{
			day=28;
		}
		month=month-1;
	}

   
	//alert(day+"#"+month);
    var year = today.getFullYear();

    if (cat === 'D') {
      output = day;
    }
    else if (cat === 'M') {
      output = month;
    }
    else {
      output = year;
    }
    return output;
  }
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    markCurrentDay: true,
   // disableUntil: this.myDate  vinay this is for disble 
    disableSince :  this.myDate

  };
  
  
  
    compareDate()
	{
		
		
		var startDate = new Date(this.editStartDate);
		var endDate = new Date(this.editEndDate);

		var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
		var stString=Date.parse(startDate.toUTCString());
		var edString=Date.parse(endDate.toUTCString());
		
	  if (endDate < startDate || seconds>86400)  
	  {
			alert("Only 24 hours data is allowed");
			this.editStartDate=null;
			this.editEndDate=null;	
            this.start_date="";			
	  }else if (edString ==stString) //86400
	  {
		alert("Start date and end date cannot be same");
		this.editStartDate=null;
			this.editEndDate=null;	
            this.start_date="";		
	  }else if(this.editEndDate!=null && this.editStartDate!=null)
		{
			
			
			 this.editStartDate=this.convert(this.editStartDate);
	         this.editEndDate=this.convert(this.editEndDate);
			 
			 
		}
	}
  
  convert(str) {
	 
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    var Hours=date.getHours();
    var getMinutes=date.getMinutes();
	
	var returnDtae=[date.getFullYear(), mnth, day].join("-");
	returnDtae=returnDtae+"T"+Hours+":"+getMinutes+":00";
  return returnDtae;
}

  onEditStartDateChanged(event) {
	 

   var splitedDate=this.start_date.toString().split(",");
	 
	  this.editStartDate=splitedDate[0];
	  this.editEndDate=splitedDate[1];
	  

	 
		if(this.editEndDate!=null && this.editStartDate!=null)
		{
			this.compareDate();
			
			
		}
		
		
	 }
	 
	applyFilter(event)
	{
		
		
		
	}
	 fetchReport() {
	  
	 
	 this.pgnNo=this.selectedSPN_array.toString();
	
	if(this.editStartDate === undefined && this.editEndDate !== undefined )
	{
		alert('Please enter proper date in the Start date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate !== undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate === undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the Start date and End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}

    if ((this.editStartDate === undefined || this.editStartDate === '' || this.editStartDate === null) ||
      (this.editEndDate === undefined || this.editEndDate === '' || this.editEndDate === null) ||
      (this.searchData === undefined || this.searchData === '' || this.searchData == '' || this.searchData == null ) ||(this.pgnNo === undefined || this.pgnNo === '' || this.pgnNo == '' || this.pgnNo == null) ) {
        alert('Please fill all mandatory fields.');

    }
    else {  

     
     

		if (window.confirm("Do you want to fetch report?")) 
	   {
		   
		    this.callReportApi();
           // alert("Notification created successfully");
			//this.reset1();
        } 
		else 
		{
            alert("Report serach is failed");
        }

    }
  }
	
	
	//==================================================================
	
	callReportApi() {
    this.spinnerService.show();
	
	//this.pgnNo='5245,976';
    let formData: FormData = new FormData();   
    formData.append('searchData', this.searchData);    
    formData.append('start_date', this.editStartDate);
    formData.append('end_date', this.editEndDate);
	var datastring="vehicle-can/"+this.searchData+"?fromDate="+this.editStartDate+"&toDate="+this.editEndDate+"&spnNo="+this.pgnNo;//64892  61441
	myExtObject.firebaseEvent('Vehicle CAN Data', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"Search Button Click"});
	
	this.baseAPI.callServiceApi(this.baseAPI.baseUrl,datastring).then((data:any) => {
        this.resultCheck=false;
		this.event_array= [];
		let tempEvent:any;
		let jsonObj2 = {}; 
		var employees = {};
	  if(data.Information!=undefined)
	  {
		  
		  
		  alert(data.Information);
		  this.spinnerService.hide();
	  }else{
	  
	  //PROACC06022081648
		  this.vechileDetails=<any>Object.values(data);	  
		 // console.log(JSON.stringify(this.vechileDetails));
		  
		  this.vechileDetails=this.vechileDetails[0];
	 // console.log(JSON.stringify(this.vechileDetails));
	 
	  if(this.vechileDetails!=undefined && this.vechileDetails.length>0)
	  {
				  for(let i =0; i < this.vechileDetails.length;i++) {
					  
					
					  if(this.vechileDetails[i].spnDat!=undefined && this.vechileDetails[i].spnDat.length>0)
					  {
									 for(let j =0; j <  this.vechileDetails[i].spnDat.length;j++) {
										 jsonObj2["eventDateTime"]=this.vechileDetails[i].eventDateTime;			  
									  
									   jsonObj2["spnName"]=this.vechileDetails[i].spnDat[j].spnName;
									   jsonObj2["spnUnit"]=this.vechileDetails[i].spnDat[j].spnUnit;
									  
									   jsonObj2["spnNumber"]=this.vechileDetails[i].spnDat[j].spnNumber;
									  jsonObj2["spnValue"]=this.vechileDetails[i].spnDat[j].spnValue;
									  this.event_array.push(jsonObj2);
									  jsonObj2 = {};
									  
									  
									}
					  }
					   //jsonObj2 = {}; 
				  }		       
						
				    
				  
	       }
		         
                        
		                this.spinnerService.hide(); 
						if(this.event_array.length>0)
						{
							this.resultCheck=true;

						}else{

							alert("No Records Found");
						}
		 
		 
	  }
      
    }, (err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert(message);
      }
      else {
        alert('Please try again');
      }
    });
	
	

  }
  downloadAsExcelFile() {
	   this.pgnNo=this.selectedSPN_array.toString();
	  if(this.editStartDate === undefined && this.editEndDate !== undefined )
	{
		alert('Please enter proper date in the Start date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate !== undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate === undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the Start date and End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}

    if ((this.editStartDate === undefined || this.editStartDate === '' || this.editStartDate === null) ||
      (this.editEndDate === undefined || this.editEndDate === '' || this.editEndDate === null) ||
      (this.searchData === undefined || this.searchData === '' || this.searchData == '' || this.searchData == null ) ||
      (this.pgnNo === undefined || this.pgnNo === '' || this.pgnNo == '' || this.pgnNo == null ) ) {
        alert('Please fill all mandatory fields.');

    }
    else {

     
     

		if (window.confirm("Do you want to download report?")) 
	   {
		   
		    this.callReportXLApi();
           // alert("Notification created successfully");
			//this.reset1();
        } 
		else 
		{
            alert("Report download has been cancelled");
			
        }

    }

    
  } 
  
  
   callReportXLApi() {
	  
this.spinnerService.show();


//https://cvp-dev.api.tatamotors/cvp/vehicle-can-csv/PROACC06022081648?fromDate=2020-08-28T00:00:00&toDate=2020-08-28T23:00:00&spnNo=5245
  
	var datastring="vehicle-can-csv/"+this.searchData+"?fromDate="+this.editStartDate+"&toDate="+this.editEndDate+"&spnNo="+this.pgnNo;//64892  61441
	myExtObject.firebaseEvent('Vehicle CAN Data', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"XL Download Click"});
	//this.baseAPI.callServiceApi(this.baseAPI.baseUrl,datastring).then(data => {
 
setTimeout(() => {
   
	this.baseAPI.callServiceApiXL(this.baseAPI.baseUrl,datastring).then((data:any) => {   


   /* var anchor = angular.element('<a/>');
     anchor.attr({
         href: 'data:attachment/csv;charset=utf-8,' + encodeURI(data),
         target: '_blank',
         download: 'filename.csv'
     })[0].click();	
	 
	 */
	 
	 
	              var downloadlink='data:attachment/csv;charset=utf-8,' + encodeURI(data);
				 // this.downloadPDF(res.data.url);
				  var datastring="vehicleCan_"+this.searchData+"_"+this.editStartDate+"_"+this.editEndDate+".csv";//xlsx
					var link = document.createElement('a');
					link.setAttribute("type", "hidden");
					link.download = datastring;
					link.href =downloadlink;
					console.log(link.href);
					document.body.appendChild(link);
					link.click();
	 
	 /* let tempEvent:any;     
      var employees = {};
	  	let jsonObj2 = {}; 
       this.globalData=[];
       if(data.Information!=undefined)
	  {
		  
		  
		  alert(data.Information);
		  this.spinnerService.hide();
		  
	  }else{
		  
		  
	  
	  this.vechileDetails=<any>Object.values(data);
	  //this.vechileDetails=this.vechileDetails[0];
	  this.vechileDetails=this.vechileDetails[0];
	  console.log(JSON.stringify(this.vechileDetails));
	 
	  if(this.vechileDetails!=undefined && this.vechileDetails.length>0)
	  {
				  for(let i =0; i < this.vechileDetails.length;i++) {
					  
					
					  if(this.vechileDetails[i].spnDat!=undefined && this.vechileDetails[i].spnDat.length>0)
					  {
									 for(let j =0; j <  this.vechileDetails[i].spnDat.length;j++) {
										 jsonObj2["Event DateTime"]=this.vechileDetails[i].eventDateTime;			  
									  
									   jsonObj2["Spn Name"]=this.vechileDetails[i].spnDat[j].spnName;
									   jsonObj2["Spn Unit"]=this.vechileDetails[i].spnDat[j].spnUnit;
									  
									   jsonObj2["Spn Number"]=this.vechileDetails[i].spnDat[j].spnNumber;
									  jsonObj2["Spn Value"]=this.vechileDetails[i].spnDat[j].spnValue;
									  this.globalData.push(jsonObj2);
									  jsonObj2 = {};
									  
									  
									}
					  }
					   //jsonObj2 = {}; 
				  }
				 
				 
				    
				   // console.log(JSON.stringify(this.pageOfItems));
	  }
	  this.spinnerService.hide();
        if(this.globalData.length>0)
	   {
		   
	       this.generateXl();
	   }else{
		   
		   alert("No Records Found");
	   }	
	  
	  
	  }
    
	  */
	   
	   
	  
	  //this.dealerOffset=this.dealerOffset+21;
    }, (err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert(message);
      }
      else {
        alert('Please try again');
      }
    });	
	
 
}, 5000);
  setTimeout(() => {
   
  }, 10000);

    
  } 
 
  
generateXl()
{
	 
		this.spinnerService.show();
		var ws = XLSX.utils.json_to_sheet(this.globalData);
		var wb = XLSX.utils.book_new(); XLSX.utils.book_append_sheet(wb, ws, "Target Data");
		var datastring="vehicleCan_"+this.searchData+"_"+this.editStartDate+"_"+this.editEndDate+".xlsx";
		XLSX.writeFile(wb, datastring);
		this.globalData = [];
		var a = 0;
		this.spinnerService.hide();
	
}
 


  ngOnInit() {
	 //   this.monthMax=this.monthMax-1;
		this.max=new Date();
	   //this.max = new Date(this.yearMax, this.monthMax, this.dateMax, this.Hours, this.getMinutes);
	 // this.spinnerService.show();  
	  
	  //this.dropdownSettings: IDropdownSettings{};

//spn
	  
	     this.dropdownList = [
     { item_id: 516127, item_text: ' 65365 / 516127 : Prop_IC_Indications - Service Indicator'},
	{ item_id: 516126, item_text: ' 65365 / 516126 : Prop_IC_Indications - ABS malfunction trailer'},
	{ item_id: 516125, item_text: ' 65365 / 516125 : Prop_IC_Indications - Air filter clogging'},
	{ item_id: 516124, item_text: ' 65365 / 516124 : Prop_IC_Indications - Cab tilt'},
	{ item_id: 516123, item_text: ' 65365 / 516123 : Prop_IC_Indications - IC_Battery_charging_2'},
	{ item_id: 516122, item_text: ' 65365 / 516122 : Prop_IC_Indications - IC_General_illumination'},
	{ item_id: 516121, item_text: ' 65365 / 516121 : Prop_IC_Indications - IC_Lift_axle_up'},
	{ item_id: 516120, item_text: ' 65365 / 516120 : Prop_IC_Indications - IC_Battery_charging_1'},
	{ item_id: 516119, item_text: ' 65365 / 516119 : Prop_IC_Indications - IC_Seat_belt'},
	
{ item_id: 516165, item_text: ' 65364 / 516165 : Prop_IC_Fuel_And_Maint - Distance to Service'},
{ item_id: 516118, item_text: ' 65364 / 516118 : Prop_IC_Fuel_And_Maint - IC_ClutchWear'},
{ item_id: 516117, item_text: ' 65364 / 516117 : Prop_IC_Fuel_And_Maint - IC_BrakeWear'},
{ item_id: 516115, item_text: ' 65364 / 516115 : Prop_IC_Fuel_And_Maint - IC_DistToEmpty'},
{ item_id: 516113, item_text: ' 65363 / 516113 : Prop_IC_TripB - TripB_Hrs'},
{ item_id: 516111, item_text: ' 65363 / 516111 : Prop_IC_TripB - TripB_FuelEffKm'},
{ item_id: 516110, item_text: ' 65363 / 516110 : Prop_IC_TripB - TripB_Distance'},
{ item_id: 516109, item_text: ' 65362 / 516109 : Prop_IC_TripA - TripA_Hours'},
{ item_id: 516107, item_text: ' 65362 / 516107 : Prop_IC_TripA - TripA_FuelEffKm'},
{ item_id: 516106, item_text: ' 65362 / 516106 : Prop_IC_TripA - TripA_Distance'},
{ item_id: 516105, item_text: ' 65361 / 516105 : Prop_IC_RunInfo - IC_Run_Time'},
//{ item_id: 516104, item_text: ' 65361 / 516104 : Prop_IC_RunInfo - IC_Total_Distance'},
{ item_id: 97, item_text: ' 65279 / 97 : OI - WaterInFuelIndicator'},
{ item_id: 5825, item_text: ' 65279 / 5825 : OI - DrvrWarningSystemIndicatorStatus'},
{ item_id: 5675, item_text: ' 65279 / 5675 : OI_CBCU - OperatorShiftPrompt'},
{ item_id: 96, item_text: ' 65276 / 96 : DD_IC - FuelLevel1'},
{ item_id: 38, item_text: ' 65276 / 38 : DD_IC - FuelLevel2'},
{ item_id: 976, item_text: ' 65265 / 976 : CCVS1 - PTO State'},
{ item_id: 86, item_text: ' 65265 / 86 : CCVS1 - Cruise Control Set Speed'},
{ item_id: 84, item_text: ' 65265 / 84 : CCVS1 - Wheel Base Vehicle Speed'},
{ item_id: 70, item_text: ' 65265 / 70 : CCVS1 - Parking Brake Switch'},
{ item_id: 602, item_text: ' 65265 / 602 : CCVS1 - Cruise Control Accelerate Switch'},
{ item_id: 601, item_text: ' 65265 / 601 : CCVS1 - Cruise Control Resume Switch'},
{ item_id: 600, item_text: ' 65265 / 600 : CCVS1 - Cruise Control Coast / Decelerate Switch'},
{ item_id: 599, item_text: ' 65265 / 599 : CCVS1 - Cruise Control Set Switch'},
{ item_id: 598, item_text: ' 65265 / 598 : CCVS1 - Clutch Switch'},
{ item_id: 597, item_text: ' 65265 / 597 : CCVS1 - Brake Switch'},
{ item_id: 596, item_text: ' 65265 / 596 : CCVS1 - Cruise Control Enable Switch'},
{ item_id: 595, item_text: ' 65265 / 595 : CCVS1 - Cruise Control Active'},
{ item_id: 527, item_text: ' 65265 / 527 : CCVS1 - Cruise Control State'},
{ item_id: 1633, item_text: ' 65265 / 1633 : CCVS1 - Cruise Control Pause Switch'},
{ item_id: 100, item_text: ' 65263 / 100 : EFL_P1_EMS - EngOilPress'},
{ item_id: 175, item_text: ' 65262 / 175 : ET1 - Engine oil temperature'},
{ item_id: 174, item_text: ' 65262 / 174 : ET1 - Fuel temperature'},
{ item_id: 110, item_text: ' 65262 / 110 : ET1 - Engine coolant temperature'},
//{ item_id: 250, item_text: ' 65257 / 250 : LFC1 - Total fuel used'},
{ item_id: 182, item_text: ' 65257 / 182 : LFC1 - Trip fuel'},
{ item_id: 779, item_text: ' 65219 / 779 : ETC5 - Transmission Low Range Sense Switch'},
{ item_id: 778, item_text: ' 65219 / 778 : ETC5 - Transmission High Range Sense Switch'},
{ item_id: 1136, item_text: ' 65188 / 1136 : ET2 - Engine ECU Temperature'},
{ item_id: 5245, item_text: ' 65110 / 5245 : AT1T1I1 - Aftertreatment Selective Catalytic Reduction Operator Inducement Active'},
{ item_id: 1761, item_text: ' 65110 / 1761 : AT1T1I1 - Aftertreatment 1 Diesel Exhaust Fluid Tank Level'},
{ item_id: 2404, item_text: ' 65088 / 2404 : LD - Running Light'},
{ item_id: 2398, item_text: ' 65088 / 2398 : LD - Implement OEM Option 2 Light'},
{ item_id: 2396, item_text: ' 65088 / 2396 : LD - Implement OEM Option 1 Light'},
{ item_id: 2390, item_text: ' 65088 / 2390 : LD - Rear Fog Lights'},
{ item_id: 2388, item_text: ' 65088 / 2388 : LD - Tractor Front Fog Lights'},
{ item_id: 2374, item_text: ' 65088 / 2374 : LD - Right Stop Light'},
{ item_id: 2372, item_text: ' 65088 / 2372 : LD - Left Stop Light'},
{ item_id: 2370, item_text: ' 65088 / 2370 : LD - Right Turn Signal Lights'},
{ item_id: 2368, item_text: ' 65088 / 2368 : LD - Left Turn Signal Lights'},
{ item_id: 2350, item_text: ' 65088 / 2350 : LD - Low Beam Head Light Data'},
{ item_id: 2348, item_text: ' 65088 / 2348 : LD - High Beam Head Light Data'},
{ item_id: 3948, item_text: ' 64932 / 3948 : PTODE - At least one PTO engaged'},
{ item_id: 4175, item_text: ' 64892 / 4175 : DPFC1 - Diesel Particulate Filter Active Regeneration Forced Status'},
{ item_id: 3750, item_text: ' 64892 / 3750 : DPFC1 - Diesel Particulate Filter 1 Conditions Not Met for Active Regeneration'},
{ item_id: 3718, item_text: ' 64892 / 3718 : DPFC1 - Diesel Particulate Filter Automatic Active Regeneration Initiation Configuration'},
{ item_id: 3717, item_text: ' 64892 / 3717 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to Vehicle Speed Below Allowed Speed'},
{ item_id: 3716, item_text: ' 64892 / 3716 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to Engine Not Warmed Up'},
{ item_id: 3715, item_text: ' 64892 / 3715 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to Permanent System Lockout'},
{ item_id: 3714, item_text: ' 64892 / 3714 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to Temporary System Lockout'},
{ item_id: 3713, item_text: ' 64892 / 3713 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to System Timeout'},
{ item_id: 3712, item_text: ' 64892 / 3712 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to System Fault Active'},
{ item_id: 3711, item_text: ' 64892 / 3711 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to Low Exhaust Gas Temperature'},
{ item_id: 3710, item_text: ' 64892 / 3710 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to Parking Brake Not Set'},
{ item_id: 3709, item_text: ' 64892 / 3709 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to Vehicle Speed Above Allowed Speed'},
{ item_id: 3708, item_text: ' 64892 / 3708 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to Out of Neutral'},
{ item_id: 3707, item_text: ' 64892 / 3707 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to Accelerator Pedal Off Idle'},
{ item_id: 3706, item_text: ' 64892 / 3706 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to PTO Active'},
{ item_id: 3705, item_text: ' 64892 / 3705 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to Service Brake Active'},
{ item_id: 3704, item_text: ' 64892 / 3704 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to Clutch Disengaged'},
{ item_id: 3703, item_text: ' 64892 / 3703 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Due to Inhibit Switch'},
{ item_id: 3702, item_text: ' 64892 / 3702 : DPFC1 - Diesel Particulate Filter Active Regeneration Inhibited Status'},
{ item_id: 3701, item_text: ' 64892 / 3701 : DPFC1 - Diesel Particulate Filter Status'},
{ item_id: 3700, item_text: ' 64892 / 3700 : DPFC1 - Diesel Particulate Filter Active Regeneration Status'},
{ item_id: 3699, item_text: ' 64892 / 3699 : DPFC1 - Diesel Particulate Filter Passive Regeneration Status'},
{ item_id: 3698, item_text: ' 64892 / 3698 : DPFC1 - Exhaust System High Temperature Lamp Command'},
{ item_id: 3697, item_text: ' 64892 / 3697 : DPFC1 - Diesel Particulate Filter Lamp Command'},
{ item_id: 5054, item_text: ' 64777 / 5054 : HRLFC - High Resolution Engine Total Fuel Used'},
{ item_id: 5053, item_text: ' 64777 / 5053 : HRLFC - High Resolution Engine Trip Fuel'},
{ item_id: 5086, item_text: ' 64775 / 5086 : DLCC1 - Engine Air Filter Restriction Lamp Command'},
{ item_id: 5084, item_text: ' 64775 / 5084 : DLCC1 - Engine Coolant Level Low Lamp Command'},
{ item_id: 5083, item_text: ' 64775 / 5083 : DLCC1 - Engine Coolant Temperature High Lamp Command'},
{ item_id: 5082, item_text: ' 64775 / 5082 : DLCC1 - Engine Oil Pressure Low Lamp Command'},
{ item_id: 5081, item_text: ' 64775 / 5081 : DLCC1 - Engine Brake Active Lamp Command'},
{ item_id: 5080, item_text: ' 64775 / 5080 : DLCC1 - OBD Malfunction Indicator Lamp Command'},
{ item_id: 5079, item_text: ' 64775 / 5079 : DLCC1 - Engine Red Stop Lamp Command'},
{ item_id: 5078, item_text: ' 64775 / 5078 : DLCC1 - Engine Amber Warning Lamp Command'},
{ item_id: 10297, item_text: ' 64712 / 10297 : EEC13 - Engine Performance Bias Level'},
{ item_id: 899, item_text: ' 61444 / 899 : EEC1 - Engine torque mode'},
{ item_id: 513, item_text: ' 61444 / 513 : EEC1 - Actual engine - percent torque'},
{ item_id: 512, item_text: ' 61444 / 512 : EEC1 - Drivers demand engine - percent torque'},
{ item_id: 2432, item_text: ' 61444 / 2432 : EEC1 - Engine Demand Percent Torque'},
{ item_id: 190, item_text: ' 61444 / 190 : EEC1 - Engine speed'},
{ item_id: 1675, item_text: ' 61444 / 1675 : EEC1 - Engine Starter Mode'},
{ item_id: 1483, item_text: ' 61444 / 1483 : EEC1 - Source Address Of Controlling Device for Engine Control'}   
];
    this.selectedItems = [
      //{ item_id: 3, item_text: 'Pune' },
      //{ item_id: 4, item_text: 'Navsari' }
    ];
    this.dropdownSettings={
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
	  limitSelection: 10,
      allowSearchFilter: true
    };
  }
  onItemSelect(item: any) {
	  
	
	  this.selectedSPN_array=[];
   // console.log(item);
	  console.log(JSON.stringify(this.selectedItems));
	  for(let i =0; i < this.selectedItems.length;i++) {
		
		 this.selectedSPN_array.push(this.selectedItems[i].item_id);
		 
	 }
	 
	
  }
  
   onItemDeSelect(item: any) {
	  
	
	 
   // console.log(item);
   
	console.log(JSON.stringify(this.selectedItems));
	for( var i = 0; i < this.selectedSPN_array.length; i++){
		if ( this.selectedSPN_array[i] === item.item_id) { 
		
		this.selectedSPN_array.splice(i, 1); 
		
		
		}
		
		}
	
	
  }
  
  
  
  
  onSelectAll(items: any) {
    console.log(items);
  }
        }

       
 

