
<div data-theme="default" data-layout="fluid" data-sidebar-position="left" data-sidebar-behavior="sticky">
	<div class="wrapper">
		<app-sidebar></app-sidebar>
		<div class="main">
			<app-header></app-header>
			<main class="content">
				<div class="container-fluid p-0">
					<div class="row mb-2 mb-xl-3">
					<div class="col-auto">
						<h3 class="dash-text">
							<svg [routerLink]="['/fecattributes']" xmlns="http://www.w3.org/2000/svg" 
							width="24" height="24" viewBox="0 0 24 24"
							fill="none" stroke="currentColor" stroke-width="2"
							stroke-linecap="round" stroke-linejoin="round"
							class="feathers feather-chevron-left align-middle">
							<polyline points="15 18 9 12 15 6"></polyline>
							</svg>
							FEC Attributes</h3>
					</div>
					<div class="col-auto ms-auto text-end mt-n1-1">
					<button class="btn download-list-btn" (click)="downloadExcelFormat()">
					<svg xmlns="http://www.w3.org/2000/svg" 
					width="24" height="24" viewBox="0 0 24 24"
					fill="none" stroke="currentColor" stroke-width="2"
					stroke-linecap="round" stroke-linejoin="round"
					class="feather feather-download align-middle">
					<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
					<polyline points="7 10 12 15 17 10"></polyline>
					<line x1="12" y1="15" x2="12" y2="3"></line>
					</svg>
					<!-- <i class="align-middle" data-feather="download">&nbsp;&nbsp;</i> -->
					<span style="white-space: nowrap;margin-left: 4px;">Format</span>
					</button>&nbsp;
					<button class="btn date-btn" (click)="openMonthModel(mySelectMonthModal)">
						<span class="icon icon-calendar"></span>
						<span class="year-text" style="white-space: nowrap;margin-left: 4px;">
							<!-- {{selectedDate | date:'dd'}} {{selectedDate | date:'MMM'}} -->
								<!-- {{selectedDate | date:'YYYY'}} -->
								{{selectedDate | date:'MMM'}} {{selectedDate | date:'YYYY'}}
						</span>
					</button>
					</div>
					</div>
                </div>
			   <div class="card bg-light-fig py-2 py-md-3 border m1-left">
				<div class="row">
					<div class="col-sm-2">
						<img src="assets/images/user-pic-1.png" class="img-fluid rounded-circle mb-2 " width="60" height="60">
					</div>
					<div class="col-sm-10">
						<div class="col-8 col-sm-12">
							<div class="row">
							<div class="col-sm-3 mobile-width-user">
								<div class="name1">Full Name</div>
					            <div class="name2">{{FecData[1]?.postn_pr_emp_fst_name}} {{FecData[1]?.postn_pr_emp_last_name}}</div>
							</div>
							<div class="col-sm-3 mobile-width-user">
								<div class="name1">Email ID</div>
					            <div class="name2">rktalati02@gmail.com</div>
							</div>
							<div class="col-sm-3 mobile-width-user">
							  <div class="name1">Dealer Name</div>
					          <div class="name2">{{FecData[1]?.org_name}}</div>
							</div>
							<br>
							<div class="col-sm-3 mobile-width-user padding-pro" >
								<div class="name1">Mobile</div>
								<div class="name2">{{FecData[1]?.postn_pr_emp_cell_ph_num}}</div>
							</div>
						</div>
						  </div>
						  <div class="row-divivder"></div>
						  <div class="col-4 col-sm-12">
							<div class="row">
								<div class="col-sm-3 mobile-width-user">
									<div class="name3">Date Of Joining</div>
									<div class="name4">{{FecData[1]?.assigned_date}}</div>
								</div>
								<div class="col-sm-3 mobile-width-user">
									<div class="name3">Total Tasks Assigned</div>
									<div class="name4">{{FecData[1]?.total_task_assigned}}</div>
								</div>
							</div>
						  </div>
					</div>
				</div>
			   </div>
				<section>
					<div class="cp-section">
						<app-fec-attribute-customer-details></app-fec-attribute-customer-details>
					</div>
				</section>
			</main>
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
			<div class="final-divider">
				
			</div>
		   </div>
		</div>
	</div>
</div>




















	<!--footer>
			Copyright &#169; 2020 TATA MOTORS All Rights Reserved.
		</footer-->


		<ng-template #mySelectMonthModal let-modal>
			<div class="modal-content">
			  <div class="modal-header">
				<h4 class="modal-title model-title-text">Apply Month Filter </h4>
				  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
			  </div>
			  <div class="modal-body">
				<form class="ui form" name="dateFilterform">
				  <div class="row col-md-12 p-calendar-cls">
					<div class="form-group col-12">
					  <p-calendar [ngModelOptions]="{standalone: true}" 
					  [(ngModel)]="Monthdate" view="month" dateFormat="MM yy" 
					  [readonlyInput]="true" [inline]="true" 
					  [showYear]="true">
					  </p-calendar>
					</div>  
					<div class="text-center" style="margin-top:4%;">
					  <button type="button" class="btn btn-default submit-button" (click)="addDateFilter()"
					  data-bs-dismiss="modal">Submit</button>
					</div>
				  </div>
				</form>
			  </div>
			</div>
		</ng-template>