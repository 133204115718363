
<app-header></app-header>
<div class="lyt-main" id="lyt-main">


<section>
    <div class="cp-section">    	
	
              <div class="sec-head full-width-lw">		   
               <div class="left-wrap" style="margin:10px;">
                <h2 class="sec-title" >FEC Assignment</h2>
            </div>
            <div class="sec-head full-width-lw" style="margin:10px;">
       <div class="right-wrap ">
                <ul class="act-list typ-multiline">
        
      
                    <li class="act-item typ-full">          
                
                
                        <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <input type="text"  [(ngModel)]="searchData" class="form-control" placeholder="FEC First Name"  value="">
                                </div>
                            </div>
                        </div>
                    </li>

                     <li class="act-item typ-full">          
                
                
                        <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <input type="text"  [(ngModel)]="searchData" class="form-control" placeholder="FEC Last Name"  value="">
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="act-item">
                        <button (click)="assignuser()"  class="btn btn-primary" style="background: linear-gradient(180deg, #03045E 0%, #023E8A 100%);
border-radius: 5px;" >
           Assign</button>
            
            
            
                    </li>
                    
                </ul>
            </div>
    </div>
			
			
			 
			
        </div>
		
      
     
		<table p-table  p-table  class="table row-border hover "  >

      
  <thead>
    <tr>
       <th>Assign</th>
       <th>Id</th>
      <th>First Name</th>
      <th>Last Name</th>
      <th>User Name</th>
      <th>Email Id</th>
      <th>Company Name</th>
       <th>Country</th>
        <th>State</th>
         <th>City</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of newData | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems:totalItems}; let i = index;" >
      <td><input type="checkbox" name="user_id_check[]" id="user_id_check" class="form-control" value="{{item.user_id}}" (change)="getchecked_value($event)">
 

      </td>
      <td>{{item.id}}</td>
      <td>{{item.first_name}}</td>
      <td>{{item.last_name}}</td>
      <td>{{item.user_name}}</td>
      <td>{{item.email_id}}</td>
      <td>{{item.company_name}}</td>

       <td>{{item.country}}</td>
      <td>{{item.state}}</td>
      <td>{{item.city}}</td>
    </tr>
  </tbody>
</table>
      
      <div class="sec-head full-width-lw c" style="float:right;">
        <div class="right-wrap ">
          <ul class="act-list typ-multiline">

            <li class="act-item" style="padding-top:5px;">
              <pagination-controls (pageChange)="pageChanged($event)" *ngIf="resultCheck"></pagination-controls>
            </li>
          </ul>
        </div>
      </div>






    </div>
   
</section>





  









<!--footer>
			Copyright &#169; 2020 TATA MOTORS All Rights Reserved.
		</footer-->