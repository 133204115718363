import { Component, OnInit } from '@angular/core';
import { BaseApiService } from 'src/app/base-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
//import { RouterModule, Router } from '@angular/router';
//import { Router } from '@angular/router'; 
import {ActivatedRoute, Router} from '@angular/router';
import { map } from 'rxjs/operators';
declare var myExtObject: any;
declare var webGlObject: any;
@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})


export class OnboardingComponent implements OnInit {

  constructor(private baseAPI: BaseApiService,public spinnerService: NgxSpinnerService,private route:ActivatedRoute,private router:Router) { }

  public loginStep: boolean = true;
  public signupStep: boolean = false;
  public verificationStep: boolean = false;
  public signupWelcomeStep: boolean = false;
  public forgotPwdEmailStep: boolean = false;
  public forgotPwdOtpStep: boolean = false;
  public resetPwdStep: boolean = false;
  public showPwd: boolean = false;
  public showCreatePwd: boolean = false;
  public showConfirmPwd: boolean = false;
  otpCheck:boolean = false; //second div
  otpSuccsessDiv:boolean = true;
  	term:string = '';
  username:string = '';
  token:string = '';
  password:string = '';
   granttype:string = 'password';
  otp:string = '';
  RememberMe:boolean = false;
	//client side validation
	usernameError:boolean = true;
	panCardError:boolean = true;
	passwordError:boolean=true;
	//response validation
	validationError:boolean = true;
	validationErrorOtp:boolean = true;
	validationErrorMember:boolean = true;
	errorMsg:string = "Invalid Credentials ";
		otpMsg:string = "Invalid Credentials ";
	memberShipMsg:string = "Invalid Credentials ";
	pancardMsg='';
	viewBalancePoint:boolean = true;
	public membershipList:any=[]
 
	sendOtpRequest:boolean = true;
	//clent side status
	usernameStatus:boolean = false;
	passwordStatus:boolean = false;
	public login_state:boolean = false;

  ngOnInit() {
    
  }

  public minutes;
  public seconds;
  public mins: number;
  public secs: number;
  public checkTimeOut;
  
  login(){
		 
		
      if(this.username !='' ){
                  this.usernameError = true;//error
                  this.usernameStatus = true; //status
                  this.validationError= true;
				  this.passwordStatus= true;
      }else{
        this.usernameError = false;
        this.usernameStatus = false;
        this.validationError= true;
      }
      

      if(this.usernameStatus && this.passwordStatus){
         
          this.spinnerService.show();
		 
		 
         
		 
          var data = {'username':this.username, 'password': this.password, 'grant_type' : this.granttype}
		  var dataString = 'username='+this.username+'&password='+this.password+'&grant_type='+this.granttype+"&client_id=analytics-data";
		  
		  
         
          // localStorage.setItem('url', 'login');
          // Cookie.set("url",'login');
          // Cookie.set("login", 'true')
          // Cookie.set("dashboard", 'true')
          // Cookie.set("users", 'false')
          // Cookie.set("roles", 'true')
          // Cookie.set("customers", 'true')
          // console.log(Cookie.get("users"));
		  
		  var formData: any = new FormData();
		  formData.append("username", this.username);
		  formData.append("password", this.password);
		   formData.append("grant_type", this.granttype);  
		    formData.append("client_id", 'analytics-data');
			
		//	const HttpUploadOptions = {
          //        headers: new HttpHeaders({ "Content-Type": "application/x-www-form-urlencoded" })
            //    }
		   

			this.baseAPI.postLogin(this.baseAPI.baseTokenUrl,dataString).subscribe((res: any) => {
			this.spinnerService.hide();
			var jsonObj = JSON.parse(JSON.stringify(res));
			
			myExtObject.firebaseUserId(this.username);  
			 var today = new Date();
			 myExtObject.firebaseEvent('login', { user_name: this.username,date:today});
			this.token = jsonObj.access_token;
			localStorage.setItem('token', this.token);
			localStorage.setItem("auth_token", this.token);
			// Cookie.set("auth_token",this.token);
			localStorage.setItem("username", this.username);
			//localStorage.setItem("refresh_time", today);
			localStorage.refresh_time = today;
			// Cookie.set("username",this.username);
			//window.location.href = "http://localhost:4200/#/dashboard/";
			this.router.navigate(['/eventdata']);
		  
		 if(res.msg=="user is valid for enrollment")
		 {
			  this.otpCheck = true;
			  this.sendOtpRequest=false;
			  this.validationErrorOtp= true;
			  this.otpSuccsessDiv= false;
              this.otpMsg = "An OTP has been sent to your registered mobile no.";
			 
		 }
		 
              //  this.otpCheck = true;
			//	this.sendOtpRequest=false;
			  
			   console.log(JSON.stringify(res));
               /*localStorage.setItem("admin_id", res.data[0].emp_row_id);
              
               localStorage.setItem("organization_name", res.data[0].organization_name);
               console.log(localStorage.getItem("organization_name")+ ' mail  ');
               localStorage.setItem("auth_token", res.token.access_token);
               localStorage.setItem("refresh_token", res.token.refresh_token);
              Cookie.set("auth_token",res.token.access_token)
              console.log("reponse",res);
              Cookie.set("dealer_code",res.data[0].dealer_code)
              Cookie.set("dsm_name",res.data[0].dsm_name)
              localStorage.setItem("dsm_name",res.data[0].dsm_name);
              Cookie.set('SESSION_TOKEN',res.token.expires_in,0.25);
              console.log(localStorage.getItem("dsm_name")+  ' ds name');*/
               //Cookie.set("user",res.user)
               //this.router.navigate(['home']);
              if(res.success=="fail")
              {
                 this.validationError= false;
                 this.errorMsg = "Please try again, server error.";
              }
             
              
            }, error => {
                this.validationError= false;
               // console.info('error', error);
               // alert(error.msg);
               var jsonObj = JSON.parse(JSON.stringify(error));
               console.log(jsonObj);
       var message = "Credential is wrong";
       //alert(message);
	   this.spinnerService.hide();
         this.errorMsg = message;
              // var message = jsonObj.msg;
               if (message != undefined && message != '' && message != null) {
                this.errorMsg = message;
               }
               else {
                this.errorMsg = message;//'Please try again, server error.';
               }
            
             this.router.navigate(['login']);
            })

      }
    
  }
  startTimer() {
    this.mins = 4;
    this.secs = 59;
    clearInterval(this.checkTimeOut);
    this.checkTimeOut = setInterval(() => {
      if (this.mins <= 0 && this.secs <= 0) {
        clearInterval(this.checkTimeOut);
        document.querySelector(".resend-otp .btn-icon").removeAttribute("disabled");
      }
      else {
        if (this.secs == 0) {
          this.secs = 60;
        }
        this.secs = this.secs - 1;

        if (this.secs < 10) {
          this.seconds = "0" + this.secs;
        } else {
          this.seconds = this.secs;
        }
        if (this.secs == 0 && this.mins > 0) {
          this.mins = this.mins - 1;
        }
        this.minutes = this.mins;
      }
    }, 1000);
  }

}
