<!-- <p>fec-attribute-customer-details works!</p> -->

<!-- <div class="card"> -->


<div class="row grid">

    <!-- <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7"> -->
    <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12 align-items-center justify-content-center">
        <ng-container *ngIf="newCustomerInformationForm">
            <div class="reset-top">
            <div class="input-group  col-lg-4 col-md-4 col-sm-4 col-xs-4" style="padding-bottom: 4%;">
                <input autocomplete="off"  type="search" name="search" [(ngModel)]="searchByContact" class="form-control py-2 border-right-0 border"
                    oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)a-zA-Z]+$/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder="Search With Mobile Number"  maxlength="10">
                <div class="input-group-append">
                    <div class="input-group-text" (click)="searchCustomerByContactNumber()" id="btnGroupAddon2"><i
                            class="icon icon-search"></i></div>
                </div>
            </div>
            <div>
                <button class="btn btn-task btn-lg" (click)="btnReset()">Reset</button>
            </div>
            </div>
            <form class="ui form" [formGroup]="newCustomerInformationForm">
                <h3 class="dash-text">ASSIGN TASK</h3>
                <div class="row">
                    <div class="from-m1 col-lg-4 col-md-4 col-sm-4 col-xs-4"><label>Company Name</label>
                    <input tabindex="-1" class="form-input-css form-control" maxlength="255"
                    [ngClass]="isCompanyName==true? 'disabled':''"
                    
                    oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');"
                    type="text" formControlName="companyName" placeholder="Company Name" />
                    <div class="error-message"
                        *ngIf="newCustomerInformationForm.value.isNew && newCustomerInformationForm.controls.companyName.errors?.required && newCustomerInformationForm.controls.companyName.touched">
                        Company Name is Required
                    </div>
                    </div>

                    <div class="from-m1 col-lg-4 col-md-4 col-sm-4 col-xs-4"><label>Account Type <span class="required">*</span></label>
                        <select class="form-select from-select-css ui dropdown " formControlName="accountType"
                            [disabled]="!(newCustomerInformationForm.value.isNew)" [ngClass]="!(newCustomerInformationForm.value.isNew)? 'disabled':''">
                            <option value="" disabled>Select Account Type</option>
                            <option *ngFor="let option of accountTypeOptions" value="{{ option.key }}">{{ option.value
                                }}</option>
                        </select>
                        <div class="error-message"
                            *ngIf="newCustomerInformationForm.controls.accountType.errors?.required && newCustomerInformationForm.controls.accountType.touched">
                            Account Type is Required
                        </div>
                    </div>
                    <div class="from-m1 col-lg-4 col-md-4 col-sm-4 col-xs-4"><label>Industry Type</label>
                        <select class="from-select-css form-select ui dropdown" formControlName="industryType"
                           
                            [disabled]="!(newCustomerInformationForm.value.isNew)">
                            <option value="" disabled>Select Industry Type</option>
                            <option *ngFor="let option of industryTypeOptions" value="{{ option.key }}">{{ option.value
                                }}</option>
                        </select>
                        <!-- <input tabindex="-1" class="form-control" maxlength="255" 
                            oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');" 
                            type="text" formControlName="industryType"
                            placeholder="Industry Type" />-->
                        <div class="error-message"
                            *ngIf="newCustomerInformationForm.value.isNew && newCustomerInformationForm.controls.industryType.errors?.required && newCustomerInformationForm.controls.industryType.touched">
                            Industry Type is Required
                        </div>
                    </div>

                    <div class="from-m1 col-lg-4 col-md-4 col-sm-4 col-xs-4"><label>First Name <span
                                class="required">*</span></label>
                        <input tabindex="-1" class="form-input-css form-control" maxlength="255"
                            [ngClass]="!(newCustomerInformationForm.value.isNew)? 'disabled':''"
                            [readonly]="!(newCustomerInformationForm.value.isNew)"
                            oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');"
                            type="text" formControlName="FirstName" placeholder="First Name" />
                        <div class="error-message"
                            *ngIf="newCustomerInformationForm.value.isNew && newCustomerInformationForm.controls.FirstName.errors?.required && newCustomerInformationForm.controls.FirstName.touched">
                            First Name is Required
                        </div>
                    </div>

                    <div class="from-m1 col-lg-4 col-md-4 col-sm-4 col-xs-4"><label>Last Name <span
                                class="required">*</span></label>
                        <input tabindex="-1" class="form-input-css form-control" maxlength="255"
                            [ngClass]="!(newCustomerInformationForm.value.isNew)? 'disabled':''"
                            [readonly]="!(newCustomerInformationForm.value.isNew)"
                            oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');"
                            type="text" formControlName="LastName" placeholder="Last Name" />
                        <div class="error-message"
                            *ngIf="newCustomerInformationForm.value.isNew && newCustomerInformationForm.controls.LastName.errors?.required && newCustomerInformationForm.controls.LastName.touched">
                            Last Name is Required
                        </div>
                    </div>

                    <div class="from-m1 col-lg-4 col-md-4 col-sm-4 col-xs-4"><label>Region <span
                                class="required">*</span></label>
                        <!-- <input tabindex="-1" class="form-input-css form-control" maxlength="255"
                            oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');"
                            type="text" formControlName="Region" placeholder="Region Name"  autocomplete="off"/> -->

                            <select class="form-select from-select-css ui dropdown" formControlName="Region">
                                <option value="" disabled>Select Your Region</option>
                                <option *ngFor="let option of getallregion" value="{{ option.key }}">{{
                                    option.value }}</option>
                            </select>
                        <div class="error-message"
                            *ngIf="newCustomerInformationForm.controls.Region.errors?.required && newCustomerInformationForm.controls.Region.touched">
                            Region is Required
                        </div>
                    </div>


                    <div class="from-m1 col-lg-4 col-md-4 col-sm-4 col-xs-4"><label>State <span
                                class="required">*</span></label>
                        <!-- <input tabindex="-1" class="form-control" maxlength="255" 
                            oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');" 
                            type="text" formControlName="State"
                            placeholder="State" /> -->
                        <!-- <select class="form-select from-select-css ui dropdown " formControlName="State"
                            (change)="getCityList(newCustomerInformationForm.value.State)"
                            [ngClass]="!(newCustomerInformationForm.value.isNew)? 'disabled':''"
                            [disabled]="!(newCustomerInformationForm.value.isNew)">
                            <option value="">Select Your State</option>

                            <option *ngFor="let option of stateOptions" value="{{ option.key }}">{{ option.value }}
                            </option>
                        </select> -->
                        <ng-select 
                        [items]="stateOptions"
                        bindLabel="state"
                        placeholder="Select Your State"
                        formControlName="State"
                        bindValue="state"
                        (ngModelChange)="getCityListApi($event)"
                        >
                         </ng-select>
                         
                           
                        <div class="error-message"
                            *ngIf="newCustomerInformationForm.value.isNew && newCustomerInformationForm.controls.State.errors?.required && newCustomerInformationForm.controls.State.touched">
                            State is Required
                        </div>
                    </div>


                    <div class="from-m1 col-lg-4 col-md-4 col-sm-4 col-xs-4"><label>City <span class="required">*</span></label>
                        <!-- <select class="form-select from-select-css ui dropdown" formControlName="City"
                            [ngClass]="!(newCustomerInformationForm.value.isNew)? 'disabled':''"
                            [disabled]="!(newCustomerInformationForm.value.isNew)">
                            <option value="">Select Your City</option>
                            <option *ngFor="let option of cityOptions" value="{{ option.key }}">{{ option.value }}
                            </option>
                        </select> -->
                        <ng-select 
                        [items]="cityOptions"
                        bindLabel="city"
                        placeholder="Select Your City"
                        formControlName="City"
                        bindValue="city"
                        >
                         </ng-select>
                        <div class="error-message"
                            *ngIf="newCustomerInformationForm.value.isNew && newCustomerInformationForm.controls.City.errors?.required && newCustomerInformationForm.controls.City.touched">
                            City is Required
                        </div>
                    </div>


                    <div class="from-m1 col-lg-4 col-md-4 col-sm-4 col-xs-4"><label>Mobile <span class="required">*</span></label>
                    <input class="form-input-css form-control" maxlength="12"
                    [ngClass]="!(newCustomerInformationForm.value.isNew)? 'disabled':''"
                    [readonly]="!(newCustomerInformationForm.value.isNew)"
                    oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)a-zA-Z]+$/g, '').replace(/(\..*)\./g, '$1');"
                    type="text" formControlName="Mobile" placeholder="Mobile " maxlength="10"/>
                    <div class="error-message"
                    *ngIf="newCustomerInformationForm.value.isNew && newCustomerInformationForm.controls.Mobile.errors?.required && newCustomerInformationForm.controls.Mobile.touched">
                    Mobile is Required
                    </div>
                    <div class="error-message" *ngIf="!(newCustomerInformationForm.controls.Mobile.errors?.required) && newCustomerInformationForm.controls.Mobile.errors && newCustomerInformationForm.controls.Mobile.touched">
                    Please,Enter 10 digit Mobile Number.
                    </div>
                    </div>
                    <div class="from-m1 col-lg-4 col-md-4 col-sm-4 col-xs-4"><label>Month
                    <span class="required">*</span>
                    </label>
                    <!-- type="month" -->
                    <!--  min="2023-06" -->
                        <input   [min]="MinDate" (change)="getDateMonth($event)" tabindex="-1" class="form-input-css form-control"  type="month" 
                        formControlName="MonthYear" [placeholder]="DateFlag==true? DateMonthFlag:'MM-YYYY'" />
                        <div class="error-message"
                        *ngIf="newCustomerInformationForm.controls.MonthYear.errors?.required && newCustomerInformationForm.controls.MonthYear.touched">
                        Month is Required
                    </div>
                    </div>
                    <div class="from-m1 col-lg-4 col-md-4 col-sm-4 col-xs-4"><label>Email</label>
                        <input tabindex="-1" class="form-input-css form-control" maxlength="255"
                            [ngClass]="!(newCustomerInformationForm.value.isNew)? 'disabled':''"
                            [readonly]="!(newCustomerInformationForm.value.isNew)"
                            type="text" formControlName="Email" placeholder="Email " />
                        <div class="error-message"
                            *ngIf="newCustomerInformationForm.value.isNew && newCustomerInformationForm.controls.Email.errors?.required && newCustomerInformationForm.controls.Email.touched">
                            Email is Required
                        </div>
                        <div *ngIf="newCustomerInformationForm.value.isNew && newCustomerInformationForm.controls.Email?.touched && newCustomerInformationForm.controls.Email.errors?.pattern"
                        class="error-message">
                        Email is Invalid
                      </div>
                        <!-- <div class="error-message"
                            *ngIf="newCustomerInformationForm.value.isNew && !(newCustomerInformationForm.controls.Email.errors?.required) && (newCustomerInformationForm.controls.Email.errors?.email || newCustomerInformationForm.controls.Email.errors?.invalidEmailError) && newCustomerInformationForm.controls.Email.touched">
                            Email is Invalid
                        </div> -->
                    </div>
                    <div class="from-m1 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label>Location</label>
                        <input tabindex="-1" class="form-input-css form-control" maxlength="255"

                            oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');"
                            type="text" formControlName="Location" placeholder="Location" />
                        <div class="error-message"
                            *ngIf="newCustomerInformationForm.value.isNew && newCustomerInformationForm.controls.Location.errors?.required && newCustomerInformationForm.controls.Location.touched">
                            Location is Required
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label>Purpose of Visit</label>
                        <select class="form-select from-select-css ui dropdown" formControlName="purposeOfVisit">
                            <option value="" disabled>Select Purpose Of Visit</option>
                            <option *ngFor="let option of purposeOfVisitOptions" value="{{ option.key }}">{{
                                option.value }}</option>
                        </select>
                        <!-- <input type="text" tabindex="-1" class="form-control" *ngIf="!(newCustomerInformationForm.value.isNew)" formControlName="purposeOfVisit"/> -->
                        <div class="error-message"
                            *ngIf="newCustomerInformationForm.value.isNew && newCustomerInformationForm.controls.purposeOfVisit.errors?.required && newCustomerInformationForm.controls.purposeOfVisit.touched">
                            Purpose of visit is Required
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label>Fleet Size</label>
                        <input disabled tabindex="-1" class="form-input-css form-control" maxlength="255"
                        type="text" formControlName="fleetSize" placeholder="0" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label>Remark</label>
                        <textarea formControlName="remark" style="height:75%!important;" oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');" class="form-input-css form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        <!-- <input tabindex="-1" class="form-input-css form-control" maxlength="255"

                            oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');"
                            type="text" formControlName="Location" placeholder="Location" />
                        <div class="error-message"
                            *ngIf="newCustomerInformationForm.value.isNew && newCustomerInformationForm.controls.Location.errors?.required && newCustomerInformationForm.controls.Location.touched">
                            Location is Required
                        </div> -->
                    </div>
                </div>
            </form>
            <br />
            
        </ng-container>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 flex justify-content-center">
        <p-divider layout="vertical"></p-divider>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-5 align-items-center justify-content-center">
        <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"> -->
            <app-file-upload [getActionToBePerformed]="performUploadActionOnData"
            (executeUploadFile)="uploadFile($event,completeTask,InValid)" ></app-file-upload>
    </div>
    
</div>

<div class="divider-cls ">
    <div class="divider-flex">
    <div class="divider-text">Click on the button to assign this task to FEC.</div>
    <div class="divider-btn">
    <button (click)="assignTask(completeTask)" class="btn btn-task btn-rem-1" *ngIf="!(newCustomerInformationForm.value.isNew)">Assign Task</button>
    <button (click)="saveCustomer(completeTask)" class="btn btn-task btn-rem-1" *ngIf="(newCustomerInformationForm.value.isNew)">Assign Task</button>
    </div>
    </div>
</div>

<div class="final-divider" style="display:none;">
</div>

<ng-template #completeTask let-modal>
    <div class="modal-content">
    <div class="modal-body">
      <div class="complete-task-align">
        <div class="stat stat-sm">
          <svg xmlns="http://www.w3.org/2000/svg" 
            width="24" height="24" viewBox="0 0 24 24"
            fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-check align-middle me-2">
            <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
        </div>
      </div>
      <div class="task-comp-text">Task Successfully Assigned.</div>
      <!-- <div class="task-comp-text-success">You task is successfully completed</div> -->
      <div class="all-task-comp-text">
       You can view this task in your FEC Attribute list.
      </div>
      <div class="complete-visit-btn">
        <button type="button" class="btn btn-completed btn-lg">
          <span class="goto-complete-visit" (click)="closeCompletepopup('scheduled_visits')">OK</span>
        </button>
      </div>
    </div> 
    </div>
  </ng-template>

  <ng-template #InValid let-modal>
    <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title history-heading" style="font-size: 16px;">Validation Error</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeInvalidPopup()"></button>
          </div>
          <div class="second-heading">
          </div>
          <div class="modal-body">
              <div class="accordion" id="accordionExample" *ngFor="let res of objarray | keyvalue">
          <div class="accordion-item" >
            <h2 class="accordion-header" id="headingOne" (click)="res.isExpand = !res.isExpand">
              <button  class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                {{getKeys(res.value)[0]}}
              </button>
            </h2>
            <div *ngIf="res.isExpand" id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body" >
                {{res.value[getKeys(res.value)[0]]}}
              </div>
            </div>
          </div><br>
          </div>
          </div>
      </div>
  </ng-template>


  