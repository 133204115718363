import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BaseApiService } from 'src/app/base-api.service';
import { SharedService } from 'src/app/shared.service';
import * as XLSX from "xlsx";
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import { ExcelService } from 'src/app/excel-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-rsmdashboard',
  templateUrl: './rsmdashboard.component.html',
  styleUrls: ['./rsmdashboard.component.scss']
})
export class RsmdashboardComponent implements OnInit {
  @Output() executeUploadFile = new EventEmitter<any>();
  currentDate = new Date();
  month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  totalFEC:number=0;
  overdueVisits:number=0;
  assignedVisits:number=0;
  completedVisits:number=0;
  scheduledVisits:number=0;
  unscheduledVisits:number=0;
  resultCheck = true;
  itemsPerPage: number = 10;
  page = 1;
  totalItems=0;
  currentPage=0;
  event=0;
  currentTab:string = "total_fec";
  searchByString:string="";
  searchTerm:any;
  dashboardDataList:any = [];
  dashboardDataListSearch:any = [];
  FECRecords:any=[];
  // for test
  premiumData : any[] = [];
  paginateData: any[] = [];
  pageSize = 10;
  collectionSize = 0;
  Next:boolean=false;
  isHidden = false;
  monthFilter: Date | null;
  firstDay: Date | null ;
  Monthdate: Date | null;
  fromdate:any;
  todate:any;
  dsm_username:any;
  allowedFileTypes:any = ["xlsx", "XLSX"];
  acceptFileTypes = "."+this.allowedFileTypes.join(", .");
   ExcelData:any=[];
  JsonDataFormat=
    [{
      "fec_id":"",
      "company_name":"",
      "account_type":"",
      "industry_type":"",
      "first_name":"",
      "last_name":"",
      "region":"",
      "state":"",
      "city":"",
      "contact":"",
      "email_id":"",
      "month":"",
      "year":"",     
      "location":"",
      "purpose_of_visit":"",
      "remark":""
  }];
  objarray:any=[
    {'First Name is Required':''},
    {'First Name Accept Only Character':''},
    {'Last Name is Required':''},
    {'Last Name Accept Only Character':''},
    {'Region is Required':''},
    {'Region Accept Only Characters':''},
    {'Account Type is Required':''},
    {'Account Type Accept Only Characters':''},
    {'Contact Number is Required':''},
    {'Contact Number Accept Only 10 Digit':''},
    {'Month is Required':''},
    {'Month Accept only Number':''},
    {'Year is Required':''},
    {'Year Accept only Number':''},
    {'FEC Id is Required':''},
    {'Email Id is Invalid':''},

  ];
  fileImage:any='';
  ValidationFlag:boolean=true;
  ValidationError:boolean=true;
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private excelService: ExcelService,
    private spinnerService: NgxSpinnerService,
    private baseAPIService: BaseApiService,
    private http: HttpClient,
    private modalService: NgbModal,
    private toastr:ToastrService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    // monthFilter
    this.dsm_username=localStorage.getItem('dsm_username');
    this.initializeComponent();
  }
  openMonthModel(mySelectMonthModal:any)
  {
    this.modalService.open(mySelectMonthModal, { size: "sm",centered: true });
  }
  initializeComponent():void {
    // let current = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth()+6, 1);
    this.baseAPIService.DataRSMObj.subscribe(res => {
      if(res==null)
      {
        this.monthFilter=new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
        this.firstDay=new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
        this.Monthdate= new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
        this.monthFilter = this.sharedService.dateFormater(this.monthFilter);
        this.fromdate=this.sharedService.dateFormater(this.firstDay);
        this.todate=this.sharedService.dateFormater(this.monthFilter);
        this.fromdate=this.sharedService.dateFormater(this.firstDay);
        this.todate=this.sharedService.dateFormater(this.monthFilter);
        let obj={'fromdate':this.fromdate,'todate':this.todate,'monthFilter':this.monthFilter};
        this.baseAPIService.RSMDateRange(obj);
      }
      else
      {
        if(isJson(res)==false)
        {      
           this.fromdate=this.sharedService.dateFormater(res['fromdate']);
           this.todate=this.sharedService.dateFormater(res['todate']);
           this.Monthdate=new Date(res['monthFilter']);
           this.fetchDashboardCount();
           this.fetchFECListing();
        }
        else
        {
          let obj=JSON.parse(res);
          this.monthFilter=obj.monthFilter;
          this.Monthdate=new Date(obj.monthFilter);
          this.fromdate=obj.fromdate;
          this.todate=obj.todate;
          this.fetchDashboardCount();
          this.fetchFECListing();
        }
      }
    }); 
    // RSMDateRange
    // this.fetchDashboardCount();
    // this.fetchFECListing();
  }
  SearchbyEfc(event:any)
  {
    
    let eventStream=event.target.value.trim();
    
    if(eventStream=='' || eventStream==undefined)
    {
      this.dashboardDataList=this.dashboardDataListSearch;
      return this.dashboardDataList;
    }
    else
    {
      if(event.target.value.length>3)
      {
       let args=event.target.value.toLowerCase();
       return this.dashboardDataList=this.dashboardDataListSearch.filter(function(item:any){
        return JSON.stringify(item).toLowerCase().includes(args)
       });
      }
      else
      {
        if(this.dashboardDataListSearch.length!=0)
        {
        let args=eventStream.toLowerCase();
        let a= this.dashboardDataListSearch.filter((ele:any) => {return(ele.user_login.toLowerCase().startsWith(event.target.value.toLowerCase()))});
        let b = this.dashboardDataListSearch.filter((ele:any) => {return(ele.empname.toLowerCase().startsWith(event.target.value.toLowerCase()))});
        if(a>=b)
        {
           this.dashboardDataList=a;
        }
        else if(b>=a)
        {
           this.dashboardDataList=b;
        }
        return this.dashboardDataList;
        }
      }
    }
  }
  getTabStatusCode(tab:string):string {
    if(tab == "completed_visits"){
      return '4';
    }
    if(tab == "overdue_visits"){
      return '3';
    }
    if(tab == "scheduled_visits"){
      return '2';
    }
    if(tab == "unscheduled_visits"){
      return '1';
    }
    if(tab == "assigned_visits"){
      return '5';
    }
    return '';
  }
  fetchDashboardCount():void {
    var user_id = localStorage.getItem("username");
    if (!(user_id)) {
      console.log("RSM user not found");
      // alert("Unable to process request. Please try again");
      this.toastr.error("Unable to process request. Please try again")
      return;
    }
    var requestURL = "fecportal/dashboard/count/";
    var requestPayload = {
      "rsm_user_id": user_id,
      "fromdate":this.fromdate,
       "todate":this.todate
    };
    if(this.monthFilter){
      var date = new Date(this.monthFilter);
      var year = date.getFullYear();
      var month = ''+(date.getMonth()+1);
      month = month.padStart(2, '0');
      // requestPayload["month"] = ""+year+"-"+month+"";
    }
    this.totalFEC = 0;
    this.overdueVisits = 0;
    this.assignedVisits = 0;
    this.completedVisits = 0;
    this.scheduledVisits = 0;
    this.unscheduledVisits = 0;
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl+requestURL,requestPayload).then((response:any) => {
      if(response){
        // this.modalService.dismissAll();
        if(response['overdue']){
          this.overdueVisits = response['overdue'];
        }
        if(response['total_task_assigned']){
          this.assignedVisits = response['total_task_assigned'];
        }
        if(response['completed']){
          this.completedVisits = response['completed'];
        }
        if(response['scheduled']){
          this.scheduledVisits = response['scheduled'];
        }
        if(response['unscheduled']){
          this.unscheduledVisits = response['unscheduled'];
        }
      } else {
        this.modalService.dismissAll();
        var message = this.baseAPIService.handleSucessfulAPIResponse(response, "Unable to process request. Please try again");
        // alert(message);
        this.toastr.error(message)
      }
    },(error) => {
      this.modalService.dismissAll();
       var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      this.toastr.error(errMessage)
       localStorage.clear();
      this.router.navigate(['login']);
    });
  }
  fetchFECListing():void {
    this.spinnerService.show();
    var requestURL = "fecportal/fec/list/";
    var requestPayload = {
      // "status": this.getTabStatusCode(this.currentTab),
      "offset": this.currentPage,
      "limit": this.itemsPerPage,
      "fromdate":this.fromdate,
       "todate":this.todate,
    };
    if(this.searchByString){
      requestPayload["search_by_name"] = this.searchByString;
    }
    this.dashboardDataList = [];
    this.dashboardDataListSearch=[];
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl+requestURL,requestPayload).then((response:any) => {
      if(response){
        if(response[0]['total_results']){
          this.totalFEC = response[0]['total_results'];
        }
        if(response[0]['total_results']){
          this.totalItems = response[0]['total_results'];
        }
        if(this.currentTab=='total_fec')
        {
          this.dashboardDataList = response;
          this.FECRecords=response;
          this.dashboardDataList.shift();
          let k=0;
          this.dashboardDataList.forEach(element => {
            this.dashboardDataList[k].empname=element.postn_pr_emp_fst_name.trim()+' '+element.postn_pr_emp_last_name.trim();
            k++;
          });
          this.dashboardDataListSearch=this.dashboardDataList;
          // console.log(this.dashboardDataList,"FEC - No Records Found");
        }
        
      } else {
        console.log("FEC - No Records Found");
        var message = this.baseAPIService.handleSucessfulAPIResponse(response, "Unable to process request. Please try again");
        this.toastr.error(message);
        this.router.navigate(['login']);
      }
      this.spinnerService.hide();
    },(error) => {
      this.spinnerService.hide();
      var message = this.baseAPIService.handleSucessfulAPIResponse(error, "Unable to process request. Please try again");
      // this.toastr.error(message);
      this.router.navigate(['login']);
    });
  }
  toggleListing(status_tab:string){
    this.totalItems=0;
    this.currentPage=0;
    this.currentTab = status_tab;
    if(this.currentTab == 'total_fec'){
      this.fetchFECListing();
    } else {
      var requestPayload = {
        "offset": this.currentPage,
        "limit": this.itemsPerPage,
        "is_fec_user_data": true,
        "fromdate":this.fromdate,
        "todate":this.todate,
        // "fec_user_id": user_id,
        "status":this.getTabStatusCode(this.currentTab)=='' ? this.getTabStatusCode(this.currentTab):Number(this.getTabStatusCode(this.currentTab)),
      };
      if(this.monthFilter){
        var date = new Date(this.monthFilter);
        var year = date.getFullYear();
        var month = ''+(date.getMonth()+1);
        month = month.padStart(2, '0');
        // requestPayload["month"] = ""+year+"-"+month+"";
      }
      // if(this.searchByString){
      //   requestPayload["search_by_name"] = this.searchByString;
      // }
      this.fetchStatusListing(requestPayload);
    }
  }
  fetchStatusListing(requestPayload:any):void {
    this.spinnerService.show();
    this.dashboardDataList = [];
    this.dashboardDataListSearch=[];
    var requestURL = "fecportal/fec/list/";
    // var requestURL = "fecportal/get/list/";
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl+requestURL,requestPayload).then((response:any) => {
    if(response ){
    if(response[0]['total_results']){
      // Dashboard Total FEC Count
      this.totalFEC = response[0]['total_results'];
    }
    if(response['total_results']){
      // Table Pagination Count
      this.totalItems = response['total_results'];
    }
    let i=0;
    response.forEach((element:any) => {
      if(requestPayload.status==2)
      {
        if(response[i].total_task_assigned>0 && response[i].scheduled>0)
        {
          this.dashboardDataList.push(response[i]);
        }
      }
      else if(requestPayload.status==1)
      {
        if(response[i].total_task_assigned>0 && response[i].unscheduled>0)
        {
          this.dashboardDataList.push(response[i]);
        }
      }
      else if(requestPayload.status=='')
      {
        if(response[i].total_task_assigned>0)
        {
          this.dashboardDataList.push(response[i]);
        }
      }
      else if(requestPayload.status==5)
      {
        if(response[i].total_task_assigned>0)
        {
          this.dashboardDataList.push(response[i]);
        }
      }
      else if(requestPayload.status==4  )
      {
        if(response[i].total_task_assigned>0 && response[i].completed>0)
        {
          this.dashboardDataList.push(response[i]);
        }
      }
      else if(requestPayload.status==3)
      {
        if(response[i].total_task_assigned>0 && response[i].overdue>0)
        {
          this.dashboardDataList.push(response[i]);
        }
      }
      // else
      // {
      //   this.dashboardDataList.push(response[i]);
      // }
    i++;
    });
    this.dashboardDataListSearch=this.dashboardDataList;
    // this.modalService.dismissAll();
    } else 
    {
    console.log("FEC - No Records Found");
    this.toastr.error(response)
    }
      this.spinnerService.hide();
      },(error) => {
        this.spinnerService.hide();
        if(error.status==401)
        {
          var message = this.baseAPIService.handleSucessfulAPIResponse(error, "Unable to process request. Please try again");
          alert('Unauthorized.Your login session might be expired');
          // this.fetchFECListing();
          this.modalService.dismissAll();
          localStorage.clear();
          this.router.navigate(['login']);
        }
        else
        {
          this.spinnerService.hide();
          this.modalService.dismissAll();
                   this.router.navigate(['login']);
        }
    });
  }
  getChampInsight(fecData:any):void {
    var temData=this.sharedService.changeFECData(fecData);
    this.router.navigate(["/fecattributes"]);
  }
  pageChanged(event) {
    this.currentPage = event;  
    this.event= this.currentPage;
    this.toggleListing(this.currentTab);
  }
  addDateFilter() {
    // console.log(this.Monthdate,'Monthdate')
    let x= new Date(this.Monthdate);
    let t=new Date(x.getFullYear(), x.getMonth() + 1, 0);
    let f=new Date(x.getFullYear(), x.getMonth(), 1);
    this.fromdate= this.sharedService.dateFormater(f);
    this.todate= this.sharedService.dateFormater(t);
    let obj={'fromdate':this.fromdate,'todate':this.todate,'monthFilter':this.Monthdate};
    this.baseAPIService.RSMDateRange(obj);
    if(!this.monthFilter){
      this.Monthdate = this.sharedService.dateFormater(this.currentDate);
    }
    this.fetchDashboardCount();
    this.toggleListing(this.currentTab);
    this.modalService.dismissAll();
  }
  downloadListAsExcel():void {
    console.log(this.dashboardDataList,'this.dashboardDataList')
    var requestPayload =
    {
      rsm_user_id:localStorage.getItem("username"),
      "fromdate":this.fromdate,
      "todate":this.todate,
    }
    this.sharedService.downloadListData(requestPayload);
  }
  searchByFECName():void{
    // alert("ok1")
    console.log("SEARCH BY:", this.searchByString);
    this.toggleListing(this.currentTab);
  }
  downloadMasterData():void{
  var requestPayload = {"type":"all"};
  this.sharedService.downloadMasterDataWithpayload(requestPayload);
  }
  downloadFECData():void{
    let FECRecordsData=[];
    let i=0;
    this.FECRecords.forEach(element => {
      FECRecordsData.push({
        'FEC Id':element.user_login,
        'FEC Name':element.postn_pr_emp_fst_name+' '+element.postn_pr_emp_last_name,
        'Contact No':element.postn_pr_emp_cell_ph_num,
        'Dealer Name':element.org_name,
      })
      i++;
    });
    this.excelService.exportAsExcelFile(FECRecordsData, "FEC_Record_Excel");
  }
  getpaginateData()
  {
    this.currentPage=(this.page - 1) * this.pageSize;
    this.event = this.currentPage;
    this.fetchFECListing();
  }
  changetab(flag)
  {
    this.Next=!flag;
  }
  downloadExcelFormat1()
  {
    this.excelService.exportAsExcelFile(this.JsonDataFormat, "FEC_Excel_Format");
  }
  onBulkUpload(event:any,completeTask:any,InValid:any)
  {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length > 1) {
      // alert('Multiple files are not allowed');
      this.toastr.error('Multiple files are not allowed')

      return;
    }
    else {
      const reader: FileReader = new FileReader();
      let jsonData;
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          jsonData = wb.SheetNames.reduce((initial, name) => {
          const sheet = wb.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
         let data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
        this.validDateExcel(data,jsonData['Products Data'],completeTask,InValid)
      }
      reader.readAsBinaryString(target.files[0]);
      
    }
  }
  validDateExcel(data:any,jsonData:any,completeTask:any,InValid:any)
  {
    // let objarray:any=[
    //   {'First Name is Required':''},
    //   {'First Name Accept Only Character':''},
    //   {'Last Name is Required':''},
    //   {'Last Name Accept Only Character':''},
    //   {'Region is Required':''},
    //   {'Region Accept Only Characters':''},
    //   {'Account Type is Required':''},
    //   {'Account Type Accept Only Characters':''},
    //   {'Contact Number is Required':''},
    //   {'Contact Number Accept Only 10 Digit':''},
    //   {'Month is Required':''},
    //   {'Month Accept only Number':''},
    //   {'Year is Required':''},
    //   {'Year Accept only Number':''},
    //   {'Champ Id is Required':''},

    // ];
    // /10 digit Mobile Number
    let fobj=[];
    let fpobj=[];
    let lobj=[];
    let lfobj=[];
    let Regionobj=[];
    let Regioncobj=[];
    let Accountobj:any=[];
    let Accountobj1:any=[];
    let contactobj:any=[];
    let contactobj1:any=[];
    let monthobj:any=[];
    let monthobj1:any=[];
    let yearobj:any=[];
    let yearobj1:any=[];
    let champobj:any=[];
    let emailobj:any=[];
    let key1=data[0];
    let key2=Object.keys(this.JsonDataFormat[0]);
    let validate=isEqualsJson(key1,key2);
    // let Records=[];
    if(validate==true)
    {
    let i=0;
    jsonData.forEach((ele:any) => {
      jsonData[i].month=ele.month.toString();
      jsonData[i].year=ele.year.toString();
      jsonData[i].contact=ele.contact.toString();
      jsonData[i].fec_user_id=ele.fec_id.toString();
      jsonData[i].is_assigned=true;
    let validateRecord=this.validateRecord(ele);
    this.ExcelData.push(validateRecord.value)
    if(validateRecord.status=="INVALID")
    {
      this.ValidationFlag=false;
      if(validateRecord.controls.first_name.status=="INVALID")
      {
        // debugger;
        if(validateRecord.controls.first_name.errors?.required==true)
        {
          fobj.push(validateRecord.value.contact);
          this.objarray[0]['First Name is Required']=fobj;
        }
        else if(validateRecord.controls.first_name.errors?.pattern)
        {
          fpobj.push(validateRecord.value.contact);
          this.objarray[1]['First Name Accept Only Character']=fpobj;
        }
      }
      if(validateRecord.controls.last_name.status=="INVALID")
      {
      if(validateRecord.controls.last_name.errors?.required==true)
      {
        lobj.push(validateRecord.value.contact);
        this.objarray[2]['Last Name is Required']=lobj;
      }
      else if(validateRecord.controls.last_name.errors?.pattern)
      {
        lfobj.push(validateRecord.value.contact);
        this.objarray[3]['Last Name Accept Only Character']=lfobj;
      }
      }
      if(validateRecord.controls.region.status=="INVALID")
      {
      if(validateRecord.controls.region.errors?.required==true)
      {
        Regionobj.push(validateRecord.value.contact);
        this.objarray[4]['Region is Required']=Regionobj;
      }
      else if(validateRecord.controls.region.errors?.pattern)
      {
        Regioncobj.push(validateRecord.value.contact);
        this.objarray[5]['Region Accept Only Characters']=Regioncobj;
      }
      } 
      if(validateRecord.controls.account_type.status=="INVALID")
      {
      if(validateRecord.controls.account_type.errors?.required==true)
      {
        Accountobj.push(validateRecord.value.contact);
        this.objarray[6]['Account Type is Required']=Accountobj;
      }
      else if(validateRecord.controls.account_type.errors?.pattern)
      {
        Accountobj1.push(validateRecord.value.contact);
        this.objarray[7]['Account Type Accept Only Characters']=Accountobj1;
      }
      }  
      if(validateRecord.controls.contact.status=="INVALID")
      {
      if(validateRecord.controls.contact.errors?.required==true)
      {
        contactobj.push(validateRecord.value.contact);
        this.objarray[8]['Contact Number is Required']=contactobj;
      }
      else if(validateRecord.controls.contact.errors?.pattern)
      {
        contactobj1.push(validateRecord.value.contact);
        this.objarray[9]['Contact Number Accept Only 10 Digit']=contactobj1;
      }
      } 
      if(validateRecord.controls.year.status=="INVALID")
      {
      if(validateRecord.controls.year.errors?.required==true)
      {
        yearobj.push(validateRecord.value.contact);
        this.objarray[10]['Year is Required']=yearobj;
      }
      else if(validateRecord.controls.year.errors?.pattern)
      {
        yearobj1.push(validateRecord.value.contact);
        this.objarray[11]['Year Accept only Number']=yearobj1;
      }
      }  
      if(validateRecord.controls.month.status=="INVALID")
      {
      if(validateRecord.controls.month.errors?.required==true)
      {
        monthobj.push(validateRecord.value.contact);
        this.objarray[12]['Month is Required']=monthobj;
      }
      else if(validateRecord.controls.month.errors?.pattern)
      {
        monthobj1.push(validateRecord.value.contact);
        this.objarray[13]['Month Accept only Number']=monthobj1;
      }
      } 
      if(validateRecord.controls.fec_user_id.status=="INVALID")
      {
      if(validateRecord.controls.fec_user_id.errors?.required==true)
      {
        champobj.push(validateRecord.value.contact);
        this.objarray[14]['FEC Id is Required']=champobj;
      }
      } 
      if(validateRecord.controls.email_id.status=="INVALID")
      {
      if(validateRecord.controls.email_id.errors?.pattern)
      {
        emailobj.push(validateRecord.value.contact);
        this.objarray[15]['Email Id is Invalid']=emailobj;
      }
      } 
      //  {'Email Id is Invalid':''},
      // email_id emailobj
      this.fileImage='';
      this.modalService.open(InValid, { size: "sm",centered: true ,backdrop : 'static',keyboard : false});
    }
    else
    {
      this.ValidationFlag=true;
    }
    i++;
    });
    this.objarray=cleanobj(this.objarray);
    if(this.ValidationFlag==true)
    {
      this.AddTask(this.ExcelData,completeTask);
    }
    }
  }
  uploadFile(event:any)
  {
    console.log(event.target,'event')
    // alert("Invalid Excel Format");
    this.toastr.error("Invalid Excel Format")

  }

  getKeys = (obj:any) => Object.keys(obj).filter(key => obj[key]!=='');
  
  AddTask(data:any,completeTask:any)
  {
    this.spinnerService.show();
    var requestURL = "fecportal/fec_customer_data/create/";
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, data).then((response: any) => {
      this.ExcelData=[];
      this.fileImage='';
      this.spinnerService.hide();
      var message = this.baseAPIService.handleSucessfulAPIResponse(response, "FEC Data Uploaded successfully");
      if(response.msg=='Successfully assigned')
      {
      this.modalService.open(completeTask, { size: "sm",centered: true ,backdrop : 'static',keyboard : false});
      this.initializeComponent();
      }
      else
      {
      //  alert(response.msg);
       this.toastr.error(response.msg)
       this.initializeComponent();
       this.ExcelData=[];
      } 
    }, (error) => {
      this.ExcelData=[];
      this.fileImage='';
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      // alert(errMessage);
      this.toastr.error(errMessage);
      this.initializeComponent();
     });
  }
  closeCompletepopup()
  {
    this.modalService.dismissAll();
    this.objarray=[];
    // this.router.navigate(['/fecattributes']);
  }
  validateRecord(record:any):any 
  {
    var validateRecord = this.formBuilder.group({
      company_name: ['', [Validators.pattern(/^[a-zA-Z ]*$/)]],
      account_type: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
      industry_type: ['', [Validators.pattern(/[_':.,(){}0-9a-zA-Z ]*$/)]],
      location_str: ['', [Validators.pattern(/[_':.,(){}0-9a-zA-Z ]*$/)]],
      purpose_of_visit: ['', [Validators.pattern(/[_':.,(){}0-9a-zA-Z ]*$/)]],
      first_name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
      last_name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
      region: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
      state: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
      city: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
      contact: ['', [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email_id: ['', [Validators.email, Validators.pattern(new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}'))]],
      country: ['India', [Validators.pattern(/^[a-zA-Z ]*$/)]],
      year: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      month: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      fec_user_id:['', [Validators.required]],
      remark: [''],
      is_assigned: true

    });
    if(record["first_name"]){
      validateRecord.controls.first_name.setValue(record["first_name"]);
    }
    if(record["last_name"]){
      validateRecord.controls.last_name.setValue(record["last_name"]);
    }
    if(record["contact"]){
      validateRecord.controls.contact.setValue(record["contact"]);
    }
    if(record["email_id"]){
      validateRecord.controls.email_id.setValue(record["email_id"]);
    }
    if(record["country"]){
      validateRecord.controls.country.setValue(record["country"]);
    }
    if(record["state"]){
      validateRecord.controls.state.setValue(record["state"]);
    }
    if(record["city"]){
      validateRecord.controls.city.setValue(record["city"]);
    }
    if(record["company_name"]){
      validateRecord.controls.company_name.setValue(record["company_name"]);
    }
    if(record["account_type"]){
      validateRecord.controls.account_type.setValue(record["account_type"]);
    }
    if(record["industry_type"]){
      validateRecord.controls.industry_type.setValue(record["industry_type"]);
    }
    if(record["region"]){
      validateRecord.controls.region.setValue(record["region"]);
    }
    if(record["year"]){
      validateRecord.controls.year.setValue(record["year"].toString());
    }
    if(record["month"]){
      validateRecord.controls.month.setValue(record["month"].toString());
    }
    if(record["location"]){
      validateRecord.controls.location_str.setValue(record["location"]);
    }
    if(record["purpose_of_visit"]){
      validateRecord.controls.purpose_of_visit.setValue(record["purpose_of_visit"]);
    }
    if(record["remark"]){
      validateRecord.controls.remark.setValue(record["remark"]);
    }
    if(record["fec_id"]){
      validateRecord.controls.fec_user_id.setValue(record["fec_id"]);
    }
    validateRecord.markAsTouched();
    validateRecord.updateValueAndValidity();
    return validateRecord;
  }
  validateEmail(control: AbstractControl){
    var validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var input = control.value;
    if (input && input.match(validRegex)) {
      return false;
    } else {
      return {invalidEmailError : true};
    }
  }
  async downloadExcelFormat()
  {
    let dropdownlist=['01','02','03','04','05','06','07','08','09','10','11','12'];
    let accountTypeOptions =['Key Account','Retail Account','Others'];
    let region =['West','East','North','South'];

    const workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Products Data');
    const header = ["fec_id", "company_name", "account_type","industry_type","first_name","last_name","region","state","city",
    "contact","email_id","month", "year","location", "purpose_of_visit","remark"
  ];
  // const header = ["fec_id", "company_name", "account_type"];
    // const title = 'Car Sell Report';
    // let titleRow = worksheet.addRow([title]);
    // Set font, size and style in title row.
    // titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
    // Blank Row
    // worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    // headerRow.eachCell((cell, number) => {
    // cell.fill = {
    // type: 'pattern',
    // pattern: 'solid',
    // fgColor: { argb: 'FFFFFF00' },
    // bgColor: { argb: 'FF0000FF' }
    // }
    // cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    // });
    // Add Data and Conditional Formatting
    let joineddropdownlist = "\""+dropdownlist.join(',')+"\"";
    let joinedaccountTypeOptions = "\""+accountTypeOptions.join(',')+"\"";
    let joinedregion = "\""+region.join(',')+"\"";
    for(let i=1;i<100;i++){
    worksheet.getCell('L'+i).dataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: [joineddropdownlist]//'"One,Two,Three,Four"'
    };
    worksheet.getCell('C'+i).dataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: [joinedaccountTypeOptions]//'"One,Two,Three,Four"'
    };
    worksheet.getCell('G'+i).dataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: [joinedregion]//'"One,Two,Three,Four"'
    };
    }
    
    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, 'Bulk_upload_FEC_Format.xlsx');
    });
  }
  }
  function isJson(str:any) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return isNaN(str);
  }
  function isEqualsJson(obj1,obj2)
  {
  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);
  return keys1.length === keys2.length && Object.keys(obj1).every(key=>obj1[key]==obj2[key]);
  }
  function cleanobj(obj) {
    for (var key in obj) {
       if (Object.values(obj[key]).indexOf('') > -1) {
        delete obj[key];
      }
    }
    return obj
  }
  