<div class="lyt-main" id="lyt-main">

  <section style="height: 100%;scroll: auto;position: relative;">
    <div class="cp-section">

      <div class="sec-head full-width-lw">
        <div class="left-wrap">
          <h2 class="sec-title">Clients</h2>
        </div>
        <div class="right-wrap">

        </div>
      </div>
      <div class="sec-head full-width-lw">
        <div class="right-wrap ">
          <ul class="act-list typ-multiline">



            <li class="act-item typ-full">
              <div class="cp-form">
                <div class="form-group">
                  <div class="input-group">
                    <input type="text" [(ngModel)]="searchText" class="form-control" placeholder="Search Here"
                      (keyup)="applyFilter($event.target.value)" value="">
                  </div>
                </div>
              </div>
            </li>
            <li class="act-item">
              <button class="btn btn-icon-text" (click)="addClient();"><span
                  class="icon icon-add"></span>Create</button>
            </li>
            <li class="act-item" style="padding-top:5px;">
              <pagination-controls (pageChange)="pageChanged($event)" *ngIf="resultCheck"></pagination-controls>
            </li>
          </ul>
        </div>
      </div>
      <hr />
     
      <div class="a" *ngIf="dataLoadingCheck && !resultCheck">
        <h2 class="loadingText">Please wait data is loading....</h2>
      </div>
      <div class="errorColor" *ngIf="!dataLoadingCheck && !resultCheck">
        <h2 class="loadingText">No Records Found.</h2>
      </div>
      <div class="sec-cont">
        <div class="cp-card typ-table">
          <!-- <div class="watermarked">
            {{username}}
          </div> -->
          <div class="mat-elevation-z8 table-responsive " style="overflow-y: scroll;
          height: 400px;" [attr.data-watermark]="username" *ngIf="resultCheck && !dataLoadingCheck">
            <!-- <div class="watermark" >
              {{username}}<br>
              {{todayDate}}
            </div> -->
            <app-watermark [class.topreduce]="CheckVinFleetTabShow" [class.onerecord]="event_array.length<3"></app-watermark>
            <table class="table table-bordered"  style="overflow-y: scroll;
            height: 400px;">
             
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Client Id</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Created By</th>
                  <th>Approval Status</th>
                  <th>API List</th>
                  <!---<th>Add/Edit API</th>--->
                  <th>Client Details</th>
                  <!--th>Spn Value</th>
					<!--th>GpsLongitude</th-->
                </tr>
              </thead>
              <tbody>
                <!---
				
				
				
				[{"clientId":"test_client","clientName":null},{"clientId":"test_client_dev","clientName":null},{"clientId":"d9ef9b86-8865-4a84-8a8b-667b9db3fdea","clientName":"test_client_1"},{"clientId":"edc318eb-e683-4e1a-819b-a0cdd9513325","clientName":"test_client_2"},{"clientId":"82529486-ec5e-4fa9-b6c3-d552cf1911f9","clientName":"test_client_3"},{"clientId":"73642b6a-0c22-4f27-b4bb-c339a544e289","clientName":"test_client_4"},{"clientId":"77a218a0-b06f-40dc-b353-289a3c1e13d0","clientName":"test_client_5"},{"clientId":"e1dca206-155f-48b5-b3ca-7664b76d3fbb","clientName":"test_client_6"},{"clientId":"7264678f-c6a7-446e-aec0-2d7dd39fe9ee","clientName":"test_client_7"},{"clientId":"a088854e-45ee-439c-ab48-c3387b1f1780","clientName":"test_client_8"},{"clientId":"6a3aa20e-1299-4093-8d6c-0a9b375bd448","clientName":"test_client_9"},{"clientId":"1c8465d0-4365-4b45-9804-58858983ee7e","clientName":"test_client_10"},{"clientId":"c52c56e6-0f58-487f-bed4-bbd05781c38e","clientName":"test_client_11"},{"clientId":"b9a41137-afc3-4434-a676-5041cb0e484c","clientName":"Anirudha"},{"clientId":"83e55652-9874-44a5-8242-4ee52a66e522","clientName":"Anirudha Agrawal"},{"clientId":"7dbfca85-0f30-45c2-a3bb-3e4f34698753","clientName":"Anirudha Agrawal ddd"},{"clientId":"b595ef85-3904-4e44-992e-a3114d198069","clientName":"tmld_client_dev"},{"clientId":"794eddbc-8bd8-44d4-a4a1-9d385d9bc187","clientName":"separted_api_test"}]
				---->


                <tr
                  *ngFor="let item of event_array | paginate: { itemsPerPage: itemsPerPage, currentPage: page, totalItems:totalItems}; let i = index;">
                  <td>{{ (tempPage*itemsPerPage) + (i+1)}}</td>
                  <td>
                    <div style="width:300px;word-wrap: break-word;color:blue;">{{item.clientId}}</div>
                  </td>
                  <td>
                    <div style="width:100px;word-wrap: break-word;color: hotpink;"> <a
                        (click)="CheckVinFleetTab(item,'fleetownerdetails');">{{item.clientName}}</a></div>
                  </td>
                  <td>
                    <div>{{item.email}}</div>
                  </td>
                  <td>
                    <div>{{item.mobile}}</div>
                  </td>
                  <td>
                    <div style="width:125px;word-wrap: break-word;">{{item.validityStartDate}}</div>
                  </td>
                  <td>
                    <div style="width:125px;word-wrap: break-word;">{{item.validityEndDate}}</div>
                  </td>
                  <td>
                    <div style="width:100px;word-wrap: break-word;" *ngIf="item.createdBy==null">NA</div>
                    <div style="width:100px;word-wrap: break-word;" *ngIf="item.createdBy!=null">{{item.createdBy}}
                    </div>
                  </td>
                  <td>
                    <div style="width:100px;word-wrap: break-word;">{{item.approvalStatus}}</div>
                  </td>
                  <td>
                    <div style="width:100px;word-wrap: break-word;">{{item.supportDataType}}</div>
                  </td>
                  <!--td><div style="width:100px;word-wrap: break-word;">{{item.spnDat[0].spnValueDescription}}</div></td-->

                  <!---<td><div style="width:100px;word-wrap: break-word;">		
							<a [routerLink]="['/vehicle']" [queryParams]="{clientId: item.clientId}" fragment="api">
							Add/View Api
							</a>
					</div></td>--->
                  <td>
                    <div style="width:100px;word-wrap: break-word;">
                      <button class="btn btn-primary" (click)="checkClientDetails(item.clientId);" mat-button
                        data-toggle="modal" data-target="#checkClientDetails">
                        Client Details</button>
                    </div>
                  </td>

                  <!--td><div style="width:100px;word-wrap: break-word;">--</div></td-->


                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
     
      <hr />
      <div class="sec-cont" id="fleetownerdetails" *ngIf="CheckVinFleetTabShow">
        <div class="cp-card typ-table">
          <div class="mat-elevation-z8 table-responsive" ><!--style="overflow-y: scroll; height:350px;"-->
            <div id="myElement">
              <!-- <mat-tab-group (selectedTabChange)="fleetVInCheck($event);" > -->
                <!---<mat-tab class="tab-cont" label="By VIN">
                  <div class="sec-head full-width-lw" style="margin:10px;">
                    <div class="right-wrap ">
                      <ul class="act-list typ-multiline">
                        <li class="act-item typ-full">


                          <div class="cp-form">
                            <div class="form-group">

                              <div class="input-group1" style="width:400px;height:400px;">

                                <ng-multiselect-dropdown class="form-control" [placeholder]="'List Of VIN Available'"
                                  [data]="VinNumberData" [(ngModel)]="selectedVinItems" [settings]="dropdownVinSettings"
                                  (onSelect)="onItemVinSelect($event)" (onSelectAll)="onSelectVinAll($event)"
                                  (onDeSelect)="onItemDeVinSelect($event)">
                                </ng-multiselect-dropdown>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="act-item typ-full">					
								
								
                        <div class="cp-form">
                            <div class="form-group">							    
                          <div class="input-group1" style="width:400px;height:400px;">
                          <select class="selection">
						  <option>List of Selected Fleet Owners</option>
						  <option *ngFor="let item of selectedItems" [value]="item.first_name">
						  {{item.fleet_id}} - {{item.first_name}}
						</option>						  
						  </select>
                                </div>
                            </div>
                        </div>
                    </li> 

                        <li class="act-item" style="width:400px;height:400px;">
                          <button class="btn btn-icon-text" (click)="fetchReportWithDropDown();">
                            <span class="icon icon-search"></span>Check VIN</button>
                        </li>
                      </ul>
                    </div>
                  </div>

                </mat-tab>--->
                <mat-card (selectedTabChange)="fleetVInCheck($event);">
                  <mat-card-title>
                  <div class="card-head-custom"> 
                     <h3 style="color: black;padding-left: 5px;">  By Fleet </h3><h3>Client - <b>{{this.clientName}}</b></h3>
                  </div>
                  </mat-card-title>

                  <div class="sec-head full-width-lw" style="margin:10px;">
                    <div class="right-wrap mt-right">
                      <ul class="act-list typ-multiline">
                        <li class="act-item typ-full">


                          <div class="cp-form">
                            <div class="form-group">

                              <div class="input-group1" style="width: 300px;">
                                <ng-multiselect-dropdown  class="form-control" [placeholder]="'List of All Fleet owner'"
                                  [data]="fleetOwnerData" [(ngModel)]="selectedItems" [settings]="dropdownSettings"
                                  (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                  (onDeSelect)="onItemDeSelect($event)">
                                </ng-multiselect-dropdown>
                              </div>
                            </div>
                          </div>
                        </li>
                        <!---<li class="act-item typ-full">					
								
								
                        <div class="cp-form">
                            <div class="form-group">							    
                          <div class="input-group1" style="width:400px;height:400px;">
                          <select class="selection">
						  <option>List of Selected Fleet Owners</option>
						  <option *ngFor="let item of selectedItems" [value]="item.first_name">
						  {{item.fleet_id}} - {{item.first_name}}
						</option>						  
						  </select>
                                </div>
                            </div>
                        </div>
                    </li> --->

                        <li class="act-item" style="width:400px">
                          <button class="btn btn-icon-text" (click)="fetchReportWithDropDownOp();" mat-button
                            data-toggle="modal" data-target="#myModal">
                            <span class="icon icon-search"></span>Check VIN</button>
                        </li>
                        <li class="act-item" *ngIf="showVehicleSection"><!--style="height:400px;"-->
                          <button class="btn btn-icon-text"
                      style="font-size: 10px;border: 1px solid green;height: 37px;background-color: white;"
                      (click)=checkVinWithFleetClient();>
                      Create Client Account</button>
                        </li>
                      </ul>
                    </div>
                     
                  </div>
                </mat-card>

              <!-- </mat-tab-group> -->
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="sec-cont" *ngIf="showVehicleSection">
        <div class="cp-card typ-table">
          <div class="mat-elevation-z8 table-responsive" style=" height:160px;">
            <div id="myElement1">
              <div class="sec-head full-width-lw" style="margin:10px;">
                <div class="right-wrap create-client">
                  <ul class="act-list typ-multiline create-clientul">
                    <!--<li class="act-item typ-full">
                      <div class="cp-form">
                        <div class="form-group">
                          <div class="input-group1" ><!--style="height:400px;"
                            <select style="width: 145px; height: 38px;">
                              <option>Vehicle Delivery Status</option>
                              <option value="All">All</option>
                              <option value="Intransit">Intransit</option>
                              <option value="Bootstrap">Bootstrap</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="act-item typ-full">
                      <div class="cp-form">
                        <div class="form-group">
                          <div class="input-group1">style="height:400px;"
                            <select style="width: 145px; height: 38px;">
                              <option>Vehicle CVP Status</option>
                              <option value="Onboarded">Onboarded</option>
                              <option value="All non-Onboarded">All non-Onboarded</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </li>
                   <li class="act-item typ-full">
                      <div class="cp-form">
                        <div class="form-group">
                          <div class="input-group1"><!--style="height:400px;"
                            <select style="width: 145px; height: 38px;">
                              <option> Frequency of access</option>
                              <option value="10 minute">10 minutes</option>
                              <option value="30 minute">30 minutes</option>
                              <option value="2 hours">2 hours</option>
                              <option value="12 hours">12 hours</option>
                              <option value="24 hours">24 hours</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="act-item typ-full">
                      <div class="cp-form">
                        <div class="form-group">
                          <div class="input-group1"><!--style="height:400px;"
                            <select style="width: 145px; height: 38px;">
                              <option>API Longevity</option>
                              <option value="2 weeks">2 weeks</option>
                              <option value="4 weeks">4 weeks</option>
                              <option value="8 weeks">8 weeks</option>
                              <option value="24 weeks">24 weeks</option>
                              <option value="52 weeks">52 weeeks</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="act-item typ-full">
                      <div class="cp-form">
                        <div class="form-group">
                          <div class="input-group1"><!--style="height:400px;"
                            <ng-multiselect-dropdown class="form-control" [placeholder]="'Type of Data'"
                              [data]="typeOfVehicledata" [(ngModel)]="selectedVehicleItems"
                              [settings]="dropdownVehicleSettings" (onSelect)="onVehicleItemSelect($event)"
                              (onSelectAll)="onVehicleSelectAll($event)" (onDeSelect)="onVehicleItemDeSelect($event)"
                              style="width: 145px; height: 38px;">
                            </ng-multiselect-dropdown>
                          </div>
                        </div>
                      </div>
                    </li>-->
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
  <div *ngIf="showRecordVin">
    <div class="modal" id="myModal" style="width: 168%;">
      <!---->
      <div class="modal-content" style="width: 40%;left: 11%; top: 25%;height: 50%;overflow-y: scroll;">
        <div class="modal-header">
          <h3>Fleet Owner Vehicles Details - <b>{{fleet_first_name}}</b></h3>
        </div>
        <div class="modal-body">
          {{multipleVinNumber.length}}
          <app-watermark [class.onerecord]="!showVehicleSection || multipleVinNumber.length<3" [singlerecord]="!showVehicleSection || multipleVinNumber.length<3"></app-watermark>
          <table style="width: 100%;" class="table table-bordered">
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>VIN Number</th>
                <th>Registration Number</th>
                <th>Fleet Id</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="!showVehicleSection">
                <td colspan="4" align="center">No data found</td>
              </tr>
              <tr *ngFor="let item of multipleVinNumber index as i">
                <td>
                  <div>{{i+1}}</div>
                </td>
                <td>
                  <div>{{item.vin}}</div>
                </td>
                <td>
                  <div>{{item.registration_number}}</div>
                </td>
                <td>
                  <div>{{item.fleet_id}}</div>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
  <div class="mycontainer1">
    <div class=" modal" id="checkClientDetails">

      <div class="modal-content">
        <div class="modal-body">
          <table style="width: 100%;" class="table table-bordered">
            <thead>
              <tr>
                <td>clientId</td>
                <td>{{clientId}}</td>
              </tr>
              <tr>
                <td>clientName</td>
                <td>{{clientName}}</td>
              </tr>
              <tr>
                <td>companyName</td>
                <td>{{companyName}}</td>
              </tr>
              <tr>
                <td>mobile</td>
                <td>{{mobile}}</td>
              </tr>
              <tr>
                <td>email</td>
                <td>{{email}}</td>
              </tr>
              <!---<tr>
    <td>address</td>
    <td>{{address}}</td>
  </tr>
  <tr>
    <td>aadharNumber</td>
    <td>{{aadharNumber}}</td>
  </tr>
  <tr>
    <td>gstNumber</td>
    <td>{{gstNumber}}</td>
  </tr>
  <tr>
    <td>panNumber</td>
    <td>{{panNumber}}</td>
  </tr>
    <tr>
    <td>createdBy</td>
    <td>{{createdBy}}</td>
  </tr>--->
              <tr>
                <td>approvalStatus</td>
                <td>{{approvalStatus}}</td>
              </tr>
              <!---<tr>
    <td>approvalStatusChangeDateTime</td>
    <td>{{approvalStatusChangeDateTime}}</td>
  </tr>
  <tr>
    <td>approvalStatusChangedBy</td>
    <td>{{approvalStatusChangedBy}}</td>
  </tr>--->
              <!---<tr>
    <td>isActive</td>
    <td>{{isActive}}</td>
  </tr>--->
              <tr>
                <td>isEnabled</td>
                <td>{{isEnabled}}</td>
              </tr>
              <!---<tr>
    <td>supportedDataTypes</td>
    <td>{{supportedDataTypes}}</td>
  </tr>
  <tr>
    <td>userType</td>
    <td>{{userType}}</td>
  </tr>--->
              <tr>
                <td>validityStartDate</td>
                <td>{{validityStartDate}}</td>
              </tr>
              <tr>
                <td>validityEndDate</td>
                <td>{{validityEndDate}}</td>
              </tr>
            </thead>
            <tbody>
            </tbody>

          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="checkClientEnableStatus();"
            *ngIf="!clientStatus">Enable</button>
          <button type="button" class="btn btn-danger" (click)="checkClientDisableStatus();"
            *ngIf="clientStatus">Disable</button>
          <button type="button" class="btn btn-green" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>

  <style>
    /*
:host::ng-deep.selected-item { border-radius: 6px !important; background: #999999 !important; border: 1px solid #999999 !important; }

:host::ng-deep.selected-item {
border-radius: 6px !important;
background: #BAA28E !important;
border: 1px solid #BAA28E !important;
}

:host::ng-deep ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox] + div:before {
border: 2px solid #5e3817 !important;
}

:host::ng-deep ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked + div:before {
background: #BAA28E !important;
}

.custom-font-size{
  height:10000px;
}*/

    :host::ng-deep multiselect-dropdown[_ngcontent-c5] .dropdown-btn[_ngcontent-c5] {
      border: 0px;
    }


    :host::ng-deep.dropdown-btn {
      border-radius: 6px !important;
      border: 0px solid #999999 !important;
      padding: 0px 0px !important;
      /* width: 400px !important;*/
    }
  </style>
  <!--footer>
			Copyright &#169; 2020 TATA MOTORS All Rights Reserved.
		</footer-->