import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
// import { PlanComponent } from './Components/plan/plan.component';
import {AuthGuard} from './guards/auth.guard';

import { OnboardingComponent } from './Components/onboarding/onboarding.component';

import { LoginComponent } from './Components/login/login.component';
import { EventDataComponent } from './Components/event-data/event-data.component';
// import { TelemetryDataComponent } from './Components/telemetry-data/telemetry-data.component';
import { VehicleCanDataComponent } from './Components/vehicle-can-data/vehicle-can-data.component';
import { VehicleFuelComponent } from './Components/vehicle-fuel/vehicle-fuel.component';

import { VehicleListComponent } from './Components/vehicle-list/vehicle-list.component';
import { ClientListComponent } from './Components/client-list/client-list.component';
import { ClientApiComponent } from './Components/client-api/client-api.component';
import { ClientinnerpageComponent } from './Components/clientinnerpage/clientinnerpage.component';
// import { BulkVehicleReportComponent } from './Components/bulk-vehicle-report/bulk-vehicle-report.component';
import { AggregationComponent } from './Components/aggregation/aggregation.component';

import { FecassignmentComponent } from './Components/fecassignment/fecassignment.component';
import { PerformancedataComponent } from './Components/performancedata/performancedata.component';

import { MaintenancedataComponent } from './Components/maintenancedata/maintenancedata.component';
import { AssigndataComponent } from './Components/assigndata/assigndata.component';
import { FectaskdetailComponent } from './Components/fectaskdetail/fectaskdetail.component';
import { RsmdashboardComponent } from './Components/rsmdashboard/rsmdashboard.component';
import { FecattributesComponent } from './Components/fecattributes/fecattributes.component';
import { FectasksComponent } from './Components/fectasks/fectasks.component';
import { FecdashboardComponent } from './Components/fecdashboard/fecdashboard.component';
import { CustomerdetailsComponent } from './Components/customerdetails/customerdetails.component';
import { FecViewHistoryComponent } from './Components/fec-view-history/fec-view-history.component';
// NationalheadDashbaordModule
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
//   {path:'forgot-password',component:ForgotPasswordComponent},
  {
        path: 'rsmdashboard',
        component: RsmdashboardComponent,
        canActivate: [AuthGuard],
  },
  {
    path: 'customerdetails',
    component: CustomerdetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nationalhead-dashboard',
    loadChildren: () => import('./Components/nationalhead-dashbaord/nationalhead-dashbaord.module').then(m => m.NationalheadDashbaordModule),
    canActivate: [AuthGuard],
  },
    {
        path: 'fecdashboard',
        component: FectasksComponent,
        canActivate: [AuthGuard],
    },

    {
        path: 'fectasks',
        component: FectasksComponent,
        canActivate: [AuthGuard],
    },

     {
        path: 'fecattributes',
        component: FecattributesComponent,
        canActivate: [AuthGuard],
    },
     {
        path: 'eventdata',
        component: EventDataComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'fectaskdetail',
        component: FectaskdetailComponent,
        canActivate: [AuthGuard],
    },

{
        path: 'assigndata',
        component: AssigndataComponent,
        canActivate: [AuthGuard],
    },


  {
        path: 'fecassignment',
        component: FecassignmentComponent
    },

    {
        path: 'maintenance',
        component: MaintenancedataComponent
    },
     {
        path: 'performance',
        component: PerformancedataComponent
    },
    

  {
        path: 'aggregation',
        component: AggregationComponent
    },
    // {
    //     path: 'telemetrydata',
    //     component: TelemetryDataComponent,
    //     canActivate: [AuthGuard],
    // },
	{
        path: 'vehicle',
        component: VehicleListComponent
    },
	{
        path: 'client',
        component: ClientListComponent
    },
	{
        path: 'clientapi',
        component: ClientApiComponent
    },
	{
        path: 'vehiclecandata',
        component: VehicleCanDataComponent
    },
	{
        path: 'vehiclefuel',
        component: VehicleFuelComponent
    },
	// {
    //     path: 'ignition',
    //     component: VehicleIgnitionDataComponent
    // },	
	{
        path: 'client-inner-page',
        component: ClientinnerpageComponent
    },
    // {
    //     path: 'bulk-report',
    //     component: BulkVehicleReportComponent
    // },
    {
        path: 'fec-view-history',
        component: FecViewHistoryComponent,
        canActivate: [AuthGuard],
    },
    
  // { path: 'plan', component: PlanComponent },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
