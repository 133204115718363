import { Component, OnInit } from '@angular/core';
import { BaseApiService } from 'src/app/base-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
//import { RouterModule, Router } from '@angular/router';
//import { Router } from '@angular/router'; 
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../login.service';
import {HttpXhrBackend} from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { encode, decode } from 'js-base64';
import {Md5} from 'ts-md5';
import { SharedService } from 'src/app/shared.service';
declare var myExtObject: any;
declare var webGlObject: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  public minutes;
  public seconds;
  public mins: number;
  public secs: number;
  public checkTimeOut;
  loginForm: FormGroup;

  constructor(
    private baseAPI: BaseApiService,
    public spinnerService: NgxSpinnerService,
    private route:ActivatedRoute,
    private router:Router,
    private loginService:LoginService,
    private backend: HttpXhrBackend,
    private formBuilder: FormBuilder,
    private toastr:ToastrService,
    private sharedService:SharedService
    )
     {
      this.loginForm = this.formBuilder.group({
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
        acceptTerms: [false, Validators.requiredTrue]
      });
     }

  public loginStep: boolean = true;
  public signupStep: boolean = false;
  public verificationStep: boolean = false;
  public signupWelcomeStep: boolean = false;
  public forgotPwdEmailStep: boolean = false;
  public forgotPwdOtpStep: boolean = false;
  public resetPwdStep: boolean = false;
  public showPwd: boolean=false;
  public showCreatePwd: boolean = false;
  public showConfirmPwd: boolean = false;
  otpCheck:boolean = false; //second div
  otpSuccsessDiv:boolean = true;
  term:string = '';
  username:string = '';
  token:string = '';
  password:string = '';
   granttype:string = 'password';
  otp:string = '';
  RememberMe:boolean = false;
	//client side validation
	usernameError:boolean = true;
	panCardError:boolean = true;
	passwordError:boolean=true;
	//response validation
	validationError:boolean = true;
	validationErrorOtp:boolean = true;
	validationErrorMember:boolean = true;
	errorMsg:string = "Invalid Credentials ";
	otpMsg:string = "Invalid Credentials ";
	memberShipMsg:string = "Invalid Credentials ";
	viewBalancePoint:boolean = true;
	public membershipList:any=[]
	sendOtpRequest:boolean = true;
	//clent side status
	usernameStatus:boolean = true;
	passwordStatus:boolean = true;
	public login_state:boolean = false;
	pancardMsg='';
  roles=[];
  get f() { return this.loginForm.controls; }

  ngOnInit() {

  }
 
  login(){
    
    if(this.loginForm.invalid)
    {
      this.validateAllFormFields(this.loginForm); //{7}
    }
    else
    {
      this.username=this.loginForm.controls.username.value;
      this.password=this.loginForm.controls.password.value;
      if(this.username !='' ){
        this.usernameError = true;//error
        this.usernameStatus = true; //status
        this.validationError= true;
            this.passwordStatus= true;
            }else{
            this.usernameError = false;
            this.usernameStatus = false;
            this.validationError= true;
            }
            if(this.usernameStatus && this.passwordStatus){

            this.spinnerService.show();
            var data = {'username':this.username, 'password': this.password, 'grant_type' : this.granttype}
            var dataString = 'username='+this.username+'&password='+this.password+'&grant_type='+this.granttype+"&client_id=analytics-data";
            // localStorage.setItem('url', 'login');
            // const cookieValue = 'your_cookie_value';
            // expirationDate.setDate(expirationDate.getDate() + 1); 
            // const cookieOptions = { expires: expirationDate, secure: true, sameSite: 'Strict' };
            // Cookie.set('your_cookie_name', cookieValue, cookieOptions);
         
            // Cookie.set("url",'login',"expiresOrOptions");
            // Cookie.set("login", 'true')
            // Cookie.set("dashboard", 'true')
            // Cookie.set("users", 'false')
            // Cookie.set("roles", 'true')
            // Cookie.set("customers", 'true')
            // console.log(Cookie.get("users"));

            var formData: any = new FormData();
            /* formData.append("username", this.username);
            formData.append("password", this.password);
            formData.append("grant_type", this.granttype);  
            formData.append("client_id", 'analytics-data');*/

            /*formData.append("username", "DUSER145");
            formData.append("password", "Sjul#2022");
            formData.append("device_id", "9UFkDqxfIb4VTJcsSDF");
            formData.append("app_version", "1.0.0");
            formData.append("app_name", "com.tatamotors.fecportal");*/
            
            var transferObject = JSON.stringify({
            "username": this.loginForm.controls.username.value.trim(),
            "password": this.sharedService.aesGcmEncrypt(this.loginForm.controls.password.value.trim()),
            "device_id": "9UFkDqxfIb4VTJcsSDF",
            "app_version": "1.0.0",
            "app_name": "com.tatamotors.fecportal"
            });
            /*const HttpUploadOptions = {
            headers: new HttpHeaders({ 'Content-Type':  'application/json' })
            }*/

            var logUrl=this.baseAPI.baseUrl+'sa/app/login/';
            //const m=this.baseAPI.postLogin(this.baseAPI.baseTokenUrl,dataString).subscribe((res: any) => {
            const m=this.baseAPI.postLogin(logUrl,transferObject).subscribe((res: any) => {
            //alert(res);
            this.spinnerService.hide();
            var jsonObj = JSON.parse(JSON.stringify(res));
            var today = new Date();
            this.token = jsonObj.token.access_token;
            localStorage.setItem('token', this.token);
            localStorage.setItem("auth_token", this.token);
            localStorage.setItem("username", this.username);
            localStorage.refresh_time = today;
            let dm:any=jsonObj.data[0].dsm_name;
            sessionStorage.setItem('dsm_username',dm);
            localStorage.setItem('dsm_username',dm);
            localStorage.setItem('position_type',jsonObj.data[0].postn_type_cd);
            localStorage.setItem('region',jsonObj.data[0].region);
            console.log(localStorage.getItem("dsm_username")+  ' ds name');
            this.loginService.isLoggedIn=true;
            if(jsonObj.data[0].postn_type_cd=="Fleetedge RSM"){
            this.router.navigate(['/rsmdashboard']);
            this.roles=["fecattributes","rsmdashboard",'customerdetails'];
            localStorage.setItem("role_access",JSON.stringify(this.roles));
            }
            else if(jsonObj.data[0].postn_type_cd=="Fleetedge Champion"){
            this.router.navigate(['/fecdashboard']);      
            this.roles=["fectasks","fecdashboard","fectaskdetail"];
            localStorage.setItem("role_access",JSON.stringify(this.roles));
            }
            else if(jsonObj.data[0].postn_type_cd=='Fleetedge National head')
           {
             this.roles=["nationalhead-dashboard"]; 
             localStorage.setItem("role_access",JSON.stringify(this.roles));
             this.router.navigate(['/nationalhead-dashboard']);
            }
            else
            {
            this.router.navigate(['/aggregation']);
            this.roles=["aggregation","fecassignment","maintenance","performance","assigndata","fectaskdetail"];
            localStorage.setItem("role_access",JSON.stringify(this.roles));
            }
            if(res.msg=="user is valid for enrollment")
            {
            this.otpCheck = true;
            this.sendOtpRequest=false;
            this.validationErrorOtp= true;
            this.otpSuccsessDiv= false;
            this.otpMsg = "An OTP has been sent to your registered mobile no.";
            }
            if(res.success=="fail")
            {
            this.validationError= false;
            this.errorMsg = "Please try again, server error.";
            }
            }, error => {
            this.validationError= false;
            // console.info('error', error);
            // alert(error.msg);
            this.loginForm.reset();
            var jsonObj = JSON.parse(JSON.stringify(error));
            if(jsonObj.status==401)
            {
            // this.toastr.error('You have entered an invalid Credentials');
            this.toastr.error(jsonObj.error.msg)
            }
            else{
              if(jsonObj.statusText=="Unknown Error")
              {
                this.toastr.error("Server Error");
              }
              else
              {
                this.toastr.error(jsonObj.error.msg);
              }
            }
            var message = "Credential is wrong";
            this.spinnerService.hide();
            this.errorMsg = message;
            if (message != undefined && message != '' && message != null) {
            this.errorMsg = message;
            }
            else {
            this.errorMsg = message;//'Please try again, server error.';
            }
            this.router.navigate(['login']);
            });  
       }
    }
  }
 
  startTimer() {
    this.mins = 4;
    this.secs = 59;
    clearInterval(this.checkTimeOut);
    this.checkTimeOut = setInterval(() => {
      if (this.mins <= 0 && this.secs <= 0) {
        clearInterval(this.checkTimeOut);
        document.querySelector(".resend-otp .btn-icon").removeAttribute("disabled");
      }
      else {
        if (this.secs == 0) {
          this.secs = 60;
        }
        this.secs = this.secs - 1;

        if (this.secs < 10) {
          this.seconds = "0" + this.secs;
        } else {
          this.seconds = this.secs;
        }
        if (this.secs == 0 && this.mins > 0) {
          this.mins = this.mins - 1;
        }
        this.minutes = this.mins;
      }
    }, 1000);
  }
  changepassType(flag:boolean)
  {
    console.log(flag,'flag');
    this.showPwd=!flag;
  }
  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);             
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);          
      }
    });
  }
}
