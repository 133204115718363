import { Component, OnInit, ViewChild } from '@angular/core';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';

import { BaseApiService } from 'src/app/base-api.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as XLSX from 'xlsx';
import { RouterModule, Router } from '@angular/router';

import { IDropdownSettings } from 'ng-multiselect-dropdown';

import {
	HttpClient,
	HttpHeaders
} from '@angular/common/http';

declare var myExtObject: any;
declare var webGlObject: any;
@Component({
	selector: 'app-client-api',
	templateUrl: './client-api.component.html',
	styleUrls: ['./client-api.component.scss']
})
export class ClientApiComponent implements OnInit {




	constructor(private router: Router, private baseAPI: BaseApiService, public spinnerService: NgxSpinnerService, private http: HttpClient) {
		var token = localStorage.getItem('token');

		if (token == undefined || token === '') {
			this.router.navigate(['/login']);
		}

		webGlObject.init();


	}


	dropdownList = [];
	selectedItems = [];
	dropdownSettings: IDropdownSettings;
	dropdownFleetSettings;
	selectedSPN_array = [];
	selectedFleet_array = [];
	resultCheck: boolean = false;
	globalData: any = [];
	editStartDate: string;
	editEndDate: string;
	start_date: string;
	end_date: string;
	clientMobile: string;
	clientEmail: string;
	clientName: string;

	companyName: string;
	gstNumber: string;
	panNumber: string;
	aadharNumber: string;
	address: string;
	CreatedBy: string = 'User';
	userType: string = 'EXTERNAL';

	public apps: any[] = [];
	vechileDetails: any;
	public min = new Date(2018, 1, 12, 10, 30);

	public yearMax = this.getCurrentDay('Y') + 1;
	public monthMax = this.getCurrentDay('M');
	public dateMax = this.getCurrentDay('D');
	private todate = new Date();
	public Hours = this.todate.getHours();
	public getMinutes = this.todate.getMinutes();
	public max = new Date(this.yearMax, this.monthMax, this.dateMax, 20, 30);
	event_array = [];
	ActualHours = '';
	ActualMinutes = '';
	fleetOwnerData = [];
	

	public myDate: IMyDate = {

		year: this.getCurrentDay('Y'),
		month: this.getCurrentDay('M'),
		day: this.getCurrentDay('D'),
	}

	showVehicleSection: boolean = false;
	vinwithchassisNumber = [];
	showRecordVin: boolean = false;
	multipleVinNumber = [];
	fleet_selected: Object={'fleet_id':'','first_name':'NA'};
    
	isActive: boolean = false;
	isSelect: boolean = false;
	fleet_first_name:string='';
	
	getCurrentDay(cat): any {
		let output;
		var today = new Date();
		var day = today.getDate();
		var month = today.getMonth() + 1; //January is 0!
		if (day != 1) {
			//day = day - 1;
		} else {
			if (month == 5 || month == 7 || month == 10 || month == 12) {
				day = 30;
			}

			if (month == 2 || month === 4 || month == 6 || month == 8 || month == 9 || month == 11 || month == 1) {
				day = 31;
			}

			if (year % 4 == 0 && month == 3) {
				day = 29;
			}
			else if (year % 4 != 0 && month == 3) {
				day = 28;
			}
			month = month - 1;
		}


		//alert(day+"#"+month);
		var year = today.getFullYear();

		if (cat === 'D') {
			output = day;
		}
		else if (cat === 'M') {
			output = month;
		}
		else {
			output = year;
		}
		return output;
	}
	public myDatePickerOptions: IMyDpOptions = {
		dateFormat: 'yyyy-mm-dd',
		markCurrentDay: true,
		disableSince: this.myDate

	};



	compareDate() {


		var startDate = new Date(this.editStartDate);
		var endDate = new Date(this.editEndDate);

		var seconds = (endDate.getTime() - startDate.getTime()) / 1000;

		if (this.editEndDate != null && this.editStartDate != null) {


			this.editStartDate = this.convert(this.editStartDate);
			this.editEndDate = this.convert(this.editEndDate);


		}
	}

	convert(str) {
		this.ActualHours = '';
		this.ActualMinutes = '';
		var date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2);
		var Hours = date.getHours();
		if (Hours < 10) {
			this.ActualHours = ("0" + Hours);
		}
		else {
			this.ActualHours = String(Hours);
		}
		var getMinutes = date.getMinutes();
		if (getMinutes < 10) {
			this.ActualMinutes = ("0" + getMinutes);
		}
		else {
			this.ActualMinutes = String(getMinutes);
		}
		var returnDtae = [date.getFullYear(), mnth, day].join("-");
		returnDtae = returnDtae + "T" + this.ActualHours + ":" + this.ActualMinutes + ":00";
		return returnDtae;
	}

	onEditStartDateChanged(event) {


		var splitedDate = this.start_date.toString().split(",");

		this.editStartDate = splitedDate[0];
		this.editEndDate = splitedDate[1];



		if (this.editEndDate != null && this.editStartDate != null) {
			this.compareDate();


		}


	}

	applyFilter(event) {



	}
	fetchReport() {
		if (this.editStartDate === undefined && this.editEndDate !== undefined) {
			alert('Please enter proper date in the Start date.');
			this.editStartDate = null;
			this.editEndDate = null;
			return false;
		}
		if (this.editStartDate !== undefined && this.editEndDate === undefined) {
			alert('Please enter  proper date in the End date.');
			this.editStartDate = null;
			this.editEndDate = null;
			return false;
		}
		if (this.editStartDate === undefined && this.editEndDate === undefined) {
			alert('Please enter  proper date in the Start date and End date.');
			this.editStartDate = null;
			this.editEndDate = null;
			return false;
		}

		if ((this.editStartDate === undefined || this.editStartDate === '' || this.editStartDate === null) ||
			(this.editEndDate === undefined || this.editEndDate === '' || this.editEndDate === null) ||
			(this.clientName === undefined || this.clientName == '' || this.clientName == null) || (this.clientEmail === undefined || this.clientEmail === '' || this.clientEmail == null)
			|| (this.clientMobile === undefined || this.clientMobile === '' || this.clientMobile == null)
			|| (this.companyName === undefined || this.companyName === '' || this.companyName == null)
			|| (this.gstNumber === undefined || this.gstNumber === '' || this.gstNumber == null)
			|| (this.panNumber === undefined || this.panNumber === '' || this.panNumber == null)
			|| (this.aadharNumber === undefined || this.aadharNumber === '' || this.aadharNumber == null)
			|| (this.address === undefined || this.address === '' || this.address == null)
			|| (this.CreatedBy === undefined || this.CreatedBy === '' || this.CreatedBy == null)
			|| (this.userType === undefined || this.userType === '' || this.userType == null)
			|| (this.selectedItems.length === 0) || (this.fleetOwnerData.length === 0)

		) {
			alert('Please fill all mandatory fields.');

		}else if(this.vinwithchassisNumber.length===0){
			alert('Please Choose a valid Fleet Owner,this Fleet Owner does not have any active Vehciles');
		}
		else {




			if (window.confirm("Do you want to submit data?")) {

				this.callApi();

			}
			else {
				alert("Report serach is failed");
			}

		}
	}
	//18602676060

	//==================================================================

	callApi() {
		this.spinnerService.show();




		//var data = {'clientName':this.clientName, 'validityStartDate' : this.editStartDate,'validityEndDate' : this.editEndDate,'emailId' : this.clientEmail,'mobile' : this.clientMobile,'companyName' : this.companyName,'gstNumber' : this.gstNumber,'panNumber' : this.panNumber,'aadharNumber' : this.aadharNumber,'address' : this.address,'createdBy' : this.CreatedBy,'userType' : this.userType,'supportedDataTypes' :this.selectedSPN_array};	

		var datastring = "admin/external/signup";
		myExtObject.firebaseEvent('Client Sign Up', { user_name: localStorage.getItem('username'), fromDate: this.editStartDate, toDate: this.editEndDate, button_name: "Submit Button Click" });

		this.baseAPI.callOnboardPostApi(this.baseAPI.onboardVinUrl + datastring, this.clientName, this.editStartDate, this.editEndDate, this.clientEmail, this.clientMobile, this.companyName, this.gstNumber, this.panNumber, this.aadharNumber, this.address, localStorage.getItem('username'), this.userType, this.selectedSPN_array).then((data: any) => {

			if (data.clientId != undefined) {

				var datastringmap = "fleet/external/vehicles";
				this.baseAPI.MapVinWithFleetClient(this.baseAPI.onboardVinUrl, datastringmap, data.clientId, this.fleetOwnerData[0].fleet_id, this.vinwithchassisNumber).then((data: any) => {

					alert('Registration has been done and Vehicles added successfully');
					this.router.navigate(['/client']);
				}, (err) => {
					alert('Registration has been done.');
					this.router.navigate(['/client']);
					this.spinnerService.hide();
					var jsonObj = JSON.parse(JSON.stringify(err));
					var message = jsonObj.msg;
					if (message != undefined) {
						//alert("Error:"+message.error);
					}
					else {
						this.router.navigate(['/client']);
						//alert('Please try again');
					}

				});
				//this.router.navigate(['/client']);
				this.spinnerService.hide();
			} else {
				alert(data);
				this.spinnerService.hide();


			}

		}, (err) => {

			this.spinnerService.hide();
			var jsonObj = JSON.parse(JSON.stringify(err));
			//alert(JSON.stringify(jsonObj));
			var message = jsonObj.error;
			if (message != undefined) {
				alert(message);
			}
			else {
				alert('Please try again');
			}
		});



	}




	ngOnInit() {
		this.monthMax = this.monthMax - 1;
		this.max = new Date(this.yearMax, this.monthMax, this.dateMax, this.Hours, this.getMinutes);
		this.dropdownList = [
			{ item_id: 'EVENTS', item_text: 'EVENTS' },
			{ item_id: 'TELEMETRY', item_text: 'TELEMETRY' }

		];
		this.fleetVInCheck();
		this.dropdownSettings = {
			singleSelection: false,
			idField: 'item_id',
			textField: 'item_text',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 1,
			limitSelection: 10,
			allowSearchFilter: true
		};
		this.dropdownFleetSettings = {
			singleSelection: true,
			idField: 'fleet_id',
			textField: 'first_name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 1,
			limitSelection: 1,
			allowSearchFilter: true,
			closeDropDownOnSelection:true,
			defaultOpen:false,
		};
	}
	onFleetDropDownClose() {
		
	}
	
	onFleetSelect(item: any) {
		console.log(item);
		this.selectedFleet_array = [];
		//	for (let i = 0; i < this.fleetOwnerData.length; i++) {
		this.selectedFleet_array.push(item);
		this.isSelect=true;
		if (typeof this.selectedFleet_array[0] == 'object') {
			this.fetchReportWithDropDownOp(this.selectedFleet_array[0]);

		}
		if(this.isActive==true){
			document.getElementById('dropdown-list').style.display='none';
		}
		//	}
	}

	onFleetDeSelect(item: any) {
		
		for (var i = 0; i < this.fleetOwnerData.length; i++) {
			if (this.fleetOwnerData[i] === item.item_id) {
				this.fleetOwnerData.splice(i, 1);
			}
		}
	}

	onSelectAll(item: any) {
	}

	onItemSelect(item: any) {


		this.selectedSPN_array = [];
		for (let i = 0; i < this.selectedItems.length; i++) {

			this.selectedSPN_array.push(this.selectedItems[i].item_id);

		}


	}

	onItemDeSelect(item: any) {
		for (var i = 0; i < this.selectedSPN_array.length; i++) {
			if (this.selectedSPN_array[i] === item.item_id) {
				this.selectedSPN_array.splice(i, 1);
			}
		}
	}
	fleetVInCheck() {
		this.fleetOwnerData = [];
		this.checkFleetOwnerList();
	}
	checkFleetOwnerList() {
		this.spinnerService.show();
		this.fleetOwnerData = [];
		//	if (this.tabType == 1) {
		var datastring = "api/user-service/get-fleet-owner-user-id-full-name-list";
		//	}
		/*else
		{
		  var datastring="api/vehicle-service/get-vehicles-registration-number-vin-fleet-id";	
		}*/
		var datastring = "api/user-service/get-fleet-owner-user-id-full-name-list";
		//myExtObject.firebaseEvent('clientlist', { user_name: localStorage.getItem('username'), fromDate: this.editStartDate, toDate: this.editEndDate, button_name: "Search Button Click" });
		this.baseAPI.callCheckONclientApi(this.baseAPI.onboardVinUrl, datastring, '').then((data: any) => {
			console.log('data', data);
			//if(this.tabType == 1){
			if (data.results.length != undefined) {
				let data_op=data.results.map((f)=>{
					if(f.first_name.length>10){
						f.first_name=f.first_name.slice(0,10)+"..";
					}
					return f;});
				console.log
				this.fleetOwnerData = data.results;
			}
			else {

			}
			/*}
			   else
			   {
				if(data.result.length != undefined )
			   {		   
				   this.VinNumberData = data.result;			  
			   }
			   else
			   { 
				  
			   }
			 }*/
			this.spinnerService.hide();
		}, (err) => {

			this.spinnerService.hide();
			var jsonObj = JSON.parse(JSON.stringify(err));
			var message = jsonObj.msg;
			if (message != undefined) {
				alert("Error:" + message.error);
			}
			else {
				alert('Please try again');
			}
		});

	}
	changeStyle() {
		this.isActive = !this.isActive
	}
	MvDownArrow:boolean=false;
	fetchReportWithDropDownOp(fleetOwnerSelected) {
		
		this.multipleVinNumber = [];
		this.vinwithchassisNumber = [];
		this.fleet_selected = fleetOwnerSelected;
		this.fleet_first_name=fleetOwnerSelected.first_name;
		if (fleetOwnerSelected) {
			this.showRecordVin = true;
			this.MvDownArrow=true;
			this.showVehicleSection = false;
			let fleet_selected = fleetOwnerSelected;
			//this.clientVinId=fleet_selected.fleet_id;
			this.spinnerService.show();

			var datastring = "api/vehicle-service/get-connected-vehicle-master-details";

			console.log(fleet_selected.fleet_id);
			//myExtObject.firebaseEvent('clientlist', { user_name: localStorage.getItem('username'), fromDate: this.editStartDate, toDate: this.editEndDate, button_name: "Search Button Click" });
			this.baseAPI.get_vins_by_fleet_id(this.baseAPI.onboardVinUrl, datastring, fleet_selected.fleet_id).then((data: any) => {

				if (data.message == 'Vehicle data not found.') {
					this.showVehicleSection = false;
					this.showRecordVin = true;
					//this.showVehicleSection = true;
					//$(".dropdown-list").hide();
					//$("#myModal").show();
					this.changeStyle();
				}
				else if (data.result.length > 0) {
					//$("#myModal").show();
					//$(".dropdown-list").hide();
					this.showRecordVin = true;
					this.showVehicleSection = true;
					this.changeStyle();
					//for (let i = 0; i < data.result.length; i++) {
					this.multipleVinNumber = data.result;
					let vinwithchassisNumber = data.result.map(obj => obj.vin);
					this.vinwithchassisNumber = vinwithchassisNumber;



					//}
				}
				else {
					this.showVehicleSection = false;
					this.changeStyle();
				}
				this.spinnerService.hide();
			}, (err) => {

				this.spinnerService.hide();
				var jsonObj = JSON.parse(JSON.stringify(err));
				var message = jsonObj.msg;
				if (message != undefined) {
					alert("Error:" + message.error);
				}
				else {
					alert('Please try again');
				}
			});
		}
		else {
			this.showRecordVin = false;
			alert('Please select Fleed Id.');
		}

	}

}




