import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseApiService } from 'src/app/base-api.service';
import { SharedService } from 'src/app/shared.service';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-fecattributes',
  templateUrl: './fecattributes.component.html',
  styleUrls: ['./fecattributes.component.scss']
})
export class FecattributesComponent implements OnInit {
  currentDate = new Date();
  month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  newData = [];
  FecData:any= [];
  totalFEC:number=0;
  overdueVisits:number=0;
  assignedVisits:number=0;
  completedVisits:number=0;
  scheduledVisits:number=0;
  unscheduledVisits:number=0;
  fleetEdgeChampList:any = [];
  CompletedTasks = 0;
  PendingTasks = 0;
  ScheduledTasks = 0;
  SubmissionPending = 0;
  itemsPerPage: number = 10;
  page = 1;
  totalItems = 0;
  currentPage = 0;
  resultCheck = true;
  event = 0;
  currentStatusTab: string = 'completed';
  currentTab:string = "total";
  collectionSize:any=0;
  collectionSizeHistory:any=0;
  currentPage1 = 0;
  page2:any=1;
  page1:any=1;
  pageSize:any=10;
  pageSize2:any=10;
  newDataFilter:any=[];
  searchTerm:any;
  selectedDate:Date | null;;
  fromdate:any;
  todate:any;
  user_id:any;
  viewHistoryDetails:any=[];
  viewHistoryDetailsSearch:any=[];
  viewHistorySingleDetail:any=[];
  Monthdate: Date | null;
  monthFilter: Date | null;
  imgSrc:any;
  showFlag: boolean = false;
  isloggedUser:any=1;
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private baseAPIService: BaseApiService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr:ToastrService
  ) { 
    var position_type = localStorage.getItem("position_type"); 
     if(position_type=='Fleetedge RSM'){
        this.isloggedUser=1;
       
     }else if(position_type=='Fleetedge Champion'){
        this.isloggedUser=2;
     }
     else if(position_type=='Fleetedge National head'){
      this.isloggedUser=3;
     }
  }

  ngOnInit(): void {
    this.baseAPIService.DataRSMObj.subscribe(res => {
      if(isJson(res)==false)
      {
        this.fromdate=this.sharedService.dateFormater(res['fromdate']);
        this.todate=this.sharedService.dateFormater(res['todate']);
        this.selectedDate=res['monthFilter'];
        this.Monthdate=res['monthFilter'];
        registerLocaleData(fr);
        this.initializeComponent();
      }
      else
      {
        let obj=JSON.parse(res);
        this.selectedDate=obj.monthFilter;
        this.fromdate=obj.fromdate;
        this.todate=obj.todate;
        this.Monthdate=obj.monthFilter;
        registerLocaleData(fr);
        this.initializeComponent();
      }
    });
  }
  getTabStatusCode(tab:string):string {
    if(tab == "completed"){
      return '4';
    }
    if(tab == "overdue"){
      return '3';
    }
    if(tab == "scheduled"){
      return '2';
    }
    if(tab == "unscheduled"){
      return '1';
    }
  }
  initializeComponent(): void {
    this.sharedService.getFECData().subscribe((data) => {
      console.log("getFECData", data);
      this.user_id=data;
      if (data && data != null && Object.keys(data).length) {
        this.getFECInformation(data);
      } else {
        this.getFECInformation(data);
        // this.router.navigate(["/rsmdashboard"]);
      }
    });
  }
  getFECInformation(data: any): void {
    var requestURL = "fecportal/fec/list/";
    var user_id = localStorage.getItem("FEC");
    if (!(user_id)) {
      console.log("FEC not found");
      // alert("Unable to process request. Please try again");
      this.toastr.error("Unable to process request. Please try again");
      return;
    }
    this.getDashboardCountForSelectedFEC();
    var requestPayloadFec = {
      "fec_user_id": this.user_id,
      'todate':this.todate,
      'fromdate':this.fromdate
    };
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayloadFec).then((response: any) => {
      console.log('response:-',response)
      let i=0;
      let j=1;
      if(this.isloggedUser==1)
      {
        i=1
        j=0;
      }
      if (response && response.length) {
        this.FecData = response[i];
        console.log(this.FecData,'this.FecData')
        if(response['total_results']){
          this.totalItems = response['total_results'];
        }
        this.totalFEC = response[j]['total_results'];
        this.fleetEdgeChampList = response;
      }
      this.getFECData();
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      // alert(errMessage);
      this.toastr.error(errMessage)
    });
  }
  ViewHistory(item:any,historyTsk:any)
  {
  this.viewHistorySingleDetail=item;
  var requestURL = "fecportal/customer_details/get/";     
  var requestPayload = {"fec_customer_data_id":item.id};
  this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl+requestURL,requestPayload).then((response:any) => {
  this.viewHistoryDetails=response;
  let i=0;
  let j=0;
  this.viewHistoryDetails.forEach((element:any) => {
  this.viewHistoryDetails[i].isExpand=false;
  this.viewHistoryDetails[i]?.upload_image_path?.forEach((ele:any) => {
  ele['isurlType']=getExtensionFromURL(ele.url);
  j++;
  });
    i++;
  });
  this.viewHistoryDetailsSearch=this.viewHistoryDetails;
  this.collectionSizeHistory=this.viewHistoryDetails.length;
  this.modalService.open(historyTsk, { size: "md",centered: true });
  });
  }
  onShowImgeIconsComplete(val:any)
  {
    if(val.isurlType.toLowerCase()=='png' ||val.isurlType.toLowerCase()=='jpeg' || val.isurlType.toLowerCase()=='jpg' ||  val.isurlType.toLowerCase()=='gif')
    {
       return val.url;
    }
    else if(val.isurlType.toLowerCase()=='xlsx')
    {
      return 'assets/images/excel.png';
    }
    else if(val.isurlType.toLowerCase()=='pdf')
    {
      return 'assets/images/pdf.jpg';
    }
    else if(val.isurlType.toLowerCase()=='txt')
    {
      return 'assets/images/txt.png';
    }
    else if(val.isurlType.toLowerCase()=='docx')
    {
      return 'assets/images/docs.png';
    }
    else if(val.isurlType.toLowerCase()=='zip')
    {
      return 'assets/images/zip.png';
    }
    else
    {
      return 'assets/images/docs.png';
    }
  }
  imagepreviewComplete(url:any,ImgSrcModel:any,index:any)
  {
    if(index==1)
    {
      this.imgSrc=url.url;
      this.showFlag = false;
      this.spinnerService.hide();
       this.modalService.open(ImgSrcModel, {centered: true });
    }
    else
    {
      this.imgSrc=url.url;
      this.showFlag = false;
      //  window.open(url, '_blank').
      this.spinnerService.hide();
      if(url.type.toLowerCase()=='pdf')
      {
        var win = window.open();
        win.document.write('<iframe src="' + url.url  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');  
      }
      else
      {
        window.open(url.url,'_self');
      }
    }
  }
  openMonthModel(mySelectMonthModal:any)
  {
    this.Monthdate= new Date(this.Monthdate);
    this.modalService.open(mySelectMonthModal, { size: "sm",centered: true });
  }
  addDateFilter() {
    // console.log(this.Monthdate,'Monthdate')
    let x= new Date(this.Monthdate);
    let t=new Date(x.getFullYear(), x.getMonth() + 1, 0);
    let f=new Date(x.getFullYear(), x.getMonth(), 1);
    this.fromdate= this.sharedService.dateFormater(f);
    this.todate= this.sharedService.dateFormater(t);
    this.selectedDate=this.Monthdate;
    let obj={'fromdate':this.fromdate,'todate':this.todate,'monthFilter':this.Monthdate};
    this.baseAPIService.RSMDateRange(obj);
    if(!this.selectedDate){
      this.Monthdate = this.sharedService.dateFormater(this.currentDate);
    }
    // this.fetchDashboardCount();
   this.initializeComponent();
    this.modalService.dismissAll();
  }
  closeCompletepopup()
  {
    this.modalService.dismissAll();
  }
  getDashboardCountForSelectedFEC():void {
    var user_id = localStorage.getItem("FEC");
    if (!(user_id)) {
      console.log("FEC not found");
      // alert("Unable to process request. Please try again");
      this.toastr.error("Unable to process request. Please try again")
      return;
    }
    this.spinnerService.show();
   
    var requestURL = "fecportal/dashboard/count/";
    //   this.fromdate=this.sharedService.dateFormater(res['fromdate']);
    console.log( this.todate,' this.todate')
    var requestPayload = {"fec_user_id": user_id.replace(/^"|"$/g, ''),'fromdate':this.fromdate,'todate': this.todate};
    this.totalFEC = 0;
    this.overdueVisits = 0;
    this.assignedVisits = 0;
    this.completedVisits = 0;
    this.scheduledVisits = 0;
    this.unscheduledVisits = 0;
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl+requestURL,requestPayload).then((response:any) => {
      if(response){
        if(response['overdue']){
          this.overdueVisits = response['overdue'];
        }
        if(response['total_task_assigned']){
          this.assignedVisits = response['total_task_assigned'];
        }
        if(response['completed']){
          this.completedVisits = response['completed'];
        }
        if(response['scheduled']){
          this.scheduledVisits = response['scheduled'];
        }
        if(response['unscheduled']){
          this.unscheduledVisits = response['unscheduled'];
        }
      } else {
        var message = this.baseAPIService.handleSucessfulAPIResponse(response, "Unable to process request. Please try again");
        // alert(message);
        this.toastr.error(message)
      }
      this.spinnerService.hide();
    },(error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      // alert(errMessage);
      this.toastr.error(errMessage)
      this.router.navigate(['login']);
    });
  }
  getFECData(): any {
    var user_id = localStorage.getItem("FEC").replace(/"/g, '');
    if (!(user_id)) {
      console.log("FEC not found");
      alert("Unable to process request. Please try again");
      this.toastr.error("Unable to process request. Please try again");
      return;
    }
    this.spinnerService.show();
    if(this.currentStatusTab=='completed')
    {
      var requestURL = "fecportal/completed/list/";
    }
    else
    {
      var requestURL = "fecportal/get/list/";
    }
    var requestPayload = {
      /*"user_type":"Fleet Owner",
     "offset":0,
     "limit":10,
     "state":"Uttar Pradesh",
     "city": "Saharanpur",
     "is_fec_user_data":true*/
      "offset": this.currentPage,
      "limit": this.itemsPerPage,
      "is_fec_user_data": true,
      "fec_user_id": user_id,
      "status": this.getTabStatusCode(this.currentStatusTab),
      'fromdate':this.fromdate,
       'todate': this.todate
    };
    this.newData=[];
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      console.log(response,'response')
      if (response) {
        var summaryData = response[0];
        if (summaryData["total_record"]) {
          this.totalItems = summaryData["total_record"];
          this.collectionSize=this.totalItems;
        }
        this.newData=[];
        for (let index = 1; index < response.length; index++) {
          const element = response[index];
          this.newData.push(element);
          this.newDataFilter.push(element);
        }
      }
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      // alert(errMessage);
    });
  }
  pageChanged(event) {
    this.currentPage = event;
    this.event = this.currentPage;
    this.getFECData();
  }
  getSelectedStatusTabData(currentTab: string, currentTabTotalRecords:string): any {
    this.currentPage=0;
    this.itemsPerPage=10;
    this.event = this.currentPage;
    this.currentStatusTab = currentTab;
    var user_id = localStorage.getItem("FEC").replace(/"/g, '');
    if (!(user_id)) {
      console.log("FEC not found");
      alert("Unable to process request. Please try again");
      return;
    }
    this.spinnerService.show();
    this.newData = [];
    if(this.currentStatusTab=='completed')
    {
      var requestURL = "fecportal/completed/list/";
    }
    else
    {
      var requestURL = "fecportal/get/list/";
    }
    var requestPayload = {
      "offset": this.currentPage,
      "limit": this.itemsPerPage,
      "is_fec_user_data": true,
      "fec_user_id": user_id,
      'fromdate':this.fromdate,
       'todate': this.todate, 
      "status": this.getTabStatusCode(this.currentStatusTab),
    };
    this.newData=[];
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      if (response) {
        var summaryData = response[0];
        if (summaryData["total_record"]) {
          this.totalItems = summaryData["total_record"];
          this.collectionSize=this.totalItems;
        }
        for (let index = 1; index < response.length; index++) {
          const element = response[index];
          this.newData.push(element);
        }
      }
      this.spinnerService.hide();
      this.totalItems = parseInt(currentTabTotalRecords);
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      //  alert(errMessage);
       this.toastr.error(errMessage);
       localStorage.clear();
       this.router.navigate(['login'])
    });
  }
  deleteFECData(data_id:any,status:any):any {
    let text = "Are you sure you want to delete record?";
    if (!(confirm(text) == true)) {
      console.log("Cancelled deletion of record ID:", data_id);
      return;
    }
    this.spinnerService.show();
    this.newData = [];
    let fecid=data_id.fec_assign[0].fec_assign_id;
    var requestURL = "fecportal/assign_data/delete/";
    var requestPayload = {
      "id": fecid,
      "status": status,
    };
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      // console.log("response" + response);
      this.spinnerService.hide();
      var message = this.baseAPIService.handleSucessfulAPIResponse(response, "Fleet assigned Successfully");
      // this.getFECData();
      // this.getDashboardCountForSelectedFEC();
       this.getFECInformation(this.user_id);
      // alert(message);
      this.toastr.success(message)
    }, (error) => {
      this.spinnerService.hide();
      this.getFECData();
      this.getDashboardCountForSelectedFEC();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      // alert(errMessage);
      this.toastr.error(errMessage)
      localStorage.clear();
      this.router.navigate(['login']);
    });
  }
  downloadMasterData():void{
    this.sharedService.downloadMasterData();
  }
  downloadListData():void{
    var user_id = localStorage.getItem("FEC");
    // console.log(this.user_id)
    let request_payload={fec_user_id:this.user_id,
      'todate':this.todate,
      'fromdate':this.fromdate
    };
    this.sharedService.downloadListData(request_payload);
  }
  toggleListing(status_tab:string){
    this.currentTab = status_tab;
    var requestPayload = {
      "status":this.currentTab,
      "offset": this.currentPage,
      "limit": this.itemsPerPage,
    };
    this.fetchDashboardListingData(requestPayload);
  }
  fetchDashboardListingData(requestPayload:any):void {
    this.spinnerService.show();
    this.fleetEdgeChampList = [];
    // var requestURL = "fecportal/fec/list/";
    if(this.currentStatusTab=='completed')
    {
      var requestURL = "fecportal/completed/list/";
    }
    else
    {
      var requestURL = "fecportal/get/list/";
    }
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl+requestURL,requestPayload).then((response:any) => {
      if(response ){
        this.fleetEdgeChampList = response;
      } else {
        console.log("FEC - No Records Found");
      }
      this.spinnerService.hide();
    },(error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      // alert(errMessage);
      // this.toastr.error(errMessage);
      localStorage.clear();
      this.router.navigate(['login']);
    });
  }
  getChangeTableData()
  {
    this.currentPage=(this.page1 - 1) * this.pageSize;
    this.event = this.currentPage;
    this.getFECData();
  }
  getChangeHistoryData()
  {
    let currentPage=(this.page2 - 1) * this.pageSize2;
    let event =currentPage;
    this.viewHistoryDetails=this.viewHistoryDetailsSearch.slice((this.page2 - 1) * this.pageSize2, (this.page2 - 1) * this.pageSize2 + this.pageSize2);
  }
}
function isJson(str:any) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return isNaN(str);
}

function getExtensionFromURL(URL){
  const tokens = URL.match(/(\.[a-zA-Z0-9]+(\?|\#|$))/g) 
  if(!tokens[0]) return false 
  let tokenpath=tokens[0].replace(/[^A-Za-z0-9\.]/g,'').split('.')
  return tokenpath[1];
}