<div data-theme="default" data-layout="fluid" data-sidebar-position="left" data-sidebar-behavior="sticky">
  <div class="wrapper">
		<app-sidebar></app-sidebar>
		<div class="main">
			<app-header></app-header>
			<main class="content">
        <div class="container-fluid p-0">
          <div class="wel_msg">
            Welcome Back,
            <span style="color:#0A0A5F">
              {{dsm_username}} !
            </span>
          </div>
					<div class="row mb-2 mb-xl-3">

        
						<div class="col-auto">
							<h3 class="dash-text">DASHBOARD</h3>
						</div>
						<div class="col-auto ms-auto text-end mt-n1">
                <button class="btn date-btn" (click)="SelectDateRange(myDateRangeModal)">
                  <a>
                    <span class="icon icon-calendar"></span> 
                    <span  class="year-text" style="white-space: nowrap;margin-left: 4px;">
                      {{filterStartDate | date:'dd'}} {{filterStartDate | date:'MMM'}}
                      {{filterStartDate | date:'YYYY'}} - {{filterEndDate | date:'dd'}} {{filterEndDate | date:'MMM'}}
                      {{filterEndDate | date:'YYYY'}}
                  </span>
                  </a>
                </button>
						</div>
					</div>
        </div>
        
        <div class="d-none d-sm-block visible-xs-block, hidden-xs visible-sm-block, hidden-sm">
          <div class="wrapper-test">
            <div (click)="toggleTaskScreen('sales_window')" >
                <div class="listing-card-desktop" [ngClass]="(currentTab == 'sales_window')? 'active': ''">
                  <div class="dashfont"  style="display: flex;" (click)="toggleTaskScreen('sales_window')">
                    <img src="assets/images/iconset/Sales.svg" style="width: auto;margin-right: 7px;" alt="">
                    <div class="card-dash-num">{{sales_opty_count }}</div>
                  </div>
                  <div style="display:flex;">
                    <div style="visibility: hidden;">
                      <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                    </div>
                    <span class="boxtext">Sales Window</span>
                  </div>
                </div>
            </div>
            <div (click)="toggleTaskScreen('completed_visits')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'completed_visits')? 'active': ''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/Visits.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{completedVisits}}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Completed Visits</span>
                </div>
              </div>
            </div>
            <div (click)="toggleTaskScreen('scheduled_visits')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'scheduled_visits')? 'active':''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/Visits-2.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{scheduledVisits}}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Scheduled Visits</span>
                </div>
              </div>
            </div>
            <div  (click)="toggleTaskScreen('unscheduled_tasks')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'unscheduled_tasks')? 'active':''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/Visits-1.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{unscheduledVisits}}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/Visits-2.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Unscheduled Visits</span>
                </div>
              </div>
            </div>
            <div>
            <div (click)="toggleTaskScreen('overdue_visits')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'overdue_visits')? 'active':''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/Visits-3.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{overdueVisits }}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Overdue Visits</span>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="d-block d-sm-none">
          <div class="wrapper-test-mb">
            <div (click)="toggleTaskScreen('sales_window')" >
                <div class="listing-card-desktop" [ngClass]="(currentTab == 'sales_window')? 'active': ''">
                  <div class="dashfont"  style="display: flex;" (click)="toggleTaskScreen('sales_window')">
                    <img src="assets/images/iconset/Sales.svg" style="width: auto;margin-right: 7px;" alt="">
                    <div class="card-dash-num">{{sales_opty_count }}</div>
                  </div>
                  <div style="display:flex;">
                    <div style="visibility: hidden;">
                      <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                    </div>
                    <span class="boxtext">Sales Window</span>
                  </div>
                </div>
            </div>
            <div (click)="toggleTaskScreen('completed_visits')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'completed_visits')? 'active': ''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/Visits.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{completedVisits}}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Completed Visits</span>
                </div>
              </div>
            </div>
            <div (click)="toggleTaskScreen('scheduled_visits')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'scheduled_visits')? 'active':''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/Visits-2.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{scheduledVisits}}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Scheduled Visits</span>
                </div>
              </div>
            </div>
            <div  (click)="toggleTaskScreen('unscheduled_tasks')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'unscheduled_tasks')? 'active':''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/Visits-1.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{unscheduledVisits}}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Unscheduled Visits</span>
                </div>
              </div>
            </div>
            <div>
            <div (click)="toggleTaskScreen('overdue_visits')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'overdue_visits')? 'active':''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/Visits-3.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{overdueVisits }}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Overdue Visits</span>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <br>
        <div class="row mb-2 mb-xl-3">
          <div class="col-auto">
            <h3 class="tbl-text">
              <ng-container *ngIf="(currentTab == 'total_fec')">
                Fleet Edge Champs
              </ng-container>
              <ng-container *ngIf="(currentTab == 'assigned_visits')">
                Total Assigned Visits
              </ng-container>
              <ng-container *ngIf="(currentTab == 'completed_visits')">
                Total Completed Visits
              </ng-container>
              <ng-container *ngIf="(currentTab == 'scheduled_visits')">
                Total Scheduled Visits
              </ng-container>
              <ng-container *ngIf="(currentTab == 'unscheduled_tasks')">
                Total Unscheduled Visits
              </ng-container>
              <ng-container *ngIf="(currentTab == 'overdue_visits')">
                Total Overdue Visits
              </ng-container>
              <ng-container *ngIf="(currentTab == 'sales_window')">
                Sales Opportunity Window
              </ng-container>
            </h3>
          </div>
          <div class="col-auto ms-auto text-end mt-n1" style="display: flex;">
            <div><button class="btn download-list-btn" (click)="downloadMasterData()">
              <!-- <i class="align-middle" data-feather="download">&nbsp;&nbsp;</i> -->
              <svg xmlns="http://www.w3.org/2000/svg" 
              width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-download align-middle">
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="7 10 12 15 17 10"></polyline>
              <line x1="12" y1="15" x2="12" y2="3"></line>
              </svg>
              <span style="white-space: nowrap;margin-left: 4px;">List</span>
              </button></div>
            &nbsp;
            <div style="margin-top:2%;">
              <div class="input-group input-group-search input-group-width">
                <button class="btn" type="button">
                  <!-- <i class="align-middle" data-feather="search"></i> -->
                  <svg xmlns="http://www.w3.org/2000/svg" 
                  width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-search align-middle">
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </button>
                <!-- <span> -->
                  <!-- placeholder="Company Name" -->
                  <input [placeholder]="currentTab!='sales_window'?'Company Name':'Customer Name'" type="text" class="form-control"  [(ngModel)]="searchTerm" (keyup)="searchCompanyName($event)">
                <!-- </span> -->
              </div>
            </div>
          </div>
        </div>
      
        <div class="row" *ngIf="currentTab!='sales_window'">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6" *ngFor="let item of newData">
            <div class="card" >
             <div class="view-card">
              <div class="card-heading" style="display:flex;justify-content: space-between;">
                <div style="padding-left:5px;">{{item?.company_name==null?'-':item.company_name}}</div>
                <div class="assign-on">
                  <ng-container  *ngIf="currentTab == 'overdue_visits'">
                    Assigned On:&nbsp;
                    <ng-container *ngIf="item?.fec_assign[0]?.creation_date">
                      {{item.fec_assign[0].creation_date | date:'dd'}}/{{item.fec_assign[0].creation_date | date:'MM'}}/
                      {{item.fec_assign[0].creation_date | date:'YYYY'}}
                    </ng-container>
                    <!-- <ng-container *ngIf="!(item.assigned_date)">
                      -
                    </ng-container> -->
                  </ng-container>
                </div>
              </div>
              <div class="card-inner-body">
                <div class="card-flex">
                  <img src="/assets/images/Icon-1.svg">
                  <span class="card-visit" *ngIf="currentTab == 'completed_visits'">
                    {{item?.contact==null?'-':item.spoc_name+' '+item.spoc_mobile}}
                  </span>
                  <!-- last_name -->
                  <span class="card-visit" *ngIf="currentTab== 'scheduled_visits'">
                    {{item?.contact==null?'-':item.first_name+' '+item.contact}}
                  </span>
                  <span class="card-visit" *ngIf="currentTab== 'overdue_visits' || currentTab=='unscheduled_tasks'">
                    {{item?.contact==null?'-':item.first_name+' '+item.contact}}
                    <!-- first_name -->
                  </span>
                </div>
                <div class="card-flex">
                  <img src="/assets/images/location.svg">
                  <span class="card-visit">
                    <ng-container *ngIf="item.city">{{item.city}},{{item.country}}</ng-container>
                   <ng-container *ngIf="!(item.city)"> - </ng-container>
                   
                  </span>
                </div>
                <div class="card-flex">
                  <img src="/assets/images/Vector.svg">
                  <span class="card-visit" style="margin-left: 8px;">
                    <ng-container *ngIf="item.purpose_of_visit">
                      {{item.purpose_of_visit}}
                    </ng-container>
                    <ng-container *ngIf="!(item.purpose_of_visit)"> - </ng-container>
                  </span>
                </div>
                <div class="card-flex" style="justify-content: space-between;">
                  <div>
                    <img src="/assets/images/calender.svg">
                    <span class="card-visit">
                      <ng-container *ngIf="item.completed_date && currentTab =='completed_visits'">
                       {{item.completed_date | date:'dd'}}/{{item.completed_date | date:'MM'}}/{{item.completed_date | date:'YYYY'}}
                      </ng-container>
                      <ng-container>
                        <span *ngIf="currentTab !=='unscheduled_tasks'">
                          
                          <!-- <ng-container *ngIf="!(item.scheduled_date)">
                           -                          
                          </ng-container>
                          <ng-container *ngIf="(item.scheduled_date)">
                            {{item.scheduled_date | date:'dd'}}/{{item.scheduled_date | date:'MM'}}/{{item.scheduled_date | date:'YYYY'}}
                          </ng-container> -->
                        </span>
                        <span *ngIf="currentTab =='unscheduled_tasks'" class="set-visit-date">
                          <span (click)="selectUnschedTaskId(item,MyUnModel);" 
                           >Set Visit Date</span>
                        </span>
                      </ng-container>  
                      <span *ngIf="currentTab =='scheduled_visits'">
                        <!-- <ng-container *ngIf="item.next_visit==true">
                        {{item.next_visit_date | date:'dd'}}/{{item.next_visit_date | date:'MM'}}/{{item.next_visit_date | date:'YYYY'}}
                        </ng-container>
                        <ng-container *ngIf="item.next_visit==false || !(item.next_visit)">
                        {{item.scheduled_date | date:'dd'}}/{{item.scheduled_date | date:'MM'}}/{{item.scheduled_date | date:'YYYY'}}
                         </ng-container> -->
                      <!-- <ng-container *ngIf="item.scheduled_date">
                        {{item.scheduled_date | date:'dd'}}/{{item.scheduled_date | date:'MM'}}/{{item.scheduled_date | date:'YYYY'}}
                      </ng-container> -->
                      <!--  -->
                      {{item?.scheduled_date | date:'dd'}}/{{item?.scheduled_date | date:'MM'}}/{{item?.scheduled_date | date:'YYYY'}}

                      </span> 
                      <!-- <span *ngIf="currentTab =='scheduled_visits'">
                        <img  class="card-edit" src="/assets/images/edit.svg" (click)="selectTaskId(item.id,MyModel);"> 
                      </span> -->
                      <span *ngIf="currentTab =='overdue_visits'">
                        {{item?.scheduled_date | date:'dd'}}/{{item?.scheduled_date | date:'MM'}}/{{item?.scheduled_date | date:'YYYY'}}
                        <img  class="card-edit" src="/assets/images/edit.svg" (click)="selectTaskId(item,MyUnModel);"> 
                      </span>
                    </span>
                  </div>
                  <div class="view-btn">
                    <ng-container  *ngIf="currentTab == 'completed_visits'">
                      <span  (click)="goToTaskDetails(item)">View 
                      <svg xmlns="http://www.w3.org/2000/svg" style="margin-top:-2px;margin-left:-6px;" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="2" stroke-linejoin="round"
                       class="feather feather-chevron-right align-middle me-2">
                      <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="currentTab == 'scheduled_visits'">
                      <span (click)="goToTaskDetails(item)">View
                      <svg xmlns="http://www.w3.org/2000/svg" style="margin-top:-2px;margin-left:-6px;" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="2" stroke-linejoin="round"
                      class="feather feather-chevron-right align-middle me-2">
                      <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="currentTab == 'unscheduled_tasks'">
                      <span (click)="goToTaskDetails(item)">View
                      <svg xmlns="http://www.w3.org/2000/svg" style="margin-top:-2px;margin-left:-6px;" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="2" stroke-linejoin="round"
                      class="feather feather-chevron-right align-middle me-2">
                      <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="currentTab =='overdue_visits'">
                      <span (click)="goToTaskDetails(item)">View
                      <svg xmlns="http://www.w3.org/2000/svg" style="margin-top:-2px;margin-left:-6px;" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="2" stroke-linejoin="round"
                      class="feather feather-chevron-right align-middle me-2">
                      <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                      </span>
                    </ng-container>
                  </div>
                </div>
              </div>
             </div>
            </div>
          </div>
          <div *ngIf="newData.length==0" class="text-center" style="font-size: 16px;font-weight: 700;">No Data Found</div>
          <div class="text-center">
            <button class="btn btn-info" (click)="Loadmore()" [hidden]="itemsPerPage>totalItems">Load More
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-down-circle align-middle me-2">
              <circle cx="12" cy="12" r="10" ></circle>
              <polyline points="8 12 12 16 16 12"></polyline>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              </svg>
            </button>
         </div>
        </div>
        <div class="row" *ngIf="currentTab == 'sales_window'">
            <div class="table-responsive">
              <table p-table p-table class="table table-cont">
                <thead>
                <tr class="table_heading">
                <th>Sr.No</th>
                <th>Customer Name</th>
                <th>City</th>
                <th>Contact No</th>
                <th>Fleet Size</th>
                <th>Sales Opportunity</th>
                </tr>
                </thead>
                <tbody>
                  <tr class="table_data" *ngFor="let res of newData index as i">
                    <td>{{i+1+currentPage}}</td>
                    <td>{{res.first_name}} {{res.last_name}}</td>
                    <td>{{res.city}}</td>
                    <td>{{res.contact}}</td>
                    <td>{{res.fleetsize}}</td>
                    <td style="padding-left: 4%;">
                      <label class="badge-int "[ngClass]="res.sales_opty=='green' ? 'bg-interset':'bg-not-sure'">{{res.sales_opty=='green' ?'Interested':res.sales_opty=='yellow' ?'Not Sure':'Not Interested'}}</label>
                    </td>
                  </tr>
                  <tr *ngIf="newData.length===0">
                    <td colspan="3"></td>
                      <td>No Data Found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="getSalespagination();">
              </ngb-pagination>
            </div>
        </div>
        <br><br>
        <section>      
          <ng-template #myDateRangeModal let-modal>
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Set Date Range</h5>
                <button (click)="modal.dismiss('Cross click');" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                    <p-calendar   [inline]="true"  [(ngModel)]="rangeDates" selectionMode="range"></p-calendar>
                <!-- <div class="col-md-12">
                  <div class="form-group">
                    <label class="lable-text" for="">From Date<span style="color:red;">*</span></label>
                    <input type="date" class="form-control sdate" [(ngModel)]="filterStartDate" name="startDate" id="startDate" dateInput>
                  </div><br>
                  <div class="form-group">
                    <label class="lable-text" for="">To Date<span style="color:red;">*</span></label>
                    <input type="date" class="form-control sdate" [(ngModel)]="filterEndDate" name="endDate" id="endDate" dateInput>
                  </div>
                </div> -->
              </div>
              <div class="text-center" style="padding-bottom:5%;">
                <button type="button" (click)="SaveDateRange()" class="btn btn-lg btnsave btnClr">Apply</button>
              </div>
            </div>
          </ng-template>
          <!-- Model for reschule date -->
          <ng-template #MyModel let-modal>
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Reschedule Visit Date</h5>
                <button (click)="modal.dismiss('Cross click');" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">

                  <p-calendar  [(ngModel)]="sdate" [inline]="true" ></p-calendar>
              
              </div>
              <div class="text-center" style="padding-bottom:5%;">
                <button type="button" (click)="saveDatetimeSch(schedTask)" class="btn btn-lg btnsave btnClr">Submit</button>
              </div>
            </div>
          </ng-template>
          <!-- overDue to Set -->
          <ng-template #MyUnModel let-modal>
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Set Visit Date</h5>
                <button (click)="modal.dismiss('Cross click');" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">

                  <p-calendar   [minDate]="minimumDate" [min]="today" [(ngModel)]="sdate" [inline]="true" ></p-calendar>
              
              </div>
              <div class="text-center" style="padding-bottom:5%;">
                <button type="button" (click)="saveOverDueDatetime(OverDueTask)" class="btn btn-lg btnsave btnClr">Submit</button>
              </div>
            </div>
          </ng-template>
          <!-- OverDue OverDueTask -->
          <ng-template #OverDueTask let-modal>
          <div class="modal-content">
          <div class="modal-body">
            <div class="complete-task-align">
              <div class="stat stat-sm">
                <svg xmlns="http://www.w3.org/2000/svg" 
                  width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-check align-middle me-2">
                  <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
              </div>
            </div>
            <div class="task-comp-text">Visit Date Set</div>
            <div class="task-comp-text-success">You have set the visit date for {{VisitDate}}</div>
            <div class="all-task-comp-text">
              You can view this task in your scheduled tasks list for action.
            </div>
            <div class="complete-visit-btn">
              <button type="button" class="btn btn-completed btn-lg">
                <span class="goto-complete-visit" (click)="closeCompletepopup('scheduled_visits')">go to Scheduled visits</span>
              </button>
            </div>
          </div> 
          </div>
          </ng-template>
          <!-- for response success msg popup -->
          <ng-template #schedTask let-modal>
            <div class="modal-content">
            <div class="modal-body">
              <div class="complete-task-align">
                <div class="stat stat-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" 
                    width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-check align-middle me-2">
                    <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                </div>
              </div>
              <div class="task-comp-text">Next Visit Date Set</div>
              <div class="task-comp-text-success">You have set the next visit date for {{VisitDate}}</div>
              <div class="all-task-comp-text">
               You can view this task in your scheduled tasks list for action.
              </div>
              <div class="complete-visit-btn">
                <button type="button" class="btn btn-completed btn-lg">
                  <span class="goto-complete-visit" (click)="closeCompletepopup('scheduled_visits')">go to Scheduled visits</span>
                </button>
              </div>
            </div> 
            </div>
          </ng-template>
        </section>
      </main>
    </div>
  </div>
</div>