import { Injectable } from '@angular/core';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public isLoggedIn: boolean = true;
  public onboardingStep:boolean = false;

  public isLoggedInUser() {
    console.log(localStorage.getItem('token'));
    if (localStorage.getItem('token') !== '' && localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null) {
      this.isLoggedIn=true;
      return of(true);
    } else {
      this.isLoggedIn=false;
      return of(false);
    }
  }
}
