import { Component, OnInit,ViewChild } from '@angular/core';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';

import { BaseApiService } from 'src/app/base-api.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as XLSX from 'xlsx';
import { RouterModule, Router } from '@angular/router';
 
 import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

declare var myExtObject: any;
declare var webGlObject: any;
@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent implements OnInit {
	
	
       

  constructor(private router: Router,private baseAPI: BaseApiService, public spinnerService: NgxSpinnerService,private http: HttpClient) {
          var token =localStorage.getItem('token');
	       
			if(token==undefined || token==='' ){
			  this.router.navigate(['/login']);
			}
			
			 webGlObject.init();
			

		 }
	  	
		
		    selectedName:string="";
			public fleetOwnerList:any=[];
			 selectedAll: any;
			  selectedAleartAll: any;
			   selectedCurrentAll: any;
			    selectedSummaryAll: any;
 
	     //@ViewChild('form') form;
			dropdownList = [];
			selectedItems = [];
			//dropdownSettings = {};
			dropdownSettings: IDropdownSettings;
			
			
			
	
	resultCheck:boolean = false; 	
	 globalData:any=[];
	event_array= [];
	selectedSPN_array= [];
	pageOfItems = [];
	 editStartDate:string;
     editEndDate:string;
	 start_date:string;
	 end_date:string;
	 pgnNo:string;
	searchData:string;
	public  apps: any[] = [];
  vechileDetails: any;
   public min = new Date(2018, 1, 12, 10, 30);
	 
	

    // Max moment: April 21 2018, 20:30
	public yearMax=this.getCurrentDay('Y')+1;
	public monthMax=this.getCurrentDay('M');
	public dateMax=this.getCurrentDay('D');
	private todate = new Date();
	 public Hours=this.todate.getHours();
    public getMinutes=this.todate.getMinutes();
    public max = new Date(this.yearMax, this.monthMax, this.dateMax, 20, 30);
	
	
	 public myDate: IMyDate = {

    year: this.getCurrentDay('Y'),
    month: this.getCurrentDay('M'),
    day: this.getCurrentDay('D'),
  }
	 
  getCurrentDay(cat): any {
    let output;
    var today = new Date();
    var day = today.getDate();
	 var month = today.getMonth() + 1; //January is 0!
    if (day != 1) {
        //day = day - 1;
    }else{
		if(month==5 || month==7 || month==10 || month==12)
		{
			day=30;
		}
		
		if(month==2 || month===4 || month==6 || month==8 ||month==9 || month==11 || month==1)
		{
			day=31;
		}
		
		if (year % 4 == 0 && month==3) 
		{
			day=29;
		}
		else if (year % 4 != 0 && month==3) 
		{
			day=28;
		}
		month=month-1;
	}

   
	//alert(day+"#"+month);
    var year = today.getFullYear();

    if (cat === 'D') {
      output = day;
    }
    else if (cat === 'M') {
      output = month;
    }
    else {
      output = year;
    }
    return output;
  }
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    markCurrentDay: true,
   // disableUntil: this.myDate  vinay this is for disble 
    disableSince :  this.myDate

  };
  
  
  
    compareDate()
	{
		
		
		var startDate = new Date(this.editStartDate);
		var endDate = new Date(this.editEndDate);

		var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
		var stString=Date.parse(startDate.toUTCString());
		var edString=Date.parse(endDate.toUTCString());
		if (edString ==stString) //86400
		{
		  alert("Start date and end date cannot be same");
		  this.editStartDate=null;
			  this.editEndDate=null;	
			  this.start_date="";		
		}else if(this.editEndDate!=null && this.editStartDate!=null)
		{
			
			
			 this.editStartDate=this.convert(this.editStartDate);
	         this.editEndDate=this.convert(this.editEndDate);
			 
			 
		} 
	}
  
  convert(str) {
	 
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    var Hours=date.getHours();
    var getMinutes=date.getMinutes();
	
	var returnDtae=[date.getFullYear(), mnth, day].join("-");
	returnDtae=returnDtae+"T"+Hours+":"+getMinutes+":00";
  return returnDtae;
}

  onEditStartDateChanged(event) {
	 

   var splitedDate=this.start_date.toString().split(",");
	 
	  this.editStartDate=splitedDate[0];
	  this.editEndDate=splitedDate[1];
	  

	 
		if(this.editEndDate!=null && this.editStartDate!=null)
		{
			this.compareDate();
			
			
		}
		
		
	 }
	 
	applyFilter()
	{
		
		
		
	}
	 fetchReport() {
	  
	 
	 this.pgnNo=this.selectedSPN_array.toString();
	
	if(this.editStartDate === undefined && this.editEndDate !== undefined )
	{
		alert('Please enter proper date in the Start date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate !== undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate === undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the Start date and End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}

    if ((this.editStartDate === undefined || this.editStartDate === '' || this.editStartDate === null) ||
      (this.editEndDate === undefined || this.editEndDate === '' || this.editEndDate === null) ||
      (this.searchData === undefined || this.searchData === '' || this.searchData == '' || this.searchData == null ) ||(this.pgnNo === undefined || this.pgnNo === '' || this.pgnNo == '' || this.pgnNo == null) ) {
        alert('Please fill all mandatory fields.');

    }
    else {  

     
     

		if (window.confirm("Do you want to fetch report?")) 
	   {
		   
		    //this.callReportApi();
           // alert("Notification created successfully");
			//this.reset1();
        } 
		else 
		{
            alert("Report serach is failed");
        }

    }
  }
	
	 selectChange( $event) {
    //In my case $event come with a id value
	
	this.selectedName=$event;
	let jsonObj2 = {}; 
	for(let i =0; i < 10;i++) {			  

					jsonObj2["vin"]='PROACC06022081648_'+i;									  
					jsonObj2["alert"]="Alert";
					jsonObj2["currentStatus"]="Current Status";								  
					jsonObj2["fleetSummary"]="Fleet Summary";	
					jsonObj2["selected"]=false;	
					jsonObj2["alertSel"]=false;	
					jsonObj2["currentSel"]=false;	
					jsonObj2["summarySel"]=false;	
					
					
					this.event_array.push(jsonObj2);
					jsonObj2 = {};	

					}
			this.spinnerService.hide(); 
			this.resultCheck=true;
  }
  
  submitApi()
  {
	  
	   for(var i=0;i<this.event_array.length;i++){
			 
			  if(this.event_array[i].selected == true){
				alert(this.event_array[i].vin);
			  }
	   }
  }

  
  
  selectAll() {
    console.log('hii '+ this.event_array.length);
    for (var i = 0; i < this.event_array.length; i++) {
     // console.log(i);
    //  this.fleetOwnerList[i].selected = this.selectedAll;
	  this.event_array[i].selected = this.selectedAll;
     console.log(this.event_array[i].selected);
    }
  }
 
 
 checkIfAllSelected() {
    this.selectedAll = this.event_array.every(function(item:any) {
        return item.selected == true;
      })
  }
  
   checkIfAllAlertSelected() {
    this.selectedAleartAll = this.event_array.every(function(item:any) {
        return item.alertSel == true;
      })
  }
  
   checkIfAllCurrentSelected() {
    this.selectedCurrentAll = this.event_array.every(function(item:any) {
        return item.currentSel == true;
      })
  }
  
   checkIfAllSummarySelected() {
    this.selectedSummaryAll = this.event_array.every(function(item:any) {
        return item.summarySel == true;
      })
  }



  ngOnInit() {
	 //   this.monthMax=this.monthMax-1;
		this.max=new Date();
	   //this.max = new Date(this.yearMax, this.monthMax, this.dateMax, this.Hours, this.getMinutes);
	  
	   
	   this.fleetOwnerList = [{"fleet_id":"1", "membership_name":"Axis Pvt. Ltd."},{"fleet_id":"2",  "membership_name":"Kailash Pvt. Ltd."}];
		
		
	
	  
	     this.dropdownList = [
		 { item_id: 'total_distance', item_text: ' 65361 / 516104 : Prop_IC_RunInfo - IC_Total_Distance'},
		 { item_id: 'total_fuel_used', item_text: ' 65257 / 250 : LFC1 - Total fuel used'}
     
    ];
    this.selectedItems = [
      //{ item_id: 3, item_text: 'Pune' },
      //{ item_id: 4, item_text: 'Navsari' }
    ];
    this.dropdownSettings={
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
	  limitSelection: 10,
      allowSearchFilter: true
    };
  }
  onItemSelect(item: any) {
	  
	
	  this.selectedSPN_array=[];
   // console.log(item);
	  console.log(JSON.stringify(this.selectedItems));
	  for(let i =0; i < this.selectedItems.length;i++) {
		
		 this.selectedSPN_array.push(this.selectedItems[i].item_id);
		 
	 }
	 
	
  }
  
   onItemDeSelect(item: any) {
	  
	
	 
   // console.log(item);
   
	console.log(JSON.stringify(this.selectedItems));
	for( var i = 0; i < this.selectedSPN_array.length; i++){
		if ( this.selectedSPN_array[i] === item.item_id) { 
		
		this.selectedSPN_array.splice(i, 1); 
		
		
		}
		
		}
	
	
  }
  
  
  
  
  onSelectAll(items: any) {
    console.log(items);
  }
        }

       
 

