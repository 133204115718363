import {Injectable} from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree, Router
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {LoginService} from '../login.service';
import {HttpErrorResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';

// noinspection JSAnnotator
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
 constructor(private router: Router,
              private LoginService: LoginService
  ) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.LoginService.isLoggedInUser().pipe(map(res => {
      if (res ==false) {
        this.router.navigate(['/login']);
        this.LoginService.isLoggedIn=true;
        return false;
      }
      if (res==true) {
        this.LoginService.isLoggedIn=true;
        return true;
      } else {
        this.router.navigate(['/login']);
        this.LoginService.isLoggedIn=true;
        return false;
      }
    }));
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
