<div class="lyt-main" id="lyt-main">


<section>
    <div class="cp-section">
	
        <div class="sec-head full-width-lw">
            <div class="left-wrap" >
                <h2 class="sec-title">Vehicle CAN Data</h2>
            </div>
            <div class="right-wrap">
               
            </div>
        </div>
		<div class="sec-head full-width-lw" >
		   <div class="right-wrap ">
                <ul class="act-list typ-multiline">
				
				<li class="act-item typ-full">
					
					
					
								
								
								<div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <input [owlDateTimeTrigger]="dt10" [min]="min" [max]="max" [owlDateTime]="dt10" [(ngModel)]="start_date" [selectMode]="'range'"  class="form-control" placeholder="Date & Time Range" (ngModelChange)="onEditStartDateChanged($event)" >
                                     <owl-date-time #dt10></owl-date-time>
									 
                                </div>
                            </div>
                        </div>
								
								
                        
                    </li>
                    
                    <li class="act-item typ-full">					
								
								
                        <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <input type="text"  [(ngModel)]="searchData" class="form-control" placeholder="VIN Number" (keyup)="applyFilter($event.target.value)" value="PROACC06022081648">
                                </div>
                            </div>
                        </div>
                    </li>
					
					 <li class="act-item typ-full">					
								
								
                        <div class="cp-form">
                            <div class="form-group">
							    
                                <div class="input-group1" style="width:500px;">
                                     <ng-multiselect-dropdown
									 class="custom-font-size form-control"
  [placeholder]="'SPN Number'"
  [data]="dropdownList"
  [(ngModel)]="selectedItems"
  [settings]="dropdownSettings"
  (onSelect)="onItemSelect($event)"
  (onSelectAll)="onSelectAll($event)"
  (onDeSelect)="onItemDeSelect($event)"
>
</ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>
                    </li>
					
					
					
					
					
                    </ul>
					<ul class="act-list typ-multiline" style="float:right;">
                    <li class="act-item">
                        <button class="btn btn-icon-text" (click)="fetchReport();" >
						<span class="icon icon-search"></span>Search</button>
						
						
						
                    </li>
                    <li class="act-item">
                        <button class="btn btn-icon-text" (click)="downloadAsExcelFile();" ><span class="icon icon-download"></span>Download</button>
                    </li>
                </ul>
            </div>
		</div>
		
        <div class="sec-cont">
            <div class="cp-card typ-table">
               <div class="mat-elevation-z8 table-responsive" *ngIf="resultCheck">
                <app-watermark [class.onerecord]="event_array.length<3"></app-watermark>
			  <table class="table table-bordered">
				<thead>
				<tr>
					<th>Sr No</th>
					<th>Event DateTime</th>
					<th>Spn Name</th>
					<th>Spn Unit</th>
					<th>Spn Number</th>
					<!--th>SpnValue Description</th--->
					<th>Spn Value</th>
					<!--th>GpsLongitude</th-->
				</tr>
				</thead>
				<tbody>
				
				<!------------"spnDat":[{"spnName":"ABS Trailer Warning Lamp State","spnUnit":"","pgn":61441,"spnNumber":1792,"spnValueDescription":"reserved for SAE assignment","spnValue":3},{"spnName":"EBS Brake Switch","spnUnit":"","pgn":61441,"spnNumber":1121,"spnValueDescription":"reserved for SAE assignment","spnValue":3},{"spnName":"ASR Hill Holder Switch","spnUnit":"","pgn":61441,"spnNumber":577,"spnValueDescription":"reserved for SAE assignment","spnValue":3},{"spnName":"ATC / ASR lamp State","spnUnit":"","pgn":61441,"spnNumber":1793,"spnValueDescription":"Off  Used when driver warning system is nonactive  which by requirement means the lowlevel and severe","spnValue":0},{"spnName":"Remote accelerator enable switch","spnUnit":"","pgn":61441,"spnNumber":969,"spnValueDescription":"reserved for SAE assignment","spnValue":3},{"spnName":"Source Address Of controlling Device For Brake Control","spnUnit":"","pgn":61441,"spnNumber":1481,"spnValueDescription":"Not Available","spnValue":11},{"spnName":"Trailer ABS Status","spnUnit":"","pgn":61441,"spnNumber":1836,"spnValueDescription":"reserved for SAE assignment","spnValue":3},{"spnName":"ASR Engine Control Active","spnUnit":"","pgn":61441,"spnNumber":561,"spnValueDescription":"reserved for SAE assignment","spnValue":3},{"spnName":"ASR / ATC Brake Control Active","spnUnit":"","pgn":61441,"spnNumber":562,"spnValueDe----------->
				
				<tr *ngFor="let item of event_array  let i=index">
					<td>{{ i+1}}</td>
					<td><div style="width:100px;word-wrap: break-word;">{{item.eventDateTime}}</div></td>
					<td><div style="width:100px;word-wrap: break-word;">{{item.spnName}}</div></td>
					<td>{{ item.spnUnit}}</td>
					<td><div style="width:100px;word-wrap: break-word;">{{item.spnNumber}}</div></td>
					<!--td><div style="width:100px;word-wrap: break-word;">{{item.spnDat[0].spnValueDescription}}</div></td-->
					<td>{{ item.spnValue}}</td>
					<!--td><div style="width:100px;word-wrap: break-word;">--</div></td-->
					
					
				</tr>
				</tbody>
			</table>	
			</div>
            </div>
        </div>
    </div>
   
</section>






<style>
/*
:host::ng-deep.selected-item { border-radius: 6px !important; background: #999999 !important; border: 1px solid #999999 !important; }

:host::ng-deep.selected-item {
border-radius: 6px !important;
background: #BAA28E !important;
border: 1px solid #BAA28E !important;
}

:host::ng-deep ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox] + div:before {
border: 2px solid #5e3817 !important;
}

:host::ng-deep ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked + div:before {
background: #BAA28E !important;
}

.custom-font-size{
  height:10000px;
}*/

:host::ng-deep multiselect-dropdown[_ngcontent-c5] .dropdown-btn[_ngcontent-c5] {
  border:0px;
}


:host::ng-deep.dropdown-btn { border-radius: 6px !important;  border: 0px solid #999999 !important;
padding: 0px 0px !important;
/* width: 400px !important;*/
 }

</style>
<!--footer>
			Copyright &#169; 2020 TATA MOTORS All Rights Reserved.
		</footer-->


































