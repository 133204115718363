import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaderResponse,HttpHeaders,HttpXhrBackend  } from "@angular/common/http";
//import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
//import 'rxjs/add/operator/toPromise';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

//var forwarded = require('forwarded');
//this.http.get('https://example.com/api/items').pipe(map(data => {})).subscribe(result => {
 //     console.log(result);

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
 constructor(private http: HttpClient, private router: Router,private backend:HttpXhrBackend) { 
   
  }
       private loggedIn = new BehaviorSubject<boolean>(false); // {1}
			// baseUrl: string = 'https://cvp-qa.api.tatamotors/cvp/';//Dev
      // Dev urls
      //  baseUrl: string = 'https://skindevreplica.api.tatamotors/api/';
			 baseTokenUrl: string ='https://cvp-qa.api.tatamotors/auth/realms/service_account/protocol/openid-connect/token';//Dev
			 logoutURL: string = '';//prod

			//baseUrl: string = 'https://cvp.api.tatamotors/cvp/';	 //prod  
			//MAT505159L8G05397  MAT505159L8G05397
			//baseTokenUrl: string ='https://cvpauth.api.tatamotors/auth/realms/service_account/protocol/openid-connect/token';//prod

			
			//****************dev details*************************************************
			onboardUrl: string ='https://cvp-qa.api.tatamotors/cv/vehicle-state/onboarded';
			onboardVinUrl: string ='https://cvp-qa.api.tatamotors/';
			CREDENTIAL='cvp-user-1:plutoisnotaplanet';
      DataObj=new BehaviorSubject(localStorage.getItem('userData'));
      DataRSMObj=new BehaviorSubject(localStorage.getItem('userRSMData'));
			
			//***************pord details*******************
			//onboardUrl: string ='https://cvp.api.tatamotors/cv/vehicle-state/onboarded';
			//onboardVinUrl: string ='https://cvp.api.tatamotors/';
			//CREDENTIAL='cvp-data-team:pr0d-Cvp-DaTA-T3a3';
      // -----------------------------------------------------------------------
      // FEC production Url
	    // baseUrl: string = 'https://skinprod.api.tatamotors/api/';
      // FEC Dev Url
		   baseUrl: string = 'https://skindevreplica.api.tatamotors/api/';

	 postLogin(URL:any,data: any) {
		var HttpUploadOptions1 = {
                 // headers: new HttpHeaders({"Content-Type": "application/x-www-form-urlencoded"}),
                 headers: new HttpHeaders({"Content-Type": 'application/json'}),
                 
                  observe:'body'
    }
        return this.http.post<any>(URL, data,{headers:HttpUploadOptions1.headers}).pipe(map(res => {
          return res}));
          
    }
	
	getTustedToken(url, dataString) {
    return new Promise((resolve, reject) => {
      
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }), 
      responseType: 'text' as 'json'    
    };

      this.http.post(url, dataString, httpOptions).subscribe(data => {

        //  var jsonObj = JSON.parse(JSON.stringify(data.json()));

          resolve(data);
        },
        error => {
           
            reject(error);


        }
      )
    });
  }
   
	callServiceApi(url, dataString) {
    return new Promise((resolve, reject) => {     
    //alert(localStorage.getItem('username'));
   // alert(localStorage.getItem('token'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',        
		'Authorization': 'Bearer ' + localStorage.getItem('token'),
    "Content-Type": 'application/json'
      })
    };
       var finalUrl=url;
      this.http.post(finalUrl, dataString,httpOptions).subscribe((data:any) => {    
		
//console.log(data);
          resolve(data);
        },
        error => {
            
            reject(error);


        }
      )
    });
  }
  callServiceApiXL(url, dataString) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
        //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
		'Authorization': 'Bearer ' + localStorage.getItem('token')
      }),
	   responseType: 'text' as 'json' 
    };
       var finalUrl=url+dataString;
      this.http.get(finalUrl, httpOptions).subscribe((data:any) => {

         // var jsonObj = JSON.parse(JSON.stringify(data.json()));
		

          resolve(data);
        },
        error => {
            var jsonObj = JSON.parse(JSON.stringify(error));
            reject(error);


        }
      )
    });
  }
  
  callOnboardApi(url, dataString) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa(this.CREDENTIAL)
      })
    };
       var finalUrl=url+dataString;
	   const body=JSON.stringify({});
      this.http.get(finalUrl, httpOptions).subscribe((data:any) => {    
		

          resolve(data);
        },
        error => {
            
            reject(error);


        }
      )
    });
  }
  
  callOnboardPostApi(url, clientName,editStartDate,editEndDate,clientEmail,clientMobile,companyName,gstNumber,panNumber,aadharNumber,address,CreatedBy,userType,supportedDataTypes) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa(this.CREDENTIAL)
      })
    };     
	 const body=JSON.stringify({
"clientName": clientName,
"emailId": clientEmail,
"mobile": clientMobile,
"companyName": companyName,
"gstNumber": gstNumber,
"panNumber": panNumber,
"aadharNumber": aadharNumber,
"address": address,
"createdBy": CreatedBy,
"userType": userType,
"supportedDataTypes": supportedDataTypes,
"validityEndDate": editEndDate,
"validityStartDate": editStartDate,

	 });   
     this.http.post(url, body, httpOptions).subscribe((data:any) => {    
		
          resolve(data);
        },
        error => {
            
            reject(error);


        }
      )
    });
  }
  
  get isLoggedInUser()
  {
	    this.login();
	   return this.loggedIn.asObservable(); // {2}
	  
  }
  login(){
     var token =localStorage.getItem('token');
	       
			if(token!=undefined && token!='' ){
			  
			  this.loggedIn.next(true);
			  console.log("  ngOnInit  ");
			}else{
				
				this.loggedIn.next(false);
			}
      
     
  }
  get_vins_by_fleet_id(url, dataString,fleetId) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',        
		'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };
    const body=JSON.stringify({
		  fleet_id:fleetId
	  });
       var finalUrl=url+dataString;
      this.http.post(finalUrl, body, httpOptions).subscribe((data:any) => {    
		

          resolve(data);
        },
        error => {
            
            reject(error);


        }
      )
    });
  }
  callCheckONclientApi(url, dataString,param) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',        
		'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };
	  const body=JSON.stringify({});
       var finalUrl=url+dataString;
      this.http.post(finalUrl, body, httpOptions).subscribe((data:any) => {    
		

          resolve(data);
        },
        error => {
            
            reject(error);


        }
      )
    });
  }
  CheckClientDetails(url, dataString) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',        
		'Authorization': 'Basic ' + btoa(this.CREDENTIAL)
      })
    };
       var finalUrl=url+dataString;
      this.http.get(finalUrl, httpOptions).subscribe((data:any) => {    
		

          resolve(data);
        },
        error => {
            
            reject(error);


        }
      )
    });
  }
  MapVinWithFleetClient(url, dataString, clientId, fleetId, vin) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({ 
		'Content-Type':  'application/json',		
		'Authorization': 'Basic ' + btoa(this.CREDENTIAL)
      })
    };
	  const body=JSON.stringify({
		  clientId:clientId,
		  fleetId:fleetId,
		  chassisNumbers:vin
	  });
       var finalUrl=url+dataString;
      this.http.put(finalUrl, body, httpOptions).subscribe((data:any) => {    
		

          resolve(data);
        },
        error => {
			if(error.status==200)
			{
				alert("Client Account Created.");
			}
			else if(error.status == 400)
			{
				alert("no active client found for :"+clientId);
			}
			else
			{
				alert('Something is wrong..');
			}
            reject(error);


        }
      )
    });
  }
  checkVinWithFleetClient(url, dataString, clientId, fleetId, vin) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({ 
		'Content-Type':  'application/json',		
		'Authorization': 'Basic ' + btoa(this.CREDENTIAL)
      })
    };
	  const body=JSON.stringify({
		  clientId:clientId,
		  fleetId:fleetId,
		  chassisNumbers:vin
	  });
       var finalUrl=url+dataString;
      this.http.put(finalUrl, body, httpOptions).subscribe((data:any) => {    
		

          resolve(data);
        },
        error => {
			if(error.status==200)
			{
				alert("Client Account Created.");
			}
			else if(error.status == 400)
			{
				alert("no active client found for :"+clientId);
			}
			else
			{
				alert('Something is wrong..');
			}
            reject(error);


        }
      )
    });
  }
  checkClientEnableStatusDetails(url, dataString) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',        
		'Authorization': 'Basic ' + btoa(this.CREDENTIAL)
      })
    };
       var finalUrl=url+dataString;
	    const body=JSON.stringify({});
	   console.log(finalUrl);
      this.http.post(finalUrl, body, httpOptions).subscribe((data:any) => {    
		alert("Client Enable Successfully.");

          resolve(data);
        },
		error => {
			if(error.status == 400)
			{
				alert("non-existing clientId found");
			}
			else
			{
				alert('Something is wrong..');
			}
            reject(error);


        }
      )
    });
  }
  checkClientDisableStatusDetails(url, dataString) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',        
		'Authorization': 'Basic ' + btoa(this.CREDENTIAL)
      })
    };
       var finalUrl=url+dataString;
	    const body=JSON.stringify({});
      this.http.post(finalUrl, body, httpOptions).subscribe((data:any) => {    
		alert("Client Disable Successfully.");

          resolve(data);
        },
		error => {
			if(error.status == 400)
			{
				alert("non-existing clientId found");
			}
			else
			{
				alert('Something is wrong..');
			}
            reject(error);


        }
      )
    });
  }
  callClientListOnboardApi(url, dataString,searchText) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa(this.CREDENTIAL)
      }),
    };
       var finalUrl=url+dataString;
	   const body=JSON.stringify(
		{
		"clientName": searchText,
		"email":searchText,
		"mobile":searchText
		}
	   );
      this.http.post(finalUrl, body, httpOptions).subscribe((data:any) => {    

          resolve(data);
        },
        error => {
            
            reject(error);


        }
      )
    });
  }
  checkUpdateCLientStatus(url, dataString, clientId, approveStatus, clientName) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
		'Content-Type':  'application/json',        
		'Authorization': 'Basic ' + btoa(this.CREDENTIAL)
      })
    };
	 const body=JSON.stringify({
		  "clientId":clientId,
		  "approvalStatus":approveStatus,
		  "approvalStatusChangedBy":clientName
	  });
       var finalUrl=url+dataString;
      this.http.put(finalUrl, body, httpOptions).subscribe((data:any) => {    
          resolve(data);
        },
		error => {
            reject(error);        }
      )
    });
  }
  callVinReport(url, dataString) {
    return new Promise((resolve, reject) => {     
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
		'Content-Type':  'application/json',        
		'Authorization': 'Basic ' + btoa(this.CREDENTIAL)
      })
    };
	 
       var finalUrl=url+dataString;
      this.http.get(finalUrl, httpOptions).subscribe((data:any) => {    
          resolve(data);
        },
		error => {
            reject(error);        }
      )
    });
  }

  callVinReportToPromise(url, dataString) {
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
		'Content-Type':  'application/json',        
		'Authorization': 'Basic ' + btoa(this.CREDENTIAL)
      })
    };
	 
       var finalUrl=url+dataString;
      return this.http.get(finalUrl, httpOptions).toPromise();
  }
  handleAPIResponse(response:any, errMessage:string):any {
      if(!(response)){
      errMessage = "!No Data Found";
      return errMessage;
      }
      if(response["status"]== 401){
        errMessage = "Unauthorized. Your login session might be expired";

      } else {
        errMessage = this.handleSucessfulAPIResponse(response, errMessage);
      }
    //}
    return errMessage;
  }

  handleSucessfulAPIResponse(apiResponse:any, errMessage:string):any {
    if(apiResponse["error"] && apiResponse["error"]["msg"]){
      var errors = apiResponse["error"]["msg"];
      if(typeof errors == "object"){
        errMessage = errors.join('. ');
      } else {
        errMessage = errors
      }
    } else {
      if(apiResponse["msg"] && apiResponse["msg"]){
        var errors = apiResponse["msg"];
        if(typeof errors == "object"){
          errMessage = errors.join('. ');
        } else {
          errMessage = errors
        }
      }
    }
    return errMessage;
  }
  onDateRange(data:any)
  {
    this.DataObj.next(data);
     localStorage.setItem('userData', JSON.stringify(data));
  }
  RSMDateRange(data:any)
  {
    this.DataRSMObj.next(data);
    localStorage.setItem('userRSMData', JSON.stringify(data));
  }
  RSMFEClogout()
  {
    // this.DataRSMObj.next(null);
    // this.DataObj.next(null);
    // localStorage.clear();
  }

}
