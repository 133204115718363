
<app-header></app-header>
<div class="lyt-main" id="lyt-main">


<section>
    <div class="cp-section">    	
	
              <div class="sec-head full-width-lw">		   
               <div class="left-wrap" style="margin:10px;">
                <h2 class="sec-title" >Maintenance Data</h2>
            </div>
            <div class="sec-head full-width-lw" style="margin:10px;">
       <div class="right-wrap ">
                <ul class="act-list typ-multiline">
        
      
                    <li class="act-item typ-full">          
                
                
                        <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <input type="text"   class="form-control" placeholder="Chassis No/Registration No"  value="">
                                </div>
                            </div>
                        </div>
                    </li>

                    

                      <li class="act-item typ-full">
                      <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <select class="form-control"><option>MHCV</option></select>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="act-item">
                        <button class="btn btn-primary" style="background: linear-gradient(180deg, #03045E 0%, #023E8A 100%);
border-radius: 5px;
width: 166px;
height: 48px;" >
         <span class="icon icon-download" style="">  Download Excel</span></button>
            
            
            
                    </li>
                    
                </ul>
            </div>
    </div>
			
			
			 
			
        </div>
	<table p-table  p-table  class="table row-border hover "  >
  <thead>
    <tr>
       <th>Product Line</th>
       <th>Chassis No </th>
      <th>Time To Service</th>
      <th>Portal Booking</th>
      <th>Job Card Data</th>
      <th>Unschedule Service</th>
      <th>Warrenty Lost</th>
       
    </tr>
  </thead>
  <tbody>
    <tr >
      <td>MHCV V1</td>
      <td>MAT808011M1P28331</td>
      <td>3500</td>
      <td>4000</td>
      <td>4000</td>
      <td>95 Hr 20 min</td>
      
    </tr>
     <tr >
      <td>MHCV V2</td>
      <td>MAT808011M1P28331</td>
      <td>3500</td>
      <td>4000</td>
      <td>4000</td>
      <td>95 Hr 20 min</td>
    </tr>
     <tr >
       <td>MHCV V3</td>
      <td>MAT808011M1P28331</td>
      <td>3500</td>
      <td>4000</td>
      <td>4000</td>
      <td>95 Hr 20 min</td>
    </tr>
     <tr >
      <td>MHCV V4</td>
      <td>MAT808011M1P28331</td>
      <td>3500</td>
      <td>4000</td>
      <td>4000</td>
      <td>95 Hr 20 min</td>
    </tr>
  </tbody>
  </table>
  <div class="sec-head full-width-lw c" style="float:right;">
    <div class="right-wrap ">
      <ul class="act-list typ-multiline">

        
      </ul>
    </div>
  </div>
  </div>
   
</section>





  









<!--footer>
			Copyright &#169; 2020 TATA MOTORS All Rights Reserved.
		</footer-->