import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseApiService } from 'src/app/base-api.service';
import { SharedService } from 'src/app/shared.service';
import { FormGroup, FormsModule, FormControl, Validators, ValidatorFn, FormBuilder, ReactiveFormsModule, AbstractControl } from "@angular/forms";
import { ExcelService } from 'src/app/excel-service.service';
import {Location} from '@angular/common';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
const today = new Date(new Date().toISOString().split('T').shift());

@Component({
  selector: 'app-fec-view-history',
  templateUrl: './fec-view-history.component.html',
  styleUrls: ['./fec-view-history.component.scss']
})
export class FecViewHistoryComponent implements OnInit {
  currentDate = new Date();
  newData = [];
  username = '';
  taskid:string="";
  event = 0;
  itemsPerPage: number = 9;
  page = 1;
  totalItems = 0;
  currentPage = 0;
  
  resultCheck = true;
  searchString = '';
 
  fleetEdgeChampList: any = [];
  listingwidth:boolean=false;
  rangeDates: Date[];
  sdate: Date | null = new Date();
  ChangeTab:any='completed_visits';
  // sheduleTaskForm: FormGroup;
  // sdate: Date | null = today;
  filterStartDate: Date | null = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
  filterEndDate: Date | null = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
  currentTab:string = '';

  // for Testing 
  saleswindowdata : any[] = [];
  paginateData: any[] = [];
  pageSize = 10;
  collectionSize = 0;
  searchTerm:any;
  VisitDate:any;
  getCustomerDetails:any=[];
  hid:any;
  imgSrc:any;
  showFlag: boolean = false;
  CmpName:any='';
  constructor(
    public router: Router,
    private sharedService: SharedService,
    private baseAPIService: BaseApiService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private _Activatedroute: ActivatedRoute,
    private excelService:ExcelService,
    private _location: Location
  ) { }
  listView = false;
  gridView = true;
  data = [
    {
      id: 1,
      name: 'Abc',
      email: 'abc@mail.com',
      isExpand: false,
      address: [
        {
          add1: 'Delhi',
          add2: 'Bangalore',
        }
      ]
    },
    {
      id: 2,
      name: 'Xyz',
      email: 'xyz@mail.com',
      isExpand: false,
      address: [
        {
          add1: 'Mumbai',
          add2: 'Pune',
        }
      ]
    },
    {
      id: 3,
      name: 'ijk',
      email: 'ijk@mail.com',
      isExpand: false,
      address: [
        {
          add1: 'Chennai',
          add2: 'Bangalore',
        }
      ]
    },
    {
      id: 4,
      name: 'def',
      email: 'def@mail.com',
      isExpand: false,
      address: [
        {
          add1: 'Kolkata',
          add2: 'Hyderabad',
        }
      ]
    }
  ]
  ngOnInit(): void {
    
      this.baseAPIService.DataObj.subscribe(res => {
        if(res==null)
        {
          this.filterEndDate = this.sharedService.dateFormater(this.filterEndDate);
          this.filterStartDate = this.sharedService.dateFormater(this.filterStartDate);
          let obj={'filterEndDate':this.filterEndDate,'filterStartDate':this.filterStartDate};
          this.baseAPIService.onDateRange(obj);
        }
        else
        {
          if(isJson(res)==false)
          {           
             this.filterEndDate = this.sharedService.dateFormater(res['filterEndDate']);
             this.filterStartDate = this.sharedService.dateFormater(res['filterStartDate']);
             this._Activatedroute.params.subscribe(params => {
              this.hid = params['hid'];
              this.getHistoryDetails(this.hid);
              });
          }
          else
          {
            let obj=JSON.parse(res);
            this.filterEndDate=obj.filterEndDate;
            this.filterStartDate=obj.filterStartDate;
            this._Activatedroute.params.subscribe(params => {
              console.log(params,'params')
              this.hid = params['hid'];
              this.ChangeTab=params['currentTab'];
              this.CmpName=params['CmpName'];
              this.getHistoryDetails(this.hid);
              });
          }
        }
      }); 
      console.log(this.hid,'123')
  }
  getHistoryDetails(hid:any)
  {
    this.spinnerService.show();
    var requestURL = "fecportal/customer_details/get/";
    var requestPayload = {
       "offset": this.currentPage,
       "limit": 10,
      "fec_customer_data_id": hid,
      "fromdate":this.filterStartDate,
      "todate":this.filterEndDate
    };      
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
     let i=0;
     let j=0;
     response.forEach(element => { 
     element?.upload_image_path?.forEach(ele => {
      ele['type']=getExtensionFromURL(ele.url)
     j++;
     });
     i++;
     });
     this.getCustomerDetails=response;
     this.paginateData=response;
     console.log(this.getCustomerDetails,'this.getCustomerDetails')
     this.spinnerService.hide();
      }, (error) => {
        this.spinnerService.hide();
        var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
        alert(errMessage);
        this.router.navigate(['login'])

      });
  }
  getSalespagination()
  {
    this.currentPage=(this.page - 1) * this.pageSize;
    this.itemsPerPage=(this.page - 1) * this.pageSize + this.pageSize;
    this.getHistoryDetails(this.hid);
  }
  ngAfterViewChecked() {
    let div=document.querySelector('.listing-card-desktop');
    if(document.querySelector('nav.sidebar')?.classList.contains('collapsed')===true)
    {
      this.listingwidth=true;
      console.log(div)
    }
    else
    {
      this.listingwidth=false;
    }
    // console.log(document.querySelector('nav.sidebar').classList.contains('collapsed'),"ok12345")
  }

  searchRecords() {
    // var requestPayload = this.applyAllFilters();
    // this.fetchDataListByFilterApplied(requestPayload);
  }
  downloadHistoryAsExcel():void {
    var jsonData=[];
    var currentdate = new Date(); 
    let ampm=currentdate.getHours() >= 12 ? 'PM' : 'AM';
    var datetime = " FEC Fleet History- " + currentdate.getDate() + "_"
                + (currentdate.getMonth()+1)  + "_" 
                + currentdate.getFullYear() + " "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ' '+ampm
    let i=0;
    this.getCustomerDetails.forEach(element => {
      jsonData.push({'Sr.No':i+1,"Visit Date":this.sharedService.dateFormater(element.next_visit_date),"SPOC Name":element.spoc_name,
      "SPOC Contact":element.spoc_mobile,"SPOC Designation":element.spoc_designation,
      "Sales Opportunity":element.sales_opty=='green'?'Interseted':element.sales_opty=='yellow'?'Not Sure':'Not Interseted',
     "Customer Feedback":element.customer_feedback});
      i++;
    });
    if(jsonData.length==0){
    console.log("No Records found");
    } else {
     let filterStartDate:any=this.filterStartDate.toString().split('-');
     let filterEndDate:any=this.filterEndDate.toString().split('-');
     let excel=filterStartDate[2]+'_'+filterStartDate[1]+'_'+filterStartDate[0]+' to '+ filterEndDate[2]+'_'+filterEndDate[1]+'_'+filterEndDate[0]+ datetime;
     this.excelService.exportAsExcelFile(jsonData, excel);
    }
  }
  fetchDataListByFilterApplied(requestPayload):void {
    this.newData = [];
    this.spinnerService.show();
    var requestURL = "fecportal/get/list/";
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      // console.log(response,'@123response@')
      if (response) {
        this.totalItems = response[0].total_record;
        console.log(this.totalItems,'@this.totalItems@')
        this.collectionSize=this.totalItems
        this.newData = response;
        this.paginateData=response;
        this.newData.shift();
      } else {
        console.log("FEC - No Records Found");
        var message = this.baseAPIService.handleAPIResponse(response, "Unable to process request. Please try again");
        alert(message);
        this.router.navigate(['login'])

      }
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      alert(errMessage);
      this.router.navigate(['login'])
    });
  }
  selectUnschedTaskId(task_id:string,MyUnModel:any):void {
    this.taskid = task_id;
    this.modalService.open(MyUnModel, { size: "sm",centered: true });
  }

  DateRangeModel(myDateRangeModal:any)
  {
    this.modalService.open(myDateRangeModal, { size: "sm",centered: true });
  }
  SaveDateRange()
  {
    if(this.rangeDates[1]==null)
    {
      alert("select proper range date")
    }
    else
    {
      this.filterEndDate = this.sharedService.dateFormater(this.rangeDates[1]);
      this.filterStartDate = this.sharedService.dateFormater(this.rangeDates[0]);
      let obj={'filterEndDate':this.filterEndDate,'filterStartDate':this.filterStartDate};
      this.baseAPIService.onDateRange(obj);
      // this.getHistoryDetails(this.hid);
      this.modalService.dismissAll();
      console.log("1:Test")
    }
  }
  closeCompletepopup(item:any)
  {
    this.modalService.dismissAll();
    this.currentTab=item;
  }
  getBackUrl()
  {
    this._location.back();
  }
  imagepreviewComplete(url:any,ImgSrcModel:any,index:any)
  {
    if(index==1)
    {
      this.imgSrc=url.url;
      this.showFlag = false;
      this.spinnerService.hide();
       this.modalService.open(ImgSrcModel, {centered: true });
    }
    else
    {
      this.imgSrc=url.url;
      this.showFlag = false;
      //  window.open(url, '_blank').
      this.spinnerService.hide();
      if(url.type.toLowerCase()=='pdf')
      {
        var win = window.open();
        win.document.write('<iframe src="' + url.url  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');  
      }
      else
      {
        window.open(url.url,'_self');
      }
    }
  }
  onShowImgeIconsComplete(val:any)
  {
    if(val.type.toLowerCase()=='png' ||val.type.toLowerCase()=='jpeg' || val.type.toLowerCase()=='jpg' ||  val.type.toLowerCase()=='gif')
    {
       return val.url;
    }
    else if(val.type.toLowerCase()=='xlsx')
    {
      return 'assets/images/excel.png';
    }
    else if(val.type.toLowerCase()=='pdf')
    {
      return 'assets/images/pdf.jpg';
    }
    else if(val.type.toLowerCase()=='txt')
    {
      return 'assets/images/txt.png';
    }
    else if(val.type.toLowerCase()=='docx')
    {
      return 'assets/images/docs.png';
    }
    else if(val.type.toLowerCase()=='zip')
    {
      return 'assets/images/zip.png';
    }
    // .docx
    else
    {
      return 'assets/images/docs.png';
    }
  }
}
function isJson(str:any) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return isNaN(str);
}
function getExtensionFromURL(URL){
  const tokens = URL.match(/(\.[a-zA-Z0-9]+(\?|\#|$))/g) 
  if(!tokens[0]) return false 
  let tokenpath=tokens[0].replace(/[^A-Za-z0-9\.]/g,'').split('.')
  return tokenpath[1];
}