<div class="cp-calendar">
        <div class="cal-filter">
            <ul class="list">
            <li class="item active">All <span class="count">(55)</span></li>
            <li class="item trip">Trip <span class="count">(15)</span></li>
            <li class="item service">servicing <span class="count">(12)</span></li>
            <li class="item renewal">renewal <span class="count">(04)</span></li>
            <li class="item payment">payments <span class="count">(09)</span></li>
            </ul>
        </div>
        <div class="mob-view date-selection">
            <button (click)="toggleCal()" class="cal-btn"><span class="icon icon-calendar"></span><span class="date-wrap"><span class="day">01</span><span class="month">Feb</span></span></button>
        </div>
        <div class="cal-cont" [ngClass]="{'show':caltoggle}">
            <div class="days-wrap">
            <ul class="list">
                <li class="item">Sun</li>
                <li class="item">Mon</li>
                <li class="item">Tue</li>
                <li class="item">Wed</li>
                <li class="item">Thu</li>
                <li class="item">Fri</li>
                <li class="item">Sat</li>
            </ul>
            </div>
            <div class="date-wrap">
            <ul class="list">
                <li class="item" *ngFor="let item of dateArr" [attr.data-dt]="item.dateObj" [ngClass]="{'active':item.currDt,'disable':!item.currMn}">
                    <div class="date">{{item.dateNo}}</div>
                    <!-- <ng-container *ngIf="item"></ng-container> -->
                    <div class="task-type">
                        <ng-container *ngFor="let task of taskData">
                            <ng-container *ngIf="item.dateObj === task.date">
                                <span class="legend-item" *ngFor="let tasktype of task.type" [ngClass]="{'trip':tasktype.name==='Trip','servicing':tasktype.name==='Servicing','renew':tasktype.name==='Renewals','pay':tasktype.name==='Payments'}">{{tasktype.data.length}}</span>
                            </ng-container>
                        </ng-container>
                    </div>
                    <!-- <div class="legend" *ngFor="let task of taskData">
                        <span *ngIf="item.dateObj === task.date" class="legend-item" [ngClass]="{'trip':task.type==='Trip','servicing':task.type==='Servicing','renew':task.type==='Renewals','pay':task.type==='Payments'}">{{task.type}}</span>
                    </div> -->
                </li>
            </ul>
            </div>
            <div class="cal-mnt-toggle">
                    <button class="btn-prev" (click)="changeMnth(monthVal,yr,$event)"><span class="icon icon-left-arrow"></span></button>
                <span class="month-name"><span class="m-name">{{monthVal}}</span><span class="year">{{yr}}</span></span>
                <button class="btn-next" (click)="changeMnth(monthVal,yr,$event)"><span class="icon icon-right-arrow"></span></button>
            </div>
        </div>
    </div>