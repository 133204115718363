<form enctype="multipart/form-data">
    <div class="box__input" FileUploadDropzone (onFileDropped)="droppedFiles($event)">
        <div class="file__upload box__upload">
            <div class="upload-text">OR Upload a Bulk Excel</div>
            <div style="margin-bottom: 1rem;">
                <button class="file-selector-btn" type="button" (click)="openFileSelector($event.target)">
                    <span class="btn-text">
                    <i class="icon icon-upload" style="font-size: 1.0rem"></i>
                    Upload FILE
                    </span>
                </button>
            </div>
           
            <div class="upload-file-text">Upload Files less than 15MB</div>
            <div class="upload-file-text">Drag to Upload</div>
            <div class="upload-file-text">1 Excel File per FEC</div>
            <input [(ngModel)]="ImageFile" (change)="changeSelectedFiles($event)" style="display: none;" class="box__file" 
                type="file" name="files[]" 
                accept="{{acceptFileTypes}}"
                data-multiple-caption="{count} files selected"/>
            <br/><br/>
        </div>
        <div class="file__upload box__uploading hide"><span>processing…</span></div>
        <div class="file__upload box__success hide">
            <span>{{ selectedFiles.length }} File(s) Selected.</span>
        </div>
        <div class="file__upload box__error hide"><span>Error! Try selecting files again.</span></div>
    </div>
</form>
