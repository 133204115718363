import { ChangeDetectorRef, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as $ from "jquery";
declare var jQuery: any;
import { FormGroup, FormsModule, FormControl, Validators, ValidatorFn, FormBuilder, ReactiveFormsModule, AbstractControl } from "@angular/forms";
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseApiService } from 'src/app/base-api.service';
import * as XLSX from 'xlsx';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

// @ViewChild("content",{static:true}) content:ElementRef;

@Component({
  selector: 'app-fec-attribute-customer-details',
  templateUrl: './fec-attribute-customer-details.component.html',
  styleUrls: ['./fec-attribute-customer-details.component.scss']
})
export class FecAttributeCustomerDetailsComponent implements OnInit {
  performUploadActionOnData: any = [];

  unassignedFECList: any = [];
  fecInformation: any;
  newCustomerInformationForm: FormGroup;
  today = new Date().toISOString().split('T')[0];

  cityOptions:any = [];
  stateOptions:any = [ 
    {key: 'Andaman and Nicobar', value: 'Andaman and Nicobar', name: 'Andaman and Nicobar'},
    {key: 'Andhra Pradesh', value: 'Andhra Pradesh', name: 'Andhra Pradesh'},
    {key: 'Andaman and Nicobar', value: 'Andaman and Nicobar', name: 'Andaman and Nicobar'}];
  cities = [];
  accountTypeOptions: any = [];
  industryTypeOptions: any = [];
  purposeOfVisitOptions: any = [];
  getallregion:any=[];
  regexp="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
  searchByContact:string="";
  isCompanyName:boolean=false;
  regex ='[a-z0-9]+@[a-z]+\.[a-z]{2,3}';
   DateFlag:boolean=false;
   DateMonthFlag:any='';
   MinDate:any;
   ImageFile:any;
   selectedCountry: string | undefined;
   objarray:any=[
    {'First Name is Required':''},
    {'First Name Accept Only Character':''},
    {'Last Name is Required':''},
    {'Last Name Accept Only Character':''},
    {'Region is Required':''},
    {'Region Accept Only Characters':''},
    {'Account Type is Required':''},
    {'Account Type Accept Only Characters':''},
    {'Contact Number is Required':''},
    {'Contact Number Accept Only 10 Digit':''},
    {'Month is Required':''},
    {'Month Accept only Number':''},
    {'Year is Required':''},
    {'Year Accept only Number':''},
    {'Email is Invalid':''}
  ];
  ValidationFlag:boolean=false;
  // objarray:any=[];
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private baseAPIService: BaseApiService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr:ToastrService

  ) { }

  ngOnInit(): void {
    this.getStateList();
    let mindate=this.today.split('-');
    this.MinDate=mindate[0]+'-'+mindate[1];
    this.accountTypeOptions = [{ "key": "Key Account", "value": "Key Account" }, { "key": "Retail Account", "value": "Retail Account" }, { "key": "Others", "value": "Others" }];
    this.industryTypeOptions = [
      { "key": "Market load transportation – 3PL, contract, spot load", "value": "Market load transportation – 3PL, contract, spot load" },
      { "key": "Construction material transportation – Sand, cement, stone", "value": "Construction material transportation – Sand, cement, stone" },
      { "key": "Tanker – Oil, milk, water, chemicals", "value": "Tanker – Oil, milk, water, chemicals" },
      { "key": "Coal and miningInter-city (Buses)", "value": "Coal and miningInter-city (Buses)" },
      { "key": "Intra-city STU (Buses)", "value": "Intra-city STU (Buses)" },
      { "key": "School/staff (Buses)", "value": "School/staff (Buses)" },
      { "key": "Pharmaceutical", "value": "Pharmaceutical" },
      { "key": "Auto Ancillary Stocks", "value": "Auto Ancillary Stocks" },
      { "key": "Cash & Gold Vans", "value": "Cash & Gold Vans" },
      { "key": "Food Commodities", "value": "Food Commodities" },
      { "key": "Natural Resources", "value": "Natural Resources" },
      { "key": "Electronic Goods", "value": "Electronic Goods" },
      { "key": "Others", "value": "Others" },

    ];
    this.purposeOfVisitOptions = [
      { "key": "Customer Training", "value": "Customer Training" },
      { "key": "Service Issues", "value": "Service Issues" },
      { "key": "New purchase", "value": "New purchase" },
      { "key": "Follow up visit", "value": "Follow up visit" },
      { "key": "Others", "value": "Others" },
    ];
    this.getallregion = [
      { "key": "West", "value": "West" },
      { "key": "East", "value": "East" },
      { "key": "North", "value": "North" },
      { "key": "South", "value": "South" },
    ];
    this.initializeComponent();
    this.sharedService.getFECData().subscribe((data) => {
      if (data && data != null && Object.keys(data).length) {
      } else {
        // this.router.navigate(["/rsmdashboard"]);
      }
    });
  }
  initializeComponent(): void {
    this.newCustomerInformationForm = this.formBuilder.group({
      reqId: [''],
      companyName: [''],
      accountType: ['', [Validators.required]],
      industryType: [''],
      Location: [''],
      fleetSize: [0],
      purposeOfVisit: [''],
      FirstName: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      Region: [localStorage.getItem('region'), [Validators.required]],
      State: ['', [Validators.required]],
      City: [null, [Validators.required]],
      Mobile: ['', [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Email: ['', [Validators.email, Validators.pattern(new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}'))]],
      Country: ['India'],
      MonthYear: ['', [Validators.required]],
      isNew:[true],
      remark:[''],
    });
  }

  validateEmail(control: AbstractControl){
    var validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var input = control.value;
    if (input && input.match(validRegex)) {
      return false;
    } else {
      return {invalidEmailError : true};
    }
  }

  getFECInformation(data: any): void {
    this.spinnerService.show();
    var requestURL = "fecportal2/";
    var requestPayload = data;
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      this.fecInformation = response;
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      // alert(errMessage);
      this.toastr.error(errMessage);
      localStorage.clear();
      this.router.navigate(['login']);
    });
  }

  getUnAssignedFECList(): void {
    this.spinnerService.show();
    var requestURL = "fecportal/fec/unassigned";
    var requestPayload = [];
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      this.unassignedFECList = response;
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(error));
      var message = jsonObj.msg;
      if (message != undefined) {
        // alert(message);
        this.toastr.error(message);
      } else {
        // alert('Unable to process request. Please try again');
      }
    });
  }
  getStateList():void {
    var requestURL = "fecportal/state/list/";
    var requestPayload = [];
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      this.stateOptions=response;
      this.newCustomerInformationForm.patchValue({State:null});
      this.stateOptions.sort((a, b) => a.state.toLowerCase() !== b.state.toLowerCase() ? a.state.toLowerCase() < b.state.toLowerCase() ? -1 : 1 : 0);
      this.cities=response;
    }, (error) => {
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      // alert(errMessage);
      this.toastr.error(errMessage)
      localStorage.clear();
      this.router.navigate(['login'])
    });
  }
  getCityList(state:any):void {
    console.log(state)
    this.cityOptions = [];
    if(state && state.length){
      var requestURL = "fecportal/city/list/";
      var requestPayload = {
        "state_name":state
      };
      this.spinnerService.show();
      this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
        this.newCustomerInformationForm.patchValue({City:null});
        for (let index = 0; index < response.length; index++) {
          var ele = response[index];
          this.cityOptions.push({"key":ele["city"], "value":ele["city"]});
          this.cityOptions.sort((a, b) => a.key.toLowerCase() !== b.key.toLowerCase() ? a.key.toLowerCase() < b.key.toLowerCase() ? -1 : 1 : 0);
        }
        this.spinnerService.hide();
      }, (error) => {
        this.spinnerService.hide();
        var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
        // alert(errMessage);
        this.toastr.error(errMessage);
      });
    }
  }
  getCityListApi(state:any):void {
    // console.log(this.newCustomerInformationForm.value.State,'State')
     let statearr=this.newCustomerInformationForm.value.State;
    this.cityOptions = [];
    if(state && state.length){
      var requestURL = "fecportal/city/list/";
      var requestPayload = {
        "state_name":state
      };
      this.spinnerService.show();
      this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
        // this.newCustomerInformationForm.patchValue({City:null});
        this.cityOptions=response;
        this.cityOptions.sort((a, b) => a.city.toLowerCase() !== b.city.toLowerCase() ? a.city.toLowerCase() < b.city.toLowerCase() ? -1 : 1 : 0);
        this.spinnerService.hide();
      }, (error) => {
        this.spinnerService.hide();
        var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
        // alert(errMessage);
        this.toastr.error(errMessage)

      });
    }
  }
  uploadFile(selectedFiles:any,completeTask:any,InValid:any):void{
    let jsonData = null;
    const reader = new FileReader();
    var file = selectedFiles[0];
    reader.onload = (event) => {
      const bstr: any = event.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      jsonData = wb.SheetNames.reduce((initial, name) => {
      const sheet = wb.Sheets[name];
      initial[name] = XLSX.utils.sheet_to_json(sheet);
      return initial;
      }, {});
      let data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
      let key1=data[0];
      var response = jsonData["Products Data"];
      const key2 = ["company_name", "account_type","industry_type","first_name","last_name","region","state","city",
                    "contact","email_id","month", "year","location", "purpose_of_visit","remark"];
      let validateBothExcel=isEqualsJson(key1,key2);
      if(validateBothExcel)
      {
        this.uploadExcelFileData(response,completeTask,InValid);
      }
      else
      {
        this.toastr.error("InValid Excel upload Format");
        this.ImageFile='';
      }   
    }
    reader.readAsBinaryString(file);
  }
  getKeys = (obj:any) => Object.keys(obj).filter(key => obj[key]!='');

  btnReset()
  {
    this.DateMonthFlag='';
    this.DateFlag=false;
    this.newCustomerInformationForm.reset();
    this.newCustomerInformationForm.patchValue({Region:localStorage.getItem('region')});

    this.isCompanyName=false;
    this.newCustomerInformationForm.controls.isNew.setValue(true);
    this.newCustomerInformationForm.controls.accountType.setValue('');
    this.newCustomerInformationForm.controls.industryType.setValue('');
    this.newCustomerInformationForm.controls.purposeOfVisit.setValue('');
    this.newCustomerInformationForm.updateValueAndValidity();
  }
  validateRecord(record:any):any {
    var validateRecord = this.formBuilder.group({
      companyName: ['', [Validators.pattern(/^[a-zA-Z ]*$/)]],
      accountType: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
      industryType: ['', [Validators.pattern(/[_':.,(){}0-9a-zA-Z ]*$/)]],
      Location: ['', [Validators.pattern(/[_':.,(){}0-9a-zA-Z ]*$/)]],
      purposeOfVisit: ['', [Validators.pattern(/[_':.,(){}0-9a-zA-Z ]*$/)]],
      FirstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
      LastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
      Region: ['', [Validators.required, Validators.pattern(/[_':.,(){}0-9a-zA-Z ]*$/)]],
      State: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
      City: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
      Mobile: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(10), Validators.maxLength(10)]],
      Email: ['', [Validators.email, Validators.pattern(new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}'))]],
      Country: ['India', [Validators.pattern(/^[a-zA-Z ]*$/)]],
      year: ['', [Validators.required]],
      month: ['', [Validators.required]],
      remark: [''],

    });

    if(record["first_name"]){
      validateRecord.controls.FirstName.setValue(record["first_name"]);
    }
    if(record["last_name"]){
      validateRecord.controls.LastName.setValue(record["last_name"]);
    }
    if(record["contact"]){
      validateRecord.controls.Mobile.setValue(record["contact"]);
    }
    if(record["email_id"]){
      validateRecord.controls.Email.setValue(record["email_id"]);
    }
    if(record["country"]){
      validateRecord.controls.Country.setValue(record["country"]);
    }
    if(record["state"]){
      validateRecord.controls.State.setValue(record["state"]);
    }
    if(record["city"]){
      validateRecord.controls.City.setValue(record["city"]);
    }
    if(record["company_name"]){
      validateRecord.controls.companyName.setValue(record["company_name"]);
    }
    if(record["account_type"]){
      validateRecord.controls.accountType.setValue(record["account_type"]);
    }
    if(record["industry_type"]){
      validateRecord.controls.industryType.setValue(record["industry_type"]);
    }
    if(record["region"]){
      validateRecord.controls.Region.setValue(record["region"]);
    }
    if(record["year"]){
      validateRecord.controls.year.setValue(record["year"]);
    }
    if(record["month"]){
      validateRecord.controls.month.setValue(record["month"]);
    }
    if(record["location"]){
      validateRecord.controls.Location.setValue(record["location"]);
    }
    if(record["purpose_of_visit"]){
      validateRecord.controls.purposeOfVisit.setValue(record["purpose_of_visit"]);
    }
    if(record["remark"]){
      validateRecord.controls.remark.setValue(record["remark"]);
    }

    validateRecord.markAsTouched();
    validateRecord.updateValueAndValidity();
    return validateRecord;
  }

  uploadExcelFileData(data:any,completeTask:any,InValid:any):any {
    let fobj=[];
    let fpobj=[];
    let lobj=[];
    let lfobj=[];
    let Regionobj=[];
    let Regioncobj=[];
    let Accountobj:any=[];
    let Accountobj1:any=[];
    let contactobj:any=[];
    let contactobj1:any=[];
    let monthobj:any=[];
    let monthobj1:any=[];
    let yearobj:any=[];
    let yearobj1:any=[];
    let emailobj:any=[];
    if(!(localStorage.getItem("FEC").replace(/"/g, ''))){
      console.log("FEC Not Selected");
      // alert("Unable to process request. Please try again");
      this.toastr.error("Unable to process request. Please try again")
      return;
    }
    if(typeof data != 'undefined' && data && data.length){
      var requestURL = "fecportal/fec_customer_data/create/";
      var invalidData = [];
      var requestPayload:any={};
      let checkvalidation=[];
      data.forEach((singleData:any, index:string) => {
        data[index]["is_assigned"] = true;
        data[index]["fec_user_id"] = localStorage.getItem("FEC").replace(/"/g, '');
        var validateRecord = this.validateRecord(singleData);
        // console.log(validateRecord,'validateRecord')
        if(validateRecord.status=="INVALID")
        {
          if(validateRecord.controls.FirstName.status=="INVALID")
          {
            if(validateRecord.controls.FirstName.errors?.required==true)
            {
              fobj.push(validateRecord.value.Mobile);
              this.objarray[0]['First Name is Required']=fobj;
            }
            else if(validateRecord.controls.FirstName.errors?.pattern)
            {
              fpobj.push(validateRecord.value.Mobile);
              this.objarray[1]['First Name Accept Only Character']=fpobj;
            }
          }
          if(validateRecord.controls.LastName.status=="INVALID")
          {
          if(validateRecord.controls.LastName.errors?.required==true)
          {
          lobj.push(validateRecord.value.Mobile);
          this.objarray[2]['Last Name is Required']=lobj;
          }
          else if(validateRecord.controls.LastName.errors?.pattern)
          {
          lfobj.push(validateRecord.value.Mobile);
          this.objarray[3]['Last Name Accept Only Character']=lfobj;
          }
          }
          if(validateRecord.controls.Region.status=="INVALID")
          {
          if(validateRecord.controls.Region.errors?.required==true)
          {
            Regionobj.push(validateRecord.value.Mobile);
            this.objarray[4]['Region is Required']=Regionobj;
          }
          else if(validateRecord.controls.Region.errors?.pattern)
          {
            Regioncobj.push(validateRecord.value.Mobile);
            this.objarray[5]['Region Accept Only Characters']=Regioncobj;
          }
          }
          if(validateRecord.controls.accountType.status=="INVALID")
          {
          if(validateRecord.controls.accountType.errors?.required==true)
          {
            Accountobj.push(validateRecord.value.Mobile);
            this.objarray[6]['Account Type is Required']=Accountobj;
          }
          else if(validateRecord.controls.accountType.errors?.pattern)
          {
            Accountobj1.push(validateRecord.value.Mobile);
            this.objarray[7]['Account Type Accept Only Characters']=Accountobj1;
          }
          }  
          if(validateRecord.controls.Mobile.status=="INVALID")
          {
          if(validateRecord.controls.Mobile.errors?.required==true)
          {
            contactobj.push(validateRecord.value.Mobile);
            this.objarray[8]['Contact Number is Required']=contactobj;
          }
          else if(validateRecord.controls.Mobile.errors?.pattern)
          {
            contactobj1.push(validateRecord.value.Mobile);
            this.objarray[9]['Contact Number Accept Only 10 Digit']=contactobj1;
          }
          } 
          if(validateRecord.controls.year.status=="INVALID")
          {
          if(validateRecord.controls.year.errors?.required==true)
          {
            yearobj.push(validateRecord.value.Mobile);
            this.objarray[10]['Year is Required']=yearobj;
          }
          else if(validateRecord.controls.year.errors?.pattern)
          {
            yearobj1.push(validateRecord.value.Mobile);
            this.objarray[11]['Year Accept only Number']=yearobj1;
          }
          }  
          if(validateRecord.controls.month.status=="INVALID")
          {
          if(validateRecord.controls.month.errors?.required==true)
          {
            monthobj.push(validateRecord.value.Mobile);
            this.objarray[12]['Month is Required']=monthobj;
          }
          else if(validateRecord.controls.month.errors?.pattern)
          {
            monthobj1.push(validateRecord.value.Mobile);
            this.objarray[13]['Month Accept only Number']=monthobj1;
          }
          } 
          if(validateRecord.controls.Email.status=="INVALID")
          {
          if(validateRecord.controls.Email.errors?.pattern)
          {
            emailobj.push(validateRecord.value.Mobile);
            this.objarray[14]['Email is Invalid']=emailobj;
          }
          }
          // Email is Invalid
          this.ImageFile='';
          this.modalService.open(InValid, { size: "sm",centered: true ,backdrop : 'static',keyboard : false});
        }
        else
        {
          this.ValidationFlag=true;
          checkvalidation.push(validateRecord.value);
          if(validateRecord.invalid){
            invalidData.push(singleData);
          } else {
            // requestPayload.push(data[index]);
          }
        }
      });
      this.objarray=cleanobj(this.objarray);
      if(this.ValidationFlag==true)
      {
        let arr=[];
        let i=0;
        checkvalidation.forEach((ele:any) => {
        arr.push({'is_assigned':true,'fec_user_id':localStorage.getItem("FEC").replace(/"/g, ''),
                 fec_customer_data_id:this.newCustomerInformationForm.value.reqId,
                 first_name:checkvalidation[i].FirstName,
                 last_name:checkvalidation[i].LastName,
                 contact:checkvalidation[i].Mobile,
                 email_id:checkvalidation[i].Email,
                 state:checkvalidation[i].State,
                 country:checkvalidation[i].Country,
                 city:checkvalidation[i].City,
                 company_name:checkvalidation[i].companyName,
                 region:checkvalidation[i].Region,
                 location_str:checkvalidation[i].Location,
                 industry_type:checkvalidation[i].industryType,
                 account_type:checkvalidation[i].accountType,
                 purpose_of_visit:checkvalidation[i].purposeOfVisit,
                 year:checkvalidation[i].year.toString(),
                 month:checkvalidation[i].month.toString(),
                 remark: checkvalidation[i].remark,
        });
        i++;
        });
        this.spinnerService.show();
        this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, arr).then((response: any) => {
        this.ImageFile='';
        this.spinnerService.hide();
        var message = this.baseAPIService.handleSucessfulAPIResponse(response, "FEC Data Uploaded successfully");
        if(response.msg=='Successfully assigned')
        {
        this.modalService.open(completeTask, { size: "sm",centered: true ,backdrop : 'static',keyboard : false});
        }
        else
        {
         this.toastr.error(response.msg)
        } 
      }, (error) => {
        this.ImageFile='';
        this.spinnerService.hide();
        var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
        // alert(errMessage);
        this.toastr.error(errMessage)
       });
     }
    }
  }
  
  searchCustomerByContactNumber(): void {
    this.isCompanyName=false;
    if (this.searchByContact && this.searchByContact.length && this.searchByContact.length >=10){
      var requestURL = "fecportal/get/list/";
      var requestPayload = {
        "limit": 1,
        "offset": 0,
        "contact": this.searchByContact.trim()
      };
      this.spinnerService.show();
      this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
        if (response && Object.keys(response).length && response[1]) {
          var customer_info = response[1];
          this.getFleetSize(customer_info['fleet_id']);
          if(customer_info["company_name"]==null)
          {
            this.isCompanyName=false;
          }
          else{
            this.isCompanyName=true;
            this.newCustomerInformationForm.patchValue({companyName: customer_info["company_name"]});
          }
          this.newCustomerInformationForm.patchValue({
            reqId: customer_info["id"],
            FirstName: customer_info["first_name"],
            LastName: customer_info["last_name"],
            Mobile: customer_info["contact"],
            Email: customer_info["email_id"],
            Country: customer_info["country"],
            State: customer_info["state"],
            District: customer_info["district"],
            City: customer_info["city"],
            accountType: customer_info["account_type"],
            industryType: customer_info["industry_type"],
            Location: customer_info["location_str"],
            remark: customer_info["remark"],
            purposeOfVisit : customer_info["purpose_of_visit"],
            MonthYear: "",
            isNew:false,
          });
          if(customer_info["state"]){
            this.getCityListApi(customer_info["state"]);
          }
          this.newCustomerInformationForm.updateValueAndValidity();
        } else {
          this.newCustomerInformationForm.reset();
          this.newCustomerInformationForm.patchValue({Region:localStorage.getItem('region')});
          this.newCustomerInformationForm.controls.isNew.setValue(true);
          this.newCustomerInformationForm.updateValueAndValidity();
        }
        this.searchByContact = "";
        this.spinnerService.hide();
        if (this.newCustomerInformationForm.invalid) {
          this.validateAllFormFields(this.newCustomerInformationForm); //{7}
          // console.log(this.newCustomerInformationForm.invalid,'this.newCustomerInformationForm.invalid');
        }
      }, (error) => {
        this.spinnerService.hide();
        var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
        this.toastr.error(errMessage);
        // alert(errMessage);
      });
    }
  }
  closeCompletepopup(ChangeTab:any)
  {
    this.modalService.dismissAll();
    this.router.navigate(['/fecattributes']);
  }
  closeInvalidPopup()
  {
    this.modalService.dismissAll();
    this.ImageFile='';
  }
  getDateMonth(event)
  {
    if(event.target.value=='')
    {
      this.DateFlag=false;
      this.DateMonthFlag='';
    }
    else
    {
      this.DateFlag=true;
      let arr=event.target.value.split('-');
      let montharr=['January','February','March','April','May','June','July','August','September','October','November','December'];
      this.DateMonthFlag=montharr[Number(arr[1])-1]+' '+Number(arr[0]);
    }
  }
  assignTask(completeTask:any):any {
    if(!(localStorage.getItem("FEC").replace(/"/g, ''))){
      this.toastr.error("Unable to process request. Please try again");
      return;
    }
    var assignTaskFormGroup = this.formBuilder.group({
      companyName: [this.newCustomerInformationForm.value.companyName],
      accountType: [this.newCustomerInformationForm.value.accountType, [Validators.required]],
      industryType: [this.newCustomerInformationForm.value.industryType],
      Location: [this.newCustomerInformationForm.value.Location],
      purposeOfVisit: [this.newCustomerInformationForm.value.purposeOfVisit],
      FirstName: [this.newCustomerInformationForm.value.FirstName],
      LastName: [this.newCustomerInformationForm.value.LastName],
      Region: [this.newCustomerInformationForm.value.Region, [Validators.required]],
      State: [this.newCustomerInformationForm.value.State],
      City: [this.newCustomerInformationForm.value.City],
      Mobile: [this.newCustomerInformationForm.value.Mobile],
      Email: [this.newCustomerInformationForm.value.Email],
      Country: [this.newCustomerInformationForm.value.Country],
      MonthYear: [this.newCustomerInformationForm.value.MonthYear],
    });
    this.newCustomerInformationForm.markAllAsTouched()
    this.newCustomerInformationForm.updateValueAndValidity();
     if(this.newCustomerInformationForm.invalid==true) {
      this.toastr.error("Please fill valid Customer Information")
      return;
    }
    else
    {
      let dates=this.newCustomerInformationForm.value.MonthYear.split('-');
      let year=dates[0];
      let month=dates[1];
      this.spinnerService.show();
      var requestURL = "fecportal/fec_customer_data/create/";
      var requestPayload = [{
      "is_assigned": true,
      "fec_user_id": localStorage.getItem("FEC").replace(/"/g, ''),
      "fec_customer_data_id": this.newCustomerInformationForm.value.reqId,
      "first_name": this.newCustomerInformationForm.value.FirstName,
      "last_name": this.newCustomerInformationForm.value.LastName,
      "contact": this.newCustomerInformationForm.value.Mobile,
      "email_id": this.newCustomerInformationForm.value.Email,
      "country": this.newCustomerInformationForm.value.Country,
      "state": this.newCustomerInformationForm.value.State,
      "district": this.newCustomerInformationForm.value.District,
      "city": this.newCustomerInformationForm.value.City,
      "company_name": this.newCustomerInformationForm.value.companyName,
      "account_type": this.newCustomerInformationForm.value.accountType,
      "industry_type": this.newCustomerInformationForm.value.industryType,
      "region": this.newCustomerInformationForm.value.Region,
      "location_str": this.newCustomerInformationForm.value.Location,
      "purpose_of_visit": this.newCustomerInformationForm.value.purposeOfVisit,
      "month":month,
      "year":year,
      "remark": this.newCustomerInformationForm.value.remark,
      }];
      this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      this.DateMonthFlag='';
      this.DateFlag=false;
      this.newCustomerInformationForm.reset();
      this.newCustomerInformationForm.patchValue({Region:localStorage.getItem('region')});
      this.isCompanyName=false;
      this.newCustomerInformationForm.controls.isNew.setValue(true);
      this.newCustomerInformationForm.controls.accountType.setValue('');
      this.newCustomerInformationForm.controls.industryType.setValue('');
      this.newCustomerInformationForm.controls.purposeOfVisit.setValue('');
      this.newCustomerInformationForm.updateValueAndValidity();
      if(response.msg=='This contact has allready assigned')
      {
        // alert("This contact has allready assigned");
        this.toastr.error("This contact has allready assigned")
      }
      else if(response.msg=='This contact number does not exist')
      {
        // alert(response.msg);
        this.toastr.error(response.msg);
      }
      else if(response.msg=='Successfully assigned')
      {
        this.modalService.open(completeTask, { size: "sm",centered: true ,backdrop : 'static',keyboard : false});
      }
      else
      {
        // alert(response.msg);
        this.toastr.error(response.msg);
      }
      this.spinnerService.hide();
      }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      this.toastr.error(errMessage);
      // alert(errMessage);
      });
     }
  }
  getFleetSize(fleet_id:any):any{
    var requestURL = "fecportal/get/fleet_size/";
    var requestPayload:any={
      "fleet_id":fleet_id
    }
    this.spinnerService.show();
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      if (response && response?.result) {
        var fleet_info = response?.result;
        this.newCustomerInformationForm.patchValue({
          fleetSize: fleet_info["vehicle_count"],
        });
      }else{
      }
    this.spinnerService.hide();
    }, (error) => {
    this.spinnerService.hide();
    var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
    // this.toastr.error(errMessage);
    // alert(errMessage);
    });
  }
  saveCustomer(completeTask:any): any {
    // console.log(this.newCustomerInformationForm.invalid,'this.newCustomerInformationForm.valueSe')

    if(!(localStorage.getItem("FEC").replace(/"/g, ''))){
      console.log("FEC Not Selected");
      // alert("Unable to process request. Please try again");
      this.toastr.error("Unable to process request. Please try again");
      return;
    }
    this.newCustomerInformationForm.markAllAsTouched()
    this.newCustomerInformationForm.updateValueAndValidity();
    if(!(this.newCustomerInformationForm.value.isNew)){
      return;
    }
    if (this.newCustomerInformationForm.invalid) {
      // alert("Please fill valid Customer Information");
      this.toastr.error("Please fill valid Customer Information")
      return;
    }
    this.spinnerService.show();
    var requestURL = "fecportal/fec_customer_data/create/";
    let dates=this.newCustomerInformationForm.value.MonthYear.split('-');
    let year=dates[0];
    let month=dates[1];
    var requestPayload = [{
      "first_name": this.newCustomerInformationForm.value.FirstName,
      "last_name": this.newCustomerInformationForm.value.LastName,
      "contact": this.newCustomerInformationForm.value.Mobile,
      "email_id": this.newCustomerInformationForm.value.Email,
      "country": this.newCustomerInformationForm.value.Country,
      "state": this.newCustomerInformationForm.value.State,
      "district": this.newCustomerInformationForm.value.District,
      "city": this.newCustomerInformationForm.value.City,
      "company_name": this.newCustomerInformationForm.value.companyName,
      "account_type": this.newCustomerInformationForm.value.accountType,
      "industry_type": this.newCustomerInformationForm.value.industryType,
      "region": this.newCustomerInformationForm.value.Region,
      "location_str": this.newCustomerInformationForm.value.Location,
      "purpose_of_visit": this.newCustomerInformationForm.value.purposeOfVisit,
      "fec_user_id": localStorage.getItem("FEC").replace(/"/g, ''),
      "month":month,
      "year":year,
      "is_assigned": true,
      "remark": this.newCustomerInformationForm.value.remark
    }];

    // console.log("requestPayload", requestPayload);
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      this.DateMonthFlag='';
      this.DateFlag=false;
      this.isCompanyName=false;
      this.newCustomerInformationForm.reset();
      this.newCustomerInformationForm.controls.accountType.setValue('');
      this.newCustomerInformationForm.controls.industryType.setValue('');
      this.newCustomerInformationForm.controls.purposeOfVisit.setValue('');
      this.newCustomerInformationForm.patchValue({Region:localStorage.getItem('region')});
      this.newCustomerInformationForm.controls.isNew.setValue(true);
      this.newCustomerInformationForm.updateValueAndValidity();
      if(response.msg=='This contact has allready assigned')
      {
        //  alert("This contact has allready assigned");
         this.toastr.error(response.msg);
      }
      else if(response.msg=='This contact number does not exist')
      {
        // alert(response.msg);
        this.toastr.error(response.msg);

      }
      else if(response.msg=='Successfully assigned')
      {
        this.modalService.open(completeTask, { size: "sm",centered: true,backdrop : 'static',keyboard : false });
      }
      else
      {
        // alert(response.msg);
        this.toastr.error(response.msg);

      }
      // alert("Task Assigned Successfully.");
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      // this.baseAPIService.handleAPIResponse(error, "Unknown Error Occured");
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      // alert(errMessage);
      this.toastr.error(errMessage);

    });
  }

  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);             
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);          
      }
    });
  }
 
}
function isEqualsJson(obj1,obj2)
{
let keys1 = Object.keys(obj1);
let keys2 = Object.keys(obj2);
return keys1.length === keys2.length && Object.keys(obj1).every(key=>obj1[key]==obj2[key]);
}
function cleanobj(obj) {
  for (var key in obj) {
     if (Object.values(obj[key]).indexOf('') > -1) {
      delete obj[key];
    }
  }
  return obj
}


