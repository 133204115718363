import { Component, OnInit,ViewChild } from '@angular/core';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';

import { BaseApiService } from 'src/app/base-api.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as XLSX from 'xlsx';
import { RouterModule, Router } from '@angular/router';
 
 import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { DatePipe } from '@angular/common';
declare var myExtObject: any;
declare var webGlObject: any;  

@Component({
  selector: 'app-clientinnerpage',
  templateUrl: './clientinnerpage.component.html',
  styleUrls: ['./clientinnerpage.component.scss'],
   providers: [DatePipe]
})
export class ClientinnerpageComponent implements OnInit {

  tutorials: any;
  currentTutorial = null;
  currentIndex = -1;
  title = '';
  page = 1;
  tempPage=1;
  searchText:string='';
  CheckVinFleetTabShow:boolean = false;
  fleetOwnerData=[];
  dropdownList=[];
  tabType:number = 0;
  VinNumberData = [];
  showRecordVin:boolean=false;
  loadingText='Please Wait Data Loading..';
  aadharNumber= '';
	address= '';
	approvalStatus= "PENDING"
	approvalStatusChangeDateTime= '';
	approvalStatusChangedBy= '';
	clientId= '';
	clientName= '';
	companyName= '';
	createdBy= '';
	email= '';
	gstNumber= '';
	isActive= '';
	isEnabled= '';
	mobile= '';
	panNumber= '';
	supportedDataTypes= '';
	userType= '';
	validityEndDate='';
	validityStartDate='';
	clientVinId='';
	VInArrayData=[];
	searchTextData='';
	supportApiList='';
	updateStatusClientType:string='';
	clientStatusName='';
	username:string='';
	todayDate :string=new Date().toLocaleString();
  constructor(private router: Router,private baseAPI: BaseApiService, public spinnerService: NgxSpinnerService,private http: HttpClient,public datepipe: DatePipe) {
	this.username=localStorage.getItem('username');
		  var token =localStorage.getItem('token');
	       
			if(token==undefined || token==='' ){
			  this.router.navigate(['/login']);
			}
			
			 webGlObject.init();	

		 }
	  
	     //@ViewChild('form') form;
			selectedItems = [];
			//dropdownSettings = {};
			dropdownSettings: IDropdownSettings;
		  itemsPerPage:number = 20;
		 currentPage = 1;	
		totalItems = 0;		 
	resultCheck:boolean = false;
	dataLoadingCheck:boolean = true; 	
	 globalData:any=[];
	event_array= [];
	selectedSPN_array= [];
	 editStartDate:string;
     editEndDate:string;
	 start_date:string;
	 end_date:string;
	 pgnNo:string;
	searchData:string;
	public  apps: any[] = [];
  vechileDetails: any;
  dropdownVinSettings: IDropdownSettings;
  selectedVinItems = [];
  selectedSPNVIN_array= [];
  multipleVinNumber=[];
  dropdownVehicleSettings: IDropdownSettings;
    selectedVehicleItems = [];
  selectedSPNVehicle_array= [];
  typeOfVehicledata=[];
  vinwithchassisNumber=[];
  updateclientId='';
   public min = new Date(2018, 1, 12, 10, 30);
	 
	

    // Max moment: April 21 2018, 20:30
	public yearMax=this.getCurrentDay('Y');
	public monthMax=this.getCurrentDay('M');
	public dateMax=this.getCurrentDay('D');
	private todate = new Date();
	 public Hours=this.todate.getHours();
    public getMinutes=this.todate.getMinutes();
    public max = new Date(this.yearMax, this.monthMax, this.dateMax, 20, 30);
	
	
	 public myDate: IMyDate = {

    year: this.getCurrentDay('Y'),
    month: this.getCurrentDay('M'),
    day: this.getCurrentDay('D'),
  }
	 
  getCurrentDay(cat): any {
    let output;
    var today = new Date();
    var day = today.getDate();
	 var month = today.getMonth() + 1; //January is 0!
    if (day != 1) {
        //day = day - 1;
    }else{
		if(month==5 || month==7 || month==10 || month==12)
		{
			day=30;
		}
		
		if(month==2 || month===4 || month==6 || month==8 ||month==9 || month==11 || month==1)
		{
			day=31;
		}
		
		if (year % 4 == 0 && month==3) 
		{
			day=29;
		}
		else if (year % 4 != 0 && month==3) 
		{
			day=28;
		}
		month=month-1;
	}

    
	//alert(day+"#"+month);
    var year = today.getFullYear();

    if (cat === 'D') {
      output = day;
    }
    else if (cat === 'M') {
      output = month;
    }
    else {
      output = year;
    }
    return output;
  }
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    markCurrentDay: true,
   // disableUntil: this.myDate  vinay this is for disble 
    disableSince :  this.myDate

  };
  
  
  
    compareDate()
	{
		
		
		var startDate = new Date(this.editStartDate);
		var endDate = new Date(this.editEndDate);

		var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
		var stString=Date.parse(startDate.toUTCString());
		var edString=Date.parse(endDate.toUTCString());
	  if (endDate < startDate || seconds>259200) //86400 
	  {
			alert("Only 24 hours data is allowed");
			this.editStartDate=null;
			this.editEndDate=null;	
            this.start_date="";			
	  }else if (edString ==stString) //86400
	  {
		alert("Start date and end date cannot be same");
		this.editStartDate=null;
			this.editEndDate=null;	
            this.start_date="";		
	  }else if(this.editEndDate!=null && this.editStartDate!=null)
		{
			
			
			 this.editStartDate=this.convert(this.editStartDate);
	         this.editEndDate=this.convert(this.editEndDate);
			 
			 
		}
	}
  
  convert(str) {
	 
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    var Hours=date.getHours();
    var getMinutes=date.getMinutes();
	
	var returnDtae=[date.getFullYear(), mnth, day].join("-");
	returnDtae=returnDtae+"T"+Hours+":"+getMinutes+":00";
  return returnDtae;
}

  onEditStartDateChanged(event) {
	 

   var splitedDate=this.start_date.toString().split(",");
	 
	  this.editStartDate=splitedDate[0];
	  this.editEndDate=splitedDate[1];
	  

	 
		if(this.editEndDate!=null && this.editStartDate!=null)
		{
			this.compareDate();
			
			
		}
		
		
	 }
	 
	applyFilter(event)
	{
		this.searchTextData = event.trim();
		this.resultCheck=false;
		this.dataLoadingCheck =true;
		this.callReportApi();
		this.page=1;		
	}
	 fetchReport() {
	  
	 
	 

    if (this.searchData === undefined || this.searchData === '' || this.searchData == '' || this.searchData == null  ) {
        alert('Please fill all mandatory fields.');

    }
    else {  

     
     

		if (window.confirm("Do you want to fetch report?")) 
	   {
		   
		    this.callReportApi();
           // alert("Notification created successfully");
			//this.reset1();
        } 
		else 
		{
            alert("Report serach is failed");
        }

    }
  }
	
	
	//==================================================================


  pageChanged(event) {
    this.page = event;
	this.resultCheck=false;
	this.dataLoadingCheck =true;
	this.CheckVinFleetTabShow = false;
	this.callReportApi();
  }
	
	callReportApi() {
    this.spinnerService.show();
	if(this.page == 1)
	{
		var datastring="admin/external/clients";
		this.tempPage =0; 
	}
	else
	{
		this.tempPage = this.page -1;
	    var datastring="admin/external/clients?page="+this.tempPage+"&itemsPerPage="+this.itemsPerPage;	
	}
	this.event_array=[];
	 myExtObject.firebaseEvent('clientlist', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"Search Button Click"});
	this.baseAPI.callClientListOnboardApi(this.baseAPI.onboardVinUrl,datastring,this.searchTextData).then((data:any) => {     
	 this.resultCheck=false;
	 this.dataLoadingCheck = true;
	 this.CheckVinFleetTabShow = false;
	 this.event_array=[];
	  let tempEvent:any;     
      var employees = {};  
	  this.spinnerService.hide();
	  if(data.content.length != undefined && data.content.length > 0)
	   {
		   this.dataLoadingCheck = false;
		   this.resultCheck=true;		   
		   this.itemsPerPage = data.size;
		   this.totalItems = data.totalElements;
		   this.currentPage = data.totalPages;
		   this.supportApiList ='No List';
		   for(let i=0;i < data.content.length;i++)
		   {
			   if(data.content[i].supportedDataTypes != null && data.content[i].supportedDataTypes.length)
			   {
				  this.supportApiList = data.content[i].supportedDataTypes.join(", ");				
			   }
			   var startDate = new Date(data.content[i].validityStartDate);
			   var latest_startDate =this.datepipe.transform(startDate, 'yyyy-MM-dd HH:mm:ss');
			   var endDate = new Date(data.content[i].validityEndDate);
			   var latest_endDate =this.datepipe.transform(endDate, 'yyyy-MM-dd HH:mm:ss');			   
			   const tempArray=
			   {
				   "clientId" : data.content[i].clientId,
				   "clientName" : data.content[i].clientName,
				   "email" : data.content[i].email,
				   "mobile" : data.content[i].mobile,
				   "validityStartDate" : latest_startDate,
				   "validityEndDate" : latest_endDate,
				   "approvalStatus" : data.content[i].approvalStatus,
				   "createdBy" : data.content[i].createdBy,
				   "supportDataType" : this.supportApiList
			   };
			   this.event_array.push(tempArray);
		   }
		   	
		   
		   
	   }else{
		   this.dataLoadingCheck = false;
		   this.resultCheck=false;
		   //alert("No Records Found");
	   }
	  
	  //this.dealerOffset=this.dealerOffset+21;
    }, (err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert("Error:"+message.error);
      }
      else {
        alert('Please try again');
      }
    });	

  }

  ngOnInit() {
	  
	     this.callReportApi();
	   // this.monthMax=this.monthMax-1;
		this.max= new Date();
	   //this.max = new Date(this.yearMax, this.monthMax, this.dateMax, this.Hours, this.getMinutes);
    this.selectedItems = [];
    this.dropdownSettings={
      singleSelection: false,
      idField: 'fleet_id',
      textField: 'first_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
	  limitSelection: 1,
      allowSearchFilter: true
    };

	 this.dropdownVinSettings={
      singleSelection: false,
      idField: 'vehicle_id',
      textField: 'vin',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
	  limitSelection: 10,
      allowSearchFilter: true
    };
	
	this.dropdownVinSettings={
      singleSelection: false,
      idField: 'vehicle_id',
      textField: 'vin',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
	  limitSelection: 10,
      allowSearchFilter: true
    };
	
		     this.typeOfVehicledata = [
		 { item_id: 'All', item_text: 'All'},
		 { item_id: 'Events', item_text: 'Events'},
		 { item_id: 'Alerts', item_text: 'Alerts'},
		 { item_id: 'CAN', item_text: 'CAN'},
		 { item_id: 'Telemetry', item_text: 'Telemetry'},		 
     
    ];
	
	    this.dropdownVehicleSettings={
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
	  limitSelection: 10,
      allowSearchFilter: true
    };
  }
        onVehicleItemSelect(item: any) {
	  this.selectedSPNVehicle_array=[];
	  for(let i =0; i < this.typeOfVehicledata.length;i++) {
		 this.selectedSPNVehicle_array.push(this.typeOfVehicledata[i].item_id); 
	 }
	
  }
  
   onVehicleItemDeSelect(item: any) {
	
	for( var i = 0; i < this.selectedSPNVehicle_array.length; i++){
		if ( this.selectedSPNVehicle_array[i] === item.item_id) { 
		
		this.selectedSPNVehicle_array.splice(i, 1); 
		
		
		}
		
		}
	
	
  }
  
  
  
  onVehicleSelectAll(items: any) {

  }
  
    onItemSelect(item: any) {
	  this.selectedSPN_array=[];
	  for(let i =0; i < this.selectedItems.length;i++) {
		 this.selectedSPN_array.push(this.selectedItems[i].fleet_id); 
	 }
	
  }
  
   onItemDeSelect(item: any) {
	
	for( var i = 0; i < this.selectedSPN_array.length; i++){
		if ( this.selectedSPN_array[i] === item.fleet_id) { 
		
		this.selectedSPN_array.splice(i, 1); 
		
		
		}
		
		}
	
	
  }
  
  
  
  onSelectAll(items: any) {

  }
  
      onItemVinSelect(item: any) {

	  this.selectedSPNVIN_array=[];
	  for(let i =0; i < this.selectedVinItems.length;i++) {
		 this.selectedSPNVIN_array.push(this.selectedVinItems[i].vehicle_id); 
	 }
	
  }
  
   onItemDeVinSelect(item: any) {
	
	for( var i = 0; i < this.selectedSPNVIN_array.length; i++){
		if ( this.selectedSPNVIN_array[i] === item.vehicle_id) { 
		
		this.selectedSPNVIN_array.splice(i, 1); 
		
		
		}
		
		}
	
	
  }
  
  
  
  onSelectVinAll(items: any) {

  }
  

  	checkFleetOwnerList() {
    this.spinnerService.show();
	this.fleetOwnerData = [];
	if(this.tabType == 1)
	{
	  var datastring="api/user-service/get-fleet-owner-user-id-full-name-list";	
	}
	else
	{
	  var datastring="api/vehicle-service/get-vehicles-registration-number-vin-fleet-id";	
	}
	
	 myExtObject.firebaseEvent('clientlist', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"Search Button Click"});
	this.baseAPI.callCheckONclientApi(this.baseAPI.onboardVinUrl,datastring,'').then((data:any) => {
	if(this.tabType == 1){
	 if(data.results.length != undefined )
	   {		   
		   this.fleetOwnerData = data.results;			  
	   }
	   else
	   { 
		  
	   }
	}
	   else
	   {
		if(data.result.length != undefined )
	   {		   
		   this.VinNumberData = data.result;			  
	   }
	   else
	   { 
		  
	   }
	 }
    }, (err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert("Error:"+message.error);
      }
      else {
        alert('Please try again');
      }
    });	

  }
  
  CheckVinFleetTab(Id)
  {
	  this.checkFleetOwnerList();
	  this.clientVinId = Id;
	  window.scrollTo(150, 150);
	  //window.scrollTo(0,document.body.scrollHeight);

	  this.CheckVinFleetTabShow = true;
  }
  fleetVInCheck(type)
  {
	  this.tabType =type.index;
	  this.fleetOwnerData = [];  
	  this.checkFleetOwnerList();   
  }
  
  fetchReportWithDropDown()
  {
	  this.multipleVinNumber = [];
	  this.vinwithchassisNumber=[];
	  if(this.selectedItems.length >0)
	  {
		this.showRecordVin = true;  
		for(let i =0; i < this.selectedItems.length;i++) {
	    this.spinnerService.show();	
	    var datastring="api/vehicle-service/get-vehicles-registration-number-vin-fleet-id";	
		
	 myExtObject.firebaseEvent('clientlist', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"Search Button Click"});
	this.baseAPI.callCheckONclientApi(this.baseAPI.onboardVinUrl,datastring,this.selectedItems[i].fleet_id).then((data:any) => {
	 if(data.result.length > 0)
	   {		   
		    for(let i =0; i < data.result.length;i++) {
		 this.multipleVinNumber.push(data.result[i]);
		 this.vinwithchassisNumber.push(data.result[i].vin);
		 this.checkVinWithFleetClient(this.clientVinId, data.result[i].fleet_id, this.vinwithchassisNumber);
		}	
	   }
	   else
	   {
		   this.loadingText="No Records Found..";
	   }
	  
    }, (err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert("Error:"+message.error);
      }
      else {
        alert('Please tryxxv again');
      }
    });	
	 }
	  }
	  else
	  {
		  this.showRecordVin = false;
		  alert('Please select Fleed Id.');
	  }
  }
  
    
  checkVinWithFleetClient(clientId, fleetId, vin)
  { 
	    this.spinnerService.show();	
	    var datastring="fleet/external/vehicles";	
		
	 myExtObject.firebaseEvent('clientlist', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"Search Button Click"});
	this.baseAPI.checkVinWithFleetClient(this.baseAPI.onboardVinUrl,datastring,clientId, fleetId, vin).then((data:any) => {
	 if(data.result.length > 0)
	   {
			
	   }
	   else
	   {
		   this.loadingText="No Records Found..";
	   }
	  
    }, (err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert("Error:"+message.error);
      }
      else {
        alert('Please try agaicvcn');
      }
    });
  }
  
  checkClientDetails(clientDetailsId)
  {
	  if(clientDetailsId != '')
	  {
	 this.spinnerService.show();
	 this.aadharNumber = "";
	 this.address = "";
	 this.approvalStatus = "";
	 this.approvalStatusChangeDateTime = "";
	 this.approvalStatusChangedBy = "";
	 this.clientId = "";
	 this.clientName = "";
	 this.companyName = "";
	 this.createdBy = "";
	 this.email = "";
	 this.gstNumber = "";
	 this.isActive = "";
	 this.isEnabled = "";
	 this.mobile = "";
	 this.panNumber = "";
	 this.supportedDataTypes = "";
	 this.userType = "";
	 this.validityEndDate = "";
	 this.validityStartDate = "";
	 this.VInArrayData=[];
	 var datastring="admin/external/clients/"+clientDetailsId;	
		
	 myExtObject.firebaseEvent('clientlist', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"Search Button Click"});
	this.baseAPI.CheckClientDetails(this.baseAPI.onboardVinUrl,datastring).then((data:any) => {
	 //console.log(data);
	 this.VInArrayData = data.chassisNumbers;
	  if(this.VInArrayData.length >0)
	  {
		  this.VInArrayData = data.chassisNumbers;
	  }
	  else
	  {
		  alert('No Data Available.');
		  window.document.getElementById("checkClientDetails").click();
		  
	  }
	  this.spinnerService.hide();
    }, (err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert("Error:"+message.error);
      }
      else {
        alert('Please try again');
      }
    });	
  }
 
  }
  
  checkClientApprovedStatus(id, status, clientName)
  {
	  this.updateclientId=id;
	  this.updateStatusClientType=status;
	  this.clientStatusName=clientName;
  }
  
  updateApproveStatus(Id)
  {
	    if(Id != '')
	  {
		var datastring="admin/external/clients/"+this.updateclientId+"/update-status";    	
		
	 myExtObject.firebaseEvent('clientlist', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"Search Button Click"});
	this.baseAPI.checkUpdateCLientStatus(this.baseAPI.onboardVinUrl,datastring,this.updateclientId, this.updateStatusClientType, this.clientStatusName).then((data:any) => {
	 if(data.approvalStatus !='')
	 {
	  this.callReportApi();
	  window.document.getElementById("checkClientApprovedStatus").click();
	  alert('Client Status Updated Successfully.');
	 }
	 else
	 {
		 alert('Please try again..');
	 }

    }, (err) => {
window.document.getElementById("checkClientApprovedStatus").click();
      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert("Error:"+message.error);
      }
      else {
        alert('Please try again');
      }
    });	
  }
  }
    addClient()
  {	  
	  this.router.navigate(['clientapi']);
  }
  getClientLists()
  {
	  this.router.navigate(['client']);
  }

}
