 <!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->

<!-- <app-header *ngIf="showHead"></app-header> -->

<!-- <div class="{{bgClasname}}" id="lyt-main" [ngClass]="{'typ-login':_loginService.isLoggedIn}" >
 
</div> -->
  <router-outlet></router-outlet>
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type ="ball-clip-rotate-pulse" >

  </ngx-spinner>



