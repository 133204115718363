import { Component, OnInit,Input,OnChanges,SimpleChanges } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
@Component({
  selector: 'app-watermark',
  templateUrl: './watermark.component.html',
  styleUrls: ['./watermark.component.scss']
})
export class WatermarkComponent implements OnInit,OnChanges {
  username:string='';
  @Input('singlerecord')singlerecord:boolean=false;
	todayDate :string=new Date().toLocaleString();
  ipAddress:string = '';
  constructor(private http:HttpClient) {
    this.username=localStorage.getItem('username');
   }

   ngOnChanges(watermarkChange:SimpleChanges){
    console.info(watermarkChange);
    if(watermarkChange.singlerecord.currentValue===true){
      this.singlerecord=watermarkChange.singlerecord.currentValue;
    }
   }
  ngOnInit() {
    this.getIPAddress();
  
  }

  getIPAddress()

  {

    
    this.http.get("https://jsonip.com/").subscribe((res:any)=>{
    console.log("res",res);
      this.ipAddress = res.ip;

    });

  }

}
