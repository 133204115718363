
<app-header></app-header>
<div class="lyt-main" id="lyt-main">


<section>
    <div class="cp-section">    	
	
              <div class="sec-head full-width-lw">		   
               <div class="left-wrap" style="margin:10px;">
                <h2 class="sec-title" >Assign Data</h2>
            </div>
            <div class="sec-head full-width-lw" style="margin:10px;">
       <div class="right-wrap ">
                <ul class="act-list typ-multiline">
                 <li class="act-item typ-full">          
                
                
                        <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <select [(ngModel)]="selectedStateName" name="state" (change)="cityapi($event)"  class="form-control" placehoder="select state">
              <option value="" selected>Select state</option>
              <option *ngFor="let item of state" [value]="item.state">
              {{item.state}}
            </option>             
              </select>
                                </div>
                            </div>
                        </div>
                    </li>

                     <li class="act-item typ-full">          
                
                
                        <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <select [(ngModel)]="selectedCityName" name='city' (change)="filtercity()"  class="form-control" placehoder="select city">
              <option value="" selected>Select City</option>
              <option *ngFor="let cityitem of citylist" [value]="cityitem.city">
              {{cityitem.city}}
            </option>             
              </select>
                                </div>
                            </div>
                        </div>
                    </li>
      
                   
                  <!--   <li class="act-item">
                        <button class="btn btn-icon-text" (click)="filterapi()" >
            <span class="icon icon-search"></span>Search</button>
            
            
            
                    </li> -->
                    
                </ul>
            </div>
    </div>
			
			
			 
			
        </div>
		
      
     
		<table p-table  p-table  class="table row-border hover "  >

      
  <thead>
    <tr>
       <th>Sr No</th>
       <th>Id</th>
        <th>Account Name</th>
    <!--   <th>First Name</th>
      <th>Last Name</th> -->
      <th>Contact No</th>
      <th>Email Id</th>
     <!--  <th>Company Name</th> -->
       <th>Country</th>
        <th>State</th>
         <th>City</th>
         
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of newData | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems:totalItems}; let i = index;" [routerLink]="['/fectaskdetail']">
      <td>{{ (tempPage*itemsPerPage) + (i+1)}}</td>
      <td>{{item.id}}</td>
       <td>{{item.company_name}}</td>
    <!--   <td>{{item.first_name}}</td>
      <td>{{item.last_name}}</td> -->
      <td>{{item.contact}}</td>
      <td>{{item.email_id}}</td>
  <!--     <td>{{item.company_name}}</td> -->

       <td>{{item.country}}</td>
      <td>{{item.state}}</td>
      <td>{{item.city}}</td>
     
    </tr>
  </tbody>
</table>
      
      <div class="sec-head full-width-lw c" style="float:right;">
        <div class="right-wrap ">
          <ul class="act-list typ-multiline">

            <li class="act-item" style="padding-top:5px;">
              <pagination-controls (pageChange)="pageChanged($event)" *ngIf="resultCheck"></pagination-controls>
            </li>
          </ul>
        </div>
      </div>






    </div>
   
</section>





  









<!--footer>
			Copyright &#169; 2020 TATA MOTORS All Rights Reserved.
		</footer-->