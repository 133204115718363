import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[FileUploadDropzone]'
})
export class FileUploadDropzoneDirective {
  @Output() onFileDropped = new EventEmitter<any>();

  @HostBinding('style.cursor') private cursor = 'default';
  @HostBinding('style.opacity') private opacity = '1';
  @HostBinding('style.outline') private borderOutline = '4px dashed #D2D2D2;';
  @HostBinding('style.background-color') private backgroundColor = 'inherit';


  @HostListener('dragover', ['$event']) public onDragOver(evt:any): any {
    evt.preventDefault();
    evt.stopPropagation();
    this.opacity = '0.9';
    this.cursor = 'grabbing';
    this.borderOutline = '4px dashed #6d6c6c';
    this.backgroundColor = '#e7ebf8';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt:any): any {
    evt.preventDefault();
    evt.stopPropagation();
    this.opacity = '1';
    this.cursor = 'default';
    this.borderOutline = '4px dashed #D2D2D2;';
    this.backgroundColor = 'initial';
  }

  @HostListener('drop', ['$event']) public ondrop(evt:any): any {
    evt.preventDefault();
    evt.stopPropagation();
    this.opacity = '1';
    this.cursor = 'default';
    this.borderOutline = '4px dashed #D2D2D2;';
    this.backgroundColor = 'initial';
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      console.log("Dropped Files:", files);
      this.onFileDropped.emit(files);
    }
  }
}
