
<div data-theme="default" data-layout="fluid" data-sidebar-position="left" data-sidebar-behavior="sticky">
	<div class="wrapper">
      <app-sidebar></app-sidebar>
		<div class="main">
      <app-header></app-header>
			<main class="content">
				<div class="container-fluid p-0">
          <div class="wel_msg">
            Welcome Back,
            <span style="color:#0A0A5F">
              {{dsm_username}} !
            </span>
          </div>
					<div class="row mb-2 mb-xl-3">
						<div class="col-auto">
							<h3 class="dash-text">DASHBOARD</h3>
						</div>
						<div class="col-auto ms-auto text-end mt-n1">
                 <div>
                 <button class="btn download-list-btn" (click)="downloadMasterData()">
                <svg xmlns="http://www.w3.org/2000/svg" 
                width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-download align-middle">
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="7 10 12 15 17 10"></polyline>
                <line x1="12" y1="15" x2="12" y2="3"></line>
                </svg>
                <span style="white-space: nowrap;margin-left: 4px;">Complete Master</span>
                 </button>
                 &nbsp;
                 <button class="btn download-list-btn" (click)="downloadListAsExcel()">
                  <svg xmlns="http://www.w3.org/2000/svg" 
                  width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-download align-middle">
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                  <polyline points="7 10 12 15 17 10"></polyline>
                  <line x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>
                  <span style="white-space: nowrap;margin-left: 4px;">List</span>
                 </button>&nbsp;
                 <button class="btn download-list-btn" (click)="downloadExcelFormat()">
                  <svg xmlns="http://www.w3.org/2000/svg" 
                  width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-download align-middle">
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                  <polyline points="7 10 12 15 17 10"></polyline>
                  <line x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>
                  <span style="white-space: nowrap;margin-left: 4px;">Format</span>
                 </button>&nbsp;
                 <label>
                  <input   [(ngModel)]="fileImage" accept="{{acceptFileTypes}}"  type="file" id="file"  (change)="onBulkUpload($event,completeTask,InValid)">
                  <div class="btn download-list-btn">
                  <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-upload align-middle me-2">
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                  <polyline points="17 8 12 3 7 8"></polyline>
                  <line x1="12" y1="3" x2="12" y2="15"></line>
                  </svg>
                  <span style="white-space: nowrap;margin-left:-3px;">Upload</span>
                  </div>
                 </label>&nbsp;
                <button class="btn date-btn"  (click)="openMonthModel(mySelectMonthModal)">
                  <span class="icon icon-calendar"></span>
                  <span class="year-text" style="white-space: nowrap;margin-left: 4px;">{{Monthdate | date:'MMM'}} {{Monthdate | date:'YYYY'}}</span>
                </button>
               </div>
						</div>
					</div>
          <div class="d-none d-sm-block visible-xs-block, hidden-xs visible-sm-block, hidden-sm">
          <div class="wrapper-test">
            <div  [style.display]="Next==true ?'none':'block'"  >
              <!-- <div class="prevbtn"  (click)="changetab(Next)" >
                <span class="next-btn-span carousel-control-next-icon" aria-hidden="true"></span>
              </div> -->

                <div class="listing-card-desktop" [ngClass]="(currentTab == 'total_fec')? 'active':''">
                  <div class="dashfont"  style="display: flex;" (click)="toggleListing('total_fec')">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                    <div class="card-dash-num">{{totalFEC }}</div>
                    <!-- <img src="assets/images/SArrow.png" style="width: auto;" alt=""> -->
                  </div>
                  <div style="display:flex;" (click)="toggleListing('total_fec')">
                    <div style="visibility: hidden;">
                      <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                    </div>
                    <span class="boxtext">Total No. of FECs</span>
                  </div>
                </div>
            </div>
            <div>
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'assigned_visits')? 'active':''">
                <div (click)="toggleListing('assigned_visits')" class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/assigned.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{assignedVisits }}</div>
                </div>
                <div (click)="toggleListing('assigned_visits')" style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Assigned Visits</span>
                </div>
              </div>
              <div class="prevbtn-icon"  (click)="changetab(Next)" [style.display]="Next==true ?'block':'none'">
                <!-- <span class="next-btn-span carousel-control-prev-icon" aria-hidden="true"></span> -->
                <img src="assets/images/Scrolling_arrow_prev.svg" style="width: auto" alt="">
                
                </div>
            </div>
            <div (click)="toggleListing('completed_visits')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'completed_visits')? 'active':''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/visit-completed.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{completedVisits }}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Completed Visits</span>
                </div>
              </div>
            </div>
            <div  (click)="toggleListing('scheduled_visits')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'scheduled_visits')? 'active':''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/visit-scheduled.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{scheduledVisits }}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Scheduled Visits</span>
                </div>
              </div>
            </div>
            <div>
            <div class="listing-card-desktop" [ngClass]="(currentTab == 'unscheduled_visits')? 'active':''">
                <div class="dashfont"  style="display: flex;" (click)="toggleListing('unscheduled_visits')">
                  <img src="assets/images/iconset/visit-unscheduled.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{unscheduledVisits }}</div>
                </div>
                <div style="display:flex;" (click)="toggleListing('unscheduled_visits')">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Unscheduled Visits</span>
                </div>
            </div>
            <!-- <div  class="nextbtn" [style.display]="Next==true ?'none':'block'" (click)="changetab(Next)" >
              <span class="next-btn-span carousel-control-next-icon" aria-hidden="true"></span>
            </div> -->
            <div class="nextbtn-icon"  [style.display]="Next==true ?'none':'block'" (click)="changetab(Next)">
              <!-- <span class="next-btn-span carousel-control-prev-icon" aria-hidden="true"></span> -->
              <img src="assets/images/Scrolling _Arrow.svg" style="width: auto" alt="">
              
              </div>
            </div>
            <div (click)="toggleListing('overdue_visits')" [style.display]="Next==true ?'block':'none'">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'overdue_visits')? 'active':''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/visit-overdue.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{overdueVisits }}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Overdue Visits</span>
                </div>
              </div>
            </div>
            <!-- <a [style.display]="Next==true ?'block':'none'" (click)="changetab(Next)" class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev">
              <span class="next-btn-span carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
              </a>
              <a [style.display]="Next==true ?'none':'block'" (click)="changetab(Next)" class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
              <span class="next-btn-span carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
              </a> -->
          </div>
          </div>
          <div class="d-block d-sm-none">
          <div class="wrapper-test-mb">
            <div>
                <div class="listing-card-desktop" [ngClass]="(currentTab == 'total_fec')? 'active':''">
                  <div class="dashfont"  style="display: flex;" (click)="toggleListing('total_fec')">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                    <div class="card-dash-num">{{totalFEC }}</div>
                  </div>
                  <div style="display:flex;" (click)="toggleListing('total_fec')">
                    <div style="visibility: hidden;">
                      <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                    </div>
                    <span class="boxtext">Total No. of FECs</span>
                  </div>
                </div>
            </div>
            <div>
              <div class="listing-card-desktop listing-card-width" [ngClass]="(currentTab == 'assigned_visits')? 'active':''">
                <div (click)="toggleListing('assigned_visits')" class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/assigned.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{assignedVisits }}</div>
                </div>
                <div (click)="toggleListing('assigned_visits')" style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Assigned Visits</span>
                </div>
              </div>
            </div>
            <div (click)="toggleListing('completed_visits')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'completed_visits')? 'active':''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/visit-completed.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{completedVisits }}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Completed Visits</span>
                </div>
              </div>
            </div>
            <div  (click)="toggleListing('scheduled_visits')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'scheduled_visits')? 'active':''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/visit-scheduled.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{scheduledVisits }}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Scheduled Visits</span>
                </div>
              </div>
            </div>
            <div>
            <div class="listing-card-desktop" [ngClass]="(currentTab == 'unscheduled_visits')? 'active':''">
                <div class="dashfont"  style="display: flex;" (click)="toggleListing('unscheduled_visits')">
                  <img src="assets/images/iconset/visit-unscheduled.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{unscheduledVisits }}</div>
                </div>
                <div style="display:flex;" (click)="toggleListing('unscheduled_visits')">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Unscheduled Visits</span>
                </div>
            </div>
            <!-- <div  class="nextbtn" [style.display]="Next==true ?'none':'block'" (click)="changetab(Next)" >
              <span class="next-btn-span carousel-control-next-icon" aria-hidden="true"></span>
            </div> -->
            </div>
            <div (click)="toggleListing('overdue_visits')">
              <div class="listing-card-desktop" [ngClass]="(currentTab == 'overdue_visits')? 'active':''">
                <div class="dashfont"  style="display: flex;">
                  <img src="assets/images/iconset/visit-overdue.svg" style="width: auto;margin-right: 7px;" alt="">
                  <div class="card-dash-num">{{overdueVisits }}</div>
                </div>
                <div style="display:flex;">
                  <div style="visibility: hidden;">
                    <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">
                  </div>
                  <span class="boxtext">Overdue Visits</span>
                </div>
              </div>
            </div>
          </div>
          </div>
           <!-- desktop View -->
           <div class="d-none d-sm-block visible-xs-block, hidden-xs visible-sm-block, hidden-sm">
            <div class="row mb-2 mb-xl-3 ">
              <div class="col-6">
                <h3 class="tbl-text btn-display">
                  <ng-container *ngIf="(currentTab == 'total_fec')">
                    Fleet Edge Champs
                  </ng-container>
                  <ng-container *ngIf="(currentTab == 'assigned_visits')">
                    Total Assigned Visits
                  </ng-container>
                  <ng-container *ngIf="(currentTab == 'completed_visits')">
                    Total Completed Visits
                  </ng-container>
                  <ng-container *ngIf="(currentTab == 'scheduled_visits')">
                    Total Scheduled Visits
                  </ng-container>
                  <ng-container *ngIf="(currentTab == 'unscheduled_visits')">
                    Total Unscheduled Visits
                  </ng-container>
                  <ng-container *ngIf="(currentTab == 'overdue_visits')">
                    Total Overdue Visits
                  </ng-container>
                </h3>
              </div>
              <div class="col-6">
                <div class="row">
                <div class="col-5" style="visibility: hidden;">
                  <button class="btn download-list-btn btn-padding" (click)="downloadListAsExcel()">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-download align-middle">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" y1="15" x2="12" y2="3"></line>
                    </svg>
                    <span style="white-space: nowrap;margin-left: 4px;">Download List</span>
                  </button>
                  <button class="btn download-list-btn" (click)="downloadExcelFormat()">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-download align-middle">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" y1="15" x2="12" y2="3"></line>
                    </svg>
                    <span style="white-space: nowrap;margin-left: 4px;">Download Format</span>
                  </button>
                </div>
                <div class="col-7">
                  <div class="input-group input-group-search input-group-width">
                    <button class="btn" type="button">
                      <!-- <i class="align-middle" data-feather="search"></i> -->
                      <svg xmlns="http://www.w3.org/2000/svg" 
                      width="24" height="24" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-search align-middle">
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                    <input type="text" class="form-control" placeholder="Search by FEC Id/Name" [(ngModel)]="searchTerm" (keyup)="SearchbyEfc($event)" >
                  </div>
                </div>
              </div>
              </div>
            </div>
            </div>
          <!-- Mobile View -->
          <div class="d-block d-sm-none" >
            <div class="row mb-2 mb-xl-3 " style="display:flex;">
              <div class="col-auto" style="display: flex;">
                <h3 class="tbl-text " >
                  <ng-container *ngIf="(currentTab == 'total_fec')">
                    Fleet Edge Champs
                  </ng-container>
                  <ng-container *ngIf="(currentTab == 'assigned_visits')">
                    Total Assigned Visits
                  </ng-container>
                  <ng-container *ngIf="(currentTab == 'completed_visits')">
                    Total Completed Visits
                  </ng-container>
                  <ng-container *ngIf="(currentTab == 'scheduled_visits')">
                    Total Scheduled Visits
                  </ng-container>
                  <ng-container *ngIf="(currentTab == 'unscheduled_visits')">
                    Total Unscheduled Visits
                  </ng-container>
                  <ng-container *ngIf="(currentTab == 'overdue_visits')">
                    Total Overdue Visits
                  </ng-container>
                </h3>
                <div style="margin-top:-3px;display:none;" >
                  <button class="btn download-list-btn" (click)="downloadListAsExcel()">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-download align-middle">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" y1="15" x2="12" y2="3"></line>
                    </svg>
                    <span style="white-space: nowrap;margin-left: 4px;">Download List</span>
                    </button>
                </div>
              </div>
              <div class="col-auto ms-auto-p text-end mt-n1" style="display:none;">
                <div>
                  <button class="btn download-list-btn" (click)="downloadExcelFormat()">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-download align-middle">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" y1="15" x2="12" y2="3"></line>
                    </svg>
                    <span style="white-space: nowrap;margin-left: 4px;">Download Format</span>
                  </button>
                </div>
                <label>
                  <input   accept="{{acceptFileTypes}}"  type="file" id="file"  (change)="onBulkUpload($event,completeTask,InValid)">
                  <div class="btn download-list-btn float-end">
                  <svg  xmlns="http://www.w3.org/2000/svg" 
                  width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-download align-middle">
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                  <polyline points="7 10 12 15 17 10"></polyline>
                  <line x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>
                  <span style="white-space: nowrap;margin-left: 4px;">Bulk Upload</span>
                  </div>
                </label>
                &nbsp;
               
              </div>
            </div>
            <div>
              <div class="input-group input-group-search input-group-width">
                <button class="btn" type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" 
                  width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-search align-middle">
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </button>
                <input type="text" class="form-control" placeholder="Search by FEC Name/Dealer Name" [(ngModel)]="searchTerm" >
              </div>
            </div>
          </div>
          <div class="row">
            <!-- Table Data -->
            <div class="table-responsive">
            <table p-table p-table class="table">
              <thead>
                <tr class="table_heading">
                  <th *ngIf="(currentTab == 'total_fec')">FEC Id</th>
                  <th *ngIf="(currentTab == 'total_fec')">FEC Name</th>
                  <th *ngIf="(currentTab == 'assigned_visits' || currentTab == 'completed_visits' || currentTab == 'scheduled_visits' || currentTab == 'unscheduled_visits' || currentTab == 'overdue_visits')">Assigned To</th>
                  <th>Contact No</th>
                  <th>Dealer Name</th>
                  <!-- <th *ngIf="(currentTab != 'total_fec')">Account Name</th> -->
                  <th *ngIf="(currentTab == 'total_fec' || currentTab == 'assigned_visits')">Tasks Assigned</th>
                  <th *ngIf="(currentTab == 'completed_visits')">Completed Visits</th>
                  <th *ngIf="(currentTab == 'scheduled_visits')">Scheduled Visits</th>
                  <th *ngIf="(currentTab == 'unscheduled_visits')">Unscheduled Visits</th>
                  <th *ngIf="(currentTab == 'overdue_visits')">Overdue Visits</th>
                  <!-- <th *ngIf="(currentTab == 'assigned_visits' || currentTab == 'completed_visits' || currentTab == 'scheduled_visits' || currentTab == 'unscheduled_visits' || currentTab == 'overdue_visits')">Assigned On</th> -->
                  <th>Get Insights</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="dashboardDataList && dashboardDataList.length">
                  <tr class="table_data"
                    *ngFor="let singleRow of dashboardDataList">
                    <!-- Total FEC -->
                    <ng-container *ngIf="(currentTab == 'total_fec')">
                      <td>{{singleRow.user_login}}</td>
                      <td>{{singleRow.postn_pr_emp_fst_name}} {{singleRow.postn_pr_emp_last_name}}</td>
                      <td>{{singleRow.postn_pr_emp_cell_ph_num}}</td>
                      <td>                        
                        <div class="breaksWords-efc">{{singleRow.org_name}}</div>
                      </td>
                      <td>{{singleRow.total_task_assigned}}</td>
                      <td>
                        <span class="view-more-text" (click)="getChampInsight(singleRow.user_login)">View More
                          <span style="margin-left: -3%;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="2" stroke-linejoin="round"
                          class="feather feather-chevron-right align-middle me-2">
                          <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                          </span>
                         </span>
                      </td>
                    </ng-container>
                    <!-- Assigned Visits -->
                    <ng-container *ngIf="(currentTab == 'assigned_visits')">
                      <td>{{singleRow.postn_pr_emp_fst_name}} {{singleRow.postn_pr_emp_last_name}}</td>
                      <td>{{singleRow.postn_pr_emp_cell_ph_num}}</td>
                      <!-- <td (mouseenter)="isHidden = !isHidden" (mouseleave)="isHidden = !isHidden">
                        <div  class="breaksWords" *ngIf="isHidden==false">{{singleRow.org_name}}</div>
                        <div   *ngIf="isHidden==true">{{singleRow.org_name}}</div>
                      </td> -->
                      <td >
                        <div class="breaksWords">{{singleRow.org_name}}</div>
                      </td>
                      <!-- <td>{{singleRow.account_name}}</td> -->
                      <td>{{singleRow.total_task_assigned}}</td>
                      <!-- <td>
                        <ng-container *ngIf="(singleRow.assigned_date)">
                          {{singleRow.assigned_date}}
                        </ng-container>
                        <ng-container *ngIf="!(singleRow.assigned_date)"> - </ng-container>
                      </td> -->
                      <td>
                        <span class="view-more-text" (click)="getChampInsight(singleRow.user_login)">View More 
                          <!-- <i class="align-middle me-2" data-feather="chevron-right"></i> -->
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="2" stroke-linejoin="round"
                          class="feather feather-chevron-right align-middle me-2">
                          <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </span>
                        <!-- <img style="height: 1rem; width: auto;" src="../../../assets/images/iconset/icon-right-filled.svg" alt="View Icon"> -->
                      </td>
                    </ng-container>
                    <!-- Completed Visits -->
                    <ng-container *ngIf="(currentTab == 'completed_visits')">
                      <td>{{singleRow.postn_pr_emp_fst_name}} {{singleRow.postn_pr_emp_last_name}}</td>
                      <td>{{singleRow.postn_pr_emp_cell_ph_num}}</td>
                      <td>{{singleRow.org_name}}</td>
                      <!-- <td>{{singleRow.account_name}}</td> -->
                      <td>{{singleRow.completed}}</td>
                      <!-- <td>
                        <ng-container *ngIf="(singleRow.assigned_date)">
                          {{singleRow.assigned_date}}
                        </ng-container>
                        <ng-container *ngIf="!(singleRow.assigned_date)"> - </ng-container>
                      </td> -->
                      <td>
                        <span class="view-more-text" (click)="getChampInsight(singleRow.user_login)">View More 
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="2" stroke-linejoin="round"
                          class="feather feather-chevron-right align-middle me-2">
                          <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </span>
                        <!-- <img style="height: 1rem; width: auto;" src="../../../assets/images/iconset/icon-right-filled.svg" alt="View Icon"> -->
                      </td>
                    </ng-container>
                    <!-- Scheduled Visits -->
                    <ng-container *ngIf="(currentTab == 'scheduled_visits')">
                      <td>{{singleRow.postn_pr_emp_fst_name}} {{singleRow.postn_pr_emp_last_name}}</td>
                      <td>{{singleRow.postn_pr_emp_cell_ph_num}}</td>
                      <td>{{singleRow.org_name}}</td>
                      <!-- <td>{{singleRow.account_name}}</td> -->
                      <td>{{singleRow.scheduled}}</td>
                      <!-- <td>
                        <ng-container *ngIf="(singleRow.assigned_date)">
                          {{singleRow.assigned_date}}
                        </ng-container>
                        <ng-container *ngIf="!(singleRow.assigned_date)"> - </ng-container>
                      </td> -->
                      <td>
                        <span class="view-more-text" (click)="getChampInsight(singleRow.user_login)">View More
                          <!-- <i class="align-middle me-2" data-feather="chevron-right"></i> -->
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="2" stroke-linejoin="round"
                          class="feather feather-chevron-right align-middle me-2">
                          <polyline points="9 18 15 12 9 6"></polyline>
                          </svg> 
                        </span>
                          
                        <!-- <img style="height: 1rem; width: auto;" src="../../../assets/images/iconset/icon-right-filled.svg" alt="View Icon"> -->
                      </td>
                    </ng-container>
                    <!-- Unscheduled Visits -->
                    <ng-container *ngIf="(currentTab == 'unscheduled_visits')">
                      <td>{{singleRow.postn_pr_emp_fst_name}} {{singleRow.postn_pr_emp_last_name}}</td>
                      <td>{{singleRow.postn_pr_emp_cell_ph_num}}</td>
                      <td>{{singleRow.org_name}}</td>
                      <!-- <td>{{singleRow.account_name}}</td> -->
                      <td>{{singleRow.unscheduled}}</td>
                      <!-- <td>
                        <ng-container *ngIf="(singleRow.assigned_date)">
                          {{singleRow.assigned_date}}
                        </ng-container>
                        <ng-container *ngIf="!(singleRow.assigned_date)"> - </ng-container>
                      </td> -->
                      <td>
                        <span class="view-more-text" (click)="getChampInsight(singleRow.user_login)">View More
                          <!-- <i class="align-middle me-2" data-feather="chevron-right"></i>  -->
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="2" stroke-linejoin="round"
                          class="feather feather-chevron-right align-middle me-2">
                          <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </span>
                        <!-- <img style="height: 1rem; width: auto;" src="../../../assets/images/iconset/icon-right-filled.svg" alt="View Icon"> -->
                      </td>
                    </ng-container>
                    <!-- Overdue Visits -->
                    <ng-container *ngIf="(currentTab == 'overdue_visits')">
                      <td>{{singleRow.postn_pr_emp_fst_name}} {{singleRow.postn_pr_emp_last_name}}</td>
                      <td>{{singleRow.postn_pr_emp_cell_ph_num}}</td>
                      <td>{{singleRow.org_name}}</td>
                      <!-- <td>{{singleRow.account_name}}</td> -->
                      <td>{{singleRow.overdue}}</td>
                      <!-- <td>
                        <ng-container *ngIf="(singleRow.assigned_date)">
                          {{singleRow.assigned_date}}
                        </ng-container>
                        <ng-container *ngIf="!(singleRow.assigned_date)"> - </ng-container>
                      </td> -->
                      <td>
                        <span class="view-more-text" (click)="getChampInsight(singleRow.user_login)">View More
                          <!-- <i class="align-middle me-2" data-feather="chevron-right"></i> -->
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="2" stroke-linejoin="round"
                          class="feather feather-chevron-right align-middle me-2">
                          <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                         </span>
                        <!-- <img style="height: 1rem; width: auto;" src="../../../assets/images/iconset/icon-right-filled.svg" alt="View Icon"> -->
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
                <ng-container *ngIf="!(dashboardDataList && dashboardDataList.length)">
                 
                  <tr class="table_data">
                    <!-- <td>1</td>
                    <td>Customer #1</td>
                    <td>Mumbai</td>
                    <td>1233211234</td>
                    <td>982 Vehicles</td> -->
                    
                    <td class="norecords" colspan="6">
                      No Records Found
                     </td>
                     <!-- <td></td> -->
                  </tr>
                </ng-container>
              </tbody>
            </table>
      
            <!-- <div class="" style="float:right;">
              <div class="right-wrap ">
                <ul class="">
      
                  <li class="act-item" style="padding-top:5px;">
                    <pagination-controls class="my-pagination" 
                      previousLabel="Previous"
                      nextLabel="Next"
                    (pageChange)="pageChanged($event)" *ngIf="resultCheck"></pagination-controls>
                    
                  </li>
                </ul>
              </div>
            </div> -->
           
           </div>
           <div class="text-center">
          <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [pageSize]="pageSize" (pageChange)="getpaginateData();">
            </ngb-pagination>
          </div>
          </div>
				</div>
			</main>
		</div>
	</div>
</div>
<ng-template #mySelectMonthModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title model-title-text">Apply Month Filter </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form class="ui form" name="dateFilterform">
        <div class="row col-md-12 p-calendar-cls">
          <div class="form-group col-12">
            <p-calendar [ngModelOptions]="{standalone: true}" [(ngModel)]="Monthdate" view="month" dateFormat="MM yy" [readonlyInput]="true" [inline]="true" [showYear]="true">
            </p-calendar>
          </div>  
          <div class="text-center" style="margin-top:4%;">
            <button type="button" class="btn btn-default submit-button" (click)="addDateFilter()"
            data-bs-dismiss="modal">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #completeTask let-modal>
  <div class="modal-content">
  <div class="modal-body">
    <div class="complete-task-align">
      <div class="stat stat-sm">
        <svg xmlns="http://www.w3.org/2000/svg" 
          width="24" height="24" viewBox="0 0 24 24"
          fill="none" stroke="currentColor" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-check align-middle me-2">
          <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
      </div>
    </div>
    <div class="task-comp-text">Uploaded successfully</div>
    <div class="task-comp-text-success">Your FEC Excel Bulk Data is uploaded successfully.</div>
    <div class="all-task-comp-text">
     You can view this task in your FEC Attribute list.
    </div>
    <div class="complete-visit-btn">
      <button type="button" class="btn btn-completed btn-lg">
        <span class="goto-complete-visit" (click)="closeCompletepopup()">OK</span>
      </button>
    </div>
  </div> 
  </div>
</ng-template>


<ng-template #InValid let-modal>
  <div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title history-heading">Validation Error</h5>
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeCompletepopup()"></button>
		</div>
		<div class="second-heading">
		</div>
		<div class="modal-body">
			<div class="accordion" id="accordionExample" *ngFor="let res of objarray | keyvalue">
        <div class="accordion-item" >
          <h2 class="accordion-header" id="headingOne" (click)="res.isExpand = !res.isExpand">
            <button  class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              {{getKeys(res.value)[0]}}
            </button>
          </h2>
          <div *ngIf="res.isExpand" id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body" >
              {{res.value[getKeys(res.value)[0]] }}
            </div>
          </div>
        </div><br>
        </div>
		</div>
	</div>
</ng-template>


