import { Component, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import { BaseApiService } from 'src/app/base-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-fecassignment',
  templateUrl: './fecassignment.component.html',
  styleUrls: ['./fecassignment.component.scss']
})
export class FecassignmentComponent implements OnInit {

newData=[];
username='';
itemsPerPage: number = 20;
page = 1;
totalItems=0;
currentPage=0;
resultCheck = true;
dataLoadingCheck: boolean = true;
CheckVinFleetTabShow=true;
event=0;
tempPage=0;
searchData='';
state=[];
citylist=[];
citylist_=[];
stateValue='';
selectedStateName=[];
selectedCityName=[];
checkboxData=[];
isChecked=[];
 selectedUsers = [];
 userlist=[];
 array=[];
 values=[];
 usersdata=[];

  constructor(private router: Router,private baseAPI: BaseApiService, public spinnerService: NgxSpinnerService,private http: HttpClient) {


var token = localStorage.getItem('token');
    this.username=localStorage.getItem('username');

    if (token == undefined || token === '') {
      this.router.navigate(['/login']);
    }

  
 

}
 ngOnInit(): void {

this.testapi();
this.stateapi();


}


testapi(){
  var transferObject = JSON.stringify({

  "user_type": "Fleet Owner",
  "offset":this.currentPage,
  "limit":this.itemsPerPage,
   "state":"",
    "city": "",
    "is_assigned":true
});
var URLrequest='fecportal/get/list/';
 
//newData = [];
this.baseAPI.callServiceApi(this.baseAPI.baseUrl+URLrequest,transferObject).then((data:any) => {     
   //this.resultCheck=false;
   //this.event_array=[];
   
 this.newData=data;
 this.totalItems=data[0].total_record;
 console.log(data);
 },(err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert(message);
      }
      else {
        alert('Please try again');
      }
    }); 

}


stateapi(){
  var stateobject = JSON.stringify({

});
var URLrequest='fecportal/state/list/';
 
//newData = [];
this.baseAPI.callServiceApi(this.baseAPI.baseUrl+URLrequest,stateobject).then((statedata:any) => {     
   //this.resultCheck=false;
   //this.event_array=[];
   
 this.state=statedata;
 
 console.log(statedata);
 },(err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert(message);
      }
      else {
        alert('Please try again');
      }
    }); 

}


cityapi($event){
  console.log($event.target.value);
  this.stateValue=$event.target.value;
this.testapi();
 this.citylist = [];
  var cityobject = JSON.stringify({
 "state_name":this.stateValue
});
 
var URLrequest='fecportal/city/list/';
 
//newData = [];
this.baseAPI.callServiceApi(this.baseAPI.baseUrl+URLrequest,cityobject).then((citydata:any) => {     
   //this.resultCheck=false;
   //this.event_array=[];
   
 this.citylist=citydata;
 //alert(this.city);
 console.log(citydata);
 
 },(err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert(message);
      }
      else {
        alert('Please try again');
      }
    }); 

}

filtercity(){
this.testapi();

}

getchecked_value($event){


const checked =  (<HTMLInputElement>event.target).checked
   if (checked) {
     this.selectedUsers.push($event.target.value); 
     
      } else {
      const index = this.selectedUsers.findIndex(list => list == $event.target.value);//Find the index of stored id
      this.selectedUsers.splice(index, 1); // Then remove
    }

    

   


}

assignuser()
{
 this.userlist = [];

  var userbject = JSON.stringify({
 "user_id":this.selectedUsers,
 "is_assigned":true,
    "first_name":"",
    "last_name":""
}); 
console.log(userbject);
var URLrequest='fecportal/assign/fleet/'; 

this.baseAPI.callServiceApi(this.baseAPI.baseUrl+URLrequest,userbject).then((usersdata:any) => {     
   this.testapi();
this.selectedUsers=[];
 alert(usersdata['msg']) ; 
 this.userlist=usersdata;
 
 console.log(usersdata);
 
 },(err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert(message);
      }
      else {
        alert('Please try again');
      }
    }); 

}

pageChanged(event) {

    this.currentPage = event;  
    this.event= this.currentPage;
    this.testapi();
  }

}
