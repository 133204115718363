import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  encodeKey:any='2f3a7e21a99db3b7e9081c6d6f255b24';
  public selectedFECData: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  constructor(
    private baseAPIService: BaseApiService,
    private spinnerService: NgxSpinnerService,
  ) { }
  
  setFECDataInLocalStorage(value:any):void {
    // localStorage.setItem("FEC", btoa(JSON.stringify(value)));
    localStorage.setItem("FEC", JSON.stringify(value));
  }

  getFECDataFromLocalStorage():any {
    if(localStorage.getItem("FEC")){
      // return JSON.parse(atob(localStorage.getItem("FEC")));
      return JSON.parse(localStorage.getItem("FEC"));
    } else {
      return [];
    }
  }
  getFECData() {
    if(this.selectedFECData && this.selectedFECData['value'] && Object.keys(this.selectedFECData['value']).length>0) {
      return this.selectedFECData.asObservable();
    } else {
      var selectedFECDataFromStorage = this.getFECDataFromLocalStorage();
      this.changeFECData(selectedFECDataFromStorage);
      return this.selectedFECData.asObservable();
    }
  }

  changeFECData(newValue:any):void {
    this.selectedFECData.next(newValue);
    this.setFECDataInLocalStorage(newValue);
  }

  downloadMasterData():void {
    this.spinnerService.show();
    var requestURL = "fecportal/download/master/";
    var requestPayload = '';
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      console.log("response" + response);
      if (response && response["url"]) {
        var download_url = response["url"];
        window.open(download_url);
      }
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      alert(errMessage);
    });
  }
  downloadMasterDataWithpayload(requestPayload):void {
    var requestURL = "fecportal/download/master/";
    // var requestPayload = {"type":"all"};
    this.spinnerService.show();
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      console.log("response" + response);
      if (response && response["url"]) {
        var download_url = response["url"];
        window.open(download_url);
      }
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      alert(errMessage);
    });
  }
  downloadListData(requestPayload):void {
    var requestURL = "fecportal/download/assigned_data/";
    // var requestPayload = '';
    this.spinnerService.show();
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      console.log("response" + response);
      if (response && response["url"]) {
        var download_url = response["url"];
        window.open(download_url);
      }
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      alert(errMessage);
    });
  }
  aesGcmEncrypt(str:any)
  {
    var secret_key = CryptoJS.enc.Utf8.parse(this.encodeKey);
    var encrypted = CryptoJS.AES.encrypt(str, secret_key, {
      mode: CryptoJS.mode.ECB,
    });
    return encrypted.toString();
  }
  dateFormater(inputDate){
    if(inputDate!=''){
      var currentTime = new Date(inputDate);
      var month = currentTime.getMonth() + 1;
      var date = currentTime.getDate();
      var year = currentTime.getFullYear();
        if(month<10){
          var n = '0'+month;
        }else{
           var n = month.toString();
        }
        if(date<10){
          var ndate = '0'+date;
        }else{
           var ndate = date.toString();
        }
        inputDate = year+'-'+n+'-'+ndate
       
        return inputDate;
    }else{
      return inputDate;
    }
  }

}
