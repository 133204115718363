import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormsModule, FormControl, Validators, ValidatorFn, FormBuilder, ReactiveFormsModule, AbstractControl } from "@angular/forms";
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseApiService } from 'src/app/base-api.service';
import { SharedService } from 'src/app/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpXhrBackend } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var $:any;


@Component({
  selector: 'app-fectaskdetail',
  templateUrl: './fectaskdetail.component.html',
  styleUrls: ['./fectaskdetail.component.scss']
})

export class FectaskdetailComponent implements OnInit,OnDestroy {
  // @ViewChild('taskcomplete') public taskcomplete!: ElementRef;

  name;
  urls = [];
  no_of_lob;
  fleet_size;
  tempe = [];
  Mobile;
  Designation;
  add_notes;
  purpose_of_visit;
  id;
  user_id = '';
  industryTypeOptions: any = [];
  industryType:any=null;
  purposeOfVisitOptions: any = [];
  fecTaskInformation: FormGroup;
  fecTaskInformationData:any=[];
  currentDate = new Date();
  isNextVisit = 'yes';
  ChangeTab:any;
  sales_opty:any;
  NextVisitDate:any;
  Schfalg:boolean=false;
  today = new Date().toISOString().split('T')[0];
  Imgurl:any='';
  ImgUrlData:any=[];
  imgSrc:any;
  currentIndex: any = -1;
  showFlag: any = false;
  imageObject:any=[];
  FileList:any=[];
  finalindex:any=0;
  CompanyName:any='';
  fec_assign_id:any;
  VisitStatus:any=1;
  setiNum:any=0;
  Imagelength:any=[];
  month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private baseAPIService: BaseApiService,
    private spinnerService: NgxSpinnerService,
    private _Activatedroute: ActivatedRoute,
    private http: HttpClient,
    private modalService: NgbModal,
    private route:Router,
    private sanitizer: DomSanitizer,
    private toastr:ToastrService


  ) { }

  ngOnInit(): void {
    //  document.getElementsByName("nextvisitdate")[0].setAttribute('min', this.today);
    this.industryTypeOptions = [
      { "key": "Market load transportation – 3PL, contract, spot load", "value": "Market load transportation – 3PL, contract, spot load" },
      { "key": "Construction material transportation – Sand, cement, stone", "value": "Construction material transportation – Sand, cement, stone" },
      { "key": "Tanker – Oil, milk, water, chemicals", "value": "Tanker – Oil, milk, water, chemicals" },
      { "key": "Coal and miningInter-city (Buses)", "value": "Coal and miningInter-city (Buses)" },
      { "key": "Intra-city STU (Buses)", "value": "Intra-city STU (Buses)" },
      { "key": "School/staff (Buses)", "value": "School/staff (Buses)" },
      { "key": "Others", "value": "Others" },
    ];
    this.purposeOfVisitOptions = [
      { "key": "Customer Training", "value": "Customer Training" },
      { "key": "Service Issues", "value": "Service Issues" },
      { "key": "New purchase", "value": "New purchase" },
      { "key": "Follow up visit", "value": "Follow up visit" },
      { "key": "Others", "value": "Others" },
    ];

    this._Activatedroute.params.subscribe(params => {
    this.id = params['data'];
    this.ChangeTab=params['currentTab'];
    this.fec_assign_id=params['fecid'];
    });
    if(this.ChangeTab=='scheduled_visits')
    {
      this.VisitStatus=2;
    }
    else if(this.ChangeTab=='completed_visits')
    {
      this.VisitStatus=4;
    }
    else if(this.ChangeTab=='unscheduled_tasks')
    {
      this.VisitStatus=1;
    }
    else if(this.ChangeTab=='overdue_visits')
    {
      this.VisitStatus=3;
    }
    this.initializeComponent();
  }
  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }
  initializeComponent(): void {
    if(this.ChangeTab=='scheduled_visits' || this.ChangeTab=='completed_visits')
    {
      this.fecTaskInformation = this.formBuilder.group({
        fleetSize: [],
        industryType: [],
        spocName: [],
        spocPhone: [],
        spocDesignation: [],
        spocPurposeOfVisit: [],
        spoc_name: ['', [Validators.required]],
        spoc_mobile: ['', [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        spoc_designation: ['', [Validators.required]],
        attachments: [[]],
        spocFeedback: ['', [Validators.required, Validators.pattern(/^[_':.,(){}0-9a-zA-Z ]*$/)]],
        salesOpportunity: ['', [Validators.required]],
        sdate: [''],
        nextVisitDate: ['', [Validators.required]]
      });
    }
    else
    {
      this.fecTaskInformation = this.formBuilder.group({
        fleetSize: [],
        industryType: [],
        spocName: [],
        spocPhone: [],
        spocDesignation: [],
        spocPurposeOfVisit: [],
        attachments: [[]],
        spocFeedback: ['', [Validators.required, Validators.pattern(/^[_':.,(){}0-9a-zA-Z ]*$/)]],
        salesOpportunity: ['', [Validators.required]],
        sdate: [''],
        nextVisitDate: ['', [Validators.required]]
      });
    }
    
    this.fetchTaskDetails();
  }
  fetchTaskDetails():void {
    if(this.id || this.id != undefined || this.id != 0 || this.id != null) {
      var y: number = +this.id;
      var requestURL = "fecportal/get/list/";
      var requestPayload:any;
      requestPayload = {
        "offset": 0,
        "limit": 10,
        "id": y,
        'status':this.VisitStatus,
      };
      if(this.ChangeTab=='scheduled_visits')
      {
        requestPayload.fec_assign_id=this.fec_assign_id;
      }
      this.spinnerService.show();
      this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
        if(response && Object.keys(response).length && response[1]) {
          console.log(response,'response')
          let keyExitOrNot:any=0;
          let i=0;
          response[1]?.upload_image_path?.forEach(element => {
           element['type']=getExtensionFromURL(element.url);
          i++;
          });
          var customer_info = response[1];
          if(customer_info)
          {

          }
          this.fecTaskInformationData=customer_info;
          this.CompanyName=customer_info['company_name'];
          this.sales_opty=customer_info["sales_opty"];
          this.industryType=customer_info["industry_type"];
          if(this.ChangeTab=='scheduled_visits')
          {
            this.fecTaskInformation.patchValue({
            spocName: customer_info["first_name"] + " " + customer_info["last_name"],
            spocPhone: customer_info["contact"],
            spocDesignation: customer_info["designation"],
            industryType: customer_info["industry_type"],
            spocPurposeOfVisit: customer_info["purpose_of_visit"],
            salesOpportunity: customer_info["sales_opty"],
            isNew: false,
            });
            if(customer_info["fec_assign"]){
              this.isNextVisitDateRequired('yes');
              try {
                var d = new Date(customer_info.scheduled_date);
                var next_visit_date = ""+this.sharedService.dateFormater(d);
                this.fecTaskInformation.controls.nextVisitDate.setValue(next_visit_date);
              } catch (error) {
                console.log("Invalid Next Visit Date", customer_info["next_visit_date"]);
              }
            }
            else
            {
              this.isNextVisitDateRequired('no');
            }
          }
          if(this.ChangeTab=='completed_visits')
          {
            // spoc_name: ['', [Validators.required]],
            // spoc_mobile: ['', [Validators.required]],
            // spoc_designation: ['', [Validators.required]],
            this.fecTaskInformation.patchValue({
              spocName: customer_info["first_name"] + " " + customer_info["last_name"],
              spocPhone: customer_info["contact"],
              spocDesignation: customer_info["designation"],
              industryType: customer_info["industry_type"],
              spocPurposeOfVisit: customer_info["purpose_of_visit"],
              salesOpportunity: customer_info["sales_opty"],
              isNew: false,
              spocFeedback: customer_info["customer_feedback"],
              spoc_name: customer_info["spoc_name"],
              spoc_mobile: customer_info["spoc_mobile"],
              spoc_designation: customer_info["spoc_designation"]
            });
            if(customer_info["next_visit_date"]){
              this.isNextVisitDateRequired('yes');
              try {
                var d = new Date(customer_info["next_visit_date"]);
                var next_visit_date = ""+this.sharedService.dateFormater(d);
                this.fecTaskInformation.controls.nextVisitDate.setValue(next_visit_date);
              } catch (error) {
                console.log("Invalid Next Visit Date", customer_info["next_visit_date"]);
              }
            }
          }
          else
          {
        
            this.fecTaskInformation.patchValue({
            spocName: customer_info["first_name"] + " " + customer_info["last_name"],
            spocPhone: customer_info["contact"],
            spocDesignation: customer_info["designation"],
            industryType: customer_info["industry_type"],
            spocPurposeOfVisit: customer_info["purpose_of_visit"],
            salesOpportunity: customer_info["sales_opty"],
            isNew: false,
            });
          }
          this.fecTaskInformation.controls.attachments.setValue([]);
          if(customer_info["upload_image_path"]){
          }
          this.fecTaskInformation.updateValueAndValidity();
        } else {
          console.log("No Customer Details Found");
          this.fecTaskInformation.reset();
          this.fecTaskInformation.updateValueAndValidity();
        }
        this.spinnerService.hide();
      }, (error) => {
        this.spinnerService.hide();
        var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
        if(error.status==401)
        {
          this.toastr.error(errMessage)
          this.route.navigate(['/login']);
        }
        else{
          this.toastr.error(errMessage)
        }
      });
    }
  }
  openFileSelector(ele: any): void {
    var fileSelector = ele.closest('#attachments').querySelector("#files-selector");
    fileSelector.click();
  }
  loopThroughSelectedFiles(thisObj:any, allSelectedFiles:any, currentIndex:number,requestPayload:any,schedTask:any):any {
    if(currentIndex <= allSelectedFiles.length) {
      thisObj.spinnerService.show();
      var formData = new FormData();
      formData.append("file_index", ""+currentIndex);
      formData.append("image_path", allSelectedFiles[currentIndex-1]);
      return thisObj.uploadFile(formData).subscribe((response: any) => {
      if (typeof response == "object") {
        console.log("UPLOAD Response:", response);
        // this.Imgurl = response.url;
        if(typeof response.url != 'undefined' && response.url) {
          var files = [];
          let name=allSelectedFiles[currentIndex-1].name.split('.');
          files.push({"url": response.url,type:name[1]});
          requestPayload['upload_image_path'].push({"url": response.url,type:name[1]});
          console.log(files,'files')
          // thisObj.fecTaskInformation.controls.attachments.setValue(files);
          this.fecTaskInformation.controls.attachments.setValue(files);
          console.log(this.fecTaskInformation.controls,' this.fecTaskInformation.controls')

          var new_index = currentIndex+1;
          this.finalindex=new_index-1;
          if(new_index <= allSelectedFiles.length) {
            // "upload_image_path": this.fecTaskInformation.value.attachments,
            thisObj.loopThroughSelectedFiles(thisObj, allSelectedFiles, new_index,requestPayload,schedTask);
          } else {
            thisObj.spinnerService.hide();
            if(this.finalindex=== allSelectedFiles.length)
            {
              this.saveSechuldedOp(requestPayload,schedTask);
            }
          }
        } else {
          thisObj.spinnerService.hide();
          var errMessage = thisObj.baseAPIService.handleAPIResponse(response, "Unable to Upload File. Please try again");
          // alert(errMessage);
          this.toastr.error(errMessage)

        }
        return true;
      } else {
        thisObj.spinnerService.hide();
        var errMessage = thisObj.baseAPIService.handleAPIResponse(response, "Unable to Upload File. Please try again");
        this.toastr.error(errMessage)
        // alert(errMessage);
        return false;
      }
      }, (error) => {
        thisObj.spinnerService.hide();
        var errMessage = thisObj.baseAPIService.handleAPIResponse(error, "Unable to Upload File. Please try again");
        this.toastr.error(errMessage);
        return false;
      });
    }
    // if(this.fecTaskInformationData?.upload_image_path)
    // {
    //   let bothlength=allSelectedFiles.length;
    //   console.log(bothlength)
    // //   if(bothlength<4)
    // //  {
    // //   if(currentIndex <= allSelectedFiles.length) {
    // //     thisObj.spinnerService.show();
    // //     var formData = new FormData();
    // //     formData.append("file_index", ""+currentIndex);
    // //     formData.append("image_path", allSelectedFiles[currentIndex-1]);
    // //     thisObj.uploadFile(formData).subscribe((response: any) => {
    // //       if (typeof response == "object") {
    // //         console.log("UPLOAD Response:", response);
    // //         this.Imgurl = response.url;
    // //         this.ImgUrlData.push(response.url);
    // //         console.log(this.ImgUrlData,'this.ImgUrlData');
    // //         if(typeof response.url != 'undefined' && response.url) {
    // //           var files = thisObj.fecTaskInformation.value.attachments;
    // //           files.push({"url": response.url});
    // //           thisObj.fecTaskInformation.controls.attachments.setValue(files);
    // //           var new_index = currentIndex+1;
    // //           if(new_index <= allSelectedFiles.length) {
    // //             thisObj.loopThroughSelectedFiles(thisObj, allSelectedFiles, new_index);
    // //           } else {
    // //             thisObj.spinnerService.hide();
    // //           }
    // //         } else {
    // //           thisObj.spinnerService.hide();
    // //           var errMessage = thisObj.baseAPIService.handleAPIResponse(response, "Unable to Upload File. Please try again");
    // //           // alert(errMessage);
    // //           this.toastr.error(errMessage)

    // //         }
    // //       } else {
    // //         thisObj.spinnerService.hide();
    // //         var errMessage = thisObj.baseAPIService.handleAPIResponse(response, "Unable to Upload File. Please try again");
    // //         // alert(errMessage);
    // //         this.toastr.error(errMessage)
    // //       }
    // //     }, (error) => {
    // //       thisObj.spinnerService.hide();
    // //       var errMessage = thisObj.baseAPIService.handleAPIResponse(error, "Unable to Upload File. Please try again");
    // //       // alert(errMessage);
    // //       this.toastr.error(errMessage)
    // //     });
    // //   }
    // //   }
    // //   else
    // //   {
    // //   alert("Only three Image allowed")

    // //   this.spinnerService.hide();
    // //   }
    // }
    // else
    // {
    //   if(allSelectedFiles.length<4)
    //   {
    //     if(currentIndex <= allSelectedFiles.length) {
    //       thisObj.spinnerService.show();
    //       var formData = new FormData();
    //       formData.append("file_index", ""+currentIndex);
    //       formData.append("image_path", allSelectedFiles[currentIndex-1]);
    //       thisObj.uploadFile(formData).subscribe((response: any) => {
    //         if (typeof response == "object") {
    //           console.log("UPLOAD Response:", response);
    //           this.Imgurl = response.url;
    //           this.ImgUrlData.push(response.url);
    //           console.log(this.ImgUrlData,'this.ImgUrlData');
    //           if(typeof response.url != 'undefined' && response.url) {
    //             var files = thisObj.fecTaskInformation.value.attachments;
    //             files.push({"url": response.url});
    //             thisObj.fecTaskInformation.controls.attachments.setValue(files);
    //             var new_index = currentIndex+1;
    //             if(new_index <= allSelectedFiles.length) {
    //               thisObj.loopThroughSelectedFiles(thisObj, allSelectedFiles, new_index);
    //             } else {
    //               thisObj.spinnerService.hide();
    //             }
    //           } else {
    //             thisObj.spinnerService.hide();
    //             var errMessage = thisObj.baseAPIService.handleAPIResponse(response, "Unable to Upload File. Please try again");
    //             // alert(errMessage);
    //             this.toastr.error(errMessage)

    //           }
    //         } else {
    //           thisObj.spinnerService.hide();
    //           var errMessage = thisObj.baseAPIService.handleAPIResponse(response, "Unable to Upload File. Please try again");
    //           // alert(errMessage);
    //           this.toastr.error(errMessage)
    //         }
    //       }, (error) => {
    //         thisObj.spinnerService.hide();
    //         var errMessage = thisObj.baseAPIService.handleAPIResponse(error, "Unable to Upload File. Please try again");
    //         // alert(errMessage);
    //         this.toastr.error(errMessage)
    //       });
    //     }
    //   }
    //   else
    //   {
    //     alert("Only three Image allowed")
    //     this.spinnerService.hide();
    //   }

    // }
  }
  saveSechuldedOp(requestPayload:any,schedTask:any)
  {
    this.spinnerService.show();
    var requestURL = "fecportal/customer_details/create/";
    console.log(requestPayload,'requestPayload')
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
    this.spinnerService.hide();
    var message = this.baseAPIService.handleAPIResponse(response, "Task Details Submitted Successfully");
    this.NextVisitDate=this.fecTaskInformation.value.nextVisitDate;
      this.modalService.open(schedTask, { size: "sm",centered: true,backdrop : 'static',keyboard : false });
  }, (error) => {
    this.spinnerService.hide();
    var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
    // alert(errMessage);
    this.toastr.error(errMessage)
  });
  }
  onSelectFile(event) {
    let selectedfiles:any=event.target.files;
    let allowedExtension = ['application/vnd.oasis.opendocument.spreadsheet','application/vnd.oasis.opendocument.text','application/doc','application/ms-doc','text/csv','image/jpeg', 'image/jpg', 'image/png','image/gif','image/bmp','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/pdf'
   ,'application/vnd.openxmlformats-officedocument.wordprocessingml.document','text/plain','application/x-zip-compressed','application/msword'];
    if(this.ImgUrlData.length+event.target.files.length<4)
    {
      let j=0;
      for(var i=0;i<event.target.files.length && this.ImgUrlData.length<3;i++)
      {
        var reader = new FileReader();
        let kb = event.target.files[0].size / 1024; // convert the file size into byte to kb
        let mb = kb / 1024; // convert kb to mb
        if(allowedExtension.indexOf(event.target.files[i].type)>-1)
        {
          if(mb>=20)
          {
            alert("file size Should be 20 Mb");
          }
          else
          {
            let filextension=event.target.files[i].name.split('.');
            let name=event.target.files[i].name;
            this.FileList.push(event.target.files[i]);
            reader.readAsDataURL(event.target.files[i]); // read file as data url
            reader.onload = (event: any) => {
            this.ImgUrlData.push({ImgUrl:event.target.result,filext:filextension[filextension.length-1],srNo:this.setiNum,name:name});
            this.setiNum++;
          }
          }
        }
        else
        {
          alert("Invalid File Format");
          return;
        }
      }
    }
    else
    {
      this.toastr.error("Maximum 3 file upload")
    }
  }
  imagepreview(url:any,ImgSrcModel:any,index:any)
  {
    if(index==1)
    {
      this.imgSrc=url.ImgUrl;
      this.showFlag = false;
      this.spinnerService.hide();
       this.modalService.open(ImgSrcModel, {centered: true });
    }
    else
    {
      this.imgSrc=url.ImgUrl;
      this.showFlag = false;
      this.spinnerService.hide();
      if(url.filext.toLowerCase()=='pdf')
      {
        var win = window.open();
        win.document.write('<iframe src="' + url.ImgUrl  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');  
      }
      else
      {
        window.open(url.ImgUrl,'_self');
      }
    }
  }
  imagepreviewComplete(url:any,ImgSrcModel:any,index:any)
  {
    if(index==1)
    {
      this.imgSrc=url.url;
      this.showFlag = false;
      this.spinnerService.hide();
       this.modalService.open(ImgSrcModel, {centered: true });
    }
    else
    {
      this.imgSrc=url.url;
      this.showFlag = false;
      this.spinnerService.hide();
      if(url.type.toLowerCase()=='pdf')
      {
        var win = window.open();
        win.document.write('<iframe src="' + url.url  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');  
      }
      else
      {
        window.open(url.url,'_self');
      }
    }
  }
  removeImg(val:any)
  {
    this.setiNum= this.setiNum-1;
    const index1= this.ImgUrlData.indexOf(val.srNo);
    const index=this.ImgUrlData.findIndex(x => x.srNo ===val.srNo);
    if (index > -1)
    { // only splice array when item is found
      this.ImgUrlData.splice(index, 1); // 2nd parameter means remove one item only
      this.FileList.splice(index, 1);
    }
  }
  onShowImgeIcons(val:any)
  {
    if(val.filext.toLowerCase()=='png' || val.filext.toLowerCase()=='jpeg' || val.filext.toLowerCase()=='jpg' ||  val.filext.toLowerCase()=='gif')
    {
       return val.ImgUrl;
    }
    else if(val.filext.toLowerCase()=='xlsx' || val.filext.toLowerCase()=='csv')
    {
      return 'assets/images/excel.png';
    }
    else if(val.filext.toLowerCase()=='pdf')
    {
      return 'assets/images/pdf.jpg';
    }
    else if(val.filext.toLowerCase()=='txt')
    {
      return 'assets/images/txt.png';
    }
    else if(val.filext.toLowerCase()=='docx' || val.filext.toLowerCase()=='doc')
    {
      return 'assets/images/docs.png';
    }
    else if(val.filext.toLowerCase()=='zip')
    {
      return 'assets/images/zip.png';
    }
    // .docx
    else
    {
      return 'assets/images/docs.png';
    }
  }
  onShowImgeIconsComplete(val:any)
  {
    if(val?.type.toLowerCase()=='png' ||val?.type.toLowerCase()=='jpeg' || val?.type.toLowerCase()=='jpg' ||  val?.type.toLowerCase()=='gif')
    {
       return val.url;
    }
    else if(val?.type.toLowerCase()=='xlsx')
    {
      return 'assets/images/excel.png';
    }
    else if(val?.type.toLowerCase()=='pdf')
    {
      return 'assets/images/pdf.jpg';
    }
    else if(val?.type.toLowerCase()=='txt')
    {
      return 'assets/images/txt.png';
    }
    else if(val?.type.toLowerCase()=='docx')
    {
      return 'assets/images/docs.png';
    }
    else if(val?.type.toLowerCase()=='zip')
    {
      return 'assets/images/zip.png';
    }
    // .docx
    else
    {
      return 'assets/images/docs.png';
    }
  }
  isNextVisitDateRequired(value:string):void {
    this.isNextVisit = value;
    this.fecTaskInformation.controls.nextVisitDate.setValue('');
    this.fecTaskInformation.controls.nextVisitDate.clearValidators();
    if(this.isNextVisit == 'yes') {
      this.Schfalg=true;
      this.fecTaskInformation.controls.nextVisitDate.addValidators([Validators.required]);
    }
    this.fecTaskInformation.controls.nextVisitDate.updateValueAndValidity();
  }
  isNextVisitDateRequiredSch(Schfalg:boolean):void {
    this.Schfalg=!Schfalg;
    if(this.Schfalg==true)
    {
      this.isNextVisit='yes';
      this.fecTaskInformation.controls.nextVisitDate.addValidators([Validators.required]);
      this.fecTaskInformation.controls.nextVisitDate.updateValueAndValidity();
    }
    else
    {
      this.isNextVisit='no';
    }
  }
  submitTask(schedTask:any):any {
    this.isNextVisit=this.Schfalg==false ?'no':'yes';
    this.fecTaskInformation.markAllAsTouched();
    if(this.fecTaskInformation.value.spoc_designation=='' || this.fecTaskInformation.value.spoc_name=='' || this.fecTaskInformation.value.spoc_mobile==''|| this.fecTaskInformation.value.spocFeedback=='' || this.fecTaskInformation.value.salesOpportunity=='' || this.fecTaskInformation.value.spocFeedback==undefined || this.fecTaskInformation.value.salesOpportunity==undefined)
    {
      var errMessage = "Unable to process request";
      this.toastr.error(errMessage)
      return;
    }
    else
    {
       if(this.isNextVisit == 'yes')
       {
          if(this.fecTaskInformation.value.nextVisitDate=='')
          {
            var errMessage = "Unable to process request";
            this.toastr.error(errMessage)
            return;
          }
          else
          {
            this.spinnerService.show();
            var requestURL = "fecportal/customer_details/create/";
            var requestPayload = {
            "fec_customer_data_id": this.id,
            "fec_assign_id":this.fec_assign_id,
            "sales_opty": this.fecTaskInformation.value.salesOpportunity,
            "customer_feedback": this.fecTaskInformation.value.spocFeedback,
            "upload_image_path": this.fecTaskInformation.value.attachments,
            "next_visit": (this.isNextVisit == 'yes')? true: false,
            "status":2,
            "next_visit_date":(this.isNextVisit == 'yes')? "" + (this.fecTaskInformation.value.nextVisitDate) + " 00:00:00" :null,
            "spoc_designation":this.fecTaskInformation.value.spoc_designation,
            "spoc_mobile":this.fecTaskInformation.value.spoc_mobile,
            "spoc_name":this.fecTaskInformation.value.spoc_name,
            }
            if(this.FileList.length==0)
            {
              this.saveSechuldedOp(requestPayload,schedTask);
            }
            else
            {
            this.loopThroughSelectedFiles(this, this.FileList, 1,requestPayload,schedTask);
            }
          }
       }
       else
       {
        this.spinnerService.show();
        var requestURL = "fecportal/customer_details/create/";
        var requestPayloadNo:any = {
          "fec_customer_data_id": this.id,
          "fec_assign_id":this.fec_assign_id,
          "sales_opty": this.fecTaskInformation.value.salesOpportunity,
          "customer_feedback": this.fecTaskInformation.value.spocFeedback,
          "upload_image_path": this.fecTaskInformation.value.attachments,
          "next_visit": (this.isNextVisit == 'yes')? true: false,
          "status":2,
          "spoc_designation":this.fecTaskInformation.value.spoc_designation,
          "spoc_mobile":this.fecTaskInformation.value.spoc_mobile,
          "spoc_name":this.fecTaskInformation.value.spoc_name,
        };
        if(this.FileList.length==0)
        {
          this.saveSechuldedOp(requestPayloadNo,schedTask);
        }
        else
        {
          this.loopThroughSelectedFiles(this, this.FileList, 1,requestPayloadNo,schedTask);
        }
      }
    }
  }
  submitCompletedTask(completedTask:any)
  {
    this.spinnerService.show();
    var requestURL = "fecportal/customer_details/create/";
    var requestPayload = {
      "fec_customer_data_id": this.id,
      "fec_assign_id":this.fec_assign_id,
      "sales_opty": this.fecTaskInformation.value.salesOpportunity,
      "customer_feedback": this.fecTaskInformation.value.spocFeedback,
      "next_visit": (this.isNextVisit == 'yes')? true: false,
    }
    if(this.isNextVisit == 'yes')
    {
      requestPayload['next_visit_date']="" + (this.fecTaskInformation.value.nextVisitDate) + " 00:00:00";
    }
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      this.spinnerService.hide();
      this.NextVisitDate=this.fecTaskInformation.value.nextVisitDate;
      var message = this.baseAPIService.handleAPIResponse(response, "Task Details Submitted Successfully");
      this.modalService.open(completedTask, { size: "sm",centered: true,backdrop : 'static',keyboard : false });
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      this.toastr.error(errMessage)
    });
  }
  submitUnshedTask(UnschedTask:any)
  {
    this.spinnerService.show();
    var requestURL = "fecportal/fec/set_date_time/";
    var requestPayload = {
      "id": this.fec_assign_id,
      "visit_date_time": this.sharedService.dateFormater(this.fecTaskInformation.value.nextVisitDate)+" 00:00:00",
    }
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      this.spinnerService.hide();
      this.NextVisitDate=this.fecTaskInformation.value.nextVisitDate;
      var message = this.baseAPIService.handleAPIResponse(response, "Task Details Submitted Successfully");
      this.modalService.open(UnschedTask, { size: "sm",centered: true,backdrop : 'static',keyboard : false });
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      this.toastr.error(errMessage)
    });
  }
  submitOverDueTask(OverDueTask:any)
  {
    this.spinnerService.show();
    var requestURL = "fecportal/fec/set_date_time/";
    var requestPayload = {
      "id": this.fec_assign_id,
      "visit_date_time": this.sharedService.dateFormater(this.fecTaskInformation.value.nextVisitDate)+" 00:00:00",
    }
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayload).then((response: any) => {
      this.spinnerService.hide();
      this.NextVisitDate=this.fecTaskInformation.value.nextVisitDate;
      var message = this.baseAPIService.handleAPIResponse(response, "Task Details Submitted Successfully");
      this.modalService.open(OverDueTask, { size: "sm",centered: true,backdrop : 'static',keyboard : false });
    }, (error) => {
      this.spinnerService.hide();
      var errMessage = this.baseAPIService.handleAPIResponse(error, "Unable to process request. Please try again");
      // alert(errMessage);
      this.toastr.error(errMessage)
    });
  }
  closeCompletepopup(ChangeTab:any)
  {
    this.modalService.dismissAll();
    this.route.navigate(['/fecdashboard',{'currentTab':ChangeTab}]);
  }
  uploadFile(formData:FormData) {
    var token = localStorage.getItem('auth_token');
    const httpOptions = {
      headers: new HttpHeaders({
        //   "Content-Type":"application/json",
        //  "Accept-Encoding":"multipart/form-data"
        "Authorization": 'Bearer ' + token
      }),
      //redirect: 'follow'
    };
    var requestURL = this.baseAPIService.baseUrl+"fecportal/upload/image/";
    var requestPayload = formData;
    return this.http.post(requestURL, requestPayload, httpOptions).pipe(tap(res => { 
      // res["request_payload"] = [];
      // requestPayload.forEach((value, index) => {
      //   res["request_payload"][index] = value;
      // });
      return res;
    }), catchError(err => of(err)));
  }
  viewHistory()
  {
    this.route.navigate(['/fec-view-history',{"hid":this.id,'currentTab':this.ChangeTab,'CmpName':this.CompanyName}]);
    // [routerLink]="['/fec-view-history']"
  }
  showLightbox(index) {
    this.currentIndex = index;
    this.showFlag = true;
  }
  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }
}
function getExtensionFromURL(URL){
  const tokens = URL.match(/(\.[a-zA-Z0-9]+(\?|\#|$))/g) 
  if(tokens==null || tokens=='null')
  {
    return 'docs';
  }
  if(!tokens[0]) return false 
  let tokenpath=tokens[0].replace(/[^A-Za-z0-9\.]/g,'').split('.');
  return tokenpath[1];
}