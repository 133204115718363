<!-- <div class="top-content"><img class="logoimg" style="width:213px;" src="assets/images/fleet_logo.png"></div> -->
<!-- <div class="cp-onboarding2">Experience the connected way of business with Tata Motors Fleet Edge–The Next-Generation Connected Vehicle platform for commercial vehicles.</div> -->
<!-- <div class="lyt-main typ-login" id="lyt-main">
<div class="cp-onboarding">
  <div class="logo-wrap">
    <a href="#" class="logo"><img src="https://fleetedge.home.tatamotors/assets/landing-page/tml-logo-login.svg" alt="" /></a>
  </div>
   <form [hidden]="login_state" class="card-body" (ngSubmit)="login()" #loginForm="ngForm" autocomplete='off'  name="myForm" ng-controller="mainCtrl"> 
  <div class="login-form onboarding-step" *ngIf="loginStep">
    <h2 class="title">Login</h2>
    <h4 class="desc">Enter details registered with TATA Motors</h4>
    <div class="cp-form">
      <ul class="field-list">
        <li class="field-item col-item">
          <div class="form-group">
            <label for="" class="form-label">User Name</label>
            <div class="input-group">
              <input type="text" pInputText class="form-control"  id="username" name="username" [(ngModel)]="username"  #userInput="ngModel" autocomplete="off"  placeholder="Enter User Name">
            </div>
          </div>
		  
		   <div *ngIf="userInput.errors && (userInput.dirty || userInput.touched)" class="alert alert-danger" >
                    <div [hidden]="!userInput.errors.required" class="login-error">
                     User name is required.
                    </div>
                    <div [hidden]="!userInput.errors.minlength" class="login-error">
                      Please provide User name.
                    </div>
                    <div [hidden]="!userInput.errors.maxlength" class="login-error">
                      User name cannot be more than 10 characters long.
                    </div>
               </div>
        </li>
        <li class="field-item col-item">
          <div class="form-group with-icon">
            <label for="" class="form-label">Password</label>
            <div class="input-group">
              <input type="{{ showPwd ? 'text' : 'password' }}" value="" pInputText class=" form-control"  minlength=1 maxlength=10  id="password" name="password" [(ngModel)]="password"   placeholder="Enter password" #userPassword="ngModel" autocomplete="off">
            </div>
          </div>
		  
		   <div *ngIf="userPassword.errors && (userPassword.dirty || userPassword.touched)" class="alert alert-danger" >
			         <div *ngIf="userPassword.errors.pattern" class="login-error">
                      Please provide a password.
                    </div>
                    <div [hidden]="!userPassword.errors.required" class="login-error">
                      password is required!
                    </div>
                    <div [hidden]="!userPassword.errors.maxlength" class="login-error">
                      password be more than 10 characters long.
                    </div>
					 <div [hidden]="panCardError" class="login-error">
                     {{pancardMsg}} 
                    </div>
               </div>
         
        </li>
		<li class="field-item col-item">
          <div class="form-group ">
              <label for="" class="form-label"><input type="checkbox" style="width:5%;margin-bottom:0px !important;height: 1.5rem;" [(ngModel)]="term" name="termandcondition" id="termandcondition" required #inputTerm="ngModel">    I agree to protect the confidentiality of the data I download from this portal.</label>
            <div class="input-group1">
            
				  
            </div>
          </div>
         <div *ngIf="inputTerm.errors && (inputTerm.dirty || inputTerm.touched)" class="alert alert-danger" >
			         <div *ngIf="inputTerm.errors.pattern" class="login-error">
                     Term and conditions is required!
                    </div>
                    <div [hidden]="!inputTerm.errors.required" class="login-error">
                     Term and conditions is required!
                    </div>
                    
               </div>	
        </li>
      </ul>
      <div class="btn-wrap">
		 <button class="btn btn-default" type="submit" id="submiLogin" [disabled]="!loginForm.form.valid" >Login</button>
      </div>
    </div>
  </div>
</form>

  
</div>
</div> -->

<div class="row">
  <div class="col-md-8 banner-image">

  </div>
  <div class="col-md-4 login-page">
         <!-- fleetsvg -->
    <div class="main-login">
      <div class="logo-img">
        <img src="/assets/images/fleetsvg.svg">
      </div>
      <div class="login-text">
        Hi, Welcome to Fleet Edge Portal!
      </div>
     
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="">
          <div class="login-label">Enter Your Email ID/Mobile Number*</div>
          <input formControlName="username" type="text" pInputText class="login-input form-control"  id="username" name="username"  autocomplete="off"  placeholder="Enter User Name">
           <!-- <div *ngIf="usernameStatus==false" class="login-user-error">
            <span class="login-error">User name is required.</span>
           </div> -->
           <div *ngIf="loginForm.controls.username?.touched && loginForm.controls.username.errors?.required" class="login-user-error">
            <span class="login-error">User name is required.</span>
           </div>
          <!-- <div class="login-user-error" *ngIf="userInput.errors && (userInput.dirty || userInput.touched)" class="alert alert-danger" >
            <div [hidden]="!userInput.errors.required" class="login-error">
             User name is required.
            </div>
            <div [hidden]="!userInput.errors.minlength" class="login-error">
              Please provide User name.
            </div>
            <div [hidden]="!userInput.errors.maxlength" class="login-error">
              User name cannot be more than 10 characters long.
            </div>
          </div> -->
          <!-- <label class="">Enter Your Email ID/Mobile Number*</label> -->
        </div>
        <div>
          

        <div class="login-label-pass">Password </div>
          <div class="input-group mb-3 login-input-pass">
          <input formControlName="password" type="{{ showPwd ? 'text' : 'password' }}" value="" pInputText class=" form-control"  minlength=1 maxlength=20  id="password" name="password"   placeholder="Enter password"  autocomplete="off">
          <span class="input-group-text" (click)="changepassType(showPwd)">
            <ng-template [ngIf]="showPwd==true">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              class="feather feather-eye align-middle me-2">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
              </svg>
            </ng-template>
            <ng-template [ngIf]="showPwd==false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              class="feather feather-eye-off align-middle me-2">
              <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24">
              </path>
              <line x1="1" y1="1" x2="23" y2="23"></line>
              </svg>
            </ng-template>
            <!-- <span *ngIf="showPwd"><i  class="align-middle me-2" data-feather="eye-off"></i></span> -->
            <!-- <span *ngIf="showPwd==true"><i  class="align-middle me-2" data-feather="eye-off"></i></span> -->
          </span>
          <div *ngIf="loginForm.controls.password?.touched && loginForm.controls.password.errors?.required" class="login-pass-error">
            <span class="login-error">Password is required.</span>
          </div>

          <!-- <div *ngIf="passwordStatus==false" class="login-pass-error">
            <span class="login-error">Password is required.</span>
           </div> -->
          <!-- <div class="login-pass-error" *ngIf="userPassword.errors && (userPassword.dirty || userPassword.touched)" class="alert alert-danger" >
			         <div *ngIf="userPassword.errors.pattern" class="login-error">
                      Please provide a password.
                    </div>
                    <div [hidden]="!userPassword.errors.required" class="login-error">
                      password is required!
                    </div>
                    <div [hidden]="!userPassword.errors.maxlength" class="login-error">
                      password be more than 10 characters long.
                    </div>
					           <div [hidden]="panCardError" class="login-error">
                     {{pancardMsg}} 
                    </div>
         </div> -->
          </div> 
        </div>
        <div class="chk-login">
          <label class="form-check m-0">
            <input  formControlName="acceptTerms"  name="termandcondition" id="termandcondition" required type="checkbox" class="form-check-input">
            <span class="form-check-label" style="margin-left:1%;width: 45ch;color: #333333;font-weight: 500;font-size:15px;"> I agree to protect the confidentiality of the data I download from this portal.</span>
          </label>
          <div class="login-error">
            <div *ngIf="loginForm.controls.acceptTerms?.touched && loginForm.controls.acceptTerms.errors?.required" class="login-error" style="color: red;">
              Term and conditions is required!
             </div>
          </div>
          <!-- <div *ngIf="inputTerm.errors && (inputTerm.dirty || inputTerm.touched)"  class="login-error" >
            <div *ngIf="inputTerm.errors.pattern" class="login-error" style="color: red;">
                  Term and conditions is required!
                 </div>
                 <div [hidden]="!inputTerm.errors.required" class="login-error" style="color: red;">
                  Term and conditions is required!
                 </div>
            </div>	 -->
        </div>
        <div class="">
          <!-- [disabled]="" -->
          <button type="submit" class="login-btn" >Login</button>
        </div>
      </form>
      <div class="forgot-pass">
        Forgot Password? <span style="color: #03045E;cursor: pointer;font-weight: 600;" ngbTooltip="Please raise a ticket to IT HELPDESK." class="my-custom-class">Click Here</span>
      </div>
      <div class="helpdesk-note-final">
        Facing Trouble Logging In? <span style="word-wrap:break-word;
        max-width:160px;color: #03045E;cursor: pointer;font-weight: 600;"  ngbTooltip="1800 209 7979">
        <a href="tel:1800 209 7979" class="helpdesk-cls">Talk to our HelpDesk</a></span>
      </div>
     
  </div>
</div>
<!-- <ngx-spinner type="ball-spin"></ngx-spinner> -->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>