<div class="lyt-main" id="lyt-main">


<section>
    <div class="cp-section">    	
	
              <div class="sec-head full-width-lw">		   
               <div class="left-wrap" style="margin:10px;">
                <h2 class="sec-title" >Event Data</h2>
            </div>
            <div class="right-wrap">
               
            </div>
			
			
			
			
			
        </div>
		<div class="sec-head full-width-lw" style="margin:10px;">
		   <div class="right-wrap ">
                <ul class="act-list typ-multiline">
				
				<li class="act-item typ-full">
					
					
								
							<!---------------------max="{{todate | date:'yyyy-MM-dd hh:mm'}}" ----------------------->	
								<div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <input [owlDateTimeTrigger]="dt10" [min]="min"  [max]="max" [owlDateTime]="dt10" [(ngModel)]="start_date" [selectMode]="'range'"  class="form-control" placeholder="Date & Time Range" (ngModelChange)="onEditStartDateChanged($event)" >
                                     <owl-date-time #dt10></owl-date-time>
									 
                                </div>
                            </div>
                        </div>
								
								
                        
                    </li>
                    
                    <li class="act-item typ-full">					
								
								
                        <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <input type="text"  [(ngModel)]="searchData" class="form-control" placeholder="VIN Number" (keyup)="applyFilter($event.target.value)" value="PROACC06022081648">
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="act-item">
                        <button class="btn btn-icon-text" (click)="fetchReport();" >
						<span class="icon icon-search"></span>Search</button>
						
						
						
                    </li>
                    <li class="act-item">
                         <button class="btn btn-icon-text" (click)="downloadAsExcelFile();" ><span class="icon icon-download"></span>Download</button>
                    </li>
                </ul>
            </div>
		</div>
		
        <div class="sec-cont">
            <div class="cp-card typ-table">
               <div class="mat-elevation-z8 table-responsive" *ngIf="resultCheck">
                <app-watermark [class.onerecord]="event_array.length<3"></app-watermark>
			  <table class="table table-bordered">
			   
				<thead>
				<tr>
					<!--th>Vin</th-->
					<th style="padding: 2rem 1rem 2rem 2.5rem;
                    color: #0a0a5f;
                    font-weight: 700;
                    font-size: 1.3rem;
                    line-height: 1.7rem;
                    text-align: left;
                    border: none;
                    background-color: #ffffff;">Event Type</th>
					<th>Event Count</th>
					<th >View Event Time </th>
				</tr>
				</thead>
				<tbody>
				
				<tr *ngFor="let item of event_array index as i">
					<!--td>{{item.VinNumber}}</td-            style="width:100px;word-wrap: break-word;" --->
					<td><div >{{item.EventName}}</div></td>
					<td><div >{{item.EventCount}} </div></td>
					<td><div > <!--button mat-button data-toggle="modal" data-target="#myModal" 
                         class="btn btn-secondary float-right btn-sm mr-2 view-vin"
                         (click)="(ShowEventTimeStamps(i))">Show Timestamps</button-->
						 
						  <span class="logout-btn" (click)="(ShowEventTimeStamps(i))" mat-button data-toggle="modal" data-target="#myModal"  style="height:100px;width:100px;">
        <span class="icon icon-more" style="height:100px;width:100px;font-size:24px"></span>
      </span>
	  
	  
	  <!--span class="logout-btn" (click)="(ShowEventTimeStamps(i))" mat-button data-toggle="modal" data-target="#myModal"  style="height:100px;width:100px;">
        <span class="icon icon-up-arrow" style="height:100px;width:100px;font-size:24px"></span>
      </span-->
	  
						 </div>
                    </td>
					
					
					
				</tr>
				</tbody>
			</table>	
			</div>
            </div>
        </div>
    </div>
   
</section>






  

<!-- The Modal -->
<div class="modal" id="myModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Vehicle Event Time</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
      <app-watermark  [class.onerecord-event]=true [class.onerecord]="!showVehicleSection || showTimeStramp.length<2" [singlerecord]="!showVehicleSection || showTimeStramp.length<2"></app-watermark>
        <table  style="width: 100%;" >  
			
			<tr *ngFor="let item of showTimeStramp index as i">
				<td colspan="2"><div >{{item}}</div></td>
			</tr>
                                
                             
                            </table>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>








<!--footer>
			Copyright &#169; 2020 TATA MOTORS All Rights Reserved.
		</footer-->