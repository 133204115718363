import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Observable } from 'rxjs';
import { map, window } from 'rxjs/operators';

import { LoginService } from './login.service';
import { AuthGuard } from './guards/auth.guard';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {HttpClient} from '@angular/common/http';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

  
// { path: 'plan', component: PlanComponent },];
export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'tata-motors';
  isLoggedIn$: Observable<boolean>;
  routesArray=[ 'login','/eventdata','/telemetrydata','/vehicle','/client','/clientapi','/vehiclecandata','/vehiclefuel','/ignition','/client-inner-page','/bulk-report'];
  public config: SwiperConfigInterface = {
    slidesPerView: 3,
    spaceBetween: 20
  }
  public show: boolean = false;
  vehCurr = 25000;
  vehTot = 45000;
  calVehHealth() {
    return {
      'transform': 'translateX(-100%) rotate(' + (this.vehCurr / this.vehTot) * 180 + 'deg)'
    }
  }

  public isLogin;
  showHead: boolean = false;
  showBackGround: boolean = true;
  bgClasname = "lyt-main";
  constructor(private idle: Idle, private keepalive: Keepalive,private router: Router, private activatedRoute: ActivatedRoute, public _loginService: LoginService, private spinner: NgxSpinnerService, private authService: AuthGuard) {
    // on route change to '/login', set the variable showHead to false
    // const id: string = route.snapshot.params.id;
    //const url: string = route.snapshot.url.join(''); 
    router.events.forEach((event) => {
      let token=localStorage.getItem('token');
      if (event instanceof NavigationStart) {
        if (event['url'] == '/login' || event['url']=='/') {
          this.showHead = false;
          this.showBackGround = true;
          this.bgClasname = "lyt-main";
        } else if (this.routesArray.includes(event['url'])&& token!=='' && token!==null && token!==undefined) {
          this.showHead = true;
          this.showBackGround = true;
          this.bgClasname = "lyt-main";
          
        } else {
          this.onIdleScreen();
          // console.log("NU")
          //  var refresh_time =localStorage.getItem('refresh_time');
          var refresh_time = Date.parse(localStorage.refresh_time);
          var today = new Date();
          var endDate = new Date(refresh_time);
          var seconds = (today.getTime() - endDate.getTime()) / 1000;

          if (seconds > 1800) {
            alert("Idle time has been expired!");
            this.showHead = false;
            this.showBackGround = true;
            this.bgClasname = "lyt-main";
            localStorage.clear();
            // window.location.href = "/";
          } else {

            localStorage.refresh_time = today;
            this.showHead = false;
            this.showBackGround = true;
            this._loginService.isLoggedIn=true;
            //this.bgClasname = "lyt-main typ-login";
            this.bgClasname = "lyt-main-content";
          }
        }
      }
    });
  }
  // showSpinner() {
  //   this.spinner.show();
  //   setTimeout(() => {
  //     /** spinner ends after 5 seconds */
  //     this.spinner.hide();
  //   }, 5000);
  // }
  ngOnInit() {
  }
  onIdleScreen(): any {
      // sets an idle timeout of 5 seconds, for testing purposes.
      this.idle.setIdle(3600);
      // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
      this.idle.setTimeout(3600);
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      this.idle.onIdleEnd.subscribe(() => (this.idleState = 'No longer idle.'));
      this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        alert("You've gone idle! ");
        localStorage.clear();
        this.router.navigateByUrl("/login");
        // window.reload();
        this.timedOut = true;
      });
      this.idle.onIdleStart.subscribe(() => (this.idleState = "You've gone idle!"));
      this.idle.onTimeoutWarning.subscribe(
        (countdown) =>
          (this.idleState = 'You will time out in ' + countdown + ' seconds!')
      );
      // sets the ping interval to 15 seconds
      this.keepalive.interval(3600);
      this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
      this.reset();
  }
  checkLogin() {
    return this._loginService.isLoggedInUser().pipe(map(res => {
      alert(res);
      if (res == false) {
        this.showHead = false;
        this.showBackGround = true;
        this.router.navigate(['/login']);
        return false;
      }
      if (res == true) {

        this.showHead = true;
        this.showBackGround = true;
        console.log(this.showHead);
        this.bgClasname = "lyt-main";
        return true;
      } else {
        this.showHead = false;
        this.showBackGround = true;
        this.bgClasname = "lyt-main";
        this.router.navigate(['/login']);
        return false;
      }
    }));

  }
  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }

}
