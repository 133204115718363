import { Component, OnInit,ViewChild } from '@angular/core';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';

import { BaseApiService } from 'src/app/base-api.service';
import { NgxSpinnerService } from 'ngx-spinner';


import { RouterModule, Router } from '@angular/router';
import * as XLSX from 'xlsx';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

declare var myExtObject: any;
declare var webGlObject: any;

@Component({
  selector: 'app-event-data',
  templateUrl: './event-data.component.html',
  styleUrls: ['./event-data.component.scss']
})
export class EventDataComponent implements OnInit {
	 constructor(private router: Router,private baseAPI: BaseApiService, public spinnerService: NgxSpinnerService,private http: HttpClient) {
		   var token =localStorage.getItem('token');
	       
			if(token==undefined || token==='' ){
			  this.router.navigate(['/login']);
			}
			
			 webGlObject.init();

		 }
	 public selectedMoment = new Date();
	//@ViewChild('form') form;
	pageOfItems = [];
	 editStartDate:string;
	 showTimeStramp:string;
     editEndDate:string;
	 start_date:string;
	 end_date:string;
	 
	searchData:string;
	resultCheck:boolean = false; 
	openDataModal:boolean = false; 
	
	event_array= [];
	
	 globalData:any=[];
	//objEventData: EventData[] = [];

	//dataSource: MatTableDataSource<EventData>;
	//TimestampdataSource: MatTableDataSource<any>;
	//timestampArray: EventDateTypeArray[];

	displayedColumns: string[] = ['EventType', 'Count', 'ShowTimestamps'];
	displayedColumnsTimestamps: string[] = ['Timestamp'];
	
	 public min = new Date(2018, 1, 12, 10, 30);
	 
	

    // Max moment: April 21 2018, 20:30
	public yearMax=this.getCurrentDay('Y');
	public monthMax=this.getCurrentDay('M');
	public dateMax=this.getCurrentDay('D');
	private todate = new Date();
	 public Hours=this.todate.getHours();
    public getMinutes=this.todate.getMinutes();
	
    //public max = new Date(this.yearMax, this.monthMax, this.dateMax, this.Hours, this.getMinutes);
	
	public max = new Date(2020, 7, 21, 20, 30);
	
	
	
	 public myDate: IMyDate = {

    year: this.getCurrentDay('Y'),
    month: this.getCurrentDay('M'),
    day: this.getCurrentDay('D'),
  }
	
  getCurrentDay(cat): any {
    let output;
    var today = new Date();
    var day = today.getDate();
	 var month = today.getMonth() + 1; //January is 0!
    if (day != 1) {
     // day = day - 1;
	  // day = day ;
    }else{
		if(month==5 || month==7 || month==10 || month==12)
		{
			day=30;
		}
		
		if(month==2 || month===4 || month==6 || month==8 ||month==9 || month==11 || month==1)
		{
			day=31;
		}
		
		if (year % 4 == 0 && month==3) 
		{
			day=29;
		}
		else if (year % 4 != 0 && month==3) 
		{
			day=28;
		}
		month=month-1;
	}

   
	//alert(day+"#"+month);
    var year = today.getFullYear();

    if (cat === 'D') {
      output = day;
    }
    else if (cat === 'M') {
      output = month;
    }
    else {
      output = year;
    }
    return output;
  }
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    markCurrentDay: true,
   // disableUntil: this.myDate  vinay this is for disble 
   disableSince :  this.myDate
   

  };
  
  
  
  
  
     compareDate()
	{
		
		
		var startDate = new Date(this.editStartDate);
		var endDate = new Date(this.editEndDate);
		var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
		var stString=Date.parse(startDate.toUTCString());
		var edString=Date.parse(endDate.toUTCString());
	  if (endDate < startDate || seconds>86400) 
	  {
			alert("Only 24 hours data is allowed");
			this.editStartDate=null;
			this.editEndDate=null;	
            this.start_date="";			
	  }else if (edString ==stString) //86400
	  {
		alert("Start date and end date cannot be same");
		this.editStartDate=null;
			this.editEndDate=null;	
            this.start_date="";		
	  }else if(this.editEndDate!=null && this.editStartDate!=null)
		{
			
			
			 this.editStartDate=this.convert(this.editStartDate);
	         this.editEndDate=this.convert(this.editEndDate);
			 
			 
		}
	}
  convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    var Hours=date.getHours();
    var getMinutes=date.getMinutes();
	
	var returnDtae=[date.getFullYear(), mnth, day].join("-");
	returnDtae=returnDtae+"T"+Hours+":"+getMinutes+":00";
  return returnDtae;
}

  onEditStartDateChanged(event) {
	 

   var splitedDate=this.start_date.toString().split(",");
	 
	  this.editStartDate=splitedDate[0];
	  this.editEndDate=splitedDate[1];
	  

	 
		if(this.editEndDate!=null && this.editStartDate!=null)
		{
			this.compareDate();
			
			
		}
		
		
	 }
	 
	applyFilter(event)
	{
		
		
		
	}
	 fetchReport() {
		
		 
		 
	  
	if(this.editStartDate === undefined && this.editEndDate !== undefined )
	{
		alert('Please enter proper date in the Start date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate !== undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate === undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the Start date and End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}

    if ((this.editStartDate === undefined || this.editStartDate === '' || this.editStartDate === null) ||
      (this.editEndDate === undefined || this.editEndDate === '' || this.editEndDate === null) ||
      (this.searchData === undefined || this.searchData === '' || this.searchData == '' || this.searchData == null ) ) {
        alert('Please fill all mandatory fields.');

    }
    else {

     
     

		if (window.confirm("Do you want to fetch report?")) 
	   {
		   
		    this.callReportApi();
           // alert("Notification created successfully");
			//this.reset1();
        } 
		else 
		{
            alert("Report serach is failed");
        }

    }
  }
	
	
	//==================================================================
	
	callReportApi() {
    this.spinnerService.show();
    let formData: FormData = new FormData();   
    formData.append('searchData', this.searchData);    
    formData.append('start_date', this.editStartDate);
    formData.append('end_date', this.editEndDate);
	var datastring="event/"+this.searchData+"?fromDate="+this.editStartDate+"&toDate="+this.editEndDate;
	
	 myExtObject.firebaseEvent('Event Data', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"Search Button Click"});
	this.baseAPI.callServiceApi(this.baseAPI.baseUrl,datastring).then((data:any) => {     
	 this.resultCheck=false;
	 this.event_array=[];
	  let tempEvent:any;     
      var employees = {};
	   if(data.Information!=undefined)
	  {
		  
		  
		  alert(data.Information);
		  this.spinnerService.hide();
	  }else{
		  
      employees["vinnumber" ] = Object.keys(data);
      employees["evetvalues"] = <any>Object.values(data);
      employees["evetdataheader"] = Object.keys(employees["evetvalues"][0]);
      employees["evetdata" ] = <any>Object.values(employees["evetvalues"][0]);
      this.event_array = [];
     for(let ii = 0;ii<employees["evetdataheader"].length;ii++)
      {
        if(ii%2==0){
        tempEvent ={
          VinNumber :employees["vinnumber" ],
          EventName: employees["evetdataheader"][ii],
          EventCount: employees["evetdata"][ii],
          EventDateType: employees["evetdata"][ii + 1]
        };
        this.event_array.push(tempEvent);
      }
    }
	
	  console.log(JSON.stringify(this.event_array));
	   this.spinnerService.hide();
	   if(this.event_array.length>0)
	   {
		   var jsonObj = JSON.parse(JSON.stringify(this.event_array));
		   this.pageOfItems =jsonObj;
		   
		   this.resultCheck=true;
	   }else{
		   
		   alert("No Records Found");
	   }
	  
	  
	  }
	  
	  //this.dealerOffset=this.dealerOffset+21;
    }, (err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert("Error:"+message.error);
      }
      else {
        alert('Please try again');
      }
    });	

  }
  
  
  downloadAsExcelFile() {
	  
	  if(this.editStartDate === undefined && this.editEndDate !== undefined )
	{
		alert('Please enter proper date in the Start date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate !== undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate === undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the Start date and End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}

    if ((this.editStartDate === undefined || this.editStartDate === '' || this.editStartDate === null) ||
      (this.editEndDate === undefined || this.editEndDate === '' || this.editEndDate === null) ||
      (this.searchData === undefined || this.searchData === '' || this.searchData == '' || this.searchData == null ) ) {
        alert('Please fill all mandatory fields.');

    }
    else {

     
     

		if (window.confirm("Do you want to download report?")) 
	   {
		   
		    this.callReportXLApi();
           // alert("Notification created successfully");
			//this.reset1();
        } 
		else 
		{
            alert("Report download has been cancelled");
			
        }

    }

    
  } 
  
  
   callReportXLApi() {
	  
this.spinnerService.show();
   var datastring="event/"+this.searchData+"?fromDate="+this.editStartDate+"&toDate="+this.editEndDate;
	var userName=localStorage.getItem('username');
	 myExtObject.firebaseEvent('Event Data', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"Excel Download Click"});
	//this.baseAPI.callServiceApi(this.baseAPI.baseUrl,datastring).then(data => {
 
setTimeout(() => {
   
	this.baseAPI.callServiceApi(this.baseAPI.baseUrl,datastring).then((data:any) => {     
	 
	  if(data.Information!=undefined)
	  {
		  
		  
		  alert(data.Information);
		  this.spinnerService.hide();
	  }else{
			let tempEvent:any;     
			var employees = {};
			let jsonObj2 = {}; 
			this.globalData=[];

      employees["vinnumber" ] = Object.keys(data);
      employees["evetvalues"] = <any>Object.values(data);
      employees["evetdataheader"] = Object.keys(employees["evetvalues"][0]);
      employees["evetdata" ] = <any>Object.values(employees["evetvalues"][0]);
     // this.event_array = [];
     for(let ii = 0;ii<employees["evetdataheader"].length;ii++)
      {
        if(ii%2==0){
       /* tempEvent ={
          VinNumber :employees["vinnumber" ],
          EventName: employees["evetdataheader"][ii],
          EventCount: employees["evetdata"][ii],
          EventDateType: employees["evetdata"][ii + 1]
        };*/
          //jsonObj2["CA Name"]  = employees["vinnumber" ];
		  jsonObj2["Event Type"]=employees["evetdataheader"][ii];
		  jsonObj2["Event Count"]=employees["evetdata"][ii];
		  jsonObj2["Timestamp"]=employees["evetdata"][ii + 1].toString();
		  
		  
		  
		  
		
		  
		  this.globalData.push(jsonObj2);
		   jsonObj2 = {}; 
      }
    }  
	     this.spinnerService.hide();
        if(this.globalData.length>0)
	   {
		   
	       this.generateXl();
	   }else{
		   
		   alert("No Records Found");
	   }	
	   
	  }
	  
	  //this.dealerOffset=this.dealerOffset+21;
    }, (err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert(message);
      }
      else {
        alert('Please try again');
      }
    });	
	
 
}, 5000);
  setTimeout(() => {
   
  }, 10000);

    
  } 
 
  
generateXl()
{
	 
		this.spinnerService.show();
		var ws = XLSX.utils.json_to_sheet(this.globalData);
		var wb = XLSX.utils.book_new(); XLSX.utils.book_append_sheet(wb, ws, "Target Data");
		var datastring="event_"+this.searchData+"_"+this.editStartDate+"_"+this.editEndDate+".xlsx";
		XLSX.writeFile(wb, datastring);
		this.globalData = [];
		var a = 0;
		this.spinnerService.hide();
	
}

showVehicleSection:boolean=false;
 ShowEventTimeStamps(vinnumber:string)
    {
		
		
		
		this.showTimeStramp=this.event_array[vinnumber].EventDateType;
		this.showVehicleSection=true;
		console.log(this.showTimeStramp.length);
      // let result: any = this.event_array.find(x => x.VinNumber === vinnumber && x.EventName==eventName select );
        this.openDataModal=true;
  }
 

  ngOnInit() {
	 //  this.monthMax=this.monthMax-1;
	   this.max= new Date();
	   //this.max = new Date(this.yearMax, this.monthMax, this.dateMax, this.Hours, this.getMinutes);
	
	 // this.spinnerService.show();  
  }

}
