
<div data-theme="default" data-layout="fluid" data-sidebar-position="left" data-sidebar-behavior="sticky">
	<div class="wrapper">
	<app-sidebar></app-sidebar>
	<div class="main">
	<app-header></app-header>
	<main class="content">
		<div class="container-fluid p-0">
			<div class="row mb-2 mb-xl-3">
			<div class="col-auto">
				<h3 class="dash-text">
				<svg [routerLink]="isloggedUser==1 ? ['/rsmdashboard']:['/nationalhead-dashboard']" xmlns="http://www.w3.org/2000/svg" 
				width="24" height="24" viewBox="0 0 24 24"
				fill="none" stroke="currentColor" stroke-width="2"
				stroke-linecap="round" stroke-linejoin="round"
				class="feathers feather-chevron-left align-middle">
				<polyline points="15 18 9 12 15 6"></polyline>
				</svg>
				<span style="margin-left: 2px;">FEC Attributes</span>
			</h3>
			</div>
			<div class="col-auto ms-auto text-end mt-n1-1">
			<button class="btn download-master-btn" (click)="downloadMasterData()">
			<!-- <i class="align-middle" data-feather="download">&nbsp;&nbsp;</i> -->
			<svg xmlns="http://www.w3.org/2000/svg" 
			width="24" height="24" viewBox="0 0 24 24"
			fill="none" stroke="currentColor" stroke-width="2"
			stroke-linecap="round" stroke-linejoin="round"
			class="feather feather-download align-middle">
			<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
			<polyline points="7 10 12 15 17 10"></polyline>
			<line x1="12" y1="15" x2="12" y2="3"></line>
			</svg>
			<span style="white-space: nowrap;margin-left: 4px;">Master</span>
			</button>
			<button class="btn download-list-btn" (click)="downloadListData()">
				<!-- <i class="align-middle" data-feather="download">&nbsp;&nbsp;</i> -->
				<svg xmlns="http://www.w3.org/2000/svg" 
				width="24" height="24" viewBox="0 0 24 24"
				fill="none" stroke="currentColor" stroke-width="2"
				stroke-linecap="round" stroke-linejoin="round"
				class="feather feather-download align-middle">
				<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
				<polyline points="7 10 12 15 17 10"></polyline>
				<line x1="12" y1="15" x2="12" y2="3"></line>
				</svg>
				<span style="white-space: nowrap;margin-left: 4px;">List</span>
			</button>
			<button class="btn date-btn" (click)="openMonthModel(mySelectMonthModal)">
				<span class="icon icon-calendar"></span>
				<span class="year-text" style="white-space: nowrap;margin-left: 4px;">
				<!-- {{selectedDate | date:'dd'}}  -->
				{{selectedDate | date:'MMM'}} {{selectedDate | date:'YYYY'}}
				</span>
			</button>
			</div>
			</div>
		</div>
		<!-- reomved container here -->
		<div class=" card bg-light-fig py-2 py-md-3 border m1-left">
		<div class="row">
			<div class="col-sm-2">
				<img src="assets/images/user-pic-1.png" class="img-fluid rounded-circle mb-2 " width="60" height="60">
			</div>
			<div class="col-sm-10">
				<div class="col-8 col-sm-12">
					<div class="row">
					<div class="col-sm-3 mobile-width-user">
						<div class="name1">Full Name</div>
						<div class="name2">{{FecData?.postn_pr_emp_fst_name}} {{FecData?.postn_pr_emp_last_name}}</div>
					</div>
					<div class="col-sm-3 mobile-width-user">
						<div class="name1">Email ID</div>
						<div class="name2">rktalati02@gmail.com</div>
					</div>
					<div class="col-sm-3 mobile-width-user">
						<div class="name1">Dealer Name</div>
						<div class="name2">{{FecData?.org_name}}</div>
					</div>
					<div class="col-sm-3 mobile-width-user contact-mb">
						<div class="name1">Mobile</div>
						<div class="name2">{{FecData?.postn_pr_emp_cell_ph_num}}</div>
					</div>
				</div>
					</div>
					<div class="row-divivder"></div>
					<div class="col-4 col-sm-12">
					<div class="row">
						<div class="col-sm-3 mobile-width-user">
							<div class="name3">Date Of Joining</div>
							<div class="name4">{{FecData?.assigned_date }}</div>
						</div>
						<div class="col-sm-3 mobile-width-user">
							<div class="name3">Total Tasks Assigned</div>
							<div class="name4">{{FecData?.total_task_assigned}}</div>
						</div>
					</div>
					</div>
			</div>
		</div>
		</div>
		<div class="row">
		<div class="col-12 col-lg-9 d-none d-sm-block visible-xs-block, hidden-xs visible-sm-block, hidden-sm">
		<div class="tab-wrapper">
			<div id="carouselExampleControltab"  class="carousel  carousel-dark"  data-bs-interval="false">
			<div class="carousel-inner">
			<div class="carousel-item active">
			<div class="inner-tab-wrapper">
			<!-- <div class="link-btn active">Fleet Edge Champs</div>
			<div class="link-btn">Assigned Visits</div> -->
			<div class="link-btn" [ngClass]="(currentStatusTab=='completed')? 'active': ''" >
				<span (click)="getSelectedStatusTabData('completed', FecData?.completed)">
				Completed Visits({{completedVisits}})
				</span>
			</div>
			<div class="link-btn" [ngClass]="(currentStatusTab=='scheduled')? 'active': ''">
				<span (click)="getSelectedStatusTabData('scheduled', FecData?.scheduled)">
				Scheduled Visits({{scheduledVisits}})
				</span>
				</div>
			<div class="link-btn" [ngClass]="(currentStatusTab=='unscheduled')? 'active': ''">
				<span (click)="getSelectedStatusTabData('unscheduled', FecData?.unscheduled)">
					Unscheduled Visits<b>({{unscheduledVisits}})</b>
				</span>
			</div>
			<div class="link-btn" [ngClass]="(currentStatusTab=='overdue')? 'active': ''">
			<span (click)="getSelectedStatusTabData('overdue', FecData?.overdue)">
				Overdue Visits({{overdueVisits}}) {{FecData?.overdue | number:'2.0':'fr'}}</span>
			</div>
			</div>
			</div>
			</div>
			</div>
		</div>
		</div>
		<!-- mobile view -->
		<div class="col-12 col-lg-9 d-block d-sm-none" >
			<div style="display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 10px;
			gap: 10px;
			grid-auto-rows: minmax(35px, auto);
			overflow-x: scroll;
			-webkit-overflow-scrolling: touch;">
			<div class="link-btn" [ngClass]="(currentStatusTab=='completed')? 'active': ''" >
				<span (click)="getSelectedStatusTabData('completed', FecData?.completed)">
				Completed Visits({{completedVisits}})
				</span>
			</div>
			<div class="link-btn" [ngClass]="(currentStatusTab=='scheduled')? 'active': ''">
				<span (click)="getSelectedStatusTabData('scheduled', FecData?.scheduled)">
				Scheduled Visits({{scheduledVisits}})
				</span>
				</div>
			<div class="link-btn" [ngClass]="(currentStatusTab=='unscheduled')? 'active': ''">
				<span (click)="getSelectedStatusTabData('unscheduled', FecData?.unscheduled)">
					Unscheduled Visits<b>({{unscheduledVisits}})</b>
				</span>
			</div>
			<div class="link-btn" [ngClass]="(currentStatusTab=='overdue')? 'active': ''">
			<span (click)="getSelectedStatusTabData('overdue', FecData?.overdue)">
				Overdue Visits({{overdueVisits}}) {{FecData?.overdue | number:'2.0':'fr'}}</span>
			</div>

			</div>
			<div class="tab-wrapper" style="display: none;">
				<div id="carouselExampleControltab1"  class="carousel  carousel-dark"  data-bs-interval="false">
				<div class="carousel-inner">
				<!-- <div class="carousel-item active">
				<div class="inner-tab-wrapper">
				<div class="link-btn active">Fleet Edge Champs</div>
				<div class="link-btn">Assigned Visits</div>
				
				</div> -->
				<!-- </div> -->
				<div class="carousel-item active">
				<div class="inner-tab-wrapper">
				<div class="link-btn" [ngClass]="(currentStatusTab=='completed')? 'active': ''" >
				<span (click)="getSelectedStatusTabData('completed', FecData?.completed)">
					Completed Visits({{completedVisits}})
				</span>
				</div>
				<div class="link-btn" [ngClass]="(currentStatusTab=='scheduled')? 'active': ''">
				<span (click)="getSelectedStatusTabData('scheduled', FecData?.scheduled)">
				Scheduled Visits({{scheduledVisits}})
				</span>
				</div>
				</div>
				</div>
				<div class="carousel-item">
				<div class="inner-tab-wrapper">
				<div class="link-btn" [ngClass]="(currentStatusTab=='unscheduled')? 'active': ''">
				<span (click)="getSelectedStatusTabData('unscheduled', FecData?.unscheduled)">
				Unscheduled Visits({{unscheduledVisits}})
				</span>
				</div>
				<div class="link-btn" [ngClass]="(currentStatusTab=='overdue')? 'active': ''">
				<span (click)="getSelectedStatusTabData('overdue', FecData.overdue)">
					Overdue Visits(0) {{FecData?.overdue | number:'2.0':'fr'}}</span>
				</div>
				</div>
				</div>
				</div>
				<a class="carousel-control-prev" href="#carouselExampleControltab1" role="button" data-bs-slide="prev">
					<span class="carousel-control-prev-icon" aria-hidden="true"></span>
					<span class="sr-only">Previous</span>
					</a>
					<a class="carousel-control-next" href="#carouselExampleControltab1" role="button" data-bs-slide="next">
					<span class="carousel-control-next-icon" aria-hidden="true"></span>
					<span class="sr-only">Next</span>
					</a>
				</div>
			</div>
		</div>
		
		<div class="col-12 col-lg-3 mobile-padding-search">
			<div class="col-auto ms-auto text-end mt-n1" style="display: flex;">
				<div>
				<div class="input-group input-group-search input-group-width">
					<button class="btn" type="button">
					<!-- <i class="align-middle" data-feather="search"></i> -->
					<svg xmlns="http://www.w3.org/2000/svg" 
					width="24" height="24" viewBox="0 0 24 24"
					fill="none" stroke="currentColor" stroke-width="2"
					stroke-linecap="round" stroke-linejoin="round"
					class="feather feather-search align-middle">
					<circle cx="11" cy="11" r="8"></circle>
					<line x1="21" y1="21" x2="16.65" y2="16.65"></line>
					</svg>
					</button>
					<input type="text" class="form-control" placeholder="Company Name" [(ngModel)]="searchTerm">
					</div>
				</div>
				&nbsp;
				<div [hidden]="isloggedUser==3">
					<button class="btn task-button" type="submit" routerLink="/customerdetails">Assign Task</button>
				</div>
				</div>
		</div>
		</div>
		<br>
		<div class="row">
		<div class="table-responsive">
		<table class="table">
			<thead>
				<tr class="table_heading">
					<th>Sr.No</th>
					<th>Company Name</th>
					<th>Account Type </th>
					<th>Industry</th>
					<!-- Completed Visits -->
					<th *ngIf="(currentStatusTab=='completed')">Completion Date</th>
					<th *ngIf="(currentStatusTab=='completed')">Action</th>
					<!-- Scheduled Visits -->
					<th *ngIf="(currentStatusTab=='scheduled')">Scheduled On</th>
					<th *ngIf="(currentStatusTab=='scheduled')">Action</th>
					<!-- Unscheduled Visits -->
					<th *ngIf="(currentStatusTab=='unscheduled')">Assigned Date</th>
					<th *ngIf="(currentStatusTab=='unscheduled')">Action</th>
					<!-- Overdue Visits -->
					<th *ngIf="(currentStatusTab=='overdue')">Overdue</th>
					<th *ngIf="(currentStatusTab=='overdue')">Action</th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngIf="(newData && newData.length)">
				<tr class="table_data"
					*ngFor="let item of newData | filter: searchTerm ;index as i">
						<!--  <td>{{ (tempPage*itemsPerPage) + (i+1)}}</td> -->
						<td>{{i+1+currentPage}}</td>
						<td>{{item.company_name}}</td>
						<td>{{item.account_type}}</td>
						<td>{{item.industry_type}}</td>
						<!-- Completed Visits -->
						<td *ngIf="(currentStatusTab=='completed')">
							<ng-container *ngIf="item.completed_date">
							{{item.completed_date | date:'dd'}} {{item.completed_date | date:'MMM'}} {{item.completed_date | date:'YYYY'}}, {{item.completed_date | date:'H'}}:{{item.completed_date | date:'mm'}} {{item.completed_date | date:'a'}}
							</ng-container>
							<ng-container *ngIf="!(item.completed_date)">-</ng-container>
						</td>
						<td *ngIf="(currentStatusTab=='completed')">
							<a  (click)="ViewHistory(item,historyTsk)">
							<b class="view-history"><span>View History
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
								stroke="currentColor" stroke-width="2" stroke-linecap="2" stroke-linejoin="round"
								class="feather feather-chevron-right align-middle me-2">
								<polyline points="9 18 15 12 9 6"></polyline>
								</svg>
								</span>
							</b>
								</a>
						</td>
						<!-- Scheduled Visits -->
						<td *ngIf="(currentStatusTab=='scheduled')">
							<ng-container *ngIf="item.scheduled_date">
							{{item.scheduled_date | date:'dd'}} {{item.scheduled_date | date:'MMM'}} {{item.scheduled_date | date:'YYYY'}}, {{item.scheduled_date | date:'H'}}:{{item.scheduled_date | date:'mm'}} {{item.scheduled_date | date:'a'}}
							</ng-container>
							<ng-container *ngIf="!(item.scheduled_date)">-</ng-container>
						</td>
						<td *ngIf="(currentStatusTab=='scheduled')">
						</td>
						<!-- Unscheduled Visits -->
						<td *ngIf="(currentStatusTab=='unscheduled')">
							<ng-container *ngIf="item?.fec_assign[0]?.creation_date">
								{{item.fec_assign[0].creation_date | date:'dd'}}/{{item.fec_assign[0].creation_date | date:'MM'}}/
								{{item.fec_assign[0].creation_date | date:'YYYY'}}								
							</ng-container>
							<ng-container *ngIf="!(item.fec_assign[0].creation_date)">-</ng-container>
						</td>
						<td *ngIf="(currentStatusTab=='unscheduled')" [hidden]="isloggedUser==3">
							<a (click)="deleteFECData(item,1)" class="delete-cls">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
							stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
							class="feather feather-trash-2 align-middle me-2">
							<polyline points="3 6 5 6 21 6"></polyline>
							<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
							</path>
							<line x1="10" y1="10" x2="10" y2="17"></line>
							<line x1="14" y1="11" x2="14" y2="17"></line>
							</svg>
							</a>
						</td>
						<!-- Overdue Visits -->
						<td *ngIf="(currentStatusTab=='overdue')">
							<ng-container *ngIf="item.overdue_date">
							{{item.overdue_date | date:'dd'}} {{item.overdue_date | date:'MMM'}} {{item.overdue_date | date:'YYYY'}}, {{item.overdue_date | date:'H'}}:{{item.overdue_date | date:'mm'}} {{item.overdue_date | date:'a'}}
							</ng-container>
							<ng-container *ngIf="!(item.overdue_date)">-</ng-container>
						</td>
						<td *ngIf="(currentStatusTab=='overdue')">
							<!-- <a (click)="deleteFECData(item.id,3)" class="delete-cls">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
							stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
							class="feather feather-trash-2 align-middle me-2">
							<polyline points="3 6 5 6 21 6"></polyline>
							<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
							</path>
							<line x1="10" y1="10" x2="10" y2="17"></line>
							<line x1="14" y1="11" x2="14" y2="17"></line>
							</svg>
							</a> -->
						</td>
				</tr>
				</ng-container>
				<ng-container *ngIf="!(newData && newData.length)">
				<tr class="table_data">
					<td class="norecords" colspan="5">No Records Found</td>
				</tr>
				</ng-container>
			</tbody>
		</table>
		</div>
		<div class="" style="float:right;">
			<ngb-pagination [collectionSize]="collectionSize" [(page)]="page1" [pageSize]="pageSize" (pageChange)="getChangeTableData();">
			</ngb-pagination>
			</div>
		</div>
	</main>
	</div>
	</div>
</div>

<div class="modal fade" id="ViewHistory" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog " role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title history-heading">View History</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="second-heading">
				<div class="row">
					<div class="col-sm-3">
                        <div class="history-heading">Customer Name</div>
						<div class="history-heading-second">Speed Transports</div>
					</div>
					<div class="col-sm-3">
                        <div class="history-heading">Customer Name</div>
						<div class="history-heading-second">Speed Transports</div>
					</div>
					<div class="col-sm-3">
                        <div class="history-heading">Customer Name</div>
						<div class="history-heading-second">Speed Transports</div>
					</div>
					<div class="col-sm-3">
                        <div class="history-heading">Customer Name</div>
						<div class="history-heading-second">Speed Transports</div>
					</div>
				</div>
                <!-- <div>1</div>
				<div>2</div>
				<div>3</div>
				<div>4</div> -->
			</div>
			<div class="modal-body">
				<div class="accordion" id="accordionFaq">
					<div class="card border mb-3">
						<div class="card-header cursor-pointer" id="faqTwo" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
							<h6 class="mb-0">
								#Visit1 - 16/01/2023
							</h6>
						</div>
						<div id="collapseTwo" class="collapse" aria-labelledby="faqTwo" data-bs-parent="#accordionFaq">
							<div class="card-body py-3">
								<div class="row">
									<div class="col-sm-3">
										<div class="history-heading">SPOC Name</div>
										<div class="history-heading-second">Speed Transports</div>
									</div>
									<div class="col-sm-3">
										<div class="history-heading">SPOC Contact</div>
										<div class="history-heading-second">Speed Transports</div>
									</div>
									<div class="col-sm-3">
										<div class="history-heading">Customer Name</div>
										<div class="history-heading-second">Speed Transports</div>
									</div>
									<div class="col-sm-3">
										<div class="history-heading">SPOC Designation</div>
										<div class="history-heading-second">Speed Transports</div>
									</div>
								</div>
								<div class="row">
									<div class="feedback-one">Feedback</div>
									<div class="pg">
									 The customer is a daily user of FleetEdge but he suggests that it should be
                                     more user friendly and the load time can be bettered.
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="card border mb-3">
						<div class="card-header cursor-pointer" id="faqThree" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
							<h6 class="mb-0">
								Is there any support included?
							</h6>
						</div>
						<div id="collapseThree" class="collapse" aria-labelledby="faqThree" data-bs-parent="#accordionFaq">
							<div class="card-body py-3">
								You have access to the Software's online support services via email for six (6) months from the purchase date. Please contact us at <a href="mailto:info@bootlab.io">info@bootlab.io</a>									for any questions.
							</div>
						</div>
					</div>
					
				</div>
			</div>
			
		</div>
	</div>
</div>

<ng-template #historyTsk let-modal>
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title history-heading">View History</h5>
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeCompletepopup()"></button>
		</div>
		<div class="second-heading">
			<div class="row">
				<div class="col-sm-3">
					<div class="history-heading">Company Name</div>
					<div class="history-heading-second">{{viewHistorySingleDetail.company_name=='' || viewHistorySingleDetail.company_name==null ?'-':viewHistorySingleDetail.company_name}}</div>
				</div>
				<div class="col-sm-3">
					<div class="history-heading">Location</div>
					<div class="history-heading-second">{{viewHistorySingleDetail.city}},{{viewHistorySingleDetail.country}}</div>
				</div>
				<div class="col-sm-3">
					<div class="history-heading">Contact No</div>
					<div class="history-heading-second">{{viewHistorySingleDetail.contact}}</div>
				</div>
				<div class="col-sm-3">
					<div class="history-heading">Visit History</div>
					<div class="history-heading-second">{{viewHistoryDetails.length==0 ? 0:viewHistoryDetails.length+' times'}}</div>
				</div>
			</div>
			<!-- <div>1</div>
			<div>2</div>
			<div>3</div>
			<div>4</div> -->
		</div>
		<div class="modal-body">
			<div class="accordion" id="accordionFaq">
				<div class="card border mb-3" *ngFor="let res of viewHistoryDetails;index as i">
					<div  (click)="res.isExpand = !res.isExpand">
					<div class="card-header cursor-pointer" id="faqTwo" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
						<h6 class="mb-0">
						 #Visit {{i+1}} - {{res.next_visit_date | date:'dd'}}/{{res.next_visit_date | date:'MM'}}/{{res.next_visit_date | date:'YYYY'}}
						</h6>

					</div>
					<div [style.display]="res.isExpand==true ?'block':'none'" id="collapseTwo" class="collapse" aria-labelledby="faqTwo" data-bs-parent="#accordionFaq" >
						<div class="card-body py-3">
							<div class="row">
								<div class="col-sm-3">
									<div class="history-heading">SPOC Name</div>
									<div class="history-heading-second">{{res.spoc_name}}</div>
								</div>
								<div class="col-sm-3">
									<div class="history-heading">SPOC Contact</div>
									<div class="history-heading-second">{{res.spoc_mobile}}</div>
								</div>
								<!-- <div class="col-sm-3">
									<div class="history-heading">Customer Name</div>
									<div class="history-heading-second">{{res.customer_feedback}}</div>
								</div> -->
								<div class="col-sm-3">
									<div class="history-heading">SPOC Designation</div>
									<div class="history-heading-second">{{res.spoc_designation}}</div>
								</div>
							</div><br/>
							<div class="row">
								<!-- <div>
								<div class="feedback-one">Feedback</div>
								<div class="pg">
								{{res.customer_feedback}}
								</div>
						     	</div>
								<div class="feedback-one">Feedback 1</div> -->
								<div class="col-sm-5">
									<div class="history-heading">Customer Feedback</div>
									<div class="history-heading-second">{{res.customer_feedback}}</div>
								</div>
								<div class="col-sm-5">
									<div class="history-heading">Uploaded Images</div>
									<div class="history-heading-second">
										<div class="image-row row" *ngIf="res?.upload_image_path?.length!==0">
											<div class="col-3 show-image" *ngFor="let resp of res.upload_image_path;index as i">
											  <img [src]="onShowImgeIconsComplete(resp)" class="img-fluids"  target="_blank">
											  <div class="inner-content-cmp">
												<!-- filext ImgUrl-->
												<ul class="social-cmp">
												
												  <!-- *ngIf="isValid;else other_content" -->
												  <li *ngIf="resp?.isurlType==='png' || resp?.isurlType==='jpeg' || resp?.isurlType==='jpg'else other_content">
													<a (click)="imagepreviewComplete(resp,ImgSrcModel,1)">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
													fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
													class="feathers-img feather-eye align-middle me-2">
													<path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
													<circle cx="12" cy="12" r="3"></circle>
													</svg>
												  </a>
												</li>
												<ng-template #other_content>
												  <li>
													<a (click)="imagepreviewComplete(resp,ImgSrcModel,2)">
													<svg xmlns="http://www.w3.org/2000/svg" 
													width="24" height="24" viewBox="0 0 24 24"
													fill="none" stroke="currentColor" stroke-width="2"
													stroke-linecap="round" stroke-linejoin="round"
													class="feathers-img feather-download align-middle">
													<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
													<polyline points="7 10 12 15 17 10"></polyline>
													<line x1="12" y1="15" x2="12" y2="3"></line>
													</svg>
												   </a>
												</li>
												</ng-template>
					  
												
												</ul>
											  </div>
											</div>
										   </div>
										   <div class="image-row row" *ngIf="res?.upload_image_path?.length==0">
										   <td>-</td>
										   </div>
									</div>
								</div>

							</div>
							

						</div>
					</div>
				  </div>
				</div>
				<div class="" style="float:right;">
					<ngb-pagination [collectionSize]="collectionSizeHistory" [(page)]="page2" [pageSize]="pageSize2" (pageChange)="getChangeHistoryData();">
					</ngb-pagination>
				</div>	
			</div>
		</div>
		
	</div>
</ng-template>
<ng-template #mySelectMonthModal let-modal>
	<div class="modal-content">
	  <div class="modal-header">
		<h4 class="modal-title model-title-text">Apply Month Filter </h4>
		  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	  </div>
	  <div class="modal-body">
		<form class="ui form" name="dateFilterform">
		  <div class="row col-md-12 p-calendar-cls">
			<div class="form-group col-12">
			  <p-calendar [ngModelOptions]="{standalone: true}" 
			  [(ngModel)]="Monthdate" view="month" dateFormat="MM yy" 
			  [readonlyInput]="true" [inline]="true" 
			  [showYear]="true">
			  </p-calendar>
			</div>  
			<div class="text-center" style="margin-top:4%;">
			  <button type="button" class="btn btn-default submit-button" (click)="addDateFilter()"
			  data-bs-dismiss="modal">Submit</button>
			</div>
		  </div>
		</form>
	  </div>
	</div>
</ng-template>

<ng-template #ImgSrcModel let-modal>
    <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Image Preview</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      </button>
    </div>
    <div class="modal-body">
      <div class="text-center">
         <img
      src="{{imgSrc}}"
      style="max-width:100%;cursor:pointer"
      class="modal-hover-opacity"
    /> 
      </div>
    </div>
    </div>
   
    
  </ng-template>
