<div class="lyt-main" id="lyt-main">


<section>
    <div class="cp-section">
	
        <div class="sec-head full-width-lw">
            <div class="left-wrap" >
			    <button class="prev-btn" [routerLink]="['/client']"><span class="icon icon-left-arrow"></span></button>
                <h2 class="sec-title">API Registration </h2>
            </div>
            <div class="right-wrap">
               
            </div>
        </div>
		<div class="sec-head full-width-lw" >
		   <div class="right-wrap ">
		   
		   
		    <ul class="act-list typ-multiline">
				
				
                    
                    <li class="act-item typ-full">					
								
								
                        <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group" style="width:500px;" >
								
								        <select [(ngModel)]="selectedName" (ngModelChange)="selectChange($event)" name="selectedName" class="input_class form-control "   required #memberInput="ngModel" >
				 <option value=""> Please select fleet owner
                       
                   </option>
				  <option *ngFor="let summary of fleetOwnerList; let i = index" value={{summary.fleet_id}}   >
                       {{summary.membership_name}} <!--{{i}}-->
                   </option>
               </select>
								
								
                                    
                                </div>
                            </div>
                        </div>
                    </li>
					
					 
					
					
					</ul>
                
					
            </div>
		</div>
		
        <div class="sec-cont">
            <div class="cp-card typ-table">
               <div class="mat-elevation-z8 table-responsive" *ngIf="resultCheck">
                <app-watermark [class.onerecord]="event_array.length<3"></app-watermark>
			  <table class="table table-bordered">
				<thead>
				<tr>
					<td>
                      <div class="form-check1" style="padding-top:5px;padding-left:33px;">
                          <input class="form-check-input" type="checkbox" value="option1" id="checkbox1" [(ngModel)]="selectedAll" (change)="selectAll();">
                      </div>
                    </td> 
					<th>VIN</th>
					<th>Alert Api</th>					
					<th>Current Status</th>
					<th>Fleet Summary</th>
					<th>Start & End Date</th>
					<!--th>Spn Value</th>
					<!--th>GpsLongitude</th-->
				</tr>
				</thead>
				<tbody>
				
				
				<tr *ngFor="let item of event_array  let i=index">
					<td>
                    <div class="form-check" style="padding-top:5px;" >
                        <input class="form-check-input" type="checkbox" value="option1" id="checkbox{{i+1}}"  [(ngModel)]="event_array[i]['selected']" (change)="checkIfAllSelected();">
                    </div>
                  </td>
					<td><div style="width:100px;word-wrap: break-word;">{{item.vin}}</div></td>
					<td>
					
					<div style="width:100px;word-wrap: break-word;">
					
					<input class="form-check-input" type="checkbox" value="option1" id="checkboxAlert{{i+1}}"  [(ngModel)]="event_array[i]['alertSel']" (change)="checkIfAllAlertSelected();">
					{{item.alert}}</div></td>
					
					<td><input class="form-check-input" type="checkbox" value="option1" id="checkboxCurrent{{i+1}}"  [(ngModel)]="event_array[i]['currentSel']" (change)="checkIfAllCurrentSelected();"> {{ item.currentStatus}}</td>
					<td><div style="width:100px;word-wrap: break-word;">
					<input class="form-check-input" type="checkbox" value="option1" id="checkboxSummary{{i+1}}"  [(ngModel)]="event_array[i]['summarySel']" (change)="checkIfAllSummarySelected();">
					
					{{item.fleetSummary}}</div></td>
					<td>
					
					<div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <input [owlDateTimeTrigger]="dt10" [min]="min" [max]="max" [owlDateTime]="dt10" [(ngModel)]="start_date" [selectMode]="'range'"  class="form-control" placeholder="Date & Time Range" (ngModelChange)="onEditStartDateChanged($event)" >
                                     <owl-date-time #dt10></owl-date-time>
									 
                                </div>
                            </div>
                        </div>
					
					</td>
					
					<!--td><div style="width:100px;word-wrap: break-word;">--</div></td-->
					
					
				</tr>
				
				</tbody>
			</table>
           <ul class="act-list typ-multiline" style="float:right;">
                   
                    <li class="act-item">
						
						
						
						<div class="act-wrap">
                           <button class="btn btn-default" (click)="submitApi();">Submit</button>
            
                        </div>
                    </li>
                  </ul>

			
			</div>
            </div>
        </div>
    </div>
   
</section>






<style>
/*
:host::ng-deep.selected-item { border-radius: 6px !important; background: #999999 !important; border: 1px solid #999999 !important; }

:host::ng-deep.selected-item {
border-radius: 6px !important;
background: #BAA28E !important;
border: 1px solid #BAA28E !important;
}

:host::ng-deep ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox] + div:before {
border: 2px solid #5e3817 !important;
}

:host::ng-deep ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked + div:before {
background: #BAA28E !important;
}

.custom-font-size{
  height:10000px;
}*/

:host::ng-deep multiselect-dropdown[_ngcontent-c5] .dropdown-btn[_ngcontent-c5] {
  border:0px;
}


:host::ng-deep.dropdown-btn { border-radius: 6px !important;  border: 0px solid #999999 !important;
padding: 0px 0px !important;
/* width: 400px !important;*/
 }

</style>
<!--footer>
			Copyright &#169; 2020 TATA MOTORS All Rights Reserved.
		</footer-->