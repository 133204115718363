<div class="lyt-main" id="lyt-main">


    <section>
        <div class="cp-section">

            <div class="sec-head full-width-lw">
                <div class="left-wrap">
                    <button class="prev-btn" [routerLink]="['/client']"><span
                            class="icon icon-left-arrow"></span></button>
                    <h2 class="sec-title">Client Registration </h2>
                </div>
                <div class="right-wrap">

                </div>
            </div>
            <div class="container">

                <div class="row">
                    <div class="col-lg-12 ">
                        <div class="row">
                            <div class="form-group col">
                                <label class="col-sm-4 col-md-4 col-lg-4 cust-label">Client Name <span
                                        style="color:red;">*</span></label>
                                <input type="text" [(ngModel)]="clientName"
                                    class="form-control col-sm-8 col-md-8 col-lg-8" placeholder="Client Name"
                                    (keyup)="applyFilter($event.target.value)" value="">
                            </div>
                            <div class="form-group col">
                                <label class="col-sm-4 col-md-4 col-lg-4 cust-label">Client Email <span
                                        style="color:red;">*</span></label>
                                <input type="text" [(ngModel)]="clientEmail"
                                    class="form-control col-sm-8 col-md-8 col-lg-8" placeholder="Client Email"
                                    (keyup)="applyFilter($event.target.value)" value="">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label class="col-sm-4 col-md-4 col-lg-4 cust-label">Client Mobile <span
                                        style="color:red;">*</span></label>
                                <input type="text" [(ngModel)]="clientMobile"
                                    class="form-control col-sm-8 col-md-8 col-lg-8" placeholder="Client Mobile"
                                    (keyup)="applyFilter($event.target.value)" value="">
                            </div>
                            <div class="form-group col">
                                <label class="col-sm-4 col-md-4 col-lg-4 cust-label">Company Name <span
                                        style="color:red;">*</span></label>
                                <input type="text" [(ngModel)]="companyName"
                                    class="form-control col-sm-8 col-md-8 col-lg-8" placeholder="Company Name"
                                    (keyup)="applyFilter($event.target.value)" value="">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                    <label class="col-sm-4 col-md-4 col-lg-4 cust-label">GST Number <span
                                    style="color:red;">*</span></label>
                            <input type="text" [(ngModel)]="gstNumber" class="form-control col-sm-8 col-md-8 col-lg-8"
                                placeholder="GST Number" (keyup)="applyFilter($event.target.value)" value="">
                        </div>
                        <div class="form-group col">
                            <label class="col-sm-4 col-md-4 col-lg-4 cust-label">PAN Number <span
                                    style="color:red;">*</span></label>
                            <input type="text" [(ngModel)]="panNumber" class="form-control col-sm-8 col-md-8 col-lg-8"
                                placeholder="PAN Number" (keyup)="applyFilter($event.target.value)" value="">
                        </div>
                        </div>
                         <div class="row">
                        <div class="form-group col">
                            <label class="col-sm-4 col-md-4 col-lg-4 cust-label">Aadhar Number <span
                                    style="color:red;">*</span></label>
                            <input type="text" [(ngModel)]="aadharNumber"
                                class="form-control col-sm-8 col-md-8 col-lg-8" placeholder="Aadhar Number"
                                (keyup)="applyFilter($event.target.value)" value="">
                        </div>
                        <div class="form-group col">
                            <label class="col-sm-4 col-md-4 col-lg-4 cust-label">Address<span
                                    style="color:red;">*</span></label>
                            <input type="text" [(ngModel)]="address" class="form-control col-sm-8 col-md-8 col-lg-8"
                                placeholder="Address" (keyup)="applyFilter($event.target.value)" value="">
                        </div>
                        </div>

                         <div class="row">
                        <div class="form-group col">
                            <label class="col-sm-4 col-md-4 col-lg-4 cust-label">User<span
                                    style="color:red;">*</span></label>
                            <select [(ngModel)]="CreatedBy" class="cust-select col-sm-8 col-md-8 col-lg-8"
                                (keyup)="applyFilter($event.target.value)">
                                <option value="User" selected=true>User</option>
                            </select>
                        </div>
                        <div class="form-group cst col">
                            <label class="col-sm-4 col-md-4 col-lg-4 cust-label">Select Support Data Type<span
                                    style="color:red;">*</span></label>
                            <ng-multiselect-dropdown
                                class="custom-font-size form-control col-sm-8 col-md-8 col-lg-8 cl-api-sel-sp"
                                [placeholder]="'List Support Data Type'" [data]="dropdownList"
                                [(ngModel)]="selectedItems" [settings]="dropdownSettings"
                                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                (onDeSelect)="onItemDeSelect($event)">
                            </ng-multiselect-dropdown>
                        </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                            <label class="col-sm-4 col-md-4 col-lg-4 cust-label">Start Date<span
                                    style="color:red;">*</span></label>
                            <input [owlDateTimeTrigger]="dt10" [min]="min" [max]="max" [owlDateTime]="dt10"
                                [(ngModel)]="start_date" [selectMode]="'range'"
                                class="form-control col-sm-8 col-md-8 col-lg-8" placeholder="Start Date & End Date"
                                (ngModelChange)="onEditStartDateChanged($event)">
                            <owl-date-time #dt10></owl-date-time>
                        </div>
                        <div class="form-group cst mt-10 col">
                            <label class="col-sm-4 col-md-4 col-lg-4 cust-label">Select Fleet Owner<span
                                    style="color:red;">*</span></label>
                            <ng-multiselect-dropdown 
                                class="custom-font-size form-control col-sm-8 col-md-8 col-lg-8 cl-api-sel-fp" [class.cl-api-sel-fp-mv]="MvDownArrow" 
                                [class.onselect]="isSelect"
                                [(ngModel)]="selectedFleet_array" [placeholder]="'List of All Fleet owner'"
                                [data]="fleetOwnerData" [settings]="dropdownFleetSettings"
                                (onSelect)="onFleetSelect($event)" (onDeSelect)="onFleetDeSelect($event)">
                            </ng-multiselect-dropdown>
                        </div>
                        </div>

                    </div>

                </div>
                <button class="btn btn-default" (click)="fetchReport();">Submit</button>
            </div>

            <div class="sec-cont">
                <div class="cp-card typ-table">
                    <div class="mat-elevation-z8 table-responsive" *ngIf="resultCheck">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr No</th>
                                    <th>Spn Name</th>
                                    <th>Event DateTime</th>
                                    <th>Value</th>
                                    <th>Spn Number</th>
                                    <!--th>SpnValue Description</th>
					<th>Spn Value</th>
					<!--th>GpsLongitude</th-->
                                </tr>
                            </thead>
                            <tbody>



                                <tr *ngFor="let item of event_array  let i=index">
                                    <td>{{ i+1}}</td>
                                    <td>
                                        <div style="width:100px;word-wrap: break-word;">{{item.spnName}}</div>
                                    </td>
                                    <td>
                                        <div style="width:100px;word-wrap: break-word;">{{item.eventDateTime}}</div>
                                    </td>

                                    <td>{{ item.value}}</td>
                                    <td>
                                        <div style="width:100px;word-wrap: break-word;">{{item.spnNumber}}</div>
                                    </td>
                                    <!--td><div style="width:100px;word-wrap: break-word;">{{item.spnDat[0].spnValueDescription}}</div></td-->

                                    <!--td><div style="width:100px;word-wrap: break-word;">--</div></td-->


                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showRecordVin">
            <div class="modal" [class.show]="isActive" id="myModal" style="width: 168%;">
                <!---->
                <div class="modal-content" style="width: 40%;left: 11%; top: 25%;height: 50%;overflow-y: scroll;">
                    <div class="modal-header">
                        <!-- <b>{{fleet_selected.first_name}}</b> -->
                        <h3>Fleet Owner Vehicles Details
                        </h3>
                        <h3 class="client-d" *ngIf="!fleet_first_name"> Client - <b>{{fleet_first_name}}</b></h3>
                    </div>
                    <div class="modal-body">
                        <table style="width: 100%;" class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr.No</th>
                                    <th>VIN Number</th>
                                    <th>Registration Number</th>
                                    <th>Fleet Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="!showVehicleSection">
                                    <td colspan="4" align="center">No data found</td>
                                </tr>
                                <tr *ngFor="let item of multipleVinNumber index as i">
                                    <td>
                                        <div>{{i+1}}</div>
                                    </td>
                                    <td>
                                        <div>{{item.vin}}</div>
                                    </td>
                                    <td>
                                        <div>{{item.registration_number}}</div>
                                    </td>
                                    <td>
                                        <div>{{item.fleet_id}}</div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal"
                            (click)="changeStyle()">Close</button>
                    </div>

                </div>
            </div>
        </div>

    </section>
    <style>
        /*
:host::ng-deep.selected-item { border-radius: 6px !important; background: #999999 !important; border: 1px solid #999999 !important; }

:host::ng-deep.selected-item {
border-radius: 6px !important;
background: #BAA28E !important;
border: 1px solid #BAA28E !important;
}

:host::ng-deep ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox] + div:before {
border: 2px solid #5e3817 !important;
}

:host::ng-deep ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked + div:before {
background: #BAA28E !important;
}

.custom-font-size{
  height:10000px;
}*/

        :host::ng-deep multiselect-dropdown[_ngcontent-c5] .dropdown-btn[_ngcontent-c5] {
            border: 0px;
        }


        :host::ng-deep.dropdown-btn {
            border-radius: 6px !important;
            border: 0px solid #999999 !important;
            padding: 0px 0px !important;
            /* width: 400px !important;*/
        }
    </style>