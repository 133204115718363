<div data-theme="default" data-layout="fluid" data-sidebar-position="left" data-sidebar-behavior="sticky">
    <div class="wrapper">
          <app-sidebar></app-sidebar>
          <div class="main">
              <app-header></app-header>
              <main class="content">
          <div class="container-fluid p-0">
            <div class="row mb-2 mb-xl-3">
            <div class="col-auto">
              <h3 class="dash-text">
              <svg (click)="getBackUrl()" xmlns="http://www.w3.org/2000/svg" 
							width="24" height="24" viewBox="0 0 24 24"
							fill="none" stroke="currentColor" stroke-width="2"
							stroke-linecap="round" stroke-linejoin="round"
							class="feathers-me feather-chevron-left align-middle">
							<polyline points="15 18 9 12 15 6"></polyline>
							</svg>
              History</h3>
            </div>
            <div>
            <ul class="breadcrumb brd-txt">
                <li>
                  <span class="task-title">
                  <span *ngIf="ChangeTab=='completed_visits'">Completed Task</span>
                  <span *ngIf="ChangeTab=='scheduled_visits'">Scheduled Tasks</span>
                  <span *ngIf="ChangeTab=='unscheduled_tasks'">Unscheduled Tasks</span>
                  <span *ngIf="ChangeTab=='overdue_visits'">Overdue Tasks</span>
                </span>
                </li>
                <li class="view-text">
                   < {{CmpName}}
                </li>
                <li> < View History</li>
            </ul>
            </div>
            </div>
            <div class="row mb-2 mb-xl-3">
              <div class="col-auto">
                <h3 class="tbl-text">
                  Visit History
                </h3>
              </div> 
              <div class="col-auto ms-auto text-end mt-n1">
              <button class="btn download-list-btn" (click)="downloadHistoryAsExcel()">
              <svg xmlns="http://www.w3.org/2000/svg" 
              width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-download align-middle">
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="7 10 12 15 17 10"></polyline>
              <line x1="12" y1="15" x2="12" y2="3"></line>
              </svg>
              <span style="white-space: nowrap;margin-left: 4px;">List</span>
              </button>
              <button class="btn date-btn" (click)="DateRangeModel(myDateRangeModal)">
              <a>
              <span class="icon icon-calendar"></span> 
              <span  class="year-text" style="white-space: nowrap;margin-left: 4px;">
              {{filterStartDate | date:'dd'}} {{filterStartDate | date:'MMM'}}
              {{filterStartDate | date:'YYYY'}} - {{filterEndDate | date:'dd'}} {{filterEndDate | date:'MMM'}}
              {{filterEndDate | date:'YYYY'}}
              </span>
              </a>
              </button>
              </div> 
            </div>
          </div>
          <div class="row">
            <div class="table-responsive">
              <table p-table p-table class="table table-cont">
                <thead >
                  <tr class="table_heading">
                    <th>Sr.No</th>
                    <th>Visit Date</th>
                    <th>SPOC Name</th>
                    <th>SPOC Contact</th>
                    <th>SPOC Designation</th>
                    <th>Sales Opportunity</th>
                    <th>Customer Feedback</th>
                  </tr>
                </thead>
                <tbody *ngIf="getCustomerDetails.length!=0">
                  <ng-container *ngFor="let row of getCustomerDetails;index as i">
                    <tr class="table_data" >
                   
                      <td>{{ i+1 }}</td>
                      <td> {{row.next_visit_date | date:'dd'}}/{{row.next_visit_date | date:'MM'}}/{{row.next_visit_date | date:'YYYY'}}</td>
                      <!-- <td>{{row.next_visit_date}}</td> -->
                      <td>{{row.spoc_name}}</td>
                      <td>{{row.spoc_mobile}}</td>
                      <td>{{row.spoc_designation}}</td>
                      <td style="padding-left: 4%;">
                        <label class="badge-int "[ngClass]="row.sales_opty=='green' ? 'bg-interset':row.sales_opty=='yellow' ?'bg-not-sure':'bg-notinterset'">
                          {{row.sales_opty=='green' ?'Interseted':row.sales_opty=='yellow' ?'Not Sure':'Not Interseted'}}
                        </label>
                      </td>
                      <td style="padding-left: 4%;" (click)="row.isExpand = !row.isExpand">
                        <span *ngIf="!row.isExpand" class="icon icon-angle-down  feedbackLblview">View
                        <svg xmlns="http://www.w3.org/2000/svg" 
                        width="24" height="24" viewBox="0 0 24 24"
                        fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-chevron-down align-middle me-2x">
                        <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                        </span>
                        <span *ngIf="row.isExpand" class="icon icon-angle-up  feedbackLblview">View
                        <svg xmlns="http://www.w3.org/2000/svg" 
                        width="24" height="24" viewBox="0 0 24 24"
                        fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-chevron-up align-middle me-2x">
                        <polyline points="18 15 12 9 6 15"></polyline>
                        </svg>
                        </span>
                      </td>
                    </tr>
                    <tr class="table_data-expand table_data"  *ngIf="row.isExpand" style="border-bottom:#dee6ed;border-top: hidden !important;">
                      <td colspan="2">
                        <div class="row">
                          <h4 class="feedbackLbl">Customer Feedback</h4>
                          <p>{{row.customer_feedback}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="row">
                          <h4 class="feedbackLbl">Uploaded Images</h4>
                          <div class="image-row row" *ngIf="row?.upload_image_path">
                            <div class="col-3 show-image" *ngFor="let resp of row.upload_image_path;index as i">
                              <img [src]="onShowImgeIconsComplete(resp)" class="img-fluids"  target="_self">
                              <div class="inner-content-cmp">
                                <!-- filext ImgUrl-->
                                <ul class="social-cmp">
                                
                                  <!-- *ngIf="isValid;else other_content" -->
                                  <li *ngIf="resp?.type==='png' || resp?.type==='jpeg' || resp?.type==='jpg'else other_content">
                                    <a (click)="imagepreviewComplete(resp,ImgSrcModel,1)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    class="feathers-img feather-eye align-middle me-2">
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                  </a>
                                </li>
                                <ng-template #other_content>
                                  <li>
                                    <a (click)="imagepreviewComplete(resp,ImgSrcModel,2)">
                                    <svg xmlns="http://www.w3.org/2000/svg" 
                                    width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feathers-img feather-download align-middle">
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                    <polyline points="7 10 12 15 17 10"></polyline>
                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                    </svg>
                                   </a>
                                </li>
                                </ng-template>
      
                                
                                </ul>
                              </div>
                            </div>
                           </div>
                           <div class="image-row row" *ngIf="row?.upload_image_path==null">
                           <td>-</td>
                           </div>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </ng-container>
                </tbody>
                <tbody *ngIf="getCustomerDetails.length==0">
                      <tr>
                        <td colspan="4"></td>                        
                        <td>Data Not Found</td>
                      </tr>
                </tbody>
              </table>
            </div>   
            <div style="float: right;">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="getSalespagination();">
              </ngb-pagination>
            </div>
          </div>
        <section>      
          <ng-template #myDateRangeModal let-modal>
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Set Date Range</h5>
                <button (click)="modal.dismiss('Cross click');" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                    <p-calendar   [inline]="true"  [(ngModel)]="rangeDates" selectionMode="range"></p-calendar>
                <!-- <div class="col-md-12">
                  <div class="form-group">
                    <label class="lable-text" for="">From Date<span style="color:red;">*</span></label>
                    <input type="date" class="form-control sdate" [(ngModel)]="filterStartDate" name="startDate" id="startDate" dateInput>
                  </div><br>
                  <div class="form-group">
                    <label class="lable-text" for="">To Date<span style="color:red;">*</span></label>
                    <input type="date" class="form-control sdate" [(ngModel)]="filterEndDate" name="endDate" id="endDate" dateInput>
                  </div>
                </div> -->
              </div>
              <div class="text-center" style="padding-bottom:5%;">
                <button type="button" (click)="SaveDateRange()" class="btn btn-lg btnsave btnClr">Apply</button>
              </div>
            </div>
          </ng-template>
         
        </section>
        </main>
      </div>
    </div>
  </div>

  <ng-template #ImgSrcModel let-modal>
    <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Image Preview</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      </button>
    </div>
    <div class="modal-body">
      <div class="text-center">
         <img
      src="{{imgSrc}}"
      style="max-width:100%;cursor:pointer"
      class="modal-hover-opacity"
    /> 
      </div>
    </div>
    </div>
   
    
  </ng-template>

 