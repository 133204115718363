<div class="cp-onboarding">
  <div class="logo-wrap">
    <a href="#" class="logo"><img src="assets/images/tm-logo-blue.svg" alt="" /></a>
  </div>
   <form [hidden]="login_state" class="card-body" (ngSubmit)="login()" #loginForm="ngForm" autocomplete='off'  name="myForm" ng-controller="mainCtrl"> 
  <div class="login-form onboarding-step" *ngIf="loginStep">
    <h2 class="title">Login</h2>
    <h4 class="desc">Enter details registered with TATA Motors</h4>
    <div class="cp-form">
      <ul class="field-list">
        <li class="field-item col-item">
          <div class="form-group">
            <label for="" class="form-label">User Name</label>
            <div class="input-group">
              <input type="text" pInputText class="form-control"  id="username" name="username" [(ngModel)]="username"  #userInput="ngModel" autocomplete="off"  placeholder="Enter User Name">
            </div>
          </div>
		  
		   <div *ngIf="userInput.errors && (userInput.dirty || userInput.touched)" class="alert alert-danger" >
                    <div [hidden]="!userInput.errors.required" class="login-error">
                     User name is required.
                    </div>
                    <div [hidden]="!userInput.errors.minlength" class="login-error">
                      Please provide User name.
                    </div>
                    <div [hidden]="!userInput.errors.maxlength" class="login-error">
                      User name cannot be more than 10 characters long.
                    </div>
               </div>
        </li>
        <li class="field-item col-item">
          <div class="form-group with-icon">
            <label for="" class="form-label">Password</label>
            <div class="input-group">
              <span class="icon icon-eye view-pwd" (click)="showPwd = !showPwd"></span>
              <input type="{{ showPwd ? 'text' : 'password' }}" value="" pInputText class=" form-control"  minlength=1 maxlength=10  id="password" name="password" [(ngModel)]="password"   placeholder="Enter password" #userPassword="ngModel" autocomplete="off">
            </div>
          </div>
		  
		   <div *ngIf="userPassword.errors && (userPassword.dirty || userPassword.touched)" class="alert alert-danger" >
			         <div *ngIf="userPassword.errors.pattern" class="login-error">
                      Please provide a password.
                    </div>
                    <div [hidden]="!userPassword.errors.required" class="login-error">
                      password is required!
                    </div>
                    <!--div [hidden]="!userPassword.errors.minlength"> 
                      Pan is required.{{pancardMsg}}
                    </div-->
                    <div [hidden]="!userPassword.errors.maxlength" class="login-error">
                      password be more than 10 characters long.
                    </div>
					 <div [hidden]="panCardError" class="login-error">
                     {{pancardMsg}} 
                    </div>
               </div>
         
        </li>
		<li class="field-item col-item">
          <div class="form-group ">
              <label for="" class="form-label"><input type="checkbox" style="width:5%;margin-bottom:0px !important;height: 1.5rem;" [(ngModel)]="term" name="termandcondition" id="termandcondition" required #inputTerm="ngModel">   I am an existing employee of Tata Motors Ltd and I agree to protect the confidentiality of the data I download from this portal.</label>
            <div class="input-group1">
            
				  
            </div>
          </div>
         <div *ngIf="inputTerm.errors && (inputTerm.dirty || inputTerm.touched)" class="alert alert-danger" >
			         <div *ngIf="inputTerm.errors.pattern" class="login-error">
                     Term and conditions is required!
                    </div>
                    <div [hidden]="!inputTerm.errors.required" class="login-error">
                     Term and conditions is required!
                    </div>
                    
               </div>	
        </li>
      </ul>
      <div class="btn-wrap">
        <!--button class="btn btn-outline" (click)="loginStep = false; signupStep = true">Sign Up</button>
        <button class="btn btn-default" [routerLink]="['/track']">Login</button-->
		 <button class="btn btn-default" type="submit" id="submiLogin" [disabled]="!loginForm.form.valid" >Login</button>
      </div>
    </div>
  </div>
</form>

  
</div>
