<div class="lyt-main" id="lyt-main">


<section>
    <div class="cp-section">
	
        <div class="sec-head full-width-lw">
            <div class="left-wrap" >
                <h2 class="sec-title">Clients</h2>
            </div>
            <div class="right-wrap">
               
            </div>
        </div>
		<div class="sec-head full-width-lw" >
		   <div class="right-wrap ">
                <ul class="act-list typ-multiline">
                    <li class="act-item typ-full">					
                        <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group" >
                                     <input type="text"  [(ngModel)]="searchText" class="form-control" placeholder="Search Here" (keyup)="applyFilter($event.target.value)" value="">
                                </div>
                            </div>
                        </div>
                    </li>
					<li class="act-item">
						<pagination-controls (pageChange)="pageChanged($event)" *ngIf="resultCheck"></pagination-controls>
                    </li>
                </ul>
            </div>
		</div>
		<div class="a" *ngIf="dataLoadingCheck && !resultCheck">
		<h2 class="loadingText">Please wait data is loading....</h2>
		</div>
		<div class="errorColor" *ngIf="!dataLoadingCheck && !resultCheck">
		<h2 class="loadingText">No Records Found.</h2>
		</div>
        <div class="sec-cont">
            <div class="cp-card typ-table">
				 
               <div class="mat-elevation-z8 table-responsive" *ngIf="resultCheck && !dataLoadingCheck" style="overflow-y: scroll; height:400px;">
				<app-watermark [class.topreduce]="CheckVinFleetTabShow" [class.onerecord]="event_array.length<3"></app-watermark>
				<table class="table table-bordered" style="overflow-y: scroll; height:400px;">
				<thead>
				<tr>
					<th>Sr.No</th>
					<th>Client Id</th>
					<th>Name</th>
					<th>Email</th>
					<th>Mobile</th>
					<th>Start Date</th>
					<th>End Date</th>
					<th>Created By</th>
					<th>Approval Status</th>
					<th>API List</th>
					<th>Status Updation</th>
					<!---<th>Add/Edit API</th>--->
					<!--th>Spn Value</th>
					<!--th>GpsLongitude</th-->
				</tr>
				</thead>
				<tbody>
				<!---
				
				
				
				[{"clientId":"test_client","clientName":null},{"clientId":"test_client_dev","clientName":null},{"clientId":"d9ef9b86-8865-4a84-8a8b-667b9db3fdea","clientName":"test_client_1"},{"clientId":"edc318eb-e683-4e1a-819b-a0cdd9513325","clientName":"test_client_2"},{"clientId":"82529486-ec5e-4fa9-b6c3-d552cf1911f9","clientName":"test_client_3"},{"clientId":"73642b6a-0c22-4f27-b4bb-c339a544e289","clientName":"test_client_4"},{"clientId":"77a218a0-b06f-40dc-b353-289a3c1e13d0","clientName":"test_client_5"},{"clientId":"e1dca206-155f-48b5-b3ca-7664b76d3fbb","clientName":"test_client_6"},{"clientId":"7264678f-c6a7-446e-aec0-2d7dd39fe9ee","clientName":"test_client_7"},{"clientId":"a088854e-45ee-439c-ab48-c3387b1f1780","clientName":"test_client_8"},{"clientId":"6a3aa20e-1299-4093-8d6c-0a9b375bd448","clientName":"test_client_9"},{"clientId":"1c8465d0-4365-4b45-9804-58858983ee7e","clientName":"test_client_10"},{"clientId":"c52c56e6-0f58-487f-bed4-bbd05781c38e","clientName":"test_client_11"},{"clientId":"b9a41137-afc3-4434-a676-5041cb0e484c","clientName":"Anirudha"},{"clientId":"83e55652-9874-44a5-8242-4ee52a66e522","clientName":"Anirudha Agrawal"},{"clientId":"7dbfca85-0f30-45c2-a3bb-3e4f34698753","clientName":"Anirudha Agrawal ddd"},{"clientId":"b595ef85-3904-4e44-992e-a3114d198069","clientName":"tmld_client_dev"},{"clientId":"794eddbc-8bd8-44d4-a4a1-9d385d9bc187","clientName":"separted_api_test"}]
				---->
				
				
				<tr *ngFor="let item of event_array |  paginate: { itemsPerPage: itemsPerPage, currentPage: page, totalItems:totalItems}; let i = index;">
					<td>{{ (tempPage*itemsPerPage) + (i+1)}}</td>
					<td><div style="width:300px;word-wrap: break-word;color:blue;cursor:pointer;" (click)="checkClientDetails(item.clientId);" mat-button data-toggle="modal" data-target="#checkClientDetails">{{item.clientId}}</div></td>
					<td><div style="width:100px;word-wrap: break-word;"><a>{{item.clientName}}</a></div></td>
					<td><div>{{item.email}}</div></td>
					<td><div>{{item.mobile}}</div></td>
					<td><div style="width:125px;word-wrap: break-word;">{{item.validityStartDate}}</div></td>
					<td><div style="width:125px;word-wrap: break-word;">{{item.validityEndDate}}</div></td>
					<td>
					<div style="width:100px;word-wrap: break-word;" *ngIf="item.createdBy==null">NA</div>
					<div style="width:100px;word-wrap: break-word;" *ngIf="item.createdBy!=null">{{item.createdBy}}</div>
					</td>
					<td><div style="width:100px;word-wrap: break-word;">{{item.approvalStatus}}</div></td>
					<td><div style="width:100px;word-wrap: break-word;">{{item.supportDataType}}</div></td>
					<!--td><div style="width:100px;word-wrap: break-word;">{{item.spnDat[0].spnValueDescription}}</div></td-->
					
					<!----<td><div style="width:100px;word-wrap: break-word;">		
							<a [routerLink]="['/vehicle']" [queryParams]="{clientId: item.clientId}" fragment="api">
							Add/View Api
							</a>
					</div></td>--->
					
					<!--td><div style="width:100px;word-wrap: break-word;">--</div></td-->
					<td><div style="width:100px;word-wrap: break-word;">			
                        <button class="btn btn-primary" (click)="checkClientApprovedStatus(item.clientId,item.approvalStatus,item.clientName);" mat-button data-toggle="modal" data-target="#checkClientApprovedStatus">
						Update Status</button>
					</div></td>
					
				</tr>
				</tbody>
			</table>	
			</div>
            </div>
        </div>
<hr/>
    </div>
</section>
<div class="mycontainer1">
<div class="modal" id="checkClientDetails" ><!--style="width: 168%;"-->	
    <div class="modal-content popResolution">
		<div class="modal-header">
			<h3>Client Vins Details</h3>
		</div>
      <div class="modal-body">
        <table class="table table-bordered vins">  
			<thead>
			<tr>
			<th>Sr.No</th>
			<th>VIN</th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let item of VInArrayData index as i">
				<td><div >{{i+1}}</div></td>
				<td><div >{{item}}</div></td>
			</tr>
             </tbody>                    
                             
                            </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>
 
  </div>
</div>
</div>
<div class="modal" id="checkClientApprovedStatus" style="width: 100%;margin: 0px 168px 1px 206px;">
  
    <div class="modal-content" style="width: 40%;margin-left: 235px; margin-top: 111px;">
      <div class="modal-body">
      <div class="row">
	  <div class="col-md-5">
	  <span style="font-size: 20px;font-weight: 600;">Update Status :</span>
	  </div>
	  <div class="col-md-7">
	 <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
								<select [(ngModel)]="updateStatusClientType" class="form-control" (keyup)="applyFilter($event.target.value)">
								<option value="PENDING">Pending</option>
								<option value="APPROVED">Approved</option>
								<option value="REJECTED">Rejected</option>
								</select>
                                </div>
                            </div>
                        </div>
	  </div>
	  </div>
      </div>
      <div class="modal-footer">
	   <button type="button" class="btn btn-primary" (click)="updateApproveStatus(updateclientId)">Update</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>
 
  </div>
</div>
<style>
/*
:host::ng-deep.selected-item { border-radius: 6px !important; background: #999999 !important; border: 1px solid #999999 !important; }

:host::ng-deep.selected-item {
border-radius: 6px !important;
background: #BAA28E !important;
border: 1px solid #BAA28E !important;
}

:host::ng-deep ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox] + div:before {
border: 2px solid #5e3817 !important;
}

:host::ng-deep ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked + div:before {
background: #BAA28E !important;
}

.custom-font-size{
  height:10000px;
}*/

:host::ng-deep multiselect-dropdown[_ngcontent-c5] .dropdown-btn[_ngcontent-c5] {
  border:0px;
}


:host::ng-deep.dropdown-btn { border-radius: 6px !important;  border: 0px solid #999999 !important;
padding: 0px 0px !important;
/* width: 400px !important;*/
 }

</style>
<!--footer>
			Copyright &#169; 2020 TATA MOTORS All Rights Reserved.
		</footer-->