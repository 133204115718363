<div data-theme="default" data-layout="fluid" data-sidebar-position="left" data-sidebar-behavior="sticky">
  <div class="wrapper">
		<app-sidebar></app-sidebar>
		<div class="main">
			<app-header></app-header>
			<main class="content">
        <div class="container-fluid p-0">
          <h1 class="h3 mb-3 task-heading">
            <svg [routerLink]="['/fecdashboard']" xmlns="http://www.w3.org/2000/svg" 
							width="24" height="24" viewBox="0 0 24 24"
							fill="none" stroke="currentColor" stroke-width="2"
							stroke-linecap="round" stroke-linejoin="round"
							class="feathers-me feather-chevron-left align-middle">
							<polyline points="15 18 9 12 15 6"></polyline>
							</svg>
              TASK LIST</h1>
          <div style="display:flex;justify-content: space-between;"> 
            <div>
              <ul class="breadcrumb">
                <li>
                  <a class="task-title">
                  <span *ngIf="ChangeTab=='completed_visits'">Completed Task</span>
                  <span *ngIf="ChangeTab=='scheduled_visits'">Scheduled Tasks</span>
                  <span *ngIf="ChangeTab=='unscheduled_tasks'">Unscheduled Tasks</span>
                  <span *ngIf="ChangeTab=='overdue_visits'">Overdue Tasks</span>
                </a>
                </li>
                <li><a>{{fecTaskInformationData.company_name}}</a></li>
                <!-- <li>View</li> -->
              </ul>
            </div>
            <div *ngIf="ChangeTab=='scheduled_visits' || ChangeTab=='completed_visits'" class="view-history" (click)="viewHistory()">View History 
              <svg xmlns="http://www.w3.org/2000/svg" 
              width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-chevron-right align-middle me-2x">
              <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </div>
          <div class="row" *ngIf="ChangeTab=='unscheduled_tasks' || ChangeTab=='overdue_visits'">
            <div class="col-12">
              <div class="card external-css">
                <ng-container *ngIf="fecTaskInformation">
                  <form  enctype="multipart/form-data"  [formGroup]="fecTaskInformation">
                    <div class="row first_heading-row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <span class="cust_fleet_details">Customer Details
                           <span class="required">*
                           </span>
                          </span>
                      </div>
                    </div>
                    <div id="cust_fleet_details" class="row">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label for="fleet_size">Total Fleet Size</label>
                        <input disabled name="fleet_size" class="form-control"
                        oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)a-z]+$/g, '').replace(/(\..*)\./g, '$1');" 
                        type="text" formControlName="fleetSize" placeholder="Fleet Size" autocomplete="false">
                        <div class="error-message" *ngIf="fecTaskInformation.controls.fleetSize.errors?.required && fecTaskInformation.controls.fleetSize.touched">
                            Fleet Size is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.fleetSize.errors?.required) && fecTaskInformation.controls.fleetSize.errors && fecTaskInformation.controls.fleetSize.touched">
                            Fleet Size is Invalid
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label for="industry_type">Industry Type</label>
                        <select disabled class="form-control ui dropdown"  id="industry_type" name="industry_type" formControlName="industryType" >
                          <option value="" selected="industryType==null">Select Industry Type</option>
                          <option  *ngFor="let option of industryTypeOptions" value="{{ option.key }}">{{ option.value }}</option>
                        </select>
                        <div class="error-message" *ngIf="fecTaskInformation.controls.industryType.errors?.required && fecTaskInformation.controls.industryType.touched">
                            Industry Type is Required
                        </div>
                      </div>
                    </div>
          
                    <!-- SPOC Details -->
                    <!-- <div class="row first_heading" style="margin-bottom:1rem;">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <span class="cust_fleet_details">SPOC Details
                        <span class="required">*</span></span>
                      </div>
                    </div> --><br><br>
                    <div id="spoc_details" class="row">
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_name">Name</label>
                        <input disabled id="spoc_name" class="form-control"
                        oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');" 
                        type="text" formControlName="spocName" placeholder="SPOC Name" autocomplete="false">
                        <div class="error-message" *ngIf="fecTaskInformation.controls.spocName.errors?.required && fecTaskInformation.controls.spocName.touched">
                          SPOC Name is Required
                      </div>
                      <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocName.errors?.required) && fecTaskInformation.controls.spocName.errors && fecTaskInformation.controls.spocName.touched">
                          SPOC Name is Invalid
                      </div>
                      </div>
                      <!-- [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" -->
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_mobile">Mobile Number</label>
                        <input  disabled id="spoc_mobile" class="form-control" 
                        type="text" maxlength="10" formControlName="spocPhone" placeholder="SPOC Mobile Number" autocomplete="false">
                        <!-- <div class="error-message" *ngIf="fecTaskInformation.controls.spocPhone.errors?.required && fecTaskInformation.controls.spocPhone.touched">
                          SPOC Mobile No. is Required
                      </div>
                      <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocPhone.errors?.required) && fecTaskInformation.controls.spocPhone.errors && fecTaskInformation.controls.spocPhone.touched">
                          SPOC Mobile No. is Invalid
                      </div> -->
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_designation">Designation</label>
                        <input disabled class="form-control" type="text" formControlName="spocDesignation" placeholder="SPOC Designation" autocomplete="false">
                        <!-- <div class="error-message" *ngIf="fecTaskInformation.controls.spocDesignation.errors?.required && fecTaskInformation.controls.spocDesignation.touched">
                          SPOC Designation is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocDesignation.errors?.required) && fecTaskInformation.controls.spocDesignation.errors && fecTaskInformation.controls.spocDesignation.touched">
                          SPOC Designation is Invalid
                        </div> -->
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_visit_purpose">Purpose Of Visit</label>
                        <select disabled class="form-control ui dropdown" formControlName="spocPurposeOfVisit" id="spoc_visit_purpose">
                          <option *ngFor="let option of purposeOfVisitOptions" value="{{ option.key }}">{{ option.value }}</option>
                        </select>
                        <!-- <div class="error-message" *ngIf="fecTaskInformation.controls.spocPurposeOfVisit.errors?.required && fecTaskInformation.controls.spocPurposeOfVisit.touched">
                          Purpose Of Visit is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocPurposeOfVisit.errors?.required) && fecTaskInformation.controls.spocPurposeOfVisit.errors && fecTaskInformation.controls.spocPurposeOfVisit.touched">
                          Purpose Of Visit is Invalid
                        </div> -->
                      </div>
                    </div>
          
                    <!-- Media Files -->
                    <div class="row first_heading" id="attachments" *ngIf="ChangeTab!=='unscheduled_tasks' && ChangeTab!=='overdue_visits'">
                      <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                        <label for="attachments" *ngIf="ChangeTab=='scheduled_visits'">
                          Add Media <span class="required-msg">
                          Upload upto max 20mb of images.
                         </span>
                        </label>
                        <label for="attachments" *ngIf="ChangeTab=='completed_visits'">
                          View Media 
                          <!-- <span class="required-msg">
                          Upload upto max 20mb of images.
                         </span> -->
                        </label>
                      <div style="display: flex;">
                        <div class="image-box">
                          <input type="file" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" id="files-selector" style="display: none;" (change)="onSelectFile($event)" multiple accept=".png, .jpeg, .jpg" name="fileInput" />
                          <i (click)="openFileSelector($event.target)" class="pi pi-upload" style="font-size: 2rem;
                          padding: 22%;"></i>
                        </div>
                        <div class="image-row row"  *ngFor="let resp of fecTaskInformationData.upload_image_path">
                          <div class="col-3 show-image" >
                            <!-- {{item}} -->
                            <img [src]="resp.url" class="img-fluids" target="_blank" >
                          </div>
                          <!-- <div class="col-3 show-image">
                            <img src="assets/images/aadhaar.png" class="img-fluids">
                          </div>
                          <div class="col-3 show-image">
                            <img src="assets/images/aadhaar.png" class="img-fluids">
                          </div> -->
                        </div>
                      </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label style="margin-bottom: 10px;white-space: nowrap;">Sales Opportunity Window <span class="required">*
                          <span style="color:#307FE2;"><svg xmlns="http://www.w3.org/2000/svg" 
                          width="24" height="24" viewBox="0 0 24 24"
                          fill="none" stroke="currentColor" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-alert-circle align-middle me-2">
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="12"></line>
                          <line x1="12" y1="16" x2="12.01" y2="16"></line>
                          </svg></span>
                         </span>
                        </label>
                        <div >
                          <label class="form-check label-check">
                            <input formControlName="salesOpportunity" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" class="form-check-input" type="radio" value="green"  [checked]="sales_opty=='green'">
                            <span class="form-check-label frm-chk-text">
                              Interseted
                            </span>
                          </label>
                          <label class="form-check">
                            <input formControlName="salesOpportunity" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" class="form-check-input" type="radio" value="yellow"  [checked]="sales_opty=='yellow'">
                            <span class="form-check-label frm-chk-text">
                              Not Sure
                            </span>
                          </label>
                          <label class="form-check">
                            <input formControlName="salesOpportunity" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" class="form-check-input" type="radio" value="red"   [checked]="sales_opty=='red'">
                            <span class="form-check-label frm-chk-text">
                              Not Interested
                            </span>
                          </label>
                        </div>
                      </div>
                      <!-- <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label>Sales Opportunity <span class="required">*</span></label>
                      </div> -->
                    </div>
                    <!-- <div id="attachments" class="row">
                      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-4">
                        <input type="file" id="files-selector" style="display: none;" (change)="onSelectFile($event)" multiple accept=".png, .jpeg, .jpg" name="fileInput" />
                        <button class="p-button p-button-outlined p-button-secondary file-selector-btn" type="button" (click)="openFileSelector($event.target)">
                          <i class="pi pi-upload" style="font-size: 2rem"></i>
                          Upload Images
                        </button>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-5 col-xs-5">
                        <div class="media_files" *ngIf="fecTaskInformation && fecTaskInformation.value && fecTaskInformation.value.attachments">
                          <div class="file_preview" *ngFor='let url of fecTaskInformation.value.attachments'>
                            <img [src]="url">
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <select formControlName="salesOpportunity" class="salesDropdown">
                          <option class="salesoption" value="red" style="color: #df0000;" [selected]>Red<span class="optioncolor" >&#8213;</span></option>
                          <option class="salesoption" value="green" style="color: #0C8F31;">Green<span class="optioncolor" ></span>&#8213;</option>
                          <option class="salesoption" value="yellow" style="color: #E6DC00;">Yellow<span class="optioncolor" ></span>&#8213;</option>
                        </select>
                        <div class="error-message" *ngIf="fecTaskInformation.controls.salesOpportunity.errors?.required && fecTaskInformation.controls.salesOpportunity.touched">
                          Sales oppurtunity is Required
                      </div>
                      </div>
                    </div> -->
                     <!-- location -->
                     <!-- <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="location">
                        <div class="location-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" 
                      width="24" height="24" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-alert-circle align-middle me-2">
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                      <circle cx="12" cy="10" r="3"></circle>
                      </svg>
                        </div>
                        <div class="location-text">location captured</div>
                        <div class="">
                          <button class="btn btn-success location-cap">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                            width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round"
                            class="feathers feather feather-check align-middle me-2">
                            <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                          </button>
                        </div>
                      </div>
                      </div>
                    </div> -->
                     
                    <!-- Feedbacks and Suggesions -->
                    <div class="row feedback-heading" *ngIf="ChangeTab!=='unscheduled_tasks' && ChangeTab!=='overdue_visits'">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <span class="cust_fleet_details">
                          Customer Suggestions and Feedback <span class="required">*</span>
                        </span>
                      </div>
                    </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top:1.2rem;"  *ngIf="ChangeTab!=='unscheduled_tasks' && ChangeTab!=='overdue_visits'">
                          <label for="spoc_name">Feedbacks <span class="required">*</span></label>
                          <textarea class="form-control" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null"
                          oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)]+$/g, '').replace(/(\..*)\./g, '$1');" 
                          formControlName="spocFeedback"></textarea>
                          <div class="error-message" *ngIf="fecTaskInformation.controls.spocFeedback.errors?.required && fecTaskInformation.controls.spocFeedback.touched">
                            Feedback is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocFeedback.errors?.required) && fecTaskInformation.controls.spocFeedback.errors && fecTaskInformation.controls.spocFeedback.touched">
                            Feedback is Invalid
                        </div>
                      </div>
                    <br>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label for="spoc_name">
                          <span *ngIf="ChangeTab=='scheduled_visits'">Reset Visit Date</span>
                          <span *ngIf="ChangeTab=='completed_visits'">Next Visit Date</span>
                          <span *ngIf="ChangeTab=='unscheduled_tasks'">Set Visit Date</span>
                          <span *ngIf="ChangeTab=='overdue_visits'">Set Visit Date</span>


                          <span class="required">*</span></label>
                      </div>
                    </div>
                    <div *ngIf="ChangeTab=='scheduled_visits'">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label class="form-check form-check-inline ">
                          <input (change)="isNextVisitDateRequiredSch(Schfalg)" class="form-check-input" type="checkbox" name="inline-radios-example" [checked]="isNextVisit=='yes'">
                          <span class="form-check-label">Yes</span>
                        </label>
                        <!-- <label class="form-check form-check-inline" *ngIf="isNextVisit=='no'">
                          <input (change)="isNextVisitDateRequired('no')"  class="form-check-input" type="checkbox" name="inline-radios-example" [checked]="isNextVisit=='no'">
                          <span class="form-check-label">No</span>
                        </label> -->
                      </div>
                    </div>
                    <!-- [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" -->
                    <div class="row">
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngIf="Schfalg===true">
                        <input [min]="today" type="date" class="form-control sdate" name="nextvisitdate" formControlName="nextVisitDate" dateInput id="nextvisitdate" >
                      </div>
                    </div>
                    </div>
                    <div *ngIf="ChangeTab=='completed_visits'">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <label class="form-check form-check-inline">
                            <input (change)="isNextVisitDateRequired('yes')" class="form-check-input" type="radio" name="inline-radios-example" [checked]="isNextVisit=='yes'">
                            <span class="form-check-label">Yes</span>
                          </label>
                          <label class="form-check form-check-inline">
                            <input (change)="isNextVisitDateRequired('no')"  class="form-check-input" type="radio" name="inline-radios-example" [checked]="isNextVisit=='no'">
                            <span class="form-check-label">No</span>
                          </label>
                        </div>
                      </div>
                      <!-- [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" -->
                      <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngIf="isNextVisit =='yes'">
                          <input [min]="today" type="date" class="form-control sdate" name="nextvisitdate" formControlName="nextVisitDate" dateInput id="nextvisitdate" >
                        </div>
                      </div>
                      </div>
                    <div class="row" *ngIf="ChangeTab=='overdue_visits' || ChangeTab=='unscheduled_tasks'">
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                        <input [min]="today" type="date" class="form-control sdate" name="nextvisitdate" formControlName="nextVisitDate" dateInput id="nextvisitdate" >
                      </div>
                    </div>
                    <!-- <div class="row">
                      
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                        <span class="radiobutton" (click)="isNextVisitDateRequired('yes')">Yes</span>&nbsp;<input type="radio" [checked]="isNextVisit=='yes'" (click)="isNextVisitDateRequired('yes')">
                      </div>
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="margin-left:-12%;" >
                        <input type="date" class="form-control sdate" name="nextvisitdate" formControlName="nextVisitDate" dateInput id="nextvisitdate" *ngIf="isNextVisit == 'yes'">
                      </div>
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                        <span class="radiobutton" (click)="isNextVisitDateRequired('no')">No</span>&nbsp;<input type="radio" [checked]="isNextVisit=='no'" (click)="isNextVisitDateRequired('no')">
                      </div>
                      <div class="error-message" *ngIf="fecTaskInformation.controls.nextVisitDate.errors?.required && fecTaskInformation.controls.nextVisitDate.touched">
                        Next Visit is Required
                      </div>
                    </div> -->
                   
                  </form>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="ChangeTab=='scheduled_visits'">
            <div class="col-12">
              <div class="card external-css">
                <ng-container *ngIf="fecTaskInformation">
                  <form  enctype="multipart/form-data"  [formGroup]="fecTaskInformation">
                    <div class="row first_heading-row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <span class="cust_fleet_details">Customer Details
                           <!-- <span class="required">*
                           </span> -->
                          </span>
                      </div>
                    </div>
                    <div id="cust_fleet_details" class="row">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label for="fleet_size">Total Fleet Size</label>
                        <input disabled name="fleet_size" class="form-control"
                        oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)a-z]+$/g, '').replace(/(\..*)\./g, '$1');" 
                        type="text" formControlName="fleetSize" placeholder="Fleet Size" autocomplete="false">
                        <div class="error-message" *ngIf="fecTaskInformation.controls.fleetSize.errors?.required && fecTaskInformation.controls.fleetSize.touched">
                            Fleet Size is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.fleetSize.errors?.required) && fecTaskInformation.controls.fleetSize.errors && fecTaskInformation.controls.fleetSize.touched">
                            Fleet Size is Invalid
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="margin-bottom: 3%;">
                        <label for="industry_type">Industry Type</label>
                        <select disabled class="form-control ui dropdown"  id="industry_type" name="industry_type" formControlName="industryType" >
                          <option value="" selected="industryType==null">Select Industry Type</option>
                          <option  *ngFor="let option of industryTypeOptions" value="{{ option.key }}">{{ option.value }}</option>
                        </select>
                        <div class="error-message" *ngIf="fecTaskInformation.controls.industryType.errors?.required && fecTaskInformation.controls.industryType.touched">
                            Industry Type is Required
                        </div>
                      </div>
                    </div>
                    <div id="spoc_details" class="row">
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_name">Name</label>
                        <input disabled id="spoc_name" class="form-control"
                        oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');" 
                        type="text" formControlName="spocName" placeholder="SPOC Name" autocomplete="false">
                        <div class="error-message" *ngIf="fecTaskInformation.controls.spocName.errors?.required && fecTaskInformation.controls.spocName.touched">
                          SPOC Name is Required
                      </div>
                      <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocName.errors?.required) && fecTaskInformation.controls.spocName.errors && fecTaskInformation.controls.spocName.touched">
                          SPOC Name is Invalid
                      </div>
                      </div>
                      <!-- [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" -->
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_mobile">Mobile Number</label>
                        <input  disabled id="spoc_mobile" class="form-control" 
                        type="text" maxlength="10" formControlName="spocPhone" placeholder="SPOC Mobile Number" autocomplete="false">
                        <!-- <div class="error-message" *ngIf="fecTaskInformation.controls.spocPhone.errors?.required && fecTaskInformation.controls.spocPhone.touched">
                          SPOC Mobile No. is Required
                      </div>
                      <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocPhone.errors?.required) && fecTaskInformation.controls.spocPhone.errors && fecTaskInformation.controls.spocPhone.touched">
                          SPOC Mobile No. is Invalid
                      </div> -->
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_designation">Designation</label>
                        <input disabled class="form-control" type="text" formControlName="spocDesignation" placeholder="Designation" autocomplete="false">
                        <!-- <div class="error-message" *ngIf="fecTaskInformation.controls.spocDesignation.errors?.required && fecTaskInformation.controls.spocDesignation.touched">
                          SPOC Designation is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocDesignation.errors?.required) && fecTaskInformation.controls.spocDesignation.errors && fecTaskInformation.controls.spocDesignation.touched">
                          SPOC Designation is Invalid
                        </div> -->
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_visit_purpose">Purpose Of Visit</label>
                        <select disabled class="form-control ui dropdown" formControlName="spocPurposeOfVisit" id="spoc_visit_purpose">
                          <option *ngFor="let option of purposeOfVisitOptions" value="{{ option.key }}">{{ option.value }}</option>
                        </select>
                        <!-- <div class="error-message" *ngIf="fecTaskInformation.controls.spocPurposeOfVisit.errors?.required && fecTaskInformation.controls.spocPurposeOfVisit.touched">
                          Purpose Of Visit is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocPurposeOfVisit.errors?.required) && fecTaskInformation.controls.spocPurposeOfVisit.errors && fecTaskInformation.controls.spocPurposeOfVisit.touched">
                          Purpose Of Visit is Invalid
                        </div> -->
                      </div>
                    </div>
                    <!-- SPOC Details -->
                    <div class="row first_heading" style="margin-bottom:1rem;">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <span class="cust_fleet_details">SPOC Details
                        <span class="required">*</span></span>
                      </div>
                    </div>
                    <div id="spoc_details" class="row">
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_name">Name</label>
                        <input  id="spoc_name" class="form-control"
                        oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');" 
                        type="text" formControlName="spoc_name" placeholder="SPOC Name" autocomplete="false">
                        <div class="error-message" *ngIf="fecTaskInformation.controls.spoc_name.errors?.required && fecTaskInformation.controls.spoc_name.touched">
                          SPOC Name is Required
                      </div>
                      <div class="error-message" *ngIf="!(fecTaskInformation.controls.spoc_name.errors?.required) && fecTaskInformation.controls.spoc_name.errors && fecTaskInformation.controls.spoc_name.touched">
                          SPOC Name is Invalid
                      </div>
                      </div>
                      <!-- [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" -->
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_mobile">Mobile Number</label>
                        <input   id="spoc_mobile" class="form-control" 
                        type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" maxlength="10" formControlName="spoc_mobile" placeholder="SPOC Mobile Number" autocomplete="false">
                        <div class="error-message" *ngIf="fecTaskInformation.controls.spoc_mobile.errors?.required && fecTaskInformation.controls.spoc_mobile.touched">
                          SPOC Mobile No. is Required
                      </div>
                      <div class="error-message" *ngIf="!(fecTaskInformation.controls.spoc_mobile.errors?.required) && fecTaskInformation.controls.spoc_mobile.errors && fecTaskInformation.controls.spoc_mobile.touched">
                          Please, Enter 10 digit SPOC Mobile Number.
                      </div>
                      </div>
                      
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_designation">Designation</label>
                        <input  oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');" class="form-control" type="text" formControlName="spoc_designation" placeholder="SPOC Designation" autocomplete="false">
                        <div class="error-message" *ngIf="fecTaskInformation.controls.spoc_designation.errors?.required && fecTaskInformation.controls.spoc_designation.touched">
                          SPOC Designation is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.spoc_designation.errors?.required) && fecTaskInformation.controls.spoc_designation.errors && fecTaskInformation.controls.spoc_designation.touched">
                          SPOC Designation is Invalid
                        </div>
                      </div>
                    </div>
          
                    <!-- Media Files -->
                    <div class="row first_heading" id="attachments" *ngIf="ChangeTab!=='unscheduled_tasks' && ChangeTab!=='overdue_visits'">
                      <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                        <label for="attachments" *ngIf="ChangeTab=='scheduled_visits'">
                          Add Media <span class="required-msg">
                          Upload upto max 20mb and format should be .png,.jpeg,.jpg,.pdf,.csv,.xlsx,.docs,.docx,.txt,.zip
                         </span>
                        </label>
                        <label for="attachments" *ngIf="ChangeTab=='completed_visits'">
                          View Media 
                          <!-- <span class="required-msg">
                          Upload upto max 20mb of images.
                         </span> -->
                        </label>
                      <div style="display: flex;">
                        <div class="image-box">
                          <input type="file" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" id="files-selector"
                           style="display: none;" (change)="onSelectFile($event)" multiple name="fileInput" />
                          <i (click)="openFileSelector($event.target)" class="pi pi-upload" style="font-size: 2rem;
                          padding: 22%;"></i>
                        </div>
                       <div class="image-row row">
                          <div class="col-3 show-image" *ngFor="let resps of ImgUrlData;index as i">
                            <!-- <img [src]="resps.filext==='png' || 'jpeg' || 'jpg'  ? resps.ImgUrl:'assets/images/excel.png'" class="img-fluids"  target="_blank"> -->
                            <img [src]="onShowImgeIcons(resps)" class="img-fluids"  target="_blank">
                            <div class="inner-content">
                              <!-- filext ImgUrl-->
                              <ul class="social">
                                <li><a (click)="removeImg(resps)">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                  class="feathers-img feather-trash-2 align-middle me-2">
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                  </path>
                                  <line x1="10" y1="10" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                  </svg>
                                </a></li>
                                <!-- *ngIf="isValid;else other_content" -->
                                <li *ngIf="resps.filext==='png' || resps.filext==='jpeg' || resps.filext==='jpg'else other_content">
                                  <a (click)="imagepreview(resps,ImgSrcModel,1)">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  class="feathers-img feather-eye align-middle me-2">
                                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                                  </svg>
                                </a>
                              </li>
                              <ng-template #other_content>
                                <li>
                                  <a (click)="imagepreview(resps,ImgSrcModel,2)">
                                  <svg xmlns="http://www.w3.org/2000/svg" 
                                  width="24" height="24" viewBox="0 0 24 24"
                                  fill="none" stroke="currentColor" stroke-width="2"
                                  stroke-linecap="round" stroke-linejoin="round"
                                  class="feathers-img feather-download align-middle">
                                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                  <polyline points="7 10 12 15 17 10"></polyline>
                                  <line x1="12" y1="15" x2="12" y2="3"></line>
                                  </svg>
                                 </a>
                              </li>
                              </ng-template>

                              
                              </ul>
                            </div>
                          </div>
                       </div>
                      </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label style="margin-bottom: 10px;white-space: nowrap;">Sales Opportunity Window <span class="required">*
                          <span style="color:#307FE2;"><svg xmlns="http://www.w3.org/2000/svg" 
                          width="24" height="24" viewBox="0 0 24 24"
                          fill="none" stroke="currentColor" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-alert-circle align-middle me-2">
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="12"></line>
                          <line x1="12" y1="16" x2="12.01" y2="16"></line>
                          </svg></span>
                         </span>
                        </label>
                        <div >
                          <label class="form-check label-check">
                            <input formControlName="salesOpportunity" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" class="form-check-input" type="radio" value="green"  [checked]="sales_opty=='green'">
                            <span class="form-check-label frm-chk-text">
                              Interseted
                            </span>
                          </label>
                          <label class="form-check">
                            <input formControlName="salesOpportunity" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" class="form-check-input" type="radio" value="yellow"  [checked]="sales_opty=='yellow'">
                            <span class="form-check-label frm-chk-text">
                              Not Sure
                            </span>
                          </label>
                          <label class="form-check">
                            <input formControlName="salesOpportunity" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" class="form-check-input" type="radio" value="red"   [checked]="sales_opty=='red'">
                            <span class="form-check-label frm-chk-text">
                              Not Interested
                            </span>
                          </label>
                        </div>
                        <div class="error-message" *ngIf="fecTaskInformation.controls.salesOpportunity.errors?.required && fecTaskInformation.controls.salesOpportunity.touched">
                          Sales Opportunity is Required
                        </div>
                      </div>
                     
                      <!-- <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label>Sales Opportunity <span class="required">*</span></label>
                      </div> -->
                    </div>
                    <!-- <div id="attachments" class="row">
                      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-4">
                        <input type="file" id="files-selector" style="display: none;" (change)="onSelectFile($event)" multiple accept=".png, .jpeg, .jpg" name="fileInput" />
                        <button class="p-button p-button-outlined p-button-secondary file-selector-btn" type="button" (click)="openFileSelector($event.target)">
                          <i class="pi pi-upload" style="font-size: 2rem"></i>
                          Upload Images
                        </button>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-5 col-xs-5">
                        <div class="media_files" *ngIf="fecTaskInformation && fecTaskInformation.value && fecTaskInformation.value.attachments">
                          <div class="file_preview" *ngFor='let url of fecTaskInformation.value.attachments'>
                            <img [src]="url">
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <select formControlName="salesOpportunity" class="salesDropdown">
                          <option class="salesoption" value="red" style="color: #df0000;" [selected]>Red<span class="optioncolor" >&#8213;</span></option>
                          <option class="salesoption" value="green" style="color: #0C8F31;">Green<span class="optioncolor" ></span>&#8213;</option>
                          <option class="salesoption" value="yellow" style="color: #E6DC00;">Yellow<span class="optioncolor" ></span>&#8213;</option>
                        </select>
                        <div class="error-message" *ngIf="fecTaskInformation.controls.salesOpportunity.errors?.required && fecTaskInformation.controls.salesOpportunity.touched">
                          Sales oppurtunity is Required
                      </div>
                      </div>
                    </div> -->
                     <!-- location -->
                     <!-- <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="location">
                        <div class="location-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" 
                      width="24" height="24" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-alert-circle align-middle me-2">
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                      <circle cx="12" cy="10" r="3"></circle>
                      </svg>
                        </div>
                        <div class="location-text">location captured</div>
                        <div class="">
                          <button class="btn btn-success location-cap">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                            width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round"
                            class="feathers feather feather-check align-middle me-2">
                            <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                          </button>
                        </div>
                      </div>
                      </div>
                    </div> -->
                     
                    <!-- Feedbacks and Suggesions -->
                    <div class="row feedback-heading" *ngIf="ChangeTab!=='unscheduled_tasks' && ChangeTab!=='overdue_visits'">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <span class="cust_fleet_details">
                          Customer Suggestions and Feedback <span class="required">*</span>
                        </span>
                      </div>
                    </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top:1.2rem;"  *ngIf="ChangeTab!=='unscheduled_tasks' && ChangeTab!=='overdue_visits'">
                          <label for="spoc_name">Feedbacks <span class="required">*</span></label>
                          <textarea class="form-control" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null"
                          oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)]+$/g, '').replace(/(\..*)\./g, '$1');" 
                          formControlName="spocFeedback"></textarea>
                          <div class="error-message" *ngIf="fecTaskInformation.controls.spocFeedback.errors?.required && fecTaskInformation.controls.spocFeedback.touched">
                            Feedback is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocFeedback.errors?.required) && fecTaskInformation.controls.spocFeedback.errors && fecTaskInformation.controls.spocFeedback.touched">
                            Feedback is Invalid
                        </div>
                      </div>
                    <br>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label for="spoc_name">
                          <span *ngIf="ChangeTab=='scheduled_visits'">Reschedule<span class="required">* </span>
                            <input (change)="isNextVisitDateRequiredSch(Schfalg)" class="form-check-input" type="checkbox" name="inline-radios-example" [checked]="isNextVisit=='yes'">
                          </span>
                          <span *ngIf="ChangeTab=='completed_visits'">Next Visit Date</span>
                          <span *ngIf="ChangeTab=='unscheduled_tasks'">Set Visit Date</span>
                          <span *ngIf="ChangeTab=='overdue_visits'">Set Visit Date</span>
                        </label>
                      </div>
                    </div>
                    <div *ngIf="ChangeTab=='scheduled_visits'">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <!-- <label class="form-check form-check-inline ">
                          <input (change)="isNextVisitDateRequiredSch(Schfalg)" class="form-check-input" type="checkbox" name="inline-radios-example" [checked]="isNextVisit=='yes'">
                          <span class="form-check-label">Yes</span>
                        </label> -->
                        <!-- <label class="form-check form-check-inline" *ngIf="isNextVisit=='no'">
                          <input (change)="isNextVisitDateRequired('no')"  class="form-check-input" type="checkbox" name="inline-radios-example" [checked]="isNextVisit=='no'">
                          <span class="form-check-label">No</span>
                        </label> -->
                      </div>
                    </div>
                    <!-- [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" -->
                    <div class="row">
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngIf="Schfalg===true">
                        <input [min]="today" type="date" class="form-control sdate" name="nextvisitdate" formControlName="nextVisitDate" dateInput id="nextvisitdate" >
                      </div>
                    </div>
                    </div>
                    <div *ngIf="ChangeTab=='completed_visits'">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <label class="form-check form-check-inline">
                            <input (change)="isNextVisitDateRequired('yes')" class="form-check-input" type="radio" name="inline-radios-example" [checked]="isNextVisit=='yes'">
                            <span class="form-check-label">Yes</span>
                          </label>
                          <label class="form-check form-check-inline">
                            <input (change)="isNextVisitDateRequired('no')"  class="form-check-input" type="radio" name="inline-radios-example" [checked]="isNextVisit=='no'">
                            <span class="form-check-label">No</span>
                          </label>
                        </div>
                      </div>
                      <!-- [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" -->
                      <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngIf="isNextVisit =='yes'">
                          <input [min]="today" type="date" class="form-control sdate" name="nextvisitdate" formControlName="nextVisitDate" dateInput id="nextvisitdate" >
                        </div>
                      </div>
                      </div>
                    <div class="row" *ngIf="ChangeTab=='overdue_visits' || ChangeTab=='unscheduled_tasks'">
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                        <input [min]="today" type="date" class="form-control sdate" name="nextvisitdate" formControlName="nextVisitDate" dateInput id="nextvisitdate" >
                      </div>
                    </div>
                    <!-- <div class="row">
                      
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                        <span class="radiobutton" (click)="isNextVisitDateRequired('yes')">Yes</span>&nbsp;<input type="radio" [checked]="isNextVisit=='yes'" (click)="isNextVisitDateRequired('yes')">
                      </div>
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="margin-left:-12%;" >
                        <input type="date" class="form-control sdate" name="nextvisitdate" formControlName="nextVisitDate" dateInput id="nextvisitdate" *ngIf="isNextVisit == 'yes'">
                      </div>
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                        <span class="radiobutton" (click)="isNextVisitDateRequired('no')">No</span>&nbsp;<input type="radio" [checked]="isNextVisit=='no'" (click)="isNextVisitDateRequired('no')">
                      </div>
                      <div class="error-message" *ngIf="fecTaskInformation.controls.nextVisitDate.errors?.required && fecTaskInformation.controls.nextVisitDate.touched">
                        Next Visit is Required
                      </div>
                    </div> -->
                   
                  </form>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="ChangeTab=='completed_visits'">
            <div class="col-12">
              <div class="card external-css">
                <ng-container *ngIf="fecTaskInformation">
                  <form  enctype="multipart/form-data"  [formGroup]="fecTaskInformation">
                    <div class="row first_heading-row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <span class="cust_fleet_details">Customer Details
                           <!-- <span class="required">*
                           </span> -->
                          </span>
                      </div>
                    </div>
                    <div id="cust_fleet_details" class="row">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label for="fleet_size">Total Fleet Size</label>
                        <input disabled name="fleet_size" class="form-control"
                        oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)a-z]+$/g, '').replace(/(\..*)\./g, '$1');" 
                        type="text" formControlName="fleetSize" placeholder="Fleet Size" autocomplete="false">
                        <div class="error-message" *ngIf="fecTaskInformation.controls.fleetSize.errors?.required && fecTaskInformation.controls.fleetSize.touched">
                            Fleet Size is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.fleetSize.errors?.required) && fecTaskInformation.controls.fleetSize.errors && fecTaskInformation.controls.fleetSize.touched">
                            Fleet Size is Invalid
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="margin-bottom: 3%;">
                        <label for="industry_type">Industry Type</label>
                        <select disabled class="form-control ui dropdown"  id="industry_type" name="industry_type" formControlName="industryType" >
                          <option value="" selected="industryType==null">Select Industry Type</option>
                          <option  *ngFor="let option of industryTypeOptions" value="{{ option.key }}">{{ option.value }}</option>
                        </select>
                        <div class="error-message" *ngIf="fecTaskInformation.controls.industryType.errors?.required && fecTaskInformation.controls.industryType.touched">
                            Industry Type is Required
                        </div>
                      </div>
                    </div>
                    <div id="spoc_details" class="row">
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_name">Name</label>
                        <input disabled id="spoc_name" class="form-control"
                        oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');" 
                        type="text" formControlName="spocName" placeholder="SPOC Name" autocomplete="false">
                        <div class="error-message" *ngIf="fecTaskInformation.controls.spocName.errors?.required && fecTaskInformation.controls.spocName.touched">
                          SPOC Name is Required
                      </div>
                      <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocName.errors?.required) && fecTaskInformation.controls.spocName.errors && fecTaskInformation.controls.spocName.touched">
                          SPOC Name is Invalid
                      </div>
                      </div>
                      <!-- [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" -->
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_mobile">Mobile Number</label>
                        <input  disabled id="spoc_mobile" class="form-control" 
                        type="text" maxlength="10" formControlName="spocPhone" placeholder="SPOC Mobile Number" autocomplete="false">
                        <!-- <div class="error-message" *ngIf="fecTaskInformation.controls.spocPhone.errors?.required && fecTaskInformation.controls.spocPhone.touched">
                          SPOC Mobile No. is Required
                      </div>
                      <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocPhone.errors?.required) && fecTaskInformation.controls.spocPhone.errors && fecTaskInformation.controls.spocPhone.touched">
                          SPOC Mobile No. is Invalid
                      </div> -->
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_designation">Designation</label>
                        <input disabled class="form-control" type="text" formControlName="spocDesignation" placeholder="Designation" autocomplete="false">
                        <!-- <div class="error-message" *ngIf="fecTaskInformation.controls.spocDesignation.errors?.required && fecTaskInformation.controls.spocDesignation.touched">
                          SPOC Designation is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocDesignation.errors?.required) && fecTaskInformation.controls.spocDesignation.errors && fecTaskInformation.controls.spocDesignation.touched">
                          SPOC Designation is Invalid
                        </div> -->
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_visit_purpose">Purpose Of Visit</label>
                        <select disabled class="form-control ui dropdown" formControlName="spocPurposeOfVisit" id="spoc_visit_purpose">
                          <option *ngFor="let option of purposeOfVisitOptions" value="{{ option.key }}">{{ option.value }}</option>
                        </select>
                        <!-- <div class="error-message" *ngIf="fecTaskInformation.controls.spocPurposeOfVisit.errors?.required && fecTaskInformation.controls.spocPurposeOfVisit.touched">
                          Purpose Of Visit is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocPurposeOfVisit.errors?.required) && fecTaskInformation.controls.spocPurposeOfVisit.errors && fecTaskInformation.controls.spocPurposeOfVisit.touched">
                          Purpose Of Visit is Invalid
                        </div> -->
                      </div>
                    </div>
                    <!-- SPOC Details -->
                    <div class="row first_heading" style="margin-bottom:1rem;">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <span class="cust_fleet_details">SPOC Details
                        <!-- <span class="required">*</span> -->
                      </span>
                      </div>
                    </div>
                    <div id="spoc_details" class="row">
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_name">Name</label>
                        <input disabled id="spoc_name" class="form-control"
                        oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)0-9]+$/g, '').replace(/(\..*)\./g, '$1');" 
                        type="text" formControlName="spoc_name" placeholder="SPOC Name" autocomplete="false">
                        <div class="error-message" *ngIf="fecTaskInformation.controls.spocName.errors?.required && fecTaskInformation.controls.spocName.touched">
                          SPOC Name is Required
                      </div>
                      <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocName.errors?.required) && fecTaskInformation.controls.spocName.errors && fecTaskInformation.controls.spocName.touched">
                          SPOC Name is Invalid
                      </div>
                      </div>
                      <!-- [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" -->
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_mobile">Mobile Number</label>
                        <input disabled  id="spoc_mobile" class="form-control" 
                        type="text" maxlength="10" formControlName="spoc_mobile" placeholder="SPOC Mobile Number" autocomplete="false">
                        <!-- <div class="error-message" *ngIf="fecTaskInformation.controls.spocPhone.errors?.required && fecTaskInformation.controls.spocPhone.touched">
                          SPOC Mobile No. is Required
                      </div>
                      <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocPhone.errors?.required) && fecTaskInformation.controls.spocPhone.errors && fecTaskInformation.controls.spocPhone.touched">
                          SPOC Mobile No. is Invalid
                      </div> -->
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label for="spoc_designation">Designation</label>
                        <input disabled class="form-control" type="text" formControlName="spoc_designation" placeholder="SPOC Designation" autocomplete="false">
                        <!-- <div class="error-message" *ngIf="fecTaskInformation.controls.spocDesignation.errors?.required && fecTaskInformation.controls.spocDesignation.touched">
                          SPOC Designation is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocDesignation.errors?.required) && fecTaskInformation.controls.spocDesignation.errors && fecTaskInformation.controls.spocDesignation.touched">
                          SPOC Designation is Invalid
                        </div> -->
                      </div>
                    </div>
          
                    <!-- Media Files -->
                    <div class="row first_heading" id="attachments" *ngIf="ChangeTab!=='unscheduled_tasks' && ChangeTab!=='overdue_visits'">
                      <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                        <label for="attachments" *ngIf="ChangeTab=='scheduled_visits'">
                          Add Media <span class="required-msg">
                          Upload upto max 20mb of images.
                         </span>
                        </label>
                        <label for="attachments" *ngIf="ChangeTab=='completed_visits'">
                          View Media 
                          <!-- <span class="required-msg">
                          Upload upto max 20mb of images.
                         </span> -->
                        </label>
                      <div style="display: flex;display:none">
                        <div class="image-box">
                          <input type="file" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" id="files-selector" style="display: none;" (change)="onSelectFile($event)" multiple accept=".png, .jpeg, .jpg" name="fileInput" />
                          <i (click)="openFileSelector($event.target)" class="pi pi-upload" style="font-size: 2rem;
                          padding: 22%;">
                          </i>
                        </div>
                        <div class="image-row row"  *ngFor="let resp of fecTaskInformationData.upload_image_path;index as i">
                          <div class="col-3 show-image" >
                            <!-- {{item}} -->
                            <img target="_blank" [src]="resp.url" class="img-fluids" (click)="imagepreview(resp.url,ImgSrcModel,1)" data-toggle="modal" data-target="#myModal">
                          </div>
                          <!-- <div class="col-3 show-image">
                            <img src="assets/images/aadhaar.png" class="img-fluids">
                          </div>
                          <div class="col-3 show-image">
                            <img src="assets/images/aadhaar.png" class="img-fluids">
                          </div> -->
                        </div>
                      </div>
                 
                     <div class="image-row row">
                      <div class="col-3 show-image" *ngFor="let resp of fecTaskInformationData.upload_image_path;index as i">
                        <!-- <img [src]="resps.filext==='png' || 'jpeg' || 'jpg'  ? resps.ImgUrl:'assets/images/excel.png'" class="img-fluids"  target="_blank"> -->
                        <img [src]="onShowImgeIconsComplete(resp)" class="img-fluids"  target="_blank">
                        <div class="inner-content-cmp">
                          <!-- filext ImgUrl-->
                          <ul class="social-cmp">
                          
                            <!-- *ngIf="isValid;else other_content" -->
                            <li *ngIf="resp?.type==='png' || resp?.type==='jpeg' || resp?.type==='jpg'else other_content">
                              <a (click)="imagepreviewComplete(resp,ImgSrcModel,1)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              class="feathers-img feather-eye align-middle me-2">
                              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                              <circle cx="12" cy="12" r="3"></circle>
                              </svg>
                            </a>
                          </li>
                          <ng-template #other_content>
                            <li>
                              <a (click)="imagepreviewComplete(resp,ImgSrcModel,2)">
                              <svg xmlns="http://www.w3.org/2000/svg" 
                              width="24" height="24" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"
                              class="feathers-img feather-download align-middle">
                              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                              <polyline points="7 10 12 15 17 10"></polyline>
                              <line x1="12" y1="15" x2="12" y2="3"></line>
                              </svg>
                             </a>
                          </li>
                          </ng-template>

                          
                          </ul>
                        </div>
                      </div>
                     </div>

                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label style="margin-bottom: 10px;">Sales Opportunity Window <span class="required">*
                          <span data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top" style="color:#307FE2;"><svg xmlns="http://www.w3.org/2000/svg" 
                          width="24" height="24" viewBox="0 0 24 24"
                          fill="none" stroke="currentColor" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-alert-circle align-middle me-2">
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="12"></line>
                          <line x1="12" y1="16" x2="12.01" y2="16"></line>
                          </svg>
                        </span>
                         </span>
                        </label>
                        <div >
                          <label class="form-check label-check">
                            <input formControlName="salesOpportunity" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" class="form-check-input" type="radio" value="green"  [checked]="sales_opty=='green'">
                            <span class="form-check-label frm-chk-text">
                              Interseted
                            </span>
                          </label>
                          <label class="form-check">
                            <input formControlName="salesOpportunity" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" class="form-check-input" type="radio" value="yellow"  [checked]="sales_opty=='yellow'">
                            <span class="form-check-label frm-chk-text">
                              Not Sure
                            </span>
                          </label>
                          <label class="form-check">
                            <input formControlName="salesOpportunity" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" class="form-check-input" type="radio" value="red"   [checked]="sales_opty=='red'">
                            <span class="form-check-label frm-chk-text">
                              Not Interested
                            </span>
                          </label>
                        </div>
                      </div>
                      <!-- <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label>Sales Opportunity <span class="required">*</span></label>
                      </div> -->
                    </div>
                    <!-- <div id="attachments" class="row">
                      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-4">
                        <input type="file" id="files-selector" style="display: none;" (change)="onSelectFile($event)" multiple accept=".png, .jpeg, .jpg" name="fileInput" />
                        <button class="p-button p-button-outlined p-button-secondary file-selector-btn" type="button" (click)="openFileSelector($event.target)">
                          <i class="pi pi-upload" style="font-size: 2rem"></i>
                          Upload Images
                        </button>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-5 col-xs-5">
                        <div class="media_files" *ngIf="fecTaskInformation && fecTaskInformation.value && fecTaskInformation.value.attachments">
                          <div class="file_preview" *ngFor='let url of fecTaskInformation.value.attachments'>
                            <img [src]="url">
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <select formControlName="salesOpportunity" class="salesDropdown">
                          <option class="salesoption" value="red" style="color: #df0000;" [selected]>Red<span class="optioncolor" >&#8213;</span></option>
                          <option class="salesoption" value="green" style="color: #0C8F31;">Green<span class="optioncolor" ></span>&#8213;</option>
                          <option class="salesoption" value="yellow" style="color: #E6DC00;">Yellow<span class="optioncolor" ></span>&#8213;</option>
                        </select>
                        <div class="error-message" *ngIf="fecTaskInformation.controls.salesOpportunity.errors?.required && fecTaskInformation.controls.salesOpportunity.touched">
                          Sales oppurtunity is Required
                      </div>
                      </div>
                    </div> -->
                     <!-- location -->
                     <!-- <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="location">
                        <div class="location-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" 
                      width="24" height="24" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-alert-circle align-middle me-2">
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                      <circle cx="12" cy="10" r="3"></circle>
                      </svg>
                        </div>
                        <div class="location-text">location captured</div>
                        <div class="">
                          <button class="btn btn-success location-cap">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                            width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round"
                            class="feathers feather feather-check align-middle me-2">
                            <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                          </button>
                        </div>
                      </div>
                      </div>
                    </div> -->
                     
                    <!-- Feedbacks and Suggesions -->
                    <div class="row feedback-heading" *ngIf="ChangeTab!=='unscheduled_tasks' && ChangeTab!=='overdue_visits'">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <span class="cust_fleet_details">
                          Customer Suggestions and Feedback <span class="required">*</span>
                        </span>
                      </div>
                    </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top:1.2rem;"  *ngIf="ChangeTab!=='unscheduled_tasks' && ChangeTab!=='overdue_visits'">
                          <label for="spoc_name">Feedbacks <span class="required">*</span></label>
                          <textarea class="form-control" [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null"
                          oninput="this.value = this.value.replace(/[=<>{}:;,+/_~\-!@#\$%\^&\*\(\)]+$/g, '').replace(/(\..*)\./g, '$1');" 
                          formControlName="spocFeedback"></textarea>
                          <div class="error-message" *ngIf="fecTaskInformation.controls.spocFeedback.errors?.required && fecTaskInformation.controls.spocFeedback.touched">
                            Feedback is Required
                        </div>
                        <div class="error-message" *ngIf="!(fecTaskInformation.controls.spocFeedback.errors?.required) && fecTaskInformation.controls.spocFeedback.errors && fecTaskInformation.controls.spocFeedback.touched">
                            Feedback is Invalid
                        </div>
                      </div>
                    <br>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label for="spoc_name">
                          <span *ngIf="ChangeTab=='scheduled_visits'">Reset Visit Date</span>
                          <span *ngIf="ChangeTab=='completed_visits'">Next Visit Date</span>
                          <span *ngIf="ChangeTab=='unscheduled_tasks'">Set Visit Date</span>
                          <span *ngIf="ChangeTab=='overdue_visits'">Set Visit Date</span>


                          <span class="required">*</span></label>
                      </div>
                    </div>
                    <div *ngIf="ChangeTab=='scheduled_visits'">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label class="form-check form-check-inline ">
                          <input (change)="isNextVisitDateRequiredSch(Schfalg)" class="form-check-input" type="checkbox" name="inline-radios-example" [checked]="isNextVisit=='yes'">
                          <span class="form-check-label">Yes</span>
                        </label>
                        <!-- <label class="form-check form-check-inline" *ngIf="isNextVisit=='no'">
                          <input (change)="isNextVisitDateRequired('no')"  class="form-check-input" type="checkbox" name="inline-radios-example" [checked]="isNextVisit=='no'">
                          <span class="form-check-label">No</span>
                        </label> -->
                      </div>
                    </div>
                    <!-- [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" -->
                    <div class="row">
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngIf="Schfalg===true">
                        <input [min]="today" type="date" class="form-control sdate" name="nextvisitdate" formControlName="nextVisitDate" dateInput id="nextvisitdate" >
                      </div>
                    </div>
                    </div>
                    <div *ngIf="ChangeTab=='completed_visits'">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <label class="form-check form-check-inline">
                            <input (change)="isNextVisitDateRequired('yes')" class="form-check-input" type="radio" name="inline-radios-example" [checked]="isNextVisit=='yes'">
                            <span class="form-check-label">Yes</span>
                          </label>
                          <label class="form-check form-check-inline">
                            <input (change)="isNextVisitDateRequired('no')"  class="form-check-input" type="radio" name="inline-radios-example" [checked]="isNextVisit=='no'">
                            <span class="form-check-label">No</span>
                          </label>
                        </div>
                      </div>
                      <!-- [attr.disabled]="ChangeTab!=='scheduled_visits' ? 'disabled':null" -->
                      <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngIf="isNextVisit =='yes'">
                          <input [min]="today" type="date" class="form-control sdate" name="nextvisitdate" formControlName="nextVisitDate" dateInput id="nextvisitdate" >
                        </div>
                      </div>
                      </div>
                    <div class="row" *ngIf="ChangeTab=='overdue_visits' || ChangeTab=='unscheduled_tasks'">
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                        <input [min]="today" type="date" class="form-control sdate" name="nextvisitdate" formControlName="nextVisitDate" dateInput id="nextvisitdate" >
                      </div>
                    </div>
                    <!-- <div class="row">
                      
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                        <span class="radiobutton" (click)="isNextVisitDateRequired('yes')">Yes</span>&nbsp;<input type="radio" [checked]="isNextVisit=='yes'" (click)="isNextVisitDateRequired('yes')">
                      </div>
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="margin-left:-12%;" >
                        <input type="date" class="form-control sdate" name="nextvisitdate" formControlName="nextVisitDate" dateInput id="nextvisitdate" *ngIf="isNextVisit == 'yes'">
                      </div>
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                        <span class="radiobutton" (click)="isNextVisitDateRequired('no')">No</span>&nbsp;<input type="radio" [checked]="isNextVisit=='no'" (click)="isNextVisitDateRequired('no')">
                      </div>
                      <div class="error-message" *ngIf="fecTaskInformation.controls.nextVisitDate.errors?.required && fecTaskInformation.controls.nextVisitDate.touched">
                        Next Visit is Required
                      </div>
                    </div> -->
                   
                  </form>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
       
      </main>
      <div class="final-divider">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="final-divider-info">
              <div class="final-divider-text" [style.display]="ChangeTab=='scheduled_visits' && isNextVisit=='yes' ? 'block':'none'">
                Click on the button to set date with this customer!
              </div>
              <div class="final-divider-text" [style.display]="ChangeTab=='scheduled_visits' && isNextVisit=='no' ? 'block':'none'">
                Click on the button to set date with this customer!
              </div>
              <div class="final-divider-text" *ngIf="ChangeTab!=='scheduled_visits'">
                Click on the button to set  
                <span *ngIf="ChangeTab=='completed_visits'">the next visit</span>
                <span *ngIf="ChangeTab=='unscheduled_tasks'">visit</span>
                date with this customer!
              </div>
              <div class="task-btn-margin" [style.display]="ChangeTab=='scheduled_visits' ? 'block':'none' " >
              <button [hidden]="isNextVisit=='no'" type="button" label="Complete Task" class="p-button p-button-outlined p-button-secondary form-btns submit-btn" (click)="submitTask(schedTask)">Reschedule</button>
              <button [hidden]="isNextVisit=='yes'" type="button" label="Complete Task" class="p-button p-button-outlined p-button-secondary form-btns submit-btn" (click)="submitTask(schedTask)">Complete Task</button>
              </div>
              <!-- <div class="task-btn-margin" [style.display]="ChangeTab=='scheduled_visits' ? 'block':'none' " >
                <button type="button" label="Complete Task" class="p-button p-button-outlined p-button-secondary form-btns submit-btn" (click)="submitTask(schedTask)">Submit</button>
                </div> -->
              <div class="task-btn-margin" [style.display]="ChangeTab=='completed_visits'? 'block':'none'">
              <button type="button" label="Submit" class="p-button p-button-outlined p-button-secondary form-btns submit-btn" (click)="submitCompletedTask(completedTask)">Submit</button>
              </div>
              <div class="task-btn-margin" [style.display]="ChangeTab=='unscheduled_tasks'? 'block':'none'">
                <button type="button" label="Submit" class="p-button p-button-outlined p-button-secondary form-btns submit-btn" (click)="submitUnshedTask(UnschedTask)">Submit</button>
                </div>
                <div class="task-btn-margin" [style.display]="ChangeTab=='overdue_visits'? 'block':'none'">
                <button type="button" label="Submit" class="p-button p-button-outlined p-button-secondary form-btns submit-btn" (click)="submitOverDueTask(OverDueTask)">Submit</button>
                </div>
              </div>
            <!-- <button  data-bs-toggle="modal" data-bs-target="#taskcomplete"
            pButton pRipple type="button" label="Complete Task" class="p-button p-button-outlined p-button-secondary form-btns submit-btn" (click)="submitTask()"></button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="taskcomplete" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h5 class="modal-title">Set Visit Date</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> -->
      <div class="modal-body">
        <div class="complete-task-align">
          <div class="stat stat-sm">
            <svg xmlns="http://www.w3.org/2000/svg" 
              width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-check align-middle me-2">
              <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
          </div>
        </div>
        <div class="task-comp-text">Task Completed</div>
        <!-- <div class="task-comp-text-success">Your task is successfully completed</div> -->
        <div class="all-task-comp-text">
          All your completed tasks will be sent to your respective
               Regional Sales Manager for record purposes.
        </div>
        <div class="complete-visit-btn">
          <button type="button" class="btn btn-completed btn-lg">
            <span class="goto-complete-visit">go to completed visits</span>
          </button>
        </div>
      </div>
     
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button"  class="btn btn-primary">Submit</button>
      </div> -->
    </div>
  </div>
</div>
<!-- Next Visit Date popup completedTask -->
<div class="modal fade" id="nextVisitDatepopup" tabindex="-1" role="dialog" aria-hidden="true" #nextVisitDatepopup>
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="complete-task-align">
          <div class="stat stat-sm">
            <svg xmlns="http://www.w3.org/2000/svg" 
              width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-check align-middle me-2">
              <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
          </div>
        </div>
        <div class="task-comp-text">Next Visit Date Set</div>
        <div class="task-comp-text-success">You have set the next visit date for 10/02/2023</div>
        <div class="all-task-comp-text">
         You can view this task in your scheduled tasks list for action.
        </div>
        <div class="complete-visit-btn">
          <button type="button" class="btn btn-completed btn-lg">
            <span class="goto-complete-visit">go to Sch visits</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #completedTask let-modal>
  <div class="modal-content" *ngIf="isNextVisit=='yes'">
    <div class="modal-body">
      <div class="complete-task-align">
        <div class="stat stat-sm">
          <svg xmlns="http://www.w3.org/2000/svg" 
            width="24" height="24" viewBox="0 0 24 24"
            fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-check align-middle me-2">
            <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
        </div>
      </div>
      <div class="task-comp-text">Next Visit Date is Set</div>
      <div class="task-comp-text-success">You have set the next visit date for {{NextVisitDate}}</div>
      <div class="all-task-comp-text">
       You can view this task in your Scheduled tasks list for action.
      </div>
      <div class="complete-visit-btn">
        <button type="button" class="btn btn-completed btn-lg">
          <span class="goto-complete-visit" (click)="closeCompletepopup('scheduled_visits')">
            go to Scheduled visits
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-content" *ngIf="isNextVisit=='no'">
    <div class="modal-body">
      <div class="complete-task-align">
        <div class="stat stat-sm">
          <svg xmlns="http://www.w3.org/2000/svg" 
            width="24" height="24" viewBox="0 0 24 24"
            fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-check align-middle me-2">
            <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
        </div>
      </div>
      <div class="task-comp-text">Next Visit Date is not Set</div>
      <!-- <div class="task-comp-text-success">You have set the next visit date for {{NextVisitDate}}</div> -->
      <div class="all-task-comp-text">
       You can view this task in your Completed  tasks list for action.
      </div>
      <div class="complete-visit-btn">
        <button type="button" class="btn btn-completed btn-lg">
          <span class="goto-complete-visit" (click)="closeCompletepopup('completed_visits')">
            go to Completed visits
          </span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #UnschedTask let-modal>
  <div class="modal-body">
    <div class="complete-task-align">
      <div class="stat stat-sm">
        <svg xmlns="http://www.w3.org/2000/svg" 
          width="24" height="24" viewBox="0 0 24 24"
          fill="none" stroke="currentColor" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-check align-middle me-2">
          <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
      </div>
    </div>
    <div class="task-comp-text">Visit Date Set</div>
    <div class="task-comp-text-success">You have set the visit date for {{NextVisitDate}}</div>
    <div class="all-task-comp-text">
     You can view this task in your scheduled tasks list for action.
    </div>
    <div class="complete-visit-btn">
      <button type="button" class="btn btn-completed btn-lg">
        <span class="goto-complete-visit"  (click)="closeCompletepopup('scheduled_visits')">go to Scheduled visits</span>
      </button>
    </div>
  </div>
</ng-template>

<!-- Scheduled Task -->
<ng-template #schedTask1 let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="complete-task-align">
        <div class="stat stat-sm">
          <svg xmlns="http://www.w3.org/2000/svg" 
            width="24" height="24" viewBox="0 0 24 24"
            fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-check align-middle me-2">
            <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
        </div>
      </div>
      <div class="task-comp-text">Task Completed</div>
      <!-- <div class="task-comp-text-success">Your task is successfully completed</div> -->
      <div class="all-task-comp-text">
        All your completed tasks will be sent to your respective
             Regional Sales Manager for record purposes.
      </div>
      <div class="complete-visit-btn">
        <button type="button" class="btn btn-completed btn-lg">
          <span class="goto-complete-visit" (click)="closeCompletepopup('completed_visits')">go to completed visits</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- OverDue OverDueTask -->
<ng-template #OverDueTask let-modal>
  <div class="modal-content">
  <div class="modal-body">
    <div class="complete-task-align">
      <div class="stat stat-sm">
        <svg xmlns="http://www.w3.org/2000/svg" 
          width="24" height="24" viewBox="0 0 24 24"
          fill="none" stroke="currentColor" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-check align-middle me-2">
          <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
      </div>
    </div>
    <div class="task-comp-text">Visit Date Set</div>
    <div class="task-comp-text-success">You have set the visit date for {{NextVisitDate}}</div>
    <div class="all-task-comp-text">
     You can view this task in your scheduled tasks list for action.
    </div>
    <div class="complete-visit-btn">
      <button type="button" class="btn btn-completed btn-lg">
        <span class="goto-complete-visit" (click)="closeCompletepopup('scheduled_visits')">go to Scheduled visits</span>
      </button>
    </div>
  </div> 
  </div>
</ng-template>

<!-- OverDue schTask -->
<ng-template #schedTask let-modal>
  <div class="modal-content">
  <div class="modal-body">
    <div class="complete-task-align">
      <div class="stat stat-sm">
        <svg xmlns="http://www.w3.org/2000/svg" 
          width="24" height="24" viewBox="0 0 24 24"
          fill="none" stroke="currentColor" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-check align-middle me-2">
          <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
      </div>
    </div>
    <div class="task-comp-text">Next Visit Date Set</div>
    <div class="task-comp-text-success">You have set the next visit date for {{NextVisitDate}}</div>
    <div class="all-task-comp-text">
     You can view this task in your scheduled tasks list for action.
    </div>
    <div class="complete-visit-btn" *ngIf="isNextVisit=='yes'">
      <button type="button" class="btn btn-completed btn-lg">
        <span class="goto-complete-visit" (click)="closeCompletepopup('scheduled_visits')">go to Scheduled visits</span>
      </button>
    </div>
    <div class="complete-visit-btn" *ngIf="isNextVisit=='no'">
      <button type="button" class="btn btn-completed btn-lg">
        <span class="goto-complete-visit" (click)="closeCompletepopup('completed_visits')">go to Completed visits</span>
      </button>
    </div>
  </div> 
  </div>
</ng-template>
<ng-template #ImgSrcModel let-modal>
  <div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Image Preview</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
       <img
    src="{{imgSrc}}"
    style="max-width:100%;cursor:pointer"
    class="modal-hover-opacity"
  /> 
    </div>
  </div>
  </div>
 
  
  </ng-template>

  <ng-image-fullscreen-view
  [images]="imageObject"
  [imageIndex]="currentIndex"
  [show]="showFlag"
  [target]="'blank'"
  (close)="closeEventHandler()">
</ng-image-fullscreen-view>



