import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as $ from "jquery";
// import { MessageService, ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Observable, observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  APIConstants: any = environment;
  today = new Date();
  selectedFiles:any = [];
  allowedFileTypes:any = ["xlsx", "XLSX"];
  acceptFileTypes = "."+this.allowedFileTypes.join(", .");
  fileUploadSizeInMB:number = 15;
  fileUploadResponse: BehaviorSubject<object> = new BehaviorSubject<object>({});
  ImageFile:any;
  @Input() getActionToBePerformed:any = [];
  @Output() closeFileUploadDialog = new EventEmitter<any>();
  @Output() executeUploadFile = new EventEmitter<any>();

  constructor(
    // private messageService:MessageService,
    // private baseAPIService: BaseApiService,
    // private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.today = new Date();
    this.reInitialize();
  }

  reInitialize():void {
    this.selectedFiles = [];
    $(".file__upload").addClass('hide');
    $(".box__upload").removeClass('hide');
  }

  openFileSelector(ele:any):void {
    var fileSelector = ele.closest('.box__upload').querySelector(".box__file");
    fileSelector.click();
  }

  validateMaxUploadedFileLimit(files:any):any {
    var limit = 1;
    if (files && files.length > limit) {
        return {boolIsValid:false, message:"You can only upload a maximum of "+limit+" files"};
    } else {
        return {boolIsValid:true, message:""};
    }
  }

  validateUploadedFileType(file:any):any {
    let allowedFileTypes = this.allowedFileTypes;
    var fileName = file.name;
    if (!fileName) return {boolIsValid:false, message:"Unknown file Type"};
    var dots = fileName.split(".")
    //get the part AFTER the LAST period.
    var fileType = dots[dots.length-1]; 
    fileType = fileType.toLowerCase();

    if (!(dots.length==2 && allowedFileTypes.includes(fileType))) {
      var errMessage = "You have Uploaded file of '."+fileType+"' type. Please upload file with types ."+ allowedFileTypes.join(", .")+"";
      return {boolIsValid:false, message:errMessage};
    } else {
      return {boolIsValid:true, message:""};
    }
  }

  validateUploadedFileSize(file:any):any {
    var size_limit = 1024*1000*this.fileUploadSizeInMB; 
    if (file.size > size_limit) {
        return {boolIsValid:false, message:"Uploaded File Limit should be less than or equal to "+this.fileUploadSizeInMB+" MB"};
    } else {
        return {boolIsValid:true, message:""};
    }
  }

  changeSelectedFiles(event:any):void{
    if(event.target.files){
      var allFiles = event.target.files;
      this.validate(allFiles, event);
      this.ImageFile='';
    }
  }

  droppedFiles(allFiles: File[]): void {
    this.validate(allFiles, null);
  }


  validate(allFiles:any, event:any):void {
    let filesCount = allFiles.length;
    let boolIsAllFilesValid = true;
    for (let i = 0; i < filesCount; i++) {
      var validateFile = this.validateUploadedFileType(allFiles[i]);
      if(!(validateFile && validateFile["boolIsValid"])){
        if(event && event["target"]){
          event.target.value = '';
        }
        $(".box__file").val("");
        this.selectedFiles = [];
        boolIsAllFilesValid = false;

        var errMsg = "";
        if(validateFile["message"]){
          errMsg = validateFile["message"];
        }
        // this.messageService.add({key: 'fileUploadMessage', severity:'error', summary: 'Error', detail:errMsg, life: 7000});
        break;
      }
    }
    if(boolIsAllFilesValid){
      this.validateSelectedFiles(allFiles);
    } else {
      this.verifyFileUpload();
    }
  }

  validateSelectedFiles(allFiles:any):void {
    $(".mainLoader").show();
    $(".file__upload").addClass('hide');
    $(".box__uploading").removeClass('hide');
    this.selectedFiles = [];
    var validateMaxFileCount = this.validateMaxUploadedFileLimit(allFiles);
    if(validateMaxFileCount && validateMaxFileCount["boolIsValid"]){
      let filesCount = allFiles.length;
      for (let i = 0; i < filesCount; i++) {
        let file = allFiles[i];
        var validateFile = this.validateUploadedFileSize(file);
        if(validateFile && validateFile["boolIsValid"]){
          this.selectedFiles.push(file);
        } else {
          var errMsg = "";
          if(validateFile["message"]){
            errMsg = validateFile["message"];
          }
          // this.messageService.add({key: 'fileUploadMessage', severity:'error', summary: 'Error', detail:errMsg, life: 7000});

          // this.selectedFiles = [];
          // break;
        }
      }
    } else {
      var errMsg = "Please select one file at a time";
      if(validateMaxFileCount["message"]){
        errMsg = validateMaxFileCount["message"];
      }
      // this.messageService.add({key: 'fileUploadMessage', severity:'error', summary: 'Error', detail:errMsg, life: 7000});
    }
    this.verifyFileUpload();
  }

  uploadFiles():void {
    this.executeUploadFile.emit(this.selectedFiles);
  }

  verifyFileUpload():void{
    setTimeout(() => {
      $(".file__upload").addClass('hide');
      if(this.selectedFiles && this.selectedFiles.length){
        this.uploadFiles();
        $(".box__success").removeClass('hide');
      } else {
        $(".box__error").removeClass('hide');
      }
      setTimeout(() => {
        $(".mainLoader").hide();
        $(".file__upload").addClass('hide');
        $(".box__upload").removeClass('hide');
      }, 2000);
    }, 1000);
    console.log("this.selectedFiles", this.selectedFiles);
  }

}
