import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { HeaderComponent } from './shared/header/header.component';
import { NgSelectModule } from '@ng-select/ng-select';

// import { AgmCoreModule } from '@agm/core';

//npm uninstall --save-dev angular-cli

//npm uninstall --save-dev plyr ngx-plyr

// form elements
// import { ScrollingModule } from '@angular/cdk/scrolling';
// import { PlyrModule } from 'ngx-plyr';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { SliderModule } from 'primeng/slider';
import {ButtonModule} from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabViewModule } from 'primeng/tabview';
import { DividerModule } from 'primeng/divider';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view'; // ng-image-fullscreen-view

// import { HideShowDirective } from './hide-show.directive';

import { CalendarComponent } from './shared/calendar/calendar.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LoginService } from './login.service';
import { OnboardingComponent } from './Components/onboarding/onboarding.component';

import { LoginComponent } from './Components/login/login.component';
import { EventDataComponent } from './Components/event-data/event-data.component';
// import { TelemetryDataComponent } from './Components/telemetry-data/telemetry-data.component';
import { VehicleCanDataComponent } from './Components/vehicle-can-data/vehicle-can-data.component';
import { VehicleFuelComponent } from './Components/vehicle-fuel/vehicle-fuel.component';
// import { VehicleIgnitionDataComponent } from './Components/vehicle-ignition-data/vehicle-ignition-data.component';

import { VehicleListComponent } from './Components/vehicle-list/vehicle-list.component';
import { ClientListComponent } from './Components/client-list/client-list.component';
import { ClientApiComponent } from './Components/client-api/client-api.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';

import { ClientinnerpageComponent } from './Components/clientinnerpage/clientinnerpage.component';

import { MatPaginatorModule } from '@angular/material/paginator';

// import {HTTP_INTERCEPTORS} from '@angular/common/http';
// import {LoggingInterceptorService} from './services/logging-interceptor.service';
import { WatermarkComponent } from './shared/watermark/watermark.component';
import { AggregationComponent } from './Components/aggregation/aggregation.component';
import { FecassignmentComponent } from './Components/fecassignment/fecassignment.component';
import { PerformancedataComponent } from './Components/performancedata/performancedata.component';
import { MaintenancedataComponent } from './Components/maintenancedata/maintenancedata.component';
import { AssigndataComponent } from './Components/assigndata/assigndata.component';
import { FectaskdetailComponent } from './Components/fectaskdetail/fectaskdetail.component';
import { RsmdashboardComponent } from './Components/rsmdashboard/rsmdashboard.component';
import { FecattributesComponent } from './Components/fecattributes/fecattributes.component';
import { FecdashboardComponent } from './Components/fecdashboard/fecdashboard.component';
import { FectasksComponent } from './Components/fectasks/fectasks.component';
import { CustomerdetailsComponent } from './Components/customerdetails/customerdetails.component';
import { FecAttributeCustomerDetailsComponent } from './Components/fec-attribute-customer-details/fec-attribute-customer-details.component';
import { FileUploadDropzoneDirective } from './shared/fileUploadDropzone/file-upload-dropzone.directive';
import { FileUploadComponent } from './Components/file-upload/file-upload.component';
import { ExcelService } from './excel-service.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FecViewHistoryComponent } from './Components/fec-view-history/fec-view-history.component';
import { ToastrModule } from 'ngx-toastr';
import { LayoutModule } from './shared/layout.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'
import { NgIdleModule } from "@ng-idle/core";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  slidesPerView: 1,
  a11y: true
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    // HideShowDirective,
    OnboardingComponent,
    CalendarComponent,
    LoginComponent,
    EventDataComponent,
    VehicleCanDataComponent,
    VehicleFuelComponent,
    // VehicleIgnitionDataComponent,
    VehicleListComponent, ClientListComponent, ClientApiComponent, ClientinnerpageComponent,
    WatermarkComponent,
    AggregationComponent,
    FecassignmentComponent,
    PerformancedataComponent,
    MaintenancedataComponent,
    AssigndataComponent,
    FectaskdetailComponent,
    RsmdashboardComponent,
    FecattributesComponent,
    FecdashboardComponent,
    FectasksComponent,
    CustomerdetailsComponent,
    FecAttributeCustomerDetailsComponent,
    FileUploadComponent,
    FileUploadDropzoneDirective,
    FecViewHistoryComponent,
  ],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    NgIdleModule.forRoot(),
    BrowserModule,
    LayoutModule,
    HttpClientModule,
    SwiperModule,
    CommonModule,
    //PlyrModule,
    NgSelectModule,
    Ng2SearchPipeModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatPaginatorModule,
    AppRoutingModule,
    TabViewModule,
    FormsModule,
    InputTextModule,
    AutoCompleteModule,
    DropdownModule,
    CalendarModule,
    FileUploadModule,
    AccordionModule,
    CheckboxModule,
    DialogModule,
    ButtonModule,
    SelectButtonModule,
    RadioButtonModule,
    SliderModule,
    MultiSelectModule,
    TableModule,
    PaginatorModule,
    CheckboxModule,
    NgxSpinnerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ConfirmDialogModule,
    DividerModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgImageFullscreenViewModule,
    // AgmCoreModule.forRoot({
    //   // apiKey:'AIzaSyA7j0OjoF3dTSmefvQf-uLannoRXh9Bfcs'
    // }),
    ToastrModule.forRoot({
      timeOut: 3000,
      toastClass: 'ngx-toastr',
      extendedTimeOut: 1000 
    }),
    NgxPaginationModule,
    NgbModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DatePipe,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    LoginService, ConfirmationService, ExcelService,
    // {provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptorService, multi:true}
  ],
  bootstrap: [AppComponent]
})


export class AppModule {

}
