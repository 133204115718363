<div class="watermark">
    {{username}}
    <ng-template [ngIf]="singlerecord">
      , 
    </ng-template>
    <ng-template [ngIf]="!singlerecord">
      <br/>
    </ng-template>
    {{todayDate}}
    <ng-template [ngIf]="singlerecord">
      , 
    </ng-template>
    <ng-template [ngIf]="!singlerecord">
      <br/>
    </ng-template>
    
    ip:{{ipAddress}}    
  </div>