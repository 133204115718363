import { Component, OnInit,ViewChild } from '@angular/core';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';

import { BaseApiService } from 'src/app/base-api.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as XLSX from 'xlsx';
import { RouterModule, Router } from '@angular/router';
 
 import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

declare var myExtObject: any;
declare var webGlObject: any;
@Component({
  selector: 'app-vehicle-fuel',
  templateUrl: './vehicle-fuel.component.html',
  styleUrls: ['./vehicle-fuel.component.scss']
})
export class VehicleFuelComponent implements OnInit {
	
	
       

  constructor(private router: Router,private baseAPI: BaseApiService, public spinnerService: NgxSpinnerService,private http: HttpClient) {
          var token =localStorage.getItem('token');
	       
			if(token==undefined || token==='' ){
			  this.router.navigate(['/login']);
			}
			
			 webGlObject.init();
			

		 }
	  
	     //@ViewChild('form') form;
			dropdownList = [];
			selectedItems = [];
			//dropdownSettings = {};
			dropdownSettings: IDropdownSettings;
			
			
			
	
	resultCheck:boolean = false; 	
	 globalData:any=[];
	event_array= [];
	selectedSPN_array= [];
	pageOfItems = [];
	 editStartDate:string;
     editEndDate:string;
	 start_date:string;
	 end_date:string;
	 pgnNo:string;
	searchData:string;
	public  apps: any[] = [];
  vechileDetails: any;
   public min = new Date(2018, 1, 12, 10, 30);
	 
	

    // Max moment: April 21 2018, 20:30
	public yearMax=this.getCurrentDay('Y');
	public monthMax=this.getCurrentDay('M');
	public dateMax=this.getCurrentDay('D');
	private todate = new Date();
	 public Hours=this.todate.getHours();
    public getMinutes=this.todate.getMinutes();
    public max = new Date(this.yearMax, this.monthMax, this.dateMax, 20, 30);
	
	
	 public myDate: IMyDate = {

    year: this.getCurrentDay('Y'),
    month: this.getCurrentDay('M'),
    day: this.getCurrentDay('D'),
  }
	 
  getCurrentDay(cat): any {
    let output;
    var today = new Date();
    var day = today.getDate();
	 var month = today.getMonth() + 1; //January is 0!
    if (day != 1) {
        //day = day - 1;
    }else{
		if(month==5 || month==7 || month==10 || month==12)
		{
			day=30;
		}
		
		if(month==2 || month===4 || month==6 || month==8 ||month==9 || month==11 || month==1)
		{
			day=31;
		}
		
		if (year % 4 == 0 && month==3) 
		{
			day=29;
		}
		else if (year % 4 != 0 && month==3) 
		{
			day=28;
		}
		month=month-1;
	}

   
	//alert(day+"#"+month);
    var year = today.getFullYear();

    if (cat === 'D') {
      output = day;
    }
    else if (cat === 'M') {
      output = month;
    }
    else {
      output = year;
    }
    return output;
  }
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    markCurrentDay: true,
   // disableUntil: this.myDate  vinay this is for disble 
    disableSince :  this.myDate

  };
  
  
  
    compareDate()
	{
		
		
		var startDate = new Date(this.editStartDate);
		var endDate = new Date(this.editEndDate);

		var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
		var stString=Date.parse(startDate.toUTCString());
		var edString=Date.parse(endDate.toUTCString());

	  if (endDate < startDate || seconds>86400) 
	  {
			alert("Only 24 hours data is allowed");
			this.editStartDate=null;
			this.editEndDate=null;	
            this.start_date="";			
	  }else if (edString ==stString) //86400
	  {
		alert("Start date and end date cannot be same");
		this.editStartDate=null;
			this.editEndDate=null;	
            this.start_date="";		
	  }else if(this.editEndDate!=null && this.editStartDate!=null)
		{
			
			
			 this.editStartDate=this.convert(this.editStartDate);
	         this.editEndDate=this.convert(this.editEndDate);
			 
			 
		}
	}
  
  convert(str) {
	 
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    var Hours=date.getHours();
    var getMinutes=date.getMinutes();
	
	var returnDtae=[date.getFullYear(), mnth, day].join("-");
	returnDtae=returnDtae+"T"+Hours+":"+getMinutes+":00";
  return returnDtae;
}

  onEditStartDateChanged(event) {
	 

   var splitedDate=this.start_date.toString().split(",");
	 
	  this.editStartDate=splitedDate[0];
	  this.editEndDate=splitedDate[1];
	  

	 
		if(this.editEndDate!=null && this.editStartDate!=null)
		{
			this.compareDate();
			
			
		}
		
		
	 }
	 
	applyFilter(event)
	{
		
		
		
	}
	 fetchReport() {
	  
	 
	 this.pgnNo=this.selectedSPN_array.toString();
	
	if(this.editStartDate === undefined && this.editEndDate !== undefined )
	{
		alert('Please enter proper date in the Start date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate !== undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate === undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the Start date and End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}

    if ((this.editStartDate === undefined || this.editStartDate === '' || this.editStartDate === null) ||
      (this.editEndDate === undefined || this.editEndDate === '' || this.editEndDate === null) ||
      (this.searchData === undefined || this.searchData === '' || this.searchData == '' || this.searchData == null ) ||(this.pgnNo === undefined || this.pgnNo === '' || this.pgnNo == '' || this.pgnNo == null) ) {
        alert('Please fill all mandatory fields.');

    }
    else {  

     
     

		if (window.confirm("Do you want to fetch report?")) 
	   {
		   
		    this.callReportApi();
           // alert("Notification created successfully");
			//this.reset1();
        } 
		else 
		{
            alert("Report serach is failed");
        }

    }
  }
	
	
	//==================================================================
	
	callReportApi() {
    this.spinnerService.show();
	
	//this.pgnNo='5245,976';
    let formData: FormData = new FormData();   
    formData.append('searchData', this.searchData);    
    formData.append('start_date', this.editStartDate);
    formData.append('end_date', this.editEndDate);
	/*[1:09 PM] Aditya Deshmukh (TTL)
    http://localhost:8080/cvp/telemetry-parameter/PROACC12091912303?fromDate=2020-08-25T10:03:05&toDate=2020-08-27T13:04:06&teleParam=total_fuel_used,total_distance*/

	var datastring="telemetry-parameter/"+this.searchData+"?fromDate="+this.editStartDate+"&toDate="+this.editEndDate+"&teleParam="+this.pgnNo;//64892  61441
	myExtObject.firebaseEvent('Fuel and Odometer', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"Search Button Click"});
	
	this.baseAPI.callServiceApi(this.baseAPI.baseUrl,datastring).then((data:any) => {
        this.resultCheck=false;
		this.event_array= [];
		let tempEvent:any;
		let jsonObj2 = {}; 
		var employees = {};
	  if(data.Information!=undefined)
	  {
		  
		  
		  alert(data.Information);
		  this.spinnerService.hide();
	  }else{
	  
	  //PROACC06022081648
	  //PROACC12091912303
	  // total_fuel_used
	  
			this.vechileDetails=<any>Object.values(data);	  
			//console.log(JSON.stringify(this.vechileDetails));  
			if(this.vechileDetails[0].total_distance!=undefined )
			{

				this.vechileDetails=this.vechileDetails[0].total_distance;
				employees["vinnumber"] = Object.keys(this.vechileDetails);
				employees["evetvalues"] = <any>Object.values(this.vechileDetails);
				//console.log(JSON.stringify(employees));
				//alert(this.vechileDetails.length);

				if(employees["vinnumber"]!=undefined && employees["vinnumber"].length>0)
				{
					for(let i =0; i < employees["vinnumber"].length;i++) {			  

					jsonObj2["eventDateTime"]=employees["vinnumber"][i];									  
					jsonObj2["spnName"]="Total Distance";
					jsonObj2["value"]=employees["evetvalues"][i];								  
					jsonObj2["spnNumber"]=516104;									  
					this.event_array.push(jsonObj2);
					jsonObj2 = {};	

					}

				}
			}
			this.vechileDetails=<any>Object.values(data);
      if(this.vechileDetails[0].total_fuel_used!=undefined )
			{			
				this.vechileDetails=this.vechileDetails[0].total_fuel_used;
				employees["vinnumber"] = Object.keys(this.vechileDetails);
				employees["evetvalues"] = <any>Object.values(this.vechileDetails);
				console.log(JSON.stringify(employees));
				//alert(this.vechileDetails.length);

				if(employees["vinnumber"]!=undefined && employees["vinnumber"].length>0)
				{
					for(let i =0; i < employees["vinnumber"].length;i++) {			  

					jsonObj2["eventDateTime"]=employees["vinnumber"][i];									  
					jsonObj2["spnName"]="Total Fuel Used";
					jsonObj2["value"]=employees["evetvalues"][i];								  
					jsonObj2["spnNumber"]=250;									  
					this.event_array.push(jsonObj2);
					jsonObj2 = {};	

					}

				}
			}         
                        
		                this.spinnerService.hide(); 
						if(this.event_array.length>0)
						{
							this.resultCheck=true;

						}else{

							alert("No Records Found");
						}
		 
		 
	  }
      
    }, (err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert(message);
      }
      else {
        alert('Please try again');
      }
    });
	
	

  }
  downloadAsExcelFile() {
	   this.pgnNo=this.selectedSPN_array.toString();
	  if(this.editStartDate === undefined && this.editEndDate !== undefined )
	{
		alert('Please enter proper date in the Start date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate !== undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate === undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the Start date and End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}

    if ((this.editStartDate === undefined || this.editStartDate === '' || this.editStartDate === null) ||
      (this.editEndDate === undefined || this.editEndDate === '' || this.editEndDate === null) ||
      (this.searchData === undefined || this.searchData === '' || this.searchData == '' || this.searchData == null ) ||
      (this.pgnNo === undefined || this.pgnNo === '' || this.pgnNo == '' || this.pgnNo == null ) ) {
        alert('Please fill all mandatory fields.');

    }
    else {

     
     

		if (window.confirm("Do you want to download report?")) 
	   {
		   
		    this.callReportXLApi();
           // alert("Notification created successfully");
			//this.reset1();
        } 
		else 
		{
            alert("Report download has been cancelled");
			
        }

    }

    
  } 
  
  
   callReportXLApi() {
	  
this.spinnerService.show();

//https://cvp-dev.api.tatamotors/cvp/telemetry-parameter/csv/PROACC12091912303?fromDate=2020-08-31T09:00:00&toDate=2020-08-31T10:00:00&teleParam=total_distance,total_fuel_used
  
	var datastring="telemetry-parameter/csv/"+this.searchData+"?fromDate="+this.editStartDate+"&toDate="+this.editEndDate+"&teleParam="+this.pgnNo;//64892  61441
	
	//this.baseAPI.callServiceApi(this.baseAPI.baseUrl,datastring).then(data => {
 myExtObject.firebaseEvent('Fuel and Odometer', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"XL Download Click"});
setTimeout(() => {
   
	this.baseAPI.callServiceApiXL(this.baseAPI.baseUrl,datastring).then((data:any) => {     
	 
	  let tempEvent:any;     
      var employees = {};
	  	let jsonObj2 = {}; 
       this.globalData=[];
	   
	            var downloadlink='data:attachment/csv;charset=utf-8,' + encodeURI(data);
				 // this.downloadPDF(res.data.url);
				  var datastring="fuelOdometer_"+this.searchData+"_"+this.editStartDate+"_"+this.editEndDate+".csv";//xlsx
					var link = document.createElement('a');
					link.setAttribute("type", "hidden");
					link.download = datastring;
					link.href =downloadlink;
					console.log(link.href);
					document.body.appendChild(link);
					link.click();
   /*    if(data.Information!=undefined)
	  {
		  
		  
		  alert(data.Information);
		  this.spinnerService.hide();
		  
	  }else{
		  
		  
	  
	  this.vechileDetails=<any>Object.values(data);	  
			//console.log(JSON.stringify(this.vechileDetails));  
			if(this.vechileDetails[0].total_distance!=undefined )
			{

				this.vechileDetails=this.vechileDetails[0].total_distance;
				employees["vinnumber"] = Object.keys(this.vechileDetails);
				employees["evetvalues"] = <any>Object.values(this.vechileDetails);
				//console.log(JSON.stringify(employees));
				//alert(this.vechileDetails.length);

				if(employees["vinnumber"]!=undefined && employees["vinnumber"].length>0)
				{
					for(let i =0; i < employees["vinnumber"].length;i++) {			  

													  
					jsonObj2["SPN Name"]="Total Distance";
					jsonObj2["Event DateTime"]=employees["vinnumber"][i];	
					jsonObj2["value"]=employees["evetvalues"][i];								  
					jsonObj2["SPN Number"]=516104;									  
					this.globalData.push(jsonObj2);
					jsonObj2 = {};	

					}

				}
			}
			this.vechileDetails=<any>Object.values(data);
      if(this.vechileDetails[0].total_fuel_used!=undefined )
			{			
				this.vechileDetails=this.vechileDetails[0].total_fuel_used;
				employees["vinnumber"] = Object.keys(this.vechileDetails);
				employees["evetvalues"] = <any>Object.values(this.vechileDetails);
				console.log(JSON.stringify(employees));
				//alert(this.vechileDetails.length);

				if(employees["vinnumber"]!=undefined && employees["vinnumber"].length>0)
				{
					for(let i =0; i < employees["vinnumber"].length;i++) {			  

													  
					jsonObj2["SPN Name"]="Total Fuel Used";
					jsonObj2["Event DateTime"]=employees["vinnumber"][i];	
					jsonObj2["value"]=employees["evetvalues"][i];								  
					jsonObj2["SPN Number"]=250;									  
					this.globalData.push(jsonObj2);
					jsonObj2 = {};	

					}

				}
			}         
	  
	 
	 
	  this.spinnerService.hide();
        if(this.globalData.length>0)
	   {
		   
	       this.generateXl();
	   }else{
		   
		   alert("No Records Found");
	   }	
	  
	  
	  }
    
	  
	   
	   */
	  
	  //this.dealerOffset=this.dealerOffset+21;
    }, (err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert(message);
      }
      else {
        alert('Please try again');
      }
    });	
	
 
}, 5000);
  setTimeout(() => {
   
  }, 10000);

    
  } 
 
  
generateXl()
{
	 
		this.spinnerService.show();
		var ws = XLSX.utils.json_to_sheet(this.globalData);
		var wb = XLSX.utils.book_new(); XLSX.utils.book_append_sheet(wb, ws, "Telematics Data");
		var datastring="fuelOdometer_"+this.searchData+"_"+this.editStartDate+"_"+this.editEndDate+".xlsx";
		XLSX.writeFile(wb, datastring);
		this.globalData = [];
		var a = 0;
		this.spinnerService.hide();
	
}
 


  ngOnInit() {
	  //  this.monthMax=this.monthMax-1;
		this.max=new Date();
	 //  this.max = new Date(this.yearMax, this.monthMax, this.dateMax, this.Hours, this.getMinutes);
	  //this.spinnerService.show();  
	  
	  //this.dropdownSettings: IDropdownSettings{};

//spn ,
	  
	     this.dropdownList = [
		 { item_id: 'total_distance', item_text: ' 65361 / 516104 : Prop_IC_RunInfo - IC_Total_Distance'},
		 { item_id: 'total_fuel_used', item_text: ' 65257 / 250 : LFC1 - Total fuel used'}
     
    ];
    this.selectedItems = [
      //{ item_id: 3, item_text: 'Pune' },
      //{ item_id: 4, item_text: 'Navsari' }
    ];
    this.dropdownSettings={
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
	  limitSelection: 10,
      allowSearchFilter: true
    };
  }
  onItemSelect(item: any) {
	  
	
	  this.selectedSPN_array=[];
   // console.log(item);
	  console.log(JSON.stringify(this.selectedItems));
	  for(let i =0; i < this.selectedItems.length;i++) {
		
		 this.selectedSPN_array.push(this.selectedItems[i].item_id);
		 
	 }
	 
	
  }
  
   onItemDeSelect(item: any) {
	  
	
	 
   // console.log(item);
   
	console.log(JSON.stringify(this.selectedItems));
	for( var i = 0; i < this.selectedSPN_array.length; i++){
		if ( this.selectedSPN_array[i] === item.item_id) { 
		
		this.selectedSPN_array.splice(i, 1); 
		
		
		}
		
		}
	
	
  }
  
  
  
  
  onSelectAll(items: any) {
    console.log(items);
  }
        }

       
 

