import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
//import { PlyrComponent } from 'ngx-plyr';
import { LoginService } from 'src/app/login.service';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { Router } from '@angular/router';
import { BaseApiService } from 'src/app/base-api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {
    username:string = '';
  role_type:string='';
  dse_name:string='';
  loggedInnUser:boolean = true;
  user=[];
  rsmlogin=false;
  feclogin=false;
  nationallogin=false;
  ActiveFalg:number=-1;
  public showMoreLink: boolean = false;
  public fleetMasterLinks: boolean = false;
  public notifiyDD: boolean = false;
  public userProfileDD: boolean = false;
  public sosNotifyDD: boolean = false;
  public showOverlay: boolean = false;
  public show: boolean = false;
  public isdisabled: boolean = false;
  sidebarfalg:boolean=false;

  // @ViewChild('plyr',{read:PlyrComponent,static:false})plyrComp:PlyrComponent;
  //@ViewChild( { static: false })
  //plyr: PlyrComponent;
//PlyrComponent,
  options = {
    'autoplay': false
  }

  // plyr: PlyrComponent;

  // player: Plyr;

  audioSources = [
    {
      src: 'assets/sos-msg/sample-audio.mp3',
      type: 'audio/mp3',
    }
  ];

  audioSources1 = [
    {
      src: 'https://cdn.plyr.io/static/demo/Kishi_Bashi_-_It_All_Began_With_a_Burst.mp3',
      type: 'audio/ogg',
    },
  ];

  mobileDevice: boolean = false;

  constructor(private router: Router,private confirmationService: ConfirmationService,public ls: LoginService,public baseservice:BaseApiService) {
	  this.user=JSON.parse(localStorage.getItem('role_access'));
    //this.user={mail:'mail@mail.com',password:'123',roles:['admin','customer']}
	   var dse_name = localStorage.getItem("username"); 
     var position_type = localStorage.getItem("position_type"); 
    
     if(position_type=='Fleetedge RSM'){
        this.rsmlogin=true;
       
     }else if(position_type=='Fleetedge Champion'){
        
        this.feclogin=true;
     }
     else if(position_type=='Fleetedge National head'){
      this.nationallogin=true;
     }
//alert(str)
      this.dse_name = dse_name;	  
	  var token =localStorage.getItem('token');
    console.log(typeof token!=='string' && localStorage.length===0);
	       if(token!=undefined && token!='' ){
			  //this.loggedInnUser=false;
			 
			}

  }

  ngOnInit() {
	  
	   var token =localStorage.getItem('token');
    	if(token!=undefined && token!='' ){
			  this.loggedInnUser=true;
			  
			  console.log("  ngOnInit  ");
			}
			
    // perfect scrollbar mobile disable
    var wd = window.innerWidth;
    if (wd < 1170) {
      this.isdisabled = true;
    }
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e) {
  //   if (window.pageYOffset > 52) {
  //     document.querySelector(".cp-sidebar").classList.add('sticky-nav');
  //     document.querySelector("body").classList.add('sticky-nav');
  //   } else {
  //     document.querySelector(".cp-sidebar").classList.remove('sticky-nav');
  //     document.querySelector("body").classList.remove('sticky-nav');
  //   }
  // }

  toggleMoreLink(event) {
    event.stopPropagation();
    if (this.showMoreLink) {
      this.showMoreLink = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    }
    else {
      document.querySelector("body").classList.add("overflow-hidden");
      this.showMoreLink = true;
    }
  }

  toggleLinks() {
    this.fleetMasterLinks = !this.fleetMasterLinks;
  }





  toggleNotify(event) {
    event.stopPropagation();
    this.showMoreLink = false;
    this.userProfileDD = false;
    this.sosNotifyDD = false;
    if (this.notifiyDD) {
      this.notifiyDD = false;
      this.showOverlay = false;
    }
    else {
      this.notifiyDD = true;
      this.showOverlay = true;
    }
  }

  toggleSosNotify(event) {
    event.stopPropagation();
    this.showMoreLink = false;
    this.userProfileDD = false;
    this.notifiyDD = false;
    if (this.sosNotifyDD) {
      this.sosNotifyDD = false;
      this.showOverlay = false;
    }
    else {
      this.sosNotifyDD = true;
      this.showOverlay = true;
    }
  }

  toggleUserProfile(event) {
    event.stopPropagation();
    this.sosNotifyDD = false;
    this.showMoreLink = false;
    this.notifiyDD = false;
    if (this.userProfileDD) {
      this.userProfileDD = false;
      this.showOverlay = false;
    }
    else {
      this.userProfileDD = true;
      this.showOverlay = true;
    }
  }

  overlayClick(event) {
    event.stopPropagation();
    this.sosNotifyDD = false;
    this.userProfileDD = false;
    this.notifiyDD = false;
    this.showOverlay = false;
  }

  public citiesDD = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' }
  ];


  cities = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' }
  ]

  // selectedCity: City;

  // qty section START

  qty: number = 0;

  incrementQty() {
    this.qty += 1;

  }
  decrementQty() {
    if (this.qty > 0) {
      this.qty -= 1;
    }
  }

  // qty section END





  rangeVal: number = 0;

  // auto-complete dd

  locations: string[] = ['mumbai', 'delhi', 'Pune'];

  filteredLocations: any[];
  location: string;

  filterLocations(event) {
    this.filteredLocations = [];
    for (let i = 0; i < this.locations.length; i++) {
      let location = this.locations[i];
      if (location.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredLocations.push(location);
      }
    }
  }



  // from - to location swap START
  fromLocations: string[] = ['mumbai', 'delhi', 'Pune'];

  filteredfromLocations: any[];
  fromLocation: string;

  filterfromLocations(event) {
    this.filteredfromLocations = [];
    for (let i = 0; i < this.fromLocations.length; i++) {
      let fromLocation = this.fromLocations[i];
      if (fromLocation.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredfromLocations.push(fromLocation);
      }
    }
  }

  toLocations: string[] = ['mumbai', 'delhi', 'Pune'];

  filteredtoLocations: any[];
  toLocation: string;

  filtertoLocations(event) {
    this.filteredtoLocations = [];
    for (let i = 0; i < this.toLocations.length; i++) {
      let toLocation = this.toLocations[i];
      if (toLocation.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredtoLocations.push(toLocation);
      }
    }
  }

  swapLocationFn() {
    let oldFromLocation = this.fromLocation;
    let oldToLocation = this.toLocation;
    this.toLocation = oldFromLocation;
    this.fromLocation = oldToLocation;
  }
  // from - to location swap END


  // range date selection - type 2 - START

  fromDateVal: Date;
  toDateVal: Date;
  minDate = new Date();
  getFromDate() {
    let selectedFromDate = this.fromDateVal;
    this.minDate = selectedFromDate;
  }
  // range date selection - type 2 - END

  // Popup start

  showPopup: boolean = false;

  showDialog() {
    this.showPopup = true;
  }
  // Popup end

  onUpload(event) {
    console.log(event);
  }

   confirm() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to Log out?',
        accept: () => {
            this.logOut();
        }
    });
}
   isLoggedInUser() {
     this.loggedInnUser=true; 
  }

  logOut(){
    localStorage.clear();
    this.baseservice.RSMFEClogout();
	 this.loggedInnUser=false;
   this.router.navigate(['/']);
    // window.location.href="/cvpanalytics/#/login";
	 window.location.href="/";
  }
  getActive(flag:number)
  {
    this.ActiveFalg=flag;
  }
  togglesidebar(flag:boolean)
  {
    console.log(document.querySelector('nav.sidebar')?.classList.contains('collapsed'),"Vibahhc")
    this.sidebarfalg=!flag;
    if(this.sidebarfalg==true)
    {
      document.querySelector('nav.sidebar')?.classList.add('collapsed');
      console.log(this.sidebarfalg,'this.sidebarfalg')

    }
    else
    {
      document.querySelector('nav.sidebar')?.classList.remove('collapsed');
    }
  }

}
