<nav id="sidebar" class="sidebar">
    <div class="sidebar-content js-simplebar">
      <a class="sidebar-brand" href="index.html" style="padding-right: 25%!important;">
        <img src="assets/images/Fleet-Edge-Gradient.svg" alt="" class="sidebar-logo-new">
      </a>
      <ul class="sidebar-nav">
        
        <!-- <li class="sidebar-item active">
        <a  class="sidebar-link active">
        <img style="width: 25px;margin-right: 0.75rem;" src="assets/images/plan.png" alt="">
        <span class="align-middle">Dashboards</span>
        </a>
        </li> -->
        <li class="sidebar-item active"  *ngIf="user.includes('rsmdashboard')">
          <a  [routerLink]="['/rsmdashboard']" class="sidebar-link active" >
          <img style="width: 25px;margin-right: 0.75rem;" src="assets/images/plan.png" alt="">
          <span class="align-middle">Dashboards</span>
          </a>
          </li>
          <li class="sidebar-item active"  *ngIf="user.includes('nationalhead-dashboard')">
            <a  [routerLink]="['/nationalhead-dashboard']" class="sidebar-link active" >
            <img style="width: 25px;margin-right: 0.75rem;" src="assets/images/plan.png" alt="">
            <span class="align-middle">Dashboards</span>
            </a>
            </li>
        <!-- <li class="sidebar-item" routerLinkActive="active" *ngIf="user.includes('fecattributes')">
          <a  class="sidebar-link" [routerLink]="['/fecattributes']" routerLinkActive="active">
            <i class="align-middle" data-feather="layout"></i> <span class="align-middle">FEC Attributes</span>
          </a>
        </li>
        <li class="sidebar-item" routerLinkActive="active"  *ngIf="user.includes('fecattributes')">
          <a  [routerLink]="['/customerdetails']" class="sidebar-link"  routerLinkActive="active">
            <i class="align-middle"  data-feather="users"></i> <span class="align-middle">
              Customer Details
            </span>
          </a>
        </li> -->
        <!-- <li class="sidebar-item active" *ngIf="user.includes('fecdashboard')">
          <a  class="sidebar-link active" [routerLink]="['/fecdashboard']">
            <img style="width: 24px;
            margin-right: 0.5rem;white-space: nowrap;" src="assets/images/plan.png" alt="">
            <span class="align-middle">FEC Dashboard</span>
          </a>
        </li> -->
        <li class="sidebar-item active" *ngIf="user.includes('fecdashboard')">
          <a  class="sidebar-link active" [routerLink]="['/fectasks']">
            <img style="width: 24px;
            margin-right: 0.5rem;white-space: nowrap;" src="assets/images/plan.png" alt="">
            <span class="align-middle">Dashboard</span>
          </a>
        </li>
        <!-- <li class="sidebar-item"  *ngIf="user.includes('fectasks')">
          <a class="sidebar-link"  [routerLink]="['/fectasks']" >
          <i class="align-middle" data-feather="list"></i> <span class="align-middle">FEC Tasks</span>
           </a>
        </li> -->

        <li class="sidebar-item" routerLinkActive="active" *ngIf="user.includes('aggregation')">
          <a class="sidebar-link"  [routerLink]="['/aggregation']" routerLinkActive="active">
          <i class="align-middle" data-feather="list"></i> <span class="align-middle">Fleet Users</span>
           </a>
        </li>
        
        <li class="sidebar-item" routerLinkActive="active" *ngIf="user.includes('fecassignment')">
          <a class="sidebar-link"  [routerLink]="['/fecassignment']" routerLinkActive="active">
          <i class="align-middle" data-feather="list"></i> <span class="align-middle">FEC</span>
           </a>
        </li>
        
        <li class="sidebar-item" routerLinkActive="active" *ngIf="user.includes('assigndata')">
          <a class="sidebar-link"  [routerLink]="['/assigndata']" routerLinkActive="active">
          <i class="align-middle" data-feather="list"></i> <span class="align-middle">Assign Data</span>
           </a>
        </li>

        <li class="sidebar-item" routerLinkActive="active" *ngIf="user.includes('performance')">
          <a class="sidebar-link"  [routerLink]="['/performance']" routerLinkActive="active">
          <i class="align-middle" data-feather="list"></i> <span class="align-middle">Performance Data</span>
           </a>
        </li>

        <li class="sidebar-item" routerLinkActive="active" *ngIf="user.includes('maintenancedata')">
          <a class="sidebar-link"  [routerLink]="['/maintenancedata']" routerLinkActive="active">
          <i class="align-middle" data-feather="list"></i> <span class="align-middle">Maintenance Data</span>
           </a>
        </li>
      </ul>
    </div>
  </nav>