import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseApiService } from 'src/app/base-api.service';
import { FormGroup, FormsModule, FormControl, Validators, ValidatorFn, FormBuilder, ReactiveFormsModule, AbstractControl } from "@angular/forms";
import { ExcelService } from 'src/app/excel-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
@Component({
  selector: 'app-customerdetails',
  templateUrl: './customerdetails.component.html',
  styleUrls: ['./customerdetails.component.scss']
})
export class CustomerdetailsComponent implements OnInit {
  currentDate = new Date();
  month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  FecData = [];
  selectedDate:any;
  fromdate:any;
  todate:any;
  Monthdate: Date | null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private excelService: ExcelService,
    private sharedService: SharedService,
    private baseAPIService: BaseApiService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,

  ) { }

  ngOnInit(): void {
   
    this.baseAPIService.DataRSMObj.subscribe(res => {
      if(isJson(res)==false)
      {
        console.log(res,'res')
        this.fromdate=this.sharedService.dateFormater(res['fromdate']);
        this.todate=this.sharedService.dateFormater(res['todate']);
        this.selectedDate=res['monthFilter'];
        this.Monthdate=res['monthFilter'];
        this.Fecdata();       
      }
      else
      {
        let obj=JSON.parse(res);
        this.selectedDate=obj.monthFilter;
        this.Monthdate=obj.monthFilter;
        this.fromdate=obj.fromdate;
        this.todate=obj.todate;
        this.Fecdata();       
      }
    });
  }
  Fecdata() {
   var requestURL = "fecportal/fec/list/";
   var user_id:string = localStorage.getItem("FEC")
   console.log(user_id,'user_id')
   let requestPayloadFec:any={};
   requestPayloadFec['fec_user_id']=user_id;
   requestPayloadFec['todate']=this.todate;
   requestPayloadFec['fromdate']=this.fromdate;
    this.baseAPIService.callServiceApi(this.baseAPIService.baseUrl + requestURL, requestPayloadFec).then((response: any) => {
      this.FecData = response;
      console.log("FecData" + this.FecData);
      this.spinnerService.hide();
    }, (error) => {
      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(error));
      var message = jsonObj.msg;
      if (message != undefined) {
        alert(message);
      } else {
        // alert('Unable to process request. Please try again');
      }
    });
  }
  downloadExcelTemplate():void {
    var jsonHeader = [{
      "company_name":"",
      "account_type":"",
      "industry_type":"",
      "first_name":"",
      "last_name":"",
      "region":"",
      "state":"",
      "city":"",
      "contact":"",
      "email_id":"",
      "month":"",
      "year":"",     
      "location":"",
      "purpose_of_visit":"",
       "remark":""
      
    }]
    this.excelService.exportAsExcelFile(jsonHeader, "bulk_upload_excel_template");
  }
  async downloadExcelFormat()
  {
    let dropdownlist=['1','2','3','4','5','6','7','8','9','10','11','12'];
    let accountTypeOptions =['Key Account','Retail Account','Others'];
    let region =['West','East','North','South'];

    const workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Products Data');
    const header = ["company_name", "account_type","industry_type","first_name","last_name","region","state","city",
    "contact","email_id","month", "year","location", "purpose_of_visit","remark"
  ];
  // const header = ["champ_id", "company_name", "account_type"];
    // const title = 'Car Sell Report';
    // let titleRow = worksheet.addRow([title]);
    // Set font, size and style in title row.
    // titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
    // Blank Row
    // worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    // headerRow.eachCell((cell, number) => {
    // cell.fill = {
    // type: 'pattern',
    // pattern: 'solid',
    // fgColor: { argb: 'FFFFFF00' },
    // bgColor: { argb: 'FF0000FF' }
    // }
    // cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    // });
    // Add Data and Conditional Formatting
    let joineddropdownlist = "\""+dropdownlist.join(',')+"\"";
    let joinedaccountTypeOptions = "\""+accountTypeOptions.join(',')+"\"";
    let joinedregion = "\""+region.join(',')+"\"";

    console.log(joineddropdownlist);
    for(let i=1;i<100;i++){
    worksheet.getCell('K'+i).dataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: [joineddropdownlist]//'"One,Two,Three,Four"'
    };
    worksheet.getCell('B'+i).dataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: [joinedaccountTypeOptions]//'"One,Two,Three,Four"'
    };
    worksheet.getCell('F'+i).dataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: [joinedregion]//'"One,Two,Three,Four"'
    };
    }
    
    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, 'Upload_FEC_Format.xlsx');
    });
  }
  addDateFilter() {
    // console.log(this.Monthdate,'Monthdate')
    let x= new Date(this.Monthdate);
    let t=new Date(x.getFullYear(), x.getMonth() + 1, 0);
    let f=new Date(x.getFullYear(), x.getMonth(), 1);
    this.fromdate= this.sharedService.dateFormater(f);
    this.todate= this.sharedService.dateFormater(t);
    let obj={'fromdate':this.fromdate,'todate':this.todate,'monthFilter':this.Monthdate};
    this.baseAPIService.RSMDateRange(obj);
    if(!this.Monthdate){
      this.Monthdate = this.sharedService.dateFormater(this.currentDate);
    }
    this.Fecdata();       
    this.modalService.dismissAll();
  }
  openMonthModel(mySelectMonthModal:any)
  {
    this.Monthdate= new Date(this.Monthdate);
    this.modalService.open(mySelectMonthModal, { size: "sm",centered: true });
  }
}
function isJson(str:any) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return isNaN(str);
}
function someFunction(site)     
{     
    return site.replace(/\/$/, "");
} 