<div data-theme="default" data-layout="fluid" data-sidebar-position="left" data-sidebar-behavior="sticky">
  <div class="wrapper">
		<app-sidebar></app-sidebar>
		<div class="main">
			<app-header></app-header>
			<main class="content">
        <div class="container-fluid p-0">

					<div class="row mb-2 mb-xl-3">
						<div class="col-auto">
							<h3 class="dash-text">DASHBOARD</h3>
						</div>

						<div class="col-auto ms-auto text-end mt-n1">
                <button class="btn date-btn" data-bs-toggle="modal" data-bs-target="#mySelectMonthModal">
                  <a data-bs-toggle="modal" data-bs-target="#myDateRangeModal">
                    <span class="icon icon-calendar"></span> 
                    <span  class="year-text" style="white-space: nowrap;margin-left: 4px;">{{filterStartDate | date:'dd'}} {{filterStartDate | date:'MMM'}}
                    {{filterStartDate | date:'YYYY'}} - {{filterEndDate | date:'dd'}} {{filterEndDate | date:'MMM'}}
                    {{filterEndDate | date:'YYYY'}}</span>
                  </a>
                </button>
						</div>
					</div>
        </div>
        <div class="row">
          <div id="carouselExampleControlid"  class="carousel slide carousel-dark" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="card-wrapper">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6 " (click)="toggleListing('completed_visits')">
                      <div class="listing-card" [ngClass]="(currentTab == 'completed_visits')? 'active':''">
                        <span class="dashfont">
                          <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">{{ completedVisits }}
                        </span>
                        <br><br>
                        <span class="boxtext">Completed Visits</span>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6" (click)="toggleListing('scheduled_visits')">
                      <div class="listing-card" [ngClass]="(currentTab == 'scheduled_visits')? 'active':''">
                        <span class="dashfont">
                          <img src="assets/images/iconset/assigned.svg" style="width: auto;margin-right: 7px;" alt="">{{ scheduledVisits }}
                        </span>
                        <br><br>
                        <span class="boxtext">Scheduled Visits</span>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6" (click)="toggleListing('unscheduled_visits')">
                      <div class="listing-card" [ngClass]="(currentTab == 'unscheduled_visits')? 'active':''">
                        <span class="dashfont">
                          <img src="assets/images/iconset/visit-completed.svg" style="width: auto;margin-right: 7px;" alt="">{{ unscheduledVisits }}
                        </span>
                        <br><br>
                        <span class="boxtext">Unscheduled Visits</span>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6" (click)="toggleListing('overdue_visits')">
                      <div class="listing-card" [ngClass]="(currentTab == 'overdue_visits')? 'active':''">
                        <span class="dashfont">
                          <img src="assets/images/iconset/visit-scheduled.svg" style="width: auto;margin-right: 7px;" alt="">{{ overdueVisits }}
                        </span>
                        <br><br>
                        <span class="boxtext">Overdue Visits</span>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="card-wrapper">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="listing-card">
                        <span class="dashfont">
                          <img src="assets/images/iconset/visit-unscheduled.svg" style="width: auto;margin-right: 7px;" alt="">{{ salesOppurtunities }}
                        </span>
                        <br><br>
                        <span class="boxtext">Sales Opportunity</span>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6 " (click)="toggleListing('completed_visits')">
                      <div class="listing-card" [ngClass]="(currentTab == 'completed_visits')? 'active':''">
                        <span class="dashfont">
                          <img src="assets/images/iconset/total-fec.svg" style="width: auto;margin-right: 7px;" alt="">{{ completedVisits }}
                        </span>
                        <br><br>
                        <span class="boxtext">Completed Visits</span>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6" (click)="toggleListing('scheduled_visits')">
                      <div class="listing-card" [ngClass]="(currentTab == 'scheduled_visits')? 'active':''">
                        <span class="dashfont">
                          <img src="assets/images/iconset/assigned.svg" style="width: auto;margin-right: 7px;" alt="">{{ scheduledVisits }}
                        </span>
                        <br><br>
                        <span class="boxtext">Scheduled Visits</span>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6" (click)="toggleListing('overdue_visits')">
                      <div class="listing-card" [ngClass]="(currentTab == 'overdue_visits')? 'active':''">
                        <span class="dashfont">
                          <img src="assets/images/iconset/visit-scheduled.svg" style="width: auto;margin-right: 7px;" alt="">{{ overdueVisits }}
                        </span>
                        <br><br>
                        <span class="boxtext">Overdue Visits</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleControlid" role="button" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControlid" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
                </a>
          </div>
        </div>
        <br>
        <div class="row mb-2 mb-xl-3">
          <div class="col-auto">
            <h3 class="tbl-text">
              <ng-container *ngIf="(currentTab == 'total_fec')">
                Fleet Edge Champs
              </ng-container>
              <ng-container *ngIf="(currentTab == 'assigned_visits')">
                Total Assigned Visits
              </ng-container>
              <ng-container *ngIf="(currentTab == 'completed_visits')">
                Total Completed Visits
              </ng-container>
              <ng-container *ngIf="(currentTab == 'scheduled_visits')">
                Total Scheduled Visits
              </ng-container>
              <ng-container *ngIf="(currentTab == 'unscheduled_visits')">
                Total Unscheduled Visits
              </ng-container>
              <ng-container *ngIf="(currentTab == 'overdue_visits')">
                Total Overdue Visits
              </ng-container>
            </h3>
          </div>
         
          <div class="col-auto ms-auto text-end mt-n1" style="display: flex;">
            <div class="down-padding"><button class="btn download-list-btn" (click)="downloadMasterData()">
              <!-- <i class="align-middle" data-feather="download">&nbsp;&nbsp;</i> -->
              <svg xmlns="http://www.w3.org/2000/svg" 
              width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-download align-middle">
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="7 10 12 15 17 10"></polyline>
              <line x1="12" y1="15" x2="12" y2="3"></line>
              </svg>
              <span style="white-space: nowrap;margin-left: 4px;">Download Master</span>
              </button></div>
            &nbsp;
            <div style="margin-top:2%;">
              <div class="input-group input-group-search input-group-width">
                <button class="btn" type="button">
                  <i class="align-middle" data-feather="search"></i>
                </button>
                <input type="text" class="form-control" placeholder="Search by Customer Name / City" pInputText [(ngModel)]="searchByString" (change)="searchByFECName()">
              </div>
            </div>
          </div>
        </div>



        <section>
            <div class="cp-section">    
                <div class="table-responsive">
                <table p-table p-table class="table table-cont">
                  <thead>
                  <tr class="table_heading">
                  <th>Sr.No</th>
                  <th>Customer Name</th>
                  <th>City</th>
                  <th>Contact No</th>
                  <th>Fleet Size</th>
                  <th>Sales Opportunity</th>
                  </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="fleetEdgeChampList && fleetEdgeChampList.length">
                      <tr class="table_data"
                        *ngFor="let champ of fleetEdgeChampList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems:totalItems}; let i = index;">
                        <td>{{i+1}}</td>
                        <td>{{champ.company_name | titlecase}}</td>
                        <td>{{champ.city}}</td>
                        <td>{{champ.contact}}</td>
                        <td>-</td>
                        <td>
                          <div style="width:9rem; background: #f6f3f3;" *ngIf="!(champ.sales_opty)">&nbsp; &nbsp;</div>
                          <div style="width:9rem;" *ngIf="champ.sales_opty" [style]="{background: champ.sales_opty}">&nbsp; &nbsp;</div>
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="!(fleetEdgeChampList && fleetEdgeChampList.length)">
                      <tr class="table_data">
                        <td class="norecords" colspan="5">No Records Found</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
          
                <div class="sec-head full-width-lw c" style="float:right;">
                  <div class="right-wrap ">
                    <ul class="act-list typ-multiline">
                      <li class="act-item" style="padding-top:5px;">
                        <pagination-controls (pageChange)="pageChanged($event)" *ngIf="resultCheck"></pagination-controls>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
                <div class="modal fade" id="myDateRangeModals" role="dialog">
                  <div class="modal-dialog">
            
                    <!-- Modal content-->
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title">Select Date Range Filter</h4>
                        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
                      </div>
                      <div class="modal-body">
                        <form class="ui form" name="sheduleTaskForm">
                          <div class="row col-md-12">
            
                            <div class="form-group col-md-6 offset-3">
                              <label class="lable-text" for="">From Date<span style="color:red;">*</span></label>
                              <input type="date" class="form-control sdate" [(ngModel)]="filterStartDate" name="sdate" id="sdate" dateInput>
                            </div>
                            <div class="form-group col-md-6 offset-3">
                              <label class="lable-text" for="">To Date<span style="color:red;">*</span></label>
                              <input type="date" class="form-control sdate" [(ngModel)]="filterEndDate" name="tdate" id="tdate" dateInput>
                            </div>
                            
                            <div class="form-group col-md-6 offset-3">
                              <label class="lable-text" for=""> </label>
                            </div>
            
                            <div class="form-group col-4 offset-4 submit-button-div">
                              <button type="button" class="btn btn-default submit-button" (click)="searchDatetime()"
                              data-bs-dismiss="modal">Apply</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal fade" id="myDateRangeModal" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">Select Date Range Filter</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body m-3">
                        <div class="mb-3">
                          <label class="form-label">From Date</label>
                          <input type="date" class="form-control" [(ngModel)]="filterStartDate" name="sdate" id="sdate" dateInput>
                        </div>
                        <div class="mb-3">
                          <label class="form-label">To Date</label>
                          <input type="date" class="form-control" [(ngModel)]="filterEndDate" name="tdate" id="tdate" dateInput>
                        </div>
    
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-apply" (click)="searchDatetime()">Apply</button>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
          </section>          
      </main>
    </div>
</div>
</div>







  









<!--footer>
            Copyright &#169; 2020 TATA MOTORS All Rights Reserved.
        </footer-->